import React, { useState, FormEvent, useContext, useEffect } from "react";
import BreadcrumbHeader from "components/common/BreadcrumbHeader";
import { FormattedMessage, useIntl } from "react-intl";
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from "reactstrap";
import EmailNotificationsTable from "./EmailNotificationsTable";
import classNames from "classnames";
import { AppContext } from "services/appContext/AppContext";
import { HashRouter, Route } from "react-router-dom";
import Navbar from "components/common/navBar/NavBar";
import UrlNotificationsTable from "./UrlNotificationsTable";

export default function WebhookLogs() {
    const [searchValue, setSearchValue] = useState("");
    const [searchInputValue, setSearchInputValue] = useState("");
    const [focusedSearchInput, setFocusedSearchInput] = useState(false);
    const [errorMessageId, setErrorMessageId] = useState("");
    const intl = useIntl();
    const { appContext } = useContext(AppContext);
    const navTitlesRoutesAdmin = [
        { routes: '/UrlNotifications', titles: 'URL Notifications' },
        { routes: '/EmailNotifications', titles: 'Email Notifications' },
        // Add more navigation items as needed
      ];
    const navTitlesRoutesUser = [
    { routes: '/UrlNotifications', titles: 'URL Notifications' },
    // Add more navigation items as needed
    ];
    const adminAuthorized = appContext.localStorageInfo.user?.permissions.includes("Admin");
    const loginBasedRoutes = adminAuthorized ? navTitlesRoutesAdmin : navTitlesRoutesUser;

    useEffect(() => { setSearchValue(''); setSearchInputValue(''); }, [appContext.localStorageInfo.selectedProfile]);

    // const handleSubmit = (e: FormEvent) => {
    //     e.preventDefault();
    //     const inputValue = searchInputValue.trim();
    //     const errorMessageIdResult = validateSearchValue();
    //     setErrorMessageId(errorMessageIdResult);

    //     if (!errorMessageIdResult) {
    //         setSearchValue(inputValue);
    //     }
    // };

    // const handleSearchChange = (searchValue: string) => {
    //     setErrorMessageId("");
    //     setSearchInputValue(searchValue);
    // };

    return (
        <>
            <BreadcrumbHeader>
                <FormattedMessage id="webhookLogs.title" />
            </BreadcrumbHeader>
            {/* <Container className="mb-5">
                <Row>
                    <Col md="6" className="offset-md-3">
                        <h1 className="display-3 pc-network-explorer-bg">
                            <FormattedMessage id="tradingPartners.title" />
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col md="6" xs="6" className="mb-1 offset-md-3">
                        <Form onSubmit={handleSubmit}>
                            <FormGroup
                                className={classNames("input-group-merge pc-search-container mb-0", {
                                    focused: focusedSearchInput
                                })}>
                                <InputGroup className="input-group-merge">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-search" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        type="text"
                                        className="pc-search"
                                        placeholder={intl.formatMessage({ id: "tradingPartners.search.placeholder" })}
                                        value={searchInputValue}
                                        onChange={(e) => handleSearchChange(e.currentTarget.value)}
                                        onFocus={() => setFocusedSearchInput(true)}
                                        onBlur={() => setFocusedSearchInput(false)}
                                    />
                                </InputGroup>
                                <Button color="warning" type="submit" className="pc-btn ml-4">
                                    <FormattedMessage id="tradingPartners.search.button" />
                                </Button>
                            </FormGroup>
                            {errorMessageId && (
                                <div className="text-red text-sm mt-1">
                                    <FormattedMessage id={errorMessageId} />
                                </div>
                            )}
                        </Form>
                    </Col>
                    <Col className="mb-1 mr-5">
                    </Col>
                </Row>
            </Container> */}
            <Container fluid>
                <HashRouter>
                <Navbar navTitlesRoutes= {loginBasedRoutes}/>
                  <switch>
                    <Route path="/EmailNotifications" exact render={(props) => (
                        <EmailNotificationsTable search={searchValue} />
                    )}/>
                    <Route path="/UrlNotifications" exact render={(props) => (
                        <UrlNotificationsTable search={searchValue} />
                    )}/>
                  </switch>
                </HashRouter>
            </Container>            
        </>
    );
}

const validateSearchValue = (): string => {
    return "";
};