import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";
import InputError from "components/framework/errorHandling/InputError";
import { ErrorHandler } from "services/customHooks/useErrors";

type Props = {
  labelTranslationId: string;
  value: string;
  errorHandler: ErrorHandler;
  className: string;
  placeholderTranslationId?: string;
  handleInputChange: (value: string) => void;
};

export default function PasswordFormInput(props: Props) {
  const intl = useIntl();
  const errors = props.errorHandler.getErrors();
  const placeholder = props.placeholderTranslationId ? intl.formatMessage({ id: props.placeholderTranslationId }) : "";

  return (
    <FormGroup className={classNames("mb-2", props.className)}>
      <Label className="form-control-label">
        <FormattedMessage id={props.labelTranslationId} />
      </Label>
      <Input
        type="password"
        className={classNames("form-control form-control-sm", {
          "pc-invalid-input": errors && errors.length > 0
        })}
        placeholder={placeholder}
        value={props.value ? props.value : ""}
        onChange={e => {
          props.errorHandler.clear();
          props.handleInputChange(e.currentTarget.value);
        }}
        autoComplete={"new-password"}
      />
      <InputError errors={props.errorHandler.getErrors()} />
    </FormGroup>
  );
}
