import { HttpClient } from "../httpClient/HttpClient";
import { ApiError } from "./types/ApiError";
import { ServiceProviderProfile } from "./types/serviceProvider/ServiceProviderProfile";
import { SpInfo } from "./types/serviceProvider/SpInfo";

export const ServiceProviderApi = {
  get: (id: string): Promise<SpInfo> => {
    return HttpClient.get<SpInfo>(`serviceprovider/${id}`);
  },
  list: (): Promise<SpInfo[]> => {
    return HttpClient.get<SpInfo[]>("serviceprovider");
  },
  addOrUpdate: (serviceProvider: ServiceProviderProfile): Promise<ApiError> => {
    return HttpClient.put<ApiError>(`serviceprovider/${serviceProvider.spId}`, serviceProvider);
  },
  getLogo: (id: string): Promise<Blob | ApiError> => {
    return HttpClient.getResource(`serviceprovider/${id}/logo`);
  },
  uploadLogo: (id: string, file: File): Promise<Blob | ApiError> => {
    const data = new FormData();
    data.append("image", file);
    return HttpClient.putResource(`serviceprovider/${id}/logo`, data);
  }
};
