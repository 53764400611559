import React, { useState } from 'react';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import { useIntl, FormattedMessage } from 'react-intl';

export default function TooltipOnHover() {
    const intl = useIntl();
    const [popoverOpen, setPopoverOpen] = useState(false);

    const handleMouseEnter = () => {
        setPopoverOpen(true);
    };

    const handleMouseLeave = () => {
        setPopoverOpen(false);
    };

    return (
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <span id="popover-target">{intl.formatMessage({ id: "sidebar.network.tradingPartners" })}</span>
            <UncontrolledPopover placement="bottom" isOpen={popoverOpen} target="popover-target">
                <PopoverBody className='popover-body'>
                    {intl.formatMessage({ id: "sidebar.network.tradingPartners.tooltip.messageOne" })}
                    <span className='cursor-pointer'>
                        <u onClick={() => window.location.href = 'mailto:lnp@porting.com'}>
                            <FormattedMessage id="sidebar.network.tradingPartners.tooltip.mail" />
                        </u>
                    </span>
                    {intl.formatMessage({ id: "sidebar.network.tradingPartners.tooltip.messageTwo" })}
                </PopoverBody>
            </UncontrolledPopover>
        </div>
    );
};
