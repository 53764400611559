import React, { useState } from "react";
import NotificationDeleteConfirmationModal from "./NotificationDeleteConfirmationModal";
import { NotificationListItemDto } from "services/apis/types/webhooks/NotificationListItemDto";
import ActionDropdown from "components/networkExplorer/common/ActionDropdown";
import ActionDropdownItem from "components/networkExplorer/common/ActionDropdownItem";
import NotificationPauseAction from "./NotificationPauseAction";
import NotificationResumeAction from "./NotificationResumeAction";

type Props = {
  notificationDto: NotificationListItemDto;
};

export default function NotificationActionsCell(props: Props) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [actionsOpen, setActionsOpen] = useState(false);

  return (
    <>
      <ActionDropdown
        className="mr-1"
        actionsOpen={actionsOpen}
        actionsToggle={() => setActionsOpen((prevState) => !prevState)}>
        <ActionDropdownItem
          handleAction={() => setShowDeleteModal(true)}
          iconClassName="fa fa-trash-alt"
          labelId="notifications.table.actions.delete"
        />
      </ActionDropdown>
      {showDeleteModal && (
        <NotificationDeleteConfirmationModal
          id={props.notificationDto.connectionId}
          notificationName={props.notificationDto.name}
          closeModal={() => setShowDeleteModal(false)}
        />
      )}
      <span className="ml-1">
        {props.notificationDto.enabled ? (
          <NotificationPauseAction notificationDto={props.notificationDto} />
        ) : (
          <NotificationResumeAction notificationDto={props.notificationDto} />
        )}
      </span>
    </>
  );
}
