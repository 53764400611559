import { toast, ToastOptions } from "react-toastify";
import React from "react";
import NotificationReloadButtons from "components/framework/notification/NotificationReloadButtons";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";

export const versionChanged = async () => {
  let versionChanged = false;
  await fetch("manifest.json", { method: "GET" }).then((x: any) => {
    if (
      process.env.REACT_APP_BUILD &&
      x.version !== undefined &&
      x.version !== process.env.REACT_APP_BUILD
    ) {
      versionChanged = true;
    }
  });

  return versionChanged;
};

export const displayVersionNotification = (
  message: string,
  closeNotificationCallback: () => void
) => {
  const toastOptions: ToastOptions = {
    autoClose: false,
    draggable: false,
    position: toast.POSITION.BOTTOM_CENTER,
    closeButton: (
      <NotificationReloadButtons closeNotificationCallback={closeNotificationCallback} />
    ),
    className: "rounded text-white bg-info text-sm p-3 pc-min-width-550"
  };

  showInfoNotification(message, toastOptions);
};
