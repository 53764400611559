import React, { ReactNode } from "react";

export type Props = {
  name: string;
  children: ReactNode | string;
};

export default function TextRow(props: Props) {
  return (
    <tr>
      <td className="pr-3">{props.name}</td>
      <td className="font-weight-bold">{props.children}</td>
    </tr>
  );
}
