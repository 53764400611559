import { SupplementType } from "./SupplementType";
import { OrderType } from "./OrderType";
import { NumberStatusDto } from "./NumberStatusDto";
import { SvRequestStatus } from "components/orders/types/SvRequestStatus";
import { OrderMetadataDto, DefaultOrderMetadataDto } from "./OrderMetadata";
import { CustomerDetailsDto, DefaultCustomerDetailsDto } from "../port/CustomerDetailsDto";
import { DocumentDto } from "./DocumentDto";
import { OrderRequestType } from "../port/OrderRequestType";
import { DefaultOptionalDataInfo, OptionalDataInfo } from "../number/OptionalDataInfo";

export type OrderDto = {
  orderId: string;
  tenant: string;
  orderType: OrderType;
  gainingProvider: string;
  loosingProvider: string;
  csrRequested: boolean;
  preOrder: boolean;
  supplementVersion: number;
  npacRegion: string;
  status: SvRequestStatus;
  desiredDueDate: Date;
  focDate: Date;
  activationDate: Date;
  customerDetails: CustomerDetailsDto;
  numbers: NumberStatusDto[];
  documents: DocumentDto[];
  availableActions: string[];
  metadata: OrderMetadataDto;
  supplementType: keyof typeof SupplementType | null;
  optionalData:OptionalDataInfo;
  tnsInOtherOrders?: object;
  autoActivation?: string;
  activationTimeZone?: string;
  svId?: number;
  adminProjectId?: string;
  requestId?: string;
};

export const DefaultOrder: OrderDto = {
  orderId: "",
  tenant: "",
  npacRegion: "",
  gainingProvider: "",
  loosingProvider: "",
  csrRequested: false,
  orderType: OrderType.PortIn,
  status: SvRequestStatus.ActionRequired,
  preOrder: false,
  supplementVersion: 0,
  desiredDueDate: new Date(),
  focDate: new Date(),
  activationDate: new Date(),
  customerDetails: DefaultCustomerDetailsDto,
  numbers: [],
  documents: [],
  availableActions: [],
  metadata: DefaultOrderMetadataDto,
  supplementType: null, 
  optionalData: DefaultOptionalDataInfo,
  tnsInOtherOrders: {},
  autoActivation: "",
  activationTimeZone: "",
  svId: 0,
  adminProjectId: "",
  requestId: ""
};

export type PreviousOrderDto = OrderDto & {
  orderRequestType: OrderRequestType;
}
