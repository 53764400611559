import React, { useState } from "react";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import DnoSwitchModal from "./DnoSwitchModal";

type Props = {
  data: any;
};

export default function ToggleCheckbox(props: Props) {
  const [isChecked, setIschecked] = useState(props.data.dno);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  
  const handleCheckboxChange = (e) => {
    setShowModal(true);
  };
  
  return (
    <>
      <label className="switch">
        <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange}
        >
        </input>
        {loading &&
          <LoadingIndicator className="toggleLoader" small/>
        }
        <span className="slider"></span>  
      </label>

      {showModal && (
        <DnoSwitchModal
          closeModal={() => setShowModal(false)}
          checked={isChecked}
          setIschecked={setIschecked}
          data={props.data}
        />
      )}
    </>
  );
}
