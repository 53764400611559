import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import TextRow from "components/networkExplorer/common/TextRow";
import { BlockResult } from "services/apis/types/block/BlockResult";
import { useIntl, FormattedMessage } from "react-intl";

type Props = {
  block: BlockResult;
};

export default function GeographicDetailsCard(props: Props) {
  const intl = useIntl();

  return (
    <div className="card-wrapper">
      <Card>
        <CardHeader>
          <h3 className="mb-0">
            <FormattedMessage id="network.block.geopgrahicDetails.title" />
          </h3>
        </CardHeader>
        <CardBody className="text-xs">
          <table>
            <tbody>
              <TextRow name={intl.formatMessage({ id: "network.block.geopgrahicDetails.lata" })}>
                {props.block.lata}
              </TextRow>
              <TextRow name={intl.formatMessage({ id: "network.block.geopgrahicDetails.state" })}>
                {props.block.state}
              </TextRow>
              <TextRow name={intl.formatMessage({ id: "network.block.geopgrahicDetails.rateCenter" })}>
                {props.block.rateCenter}
              </TextRow>
              <TextRow name={intl.formatMessage({ id: "network.block.geopgrahicDetails.lrn" })}>
                {props.block.lrn}
              </TextRow>
            </tbody>
          </table>
        </CardBody>
      </Card>
    </div>
  );
}
