import React from "react";
import { FormGroup, Label } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";
import { Moment } from "moment";
import { ErrorHandler } from "services/customHooks/useErrors";
import InputError from "../errorHandling/InputError";
import DateTimeInput from "./DateTimeInput";

export type DateTimeFormInputProps = {
  disabled?: boolean;
  labelTranslationId: string;
  placeholderTranslationId?: string;
  value?: Moment | null;
  errorHandler?: ErrorHandler;
  className?: string;
  isTimeInput?: boolean;
  showModalOnTop?: boolean;
  required? : boolean;
  handleInputChange?: (value: Moment) => void;
};

export default function DateTimeFormInput(props: DateTimeFormInputProps) {
  const intl = useIntl();
  const errors = props.errorHandler?.getErrors() || [];
  const placeholder =
    props.disabled || !props.placeholderTranslationId
      ? ""
      : intl.formatMessage({ id: props.placeholderTranslationId });

  return (
    <FormGroup className={classNames("mb-2", props.className ? props.className : "")}>
      <Label className={classNames("form-control-label", {
            "pc-required-label": props.required
          })}>
        <FormattedMessage id={props.labelTranslationId} />
      </Label>
      <DateTimeInput
        disabled={props.disabled}
        className={classNames({
          "pc-invalid-input": errors && errors.length > 0
        })}
        placeholder={placeholder}
        value={props.value}
        showModalOnTop={props.showModalOnTop}
        onChange={(value) => {
          props.errorHandler?.clear();
          props.handleInputChange && props.handleInputChange(value);
        }}
        isTimeInput={props.isTimeInput}
      />
      <InputError errors={errors} />
    </FormGroup>
  );
}
