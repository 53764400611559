import React, { useMemo } from "react";
import { useIntl, IntlShape } from "react-intl";
import ReactTable from "components/framework/table/ReactTable";
import ServiceProviderActionsCell from "./ServiceProviderActionsCell";
import { CompanyInfo } from "services/apis/types/company/CompanyInfo";
import { SpInfo } from "services/apis/types/serviceProvider/SpInfo";
import { getTranslation } from "translations/TranslationService";

type Props = {
  companies: CompanyInfo[];
  serviceProviders: SpInfo[];
};

export default function ServiceProvidersCard(props: Props) {
  const intl = useIntl();
  const columns = useMemo(() => getColumns(intl, props.companies), [intl, props.companies]);
  const filterMapper = useMemo(() => getFilterMapper(intl), [intl]);

  const exportColumns = useMemo(()=> {
    var updColumns = [...columns];
    updColumns.splice(columns.findIndex(c=> c.accessor === "actions"), 1);
    return updColumns;
    },[columns]
);

  return (
    <ReactTable
      title={intl.formatMessage({ id: "manage.serviceProvider.title" })}
      data={props.serviceProviders}
      columns={columns}
      filterMapper={filterMapper}
      exportColumns={exportColumns}
      showExport={true}
      showPrint={false}
      showCopy={false}
    />
  );
}

const getColumns = (intl: IntlShape, companies: CompanyInfo[]) => [
  {
    Header: intl.formatMessage({ id: "manage.serviceProvider.table.npac" }),
    accessor: "name"
  },
  {
    Header: intl.formatMessage({ id: "manage.serviceProvider.table.spid" }),
    accessor: "spId"
  },
  {
    Header: intl.formatMessage({ id: "manage.serviceProvider.table.inPortControl" }),
    accessor: "inPortControl",
    Cell: (cell: any) =>
      intl.formatMessage({ id: `general.${cell.cell.value ? "yes" : "no"}` }) + (cell.cell.value ? ` (${cell.cell.row.original.tenantType})` : ''),
    sortType: "basic"
  },
  {
    Header: intl.formatMessage({ id: "manage.serviceProvider.table.lastMonthPortIns" }),
    accessor: "lastMonthPortInCount"
  },
  {
    Header: intl.formatMessage({ id: "manage.serviceProvider.table.actions" }),
    Cell: (cell: any) =>
      cell.cell.row.original.inPortControl && (
        <ServiceProviderActionsCell
          spId={cell.cell.row.original.spId}
          availableCompanies={companies.filter((x) =>
            x.associatedSpId.includes(cell.cell.row.original.spId)
          )}
        />
      )
  }
];

const getFilterMapper = (intl: IntlShape) => (spInfo: SpInfo) => {
  return {
    ...spInfo,
    inPortControl: (spInfo.inPortControl
      ? getTranslation(intl, "general.yes")
      : getTranslation(intl, "general.no")
    )
      + (spInfo.inPortControl && spInfo.tenantType ? ` (${spInfo.tenantType})` : '')
  };
};
