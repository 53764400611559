import React, { useState, useEffect, useCallback, useContext } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import BreadcrumbHeader from "components/common/BreadcrumbHeader";
import OrderDetailsContainer from "./OrderDetailsContainer";
import { AppContext, GetAppContext } from "services/appContext/AppContext";
import { useProfileSwitcher } from "services/customHooks/useProfileSwitcher";

export default function OrderDetails() {
  let { id }: any = useParams();
  
  const location = useLocation();
  const isDisasterRecovery = location.pathname.includes("DisasterRecovery");
  const isSinch = location.pathname.includes("SinchOrders");
  const [appContext] = useState(GetAppContext());
  const getTenant = useCallback(()=> isDisasterRecovery ? id?.split("-")[1] : id?.split("-")[0],[id, isDisasterRecovery]);
  const needToSwitchProfile = useCallback(()=>appContext?.localStorageInfo?.selectedProfile?.spId !== id ?? false,[appContext, id]);
  const {navigate: switchProfile} = useProfileSwitcher();
  const { appContext: context } = useContext(AppContext);
  const theme = context.theme;

  useEffect(()=> {
    if (needToSwitchProfile()){
      switchProfile(`/${isSinch ? "SinchOrders" : `Orders${isDisasterRecovery ? "/DisasterRecovery" : ""}`}/${id}`,getTenant());
    }
  },[location, id, getTenant, needToSwitchProfile, switchProfile, isDisasterRecovery, isSinch]);

  return (
    <>
      <BreadcrumbHeader>
        <Link to={`${isSinch ? "/SinchOrders" : "/Orders"}`}>
          <span className={`${theme === "light" ? "lblue-color" : "text-white"}`}>
            <FormattedMessage id="orders.title" />
          </span>
        </Link>
        <span className={`${theme === "light" ? "lblue-color" : "text-white"}`}>
          <FormattedMessage id="orders.orderDetails.breadcrumb" values={{ id }} />
        </span>
      </BreadcrumbHeader>
      <OrderDetailsContainer orderId={id} isDisasterRecovery={isDisasterRecovery} isSinch={isSinch} />
    </>
  );
}

