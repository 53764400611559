import React, { useMemo } from "react";
import { Modal } from "reactstrap";
import CloseButton from "components/framework/modals/CloseButton";
import TradingPartnerProfileCard from "./serviceProviderCard/TradingPartnerProfileCard";
import useServiceProvider from "services/customHooks/useServiceProvider";
import { DefaultServiceProviderResult } from "./types/ServiceProviderProfileResult";

type Props = {
  closeModal: () => void;
  spId?: string;
  isTpp?: boolean;
  onActionExecuted?: () => void;
};

export default function ServiceProviderModal(props: Props) {
  const { serviceProvider, isLoading, fetchServiceProvider } = useServiceProvider(props.spId);

  const showModal = useMemo(() => (serviceProvider || !props.spId) && !isLoading, [
    serviceProvider,
    props.spId,
    isLoading
  ]);

  return (
    <>
      {showModal && (
        <Modal className="pc-modal-xlg modal-dialog-centered" isOpen={true}>
          <CloseButton close={props.closeModal} />
          <TradingPartnerProfileCard
            serviceProviderResult={serviceProvider ?? DefaultServiceProviderResult}
            cardIsUsedInModal={true}
            closeModalOnCancel={props.closeModal}
            fetchServiceProvider={fetchServiceProvider}
            isTpp={props.isTpp}
            onActionExecuted={props.onActionExecuted}
          />
        </Modal>
      )}
    </>
  );
}
