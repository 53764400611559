import { OrderHandlerType } from "services/apis/types/port/OrderHandlerType";

export type ExcelTemplateRow = {
  phoneNumber: string;
  projectId: string;
  dueDate: string;
  dueTime: string;
  accountName: string;
  accountNumber: string;
  accountPin: string;
  accountType: string;
  authorizedName: string;
  billingPhoneNumber: string;
  city: string;
  addressLine2?: string;
  directionPrefix: string;
  directionSuffix: string;
  number: string;
  state: string;
  streetName: string;
  streetNameSuffix: string;
  zipCode: string;
  portToOrginal: string;
  lrn: string;
  csrRequest: boolean;
  handler: OrderHandlerType;
};

export const DefaultExcelTemplateRow: ExcelTemplateRow = {
  phoneNumber: "",
  projectId: "",
  dueDate: "",
  dueTime: "",
  accountName: "",
  accountNumber: "",
  accountPin: "",
  accountType: "",
  authorizedName: "",
  billingPhoneNumber: "",
  city: "",
  addressLine2: "",
  directionPrefix: "",
  directionSuffix: "",
  number: "",
  state: "",
  streetName: "",
  streetNameSuffix: "",
  zipCode: "",
  portToOrginal: "",
  lrn: "",
  csrRequest: false,
  handler: OrderHandlerType.SelectOne
};
