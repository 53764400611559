import React, { useState } from "react";
import NumberDetailsModal from "./NumberDetailsModal";
import { PhoneNumberType } from "services/apis/types/number/PhoneNumberType";

type Props = {
  phoneNumber: string;
  phoneNumberType: keyof typeof PhoneNumberType;
};

export const NumberDetailsCell = (props: Props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <i
        className="fas fa-search-plus cursor-pointer"
        onClick={e => {
          e.preventDefault();
          setShowModal(props.phoneNumberType !== PhoneNumberType.TollFree);
        }}></i>
      {showModal && (
        <NumberDetailsModal setShowModal={x => setShowModal(x)} showModal={showModal} phoneNumber={props.phoneNumber} />
      )}
    </>
  );
};
