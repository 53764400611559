import { AppContextAccessor } from "services/appContext/AppContextAccessor";

export const RestoreFilterService = {
  clearFilter: (identifier: string) => {
    const appContext = AppContextAccessor.getAppContext();
    if (appContext && appContext.localStorageInfo[identifier]) {
      AppContextAccessor.setAppContext((appContext) => {
        for (const key in appContext.localStorageInfo[identifier]) {
          if (key === "pageSize") {
            appContext.localStorageInfo[identifier][key] = 10;
          }
          else if (key === "pageIndex") {
            appContext.localStorageInfo[identifier][key] = 1;
          }
          else if (Array.isArray(appContext.localStorageInfo[identifier][key])) {
            appContext.localStorageInfo[identifier][key] = [];
          }
          else {
            appContext.localStorageInfo[identifier][key] = "Any"
          }
        }
        return {
          ...appContext,
          localStorageInfo: {
            ...appContext.localStorageInfo
          },
        };
      });
    }
  },

  setPageSize: (identifier: string, pageSize: number) => {
    const appContext = AppContextAccessor.getAppContext();
    if (appContext && appContext.localStorageInfo[identifier]) {
      AppContextAccessor.setAppContext((appContext) => {
        appContext.localStorageInfo[identifier].pageSize = pageSize;
        return {
          ...appContext,
          localStorageInfo: {
            ...appContext.localStorageInfo
          },
        };
      });
    }
  },

  hasFilter: (identifier: string): boolean => {
    let hasFilterRestore = false;
    const appContext = AppContextAccessor.getAppContext();
    if (appContext && appContext.localStorageInfo[identifier]) {
      for (const key in appContext.localStorageInfo[identifier]) {
        if (key === "pageSize") {
          if (appContext.localStorageInfo[identifier][key] !== 10)
            hasFilterRestore = true;
        }
        else if (key === "pageIndex") {
          if (appContext.localStorageInfo[identifier][key] !== 1)
            hasFilterRestore = true;
        }
        else if (Array.isArray(appContext.localStorageInfo[identifier][key])) {
          if (appContext.localStorageInfo[identifier][key].length > 0)
            hasFilterRestore = true;
        }
        else if (appContext.localStorageInfo[identifier][key] !== "Any") {
          hasFilterRestore = true;
        }
      }
    }
    return hasFilterRestore;
  },

  getPageSize: (identifier: string, originalPageSize: number): number => {
    const appContext = AppContextAccessor.getAppContext();
    if (appContext && appContext.localStorageInfo[identifier] && appContext.localStorageInfo[identifier].pageSize) {
      return appContext.localStorageInfo[identifier].pageSize;
    }
    return originalPageSize;
  },

  hasFilterColumn: (identifier: string, column: string): boolean => {
    const appContext = AppContextAccessor.getAppContext();
    return appContext && appContext.localStorageInfo[identifier] && appContext.localStorageInfo[identifier][column];
  },

  hasFilterDateColumn: (identifier: string, column: string): boolean => {
    const appContext = AppContextAccessor.getAppContext();
    return appContext && appContext.localStorageInfo[identifier] && appContext.localStorageInfo[identifier][column];
  },

  getFilterValue: (identifier: string, column: string): any => {
    const appContext = AppContextAccessor.getAppContext();
    if (appContext && appContext.localStorageInfo[identifier] && appContext.localStorageInfo[identifier][column]) {
      return appContext.localStorageInfo[identifier][column];
    }
    return "";
  },

  setFilterValue: (identifier: string, column: string, value: string) => {
    const appContext = AppContextAccessor.getAppContext();
    if (appContext && appContext.localStorageInfo[identifier] && appContext.localStorageInfo[identifier][column]) {
      AppContextAccessor.setAppContext((appContext) => {
        appContext.localStorageInfo[identifier][column] = value;
        return {
          ...appContext,
          localStorageInfo: {
            ...appContext.localStorageInfo
          },
        };
      });
    }
  },

  setFilterDate: (identifier: string, column: string, dateMax: any, dateMin: any) => {
    const appContext = AppContextAccessor.getAppContext();
    if (appContext && appContext.localStorageInfo[identifier]) {
      AppContextAccessor.setAppContext((appContext) => {
        const wholeDate: any = [];
        wholeDate.push(dateMax, dateMin);
        appContext.localStorageInfo[identifier][column] = wholeDate;
        return {
          ...appContext,
          localStorageInfo: {
            ...appContext.localStorageInfo
          },
        };
      });
    }
  },

  getFilterIdentifier: (identifier: string) => {
    return AppContextAccessor.getAppContext().localStorageInfo[identifier];
  }
};

