import { HttpClient } from "services/httpClient/HttpClient";
import { WorkStatisticsDto } from "./types/workQueue/WorkStatisticsDto";
import { WorkItemDto } from "./types/workQueue/WorkItemDto";

export const WorkQueueApi = {
  list: (searchValue: string): Promise<WorkItemDto[]> => {
    return HttpClient.get<WorkItemDto[]>(`work/?search=${searchValue}`, true, true);
  },

  statistics: (): Promise<WorkStatisticsDto> => {
    return HttpClient.get<WorkStatisticsDto>(`work/statistics`);
  }
};
