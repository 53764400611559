import { SpIdConnectionType } from "./SpIdConnectionType";

export type ServiceProvider = {
  spId: string;
  companyId: string;
  type: string;
  mediumTimer: boolean;
};

export const DefaultServiceProvider: ServiceProvider = {
  spId: "",
  type: SpIdConnectionType["SecondarySpId"].toString(),
  companyId: "",
  mediumTimer: false
};
