import React, { useState, useContext, useEffect } from "react";
import { Card, CardBody, Row, Col, Label, Input } from "reactstrap";
import { FormattedMessage } from "react-intl";
import ReactDatetime from "react-datetime";
import { Authorized } from "components/orders/types/Authorized";
import { PortOutModel } from "components/orders/types/PortOutModel";
import PhoneNumberListItem from "../PhoneNumberListItem";
import DeleteLastPhoneNumberModal from "../DeleteLastPhoneNumberModal";
import { PortOutRejectionReason } from "components/orders/types/PortOutRejectionReason";
import moment, { Moment } from "moment";
import InputError from "components/framework/errorHandling/InputError";
import { nameOf } from "services/util/ObjectUtil";
import { HasErrors, Errors, AddError } from "components/framework/errorHandling/ErrorUtil";

import PortCardHeader from "../PortCardHeader";
import { AppConfiguration } from "AppConfiguration";
import { isAfterNextYearToday } from "services/util/DateUtil";
import classNames from "classnames";
import { PortOutContext } from "./PortOut";

type Props = {
  portOutModel: PortOutModel;
  setPortOutModel: React.Dispatch<React.SetStateAction<PortOutModel>>;
};

export default function PortOutCard(props: Props) {
  const [showDeleteLastPhoneNumberModal, setShowDeleteLastPhoneNumberModal] = useState(false);
  const { deletePhoneNumberCallback, deleteOrderCardCallback } = useContext(PortOutContext);
  const [errors, setErrors] = useState<Errors>({});

  useEffect(() => {
    let errors = validateOrder(props.portOutModel);
    let hasErrors = HasErrors(errors);
    setErrors(errors);
    if (hasErrors !== props.portOutModel.hasErrors) {
      props.setPortOutModel({ ...props.portOutModel, hasErrors: hasErrors });
    }
  }, [props, props.portOutModel]);

  const deletePhoneNumberHandler = (phoneNumber: string) => {
    if (props.portOutModel.phoneNumbers.length === 1) {
      setShowDeleteLastPhoneNumberModal(true);
    } else {
      deletePhoneNumberCallback(phoneNumber);
    }
  };

  const setDueDate = (dateTime: Moment) => {
    props.setPortOutModel({ ...props.portOutModel, dueDate: dateTime });
  };

  return (
    <>
      {props.portOutModel.phoneNumbers.length > 0 && (
        <Col lg="3" md="4" className="pc-min-width-375">
          <Card>
            <PortCardHeader
              name={props.portOutModel.newProviderName}
              spId={props.portOutModel.newSpId}
            />
            <CardBody>
              <Row className="mb-1">
                <Label className="form-control-label" lg="4" md="12">
                  <FormattedMessage id="orders.portOut.stepTwo.dueDate" />
                </Label>
                <Col lg="8" md="12">
                  <ReactDatetime
                    inputProps={{
                      className: "form-control form-control-sm bg-white",
                      readOnly: true
                    }}
                    timeFormat={false}
                    value={props.portOutModel.dueDate}
                    onChange={(e: Moment | string) => setDueDate(moment.utc(e))}
                    closeOnSelect={true}
                    dateFormat={AppConfiguration.dateFormat}
                  />
                  <InputError errors={errors[nameOf<PortOutModel>("dueDate")]} />
                </Col>
              </Row>
              <Row className="mb-1">
                <Label className="form-control-label" lg="4">
                  <FormattedMessage id="orders.portOut.stepTwo.dueTime" />
                </Label>
                <Col lg="8" md="12">
                  <ReactDatetime
                    inputProps={{
                      className: "form-control form-control-sm bg-white",
                      readOnly: true
                    }}
                    dateFormat={false}
                    value={props.portOutModel.dueDate}
                    onChange={(e: Moment | string) => setDueDate(moment.utc(e))}
                    closeOnSelect={true}
                    timeFormat={AppConfiguration.timeFormat}
                  />
                </Col>
              </Row>
              <Row className="mb-1">
                <Label className="form-control-label" lg="4">
                  <FormattedMessage id="orders.portOut.stepTwo.projectId" />
                </Label>
                <Col lg="8">
                  <Input
                    type="text"
                    className="form-control form-control-sm"
                    onChange={(e) =>
                      props.setPortOutModel({
                        ...props.portOutModel,
                        clientReference: e.currentTarget.value
                      })
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-1">
                <Label className="form-control-label" lg="4">
                  <FormattedMessage id="orders.portOut.stepTwo.authorized" />
                </Label>
                <Col lg="8" md="12">
                  <select
                    className="form-control form-control-sm p-1"
                    onChange={(e) =>
                      props.setPortOutModel({
                        ...props.portOutModel,
                        authorized: e.currentTarget.value
                      })
                    }
                    value={props.portOutModel.authorized}>
                    {Object.keys(Authorized).map((x) => (
                      <option key={x} value={x}>
                        {Authorized[x]}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
              {props.portOutModel.authorized === Authorized.NO && (
                <Row className="mb-1">
                  <Label className="form-control-label" lg="4">
                    <FormattedMessage id="orders.portOut.stepTwo.causeCode" />
                  </Label>
                  <Col lg="8" md="12">
                    <select
                      className="form-control form-control-sm p-1"
                      onChange={(e) =>
                        props.setPortOutModel({
                          ...props.portOutModel,
                          rejectionReason: e.currentTarget.value
                        })
                      }
                      value={props.portOutModel.rejectionReason}>
                      {Object.keys(PortOutRejectionReason).map((x) => (
                        <option key={x} value={x}>
                          {PortOutRejectionReason[x]}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              )}
              <Row className="mb-1">
                <Label className="form-control-label" lg="4">
                  <FormattedMessage id="orders.portIn.stepTwo.portingNumbers" />
                </Label>
                <Col lg="8">
                  <div
                    className={classNames(
                      "list pc-list-group list-group-flush form-control form-control-sm pl-2 pt-1 mt-1"
                    )}>
                    {props.portOutModel.phoneNumbers.map((x) => (
                      <PhoneNumberListItem
                        key={x}
                        phoneNumber={x}
                        deletePhoneNumberHandler={() => deletePhoneNumberHandler(x)}
                      />
                    ))}
                    {showDeleteLastPhoneNumberModal && (
                      <DeleteLastPhoneNumberModal
                        closeModal={() => setShowDeleteLastPhoneNumberModal(false)}
                        confirmCallback={deleteOrderCardCallback}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      )}
    </>
  );
}

const validateOrder = (portOutModel: PortOutModel) => {
  const errors: Errors = {};

  if (isAfterNextYearToday(portOutModel.dueDate)) {
    AddError(errors, nameOf<PortOutModel>("dueDate"), "orders.portOut.stepTwo.dueDateTooFar");
  }

  return errors;
};
