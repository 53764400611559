import React from "react";
import CardSectionHeader from "components/framework/cards/CardSectionHeader";
import { Row, Col } from "reactstrap";
import { useIntl } from "react-intl";
import CheckboxFormInput from "components/framework/forms/CheckboxFormInput";
import { authorizeAny } from "services/authorization/AuthorizationService"
import { PermissionType } from "services/authorization/PermissionType";
import { AppContextAccessor } from "services/appContext/AppContextAccessor";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork

type Props = {
  csrRequest: boolean;
  setCsrRequest: (value: boolean) => void;
  disabled: boolean;
}

export default function CsrRequest(props: Props) {
  const intl = useIntl();
  const allowCsr = authorizeAny([PermissionType.AllowCsr], false) &&
    AppContextAccessor.getAppContext().localStorageInfo.selectedProfile?.allowCsr;

  return <>

    <div className="my-3">
      <CardSectionHeader labelTranslationId="orders.csrRequest" />
      <Row>
        <Col className="d-flex px-0 ml-3">
            <CheckboxFormInput
              label={intl.formatMessage({ id: !isMixNetworkUrl ? "orders.csrRequest.label" : "orders.csrRequest.labelMix" })}
              checked={allowCsr ? props.csrRequest : false}
              value={"csrRequest"}
              handleInputChange={props.setCsrRequest}
              disabled={!allowCsr || props.disabled}
              tooltipId={allowCsr ? undefined : "orders.csr.csrDisabled"}
            />
        </Col>
      </Row>
    </div>
  </>
}
