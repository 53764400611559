import { useIntl } from "react-intl";
import { useEffect } from "react";
import { versionChanged, displayVersionNotification } from "services/portControl/VersionService";
import React from "react";

export default function VersionChecker() {
  const intl = useIntl();
  useEffect(() => {
    let notificationDisplayed = false;
    const interval = setInterval(async () => {
      if (!notificationDisplayed && (await versionChanged())) {
        notificationDisplayed = true;
        displayVersionNotification(
          intl.formatMessage({ id: "framework.notifications.version.message" }),
          () => {
            notificationDisplayed = false;
          }
        );
      }
    }, 1000 * 60 * 30);

    return () => clearInterval(interval);
  }, [intl]);
  return <></>;
}
