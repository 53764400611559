import React, { useMemo } from "react";
import { useIntl, IntlShape } from "react-intl";
import { getValueOrEmpty } from "services/util/StringUtil";
import { DpcSsnInfo } from "services/apis/types/block/DpcSsnInfo";
import ReactTable from "components/framework/table/ReactTable";
import { hasAnyValue } from "services/util/ObjectUtil";

type Props = {
  networkData: DpcSsnInfo;
};

export default function NetworkDataTable(props: Props) {
  const intl = useIntl();
  const columns = useMemo(() => getColumns(intl), [intl]);
  const data = hasAnyValue(props.networkData) ? [props.networkData] : [];

  return (
    <ReactTable
      title={intl.formatMessage({ id: "explore.networkData.table.title" })}
      data={data}
      columns={columns}
      hideSearch={true}
    />
  );
}

const getColumns = (intl: IntlShape) => [
  {
    Header: intl.formatMessage({ id: "explore.networkData.table.cnamDPC" }),
    accessor: "cnamDpc",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.networkData.table.cnamSSN" }),
    accessor: "cnamSsn",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.networkData.table.classDPC" }),
    accessor: "classDpc",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.networkData.table.classSSN" }),
    accessor: "classSsn",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.networkData.table.lidbDPC" }),
    accessor: "lidbDpc",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.networkData.table.lidbSSN" }),
    accessor: "lidbSsn",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.networkData.table.isvmDPC" }),
    accessor: "isvmDpc",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.networkData.table.isvmSSN" }),
    accessor: "isvmSsn",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.networkData.table.wsmscDPC" }),
    accessor: "wsmscDpc",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.networkData.table.wsmscSSN" }),
    accessor: "wsmscSsn",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  }
];
