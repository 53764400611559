import { isRange, removeDashes, isPhoneNumber, getPhoneNumberListItems } from "services/util/StringUtil";
import { ValidationResult } from "./ValidationResult";

const validateNumberInputValueListItem = (numberInputValue: string): ValidationResult => {
  if (numberInputValue.length === 15) {
    if (!isRange(numberInputValue)) {
      return ValidationResult.InvalidRange;
    }
  } else if (removeDashes(numberInputValue).length !== 10 || !isPhoneNumber(numberInputValue)) {
    return ValidationResult.InvalidNumber;
  }

  return ValidationResult.Valid;
};

export const validateNumberInputValue = (numberInputValue: string): ValidationResult => {
  if (!numberInputValue) {
    return ValidationResult.NoInput;
  }

  const listItems = getPhoneNumberListItems(numberInputValue);

  if (listItems.length > 0) {
    for (const listItem of listItems) {
      if (!listItem) {
        continue;
      }

      const validationResult = validateNumberInputValueListItem(listItem);

      if (validationResult !== ValidationResult.Valid) {
        return validationResult;
      }
    }
  } else {
    return ValidationResult.NoInput;
  }

  return ValidationResult.Valid;
};
