import React, { useMemo } from "react";
import { useDropzone, DropzoneRootProps } from "react-dropzone";
import { FormattedMessage } from "react-intl";
import { parse } from "papaparse";
import {
  getFileExtension,
  isPhoneNumber,
  isNumber,
  isRange,
  lowerCaseEquals,
  removeWhitespace
} from "services/util/StringUtil";
import { read, utils } from "xlsx";
import { getUniqueElements } from "services/util/ArrayUtil";
import classNames from "classnames";
import { ExcelTemplateRow, DefaultExcelTemplateRow } from "../types/ExcelTemplateRow";
import { ExcelTemplateRowHeaders } from "../types/ExcelTemplateRowHeaders";
import { ExcelTemplateAccount } from "../types/ExcelTemplateAccount";
import { ExcelTemplateLrnPtoGroup } from "../types/ExcelTemplateLrnPtoGroup";
import { OrderHandlerType } from "services/apis/types/port/OrderHandlerType";
import { showErrorNotification } from "components/framework/notification/NotificationUtil";

type Props = {
  addNumbersToTextAreaHandler: (result: Array<string>) => void;
  saveExcelTemplateAccounts?: (result: Array<ExcelTemplateAccount>) => void;
};

export default function DropzoneArea(props: Props) {
  const dropzoneOptions = useMemo(
    () => ({
      onDrop: (acceptedFiles: File[]) => {
        onDrop(acceptedFiles, props.addNumbersToTextAreaHandler, props.saveExcelTemplateAccounts);
      },
      accept: acceptedFileTypes
    }),
    [props.addNumbersToTextAreaHandler, props.saveExcelTemplateAccounts]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone(
    dropzoneOptions
  );

  return (
    <div
      className={classNames(
        "h-180",
        getContainerClassName(getRootProps({ isDragActive, isDragAccept, isDragReject }))
      )}
      {...getRootProps()}>
      <input {...getInputProps()} />
      <p>
        <FormattedMessage id="orders.dropzone.dragAndDropMessage" />
      </p>
      <i className="fa fa-cloud-upload-alt"></i>
      <p>
        <FormattedMessage id="orders.dropzone.allowedFileFormatMessage" />
      </p>
    </div>
  );
}

const onDrop = (
  acceptedFiles: File[],
  numberResultCallback: (result: Array<string>) => void,
  excelTemplateAccountsCallback?: (result: Array<ExcelTemplateAccount>) => void
) => {
  acceptedFiles.forEach((file: File) => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>): any => {
      if (reader.result) {
        var binary = "";
        var bytes = new Uint8Array(reader.result as ArrayBuffer);
        var length = bytes.byteLength;
        for (var i = 0; i < length; i++) {
          binary += String.fromCharCode(bytes[i]);
        }

        const fileExtension = getFileExtension(file.name);
        
        try{
          if (fileExtension === ".csv" || fileExtension === ".txt") {
            handleTextContents(file, numberResultCallback);
          } else if (fileExtension === ".xls" || fileExtension === ".xlsx") {
            handleExcelContents(binary, numberResultCallback, excelTemplateAccountsCallback);
          }
        }catch(error) {
          showErrorNotification("Spreadsheet import has failed.");
          showErrorNotification(error.message);
          return;
        }
      }
    };
    reader.readAsArrayBuffer(file);
  });
};

const acceptedFileTypes = [
  ".csv",
  ".txt",
  ".xls",
  ".xlsx",
  "text/plain",
  "text/csv",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
];

const handleTextContents = (file: File, numberResultCallback: (result: Array<string>) => void) => {
  parse(file, {
    complete: (result) => {
      const elems = result.data as Array<Array<string>>;
      let elemsArray: string[] = [];
      elems.forEach((x) => (elemsArray = [...elemsArray, ...x]));
      numberResultCallback(getUniqueElements(elemsArray));
    }
  });
};

const handleExcelContents = (
  contents: string | ArrayBuffer,
  numberResultCallback: (result: Array<string>) => void,
  excelTemplateAccountsCallback?: (result: Array<ExcelTemplateAccount>) => void
) => {
  let workbook = read(contents, {
    type: "binary"
  });
  const elems = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
    raw: false
  }) as Array<{
    [key: string]: string;
  }>;

  if (elems.length) {
    if (
      Object.keys(elems[0]).some(
        (x) => !isPhoneNumber(x) && !isRange(x) && !(x.length === 10 && isNumber(x))
      ) &&
      excelTemplateAccountsCallback
    ) {
      const excelTemplateRows = parseExcelTemplate(elems);
      numberResultCallback(excelTemplateRows.map((x) => x.phoneNumber));
      const excelTemplateAccounts = getAccountsFromExcelTemplate(excelTemplateRows);
      excelTemplateAccountsCallback(excelTemplateAccounts);
    } else {
      let elemsArray: string[] = [];
      elems.forEach(
        (x) =>
          (elemsArray = [
            ...elemsArray,
            ...Object.keys(x),
            ...Object.values(x).map((x) => x.toString())
          ])
      );
      numberResultCallback(getUniqueElements(elemsArray));
    }
  }
};

const parseExcelTemplate = (
  excelContent: Array<{ [key: string]: any }>
): Array<ExcelTemplateRow> =>
  excelContent.map((excelRow) => {
    const parsedRow: ExcelTemplateRow = { ...DefaultExcelTemplateRow };

    let propertyName: keyof ExcelTemplateRow;
    for (propertyName in parsedRow) {
      const configuredExcelHeader = ExcelTemplateRowHeaders[propertyName];
      const matchingExcelRowPropertyName = Object.keys(excelRow).find((excelRowPropertyName) =>
        lowerCaseEquals(
          removeWhitespace(excelRowPropertyName),
          removeWhitespace(configuredExcelHeader)
        )
      );
      if (matchingExcelRowPropertyName) {
        const value = excelRow[matchingExcelRowPropertyName]?.toString().trim() ?? "";
        const type = typeof(parsedRow[propertyName]);
        parsedRow[propertyName] = parseValue(propertyName, type, value) as never;
      }
    }

    if (parsedRow.csrRequest === true)
      parsedRow.handler = OrderHandlerType.PortingDotCom;

    return parsedRow;
  });

const isFromAccount = (row: ExcelTemplateRow, account: ExcelTemplateAccount): boolean => {
  let propertyName: keyof ExcelTemplateRow;

  for (propertyName in row) {
    if (
      propertyName !== "phoneNumber" &&
      propertyName !== "portToOrginal" &&
      propertyName !== "lrn" &&
      account[propertyName] !== row[propertyName]
    ) {
      return false;
    }
  }

  return true;
};

const addDataToExistingAccount = (row: ExcelTemplateRow, account: ExcelTemplateAccount): void => {
  const existingLrnPtoGroup = account.lrnPtoGroupNumbers.find(
    (lrnPtoGroup) =>
      lrnPtoGroup.lrn === row.lrn && lrnPtoGroup.portToOrginal === row.portToOrginal
  );
  if (existingLrnPtoGroup) {
    existingLrnPtoGroup.phoneNumbers = [...existingLrnPtoGroup.phoneNumbers, row.phoneNumber];
  } else {
    const newGroup: ExcelTemplateLrnPtoGroup = {
      lrn: row.lrn,
      portToOrginal: row.portToOrginal,
      phoneNumbers: [row.phoneNumber],
      orderHandler: row.handler
    };
    account.lrnPtoGroupNumbers = [...account.lrnPtoGroupNumbers, newGroup];
  }
};

const createNewAccount = (row: ExcelTemplateRow): ExcelTemplateAccount => {
  const newGroup: ExcelTemplateLrnPtoGroup = {
    lrn: row.lrn,
    portToOrginal: row.portToOrginal,
    phoneNumbers: [row.phoneNumber],
    orderHandler: row.handler
  };

  return {
    lrnPtoGroupNumbers: [newGroup],
    projectId: row.projectId,
    dueDate: row.dueDate,
    dueTime: row.dueTime,
    accountName: row.accountName,
    accountNumber: row.accountNumber,
    accountPin: row.accountPin,
    accountType: row.accountType,
    authorizedName: row.authorizedName,
    billingPhoneNumber: row.billingPhoneNumber,
    city: row.city,
    addressLine2: row.addressLine2,
    directionPrefix: row.directionPrefix,
    directionSuffix: row.directionSuffix,
    number: row.number,
    state: row.state,
    streetName: row.streetName,
    streetNameSuffix: row.streetNameSuffix,
    zipCode: row.zipCode,
    csrRequest: row.csrRequest,
    handler: row.handler
  };
};

const getAccountsFromExcelTemplate = (
  excelRows: Array<ExcelTemplateRow>
): Array<ExcelTemplateAccount> => {
  let excelAccounts: Array<ExcelTemplateAccount> = [];

  excelRows.forEach((row) => {
    const existingAccount = excelAccounts.find((account) => isFromAccount(row, account));

    if (existingAccount) {
      addDataToExistingAccount(row, existingAccount);
    } else {
      excelAccounts = [...excelAccounts, createNewAccount(row)];
    }
  });

  return excelAccounts;
};

const getContainerClassName = (props: DropzoneRootProps) => {
  let containerClass = "pc-drag-drop-container container";

  if (props.isDragAccept) {
    return containerClass + " pc-accept";
  }
  if (props.isDragReject) {
    return containerClass + " pc-reject";
  }
  if (props.isDragActive) {
    return containerClass + " pc-active";
  }
  return containerClass + " pc-default";
};

const parseValue = (propertyName: string, type: any, value: string) : any =>{
  
  const normalizedValue = value.toLocaleLowerCase();
  var parsedValue : any;
  
  switch(true) {
    
    case type === "boolean" :
      parsedValue = normalizedValue === "y" || normalizedValue === "true";
      break;

    case propertyName === "handler":
      parsedValue = OrderHandlerMap[value];
        if (!parsedValue)
          throw new Error(`Invalid value found : '${value}', on column : 'Handler'. Valid order handler types are : 'ATLC', 'ServiceProvider'`); 
      break;

    default:
      parsedValue = value; 
      break;
  }

  return parsedValue;
}

enum ExcelOrderHandlerType {
  ServiceProvider,
  ATLC
}

const OrderHandlerMap : Record<keyof typeof ExcelOrderHandlerType, OrderHandlerType> = {
 ServiceProvider : OrderHandlerType.ServiceProvider,
 ATLC : OrderHandlerType.PortingDotCom
};
  
