import { useCallback, useContext } from "react";
import { useHistory } from "react-router";
import { AppContext } from "services/appContext/AppContext";

export const useProfileSwitcher = () => {
    const {appContext, setAppContext} = useContext(AppContext);
    const history = useHistory();

    const navigate = useCallback((url: string, tenant: string) => {
        
        const needToSwitchProfile = appContext?.localStorageInfo?.selectedProfile?.spId !== tenant;

        const spIdProfile = appContext.localStorageInfo.user
        ? appContext.localStorageInfo.user.spIdProfiles.find((x) => x.spId === tenant)
        : undefined;
      
      if (needToSwitchProfile && spIdProfile) {
        setAppContext({
          ...appContext,
          localStorageInfo: { ...appContext.localStorageInfo, selectedProfile: spIdProfile }
        });
      }
      
      history.push(url);
      },[appContext, history, setAppContext]);

      return { navigate: navigate};
}