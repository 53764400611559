import React from "react";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { FormattedMessage } from "react-intl";
import PortedNumbersChart from "./PortedNumbersChart";
import { ReportDto } from "services/apis/types/reports/ReportDto";

type Props = {
  reportDto: ReportDto;
};

export default function PortedNumbersChartCard(props: Props) {
  return (
    <Row>
      <Col md="12">
        <Card>
          <CardHeader>
            <h6 className="text-light text-uppercase ls-1 mb-1">
              <FormattedMessage id="reports.chart.title" />
            </h6>
            <h5 className="h3 mb-0">
              <FormattedMessage id="reports.chart.subtitle" />
            </h5>
          </CardHeader>
          <CardBody>
            <PortedNumbersChart reportDto={props.reportDto} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}
