export type SignupModel = {
  email: string;
  name: string;
  companyName: string;
  phoneNumber: string;
  associatedSpid: string;
  portControlSpidsAccess: string;
  password: string;
  confirmPassword: string;
  licenseAgreed: boolean;
};

export const DefaultSignupModel: SignupModel = {
  email: "",
  name: "",
  companyName: "",
  phoneNumber: "",
  associatedSpid: "",
  portControlSpidsAccess: "",
  password: "",
  confirmPassword: "",
  licenseAgreed: false
};
