export const TppStatus = [
    { key: "Any", value: "Any" },
    { key: "Active", value: "Active" },
    { key: "Denied", value: "Denied" },
    { key: "Request To Trade", value: "Request To Trade" },
    { key: "Submitted", value: "Submitted" }
];

export const DefaultYesNoFilterValues = [
    { key: "Any", value: "Any" },
    { key: "Yes", value: "Yes" },
    { key: "No", value: "No" }
]