export type DpcSsnInfo = {
  classDpc: string;
  classSsn: string;
  lidbDpc: string;
  lidbSsn: string;
  isvmDpc: string;
  isvmSsn: string;
  cnamDpc: string;
  cnamSsn: string;
  wsmscDpc: string;
  wsmscSsn: string;
};

export const DefaultDpcSsnInfo: DpcSsnInfo = {
  classDpc: "",
  classSsn: "",
  lidbDpc: "",
  lidbSsn: "",
  isvmDpc: "",
  isvmSsn: "",
  cnamDpc: "",
  cnamSsn: "",
  wsmscDpc: "",
  wsmscSsn: ""
};
