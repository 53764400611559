import { getUtcDate } from "services/util/DateUtil";

export type ExportDto = {
  emailAddress: string;
  spId: string;
  productType: string;
  activity: string;
  direction: string;
  modality: string;
  transactionCount: number;
  transactionDate: Date;
};

export const DefaultExportDto: ExportDto = {
  emailAddress: "",
  spId: "",
  productType: "",
  activity: "",
  direction: "",
  modality: "",
  transactionCount: 0,
  transactionDate: getUtcDate().toDate()
};

export type ExportActiveTnsDto = {
  phoneNumber: string;
  returnedDate: string;
  returnedSpid: string;
}

export const DefaultExportActiveTnsDto: ExportActiveTnsDto = {
  phoneNumber: "",
  returnedDate: "",
  returnedSpid: "",
}
