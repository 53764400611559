import { OrderHandlerType } from "services/apis/types/port/OrderHandlerType";

export type ExcelTemplateLrnPtoGroup = {
    lrn: string;
    portToOrginal: string;
    phoneNumbers: string[];
    orderHandler: OrderHandlerType;
}

export const DefaultExcelTemplateLrnPtoGroup: ExcelTemplateLrnPtoGroup = {
    lrn: "",
    portToOrginal: "",
    phoneNumbers: [],
    orderHandler: OrderHandlerType.SelectOne
  };
