import { HttpClient } from "services/httpClient/HttpClient";
import { NpacAuditListDto } from "./types/audit/NpacAuditListDto";
import { RequestAuditModel } from "./types/audit/RequestAuditModel";
import { NpacAuditReply } from "./types/audit/NpacAuditReply";
import { ApiError } from "./types/ApiError";

export const AuditApi = {
  list: (searchValue: string): Promise<NpacAuditListDto[]> => {
    return HttpClient.get<NpacAuditListDto[]>(`audit/?search=${searchValue}`, true, true);
  },

  create: (audit: RequestAuditModel): Promise<ApiError> => {
    return HttpClient.post<ApiError>("audit/audit", audit, true, true);
  },

  details: (auditId: string): Promise<NpacAuditReply> => {
    return HttpClient.get<NpacAuditReply>(`audit/audit/${auditId}`, true, true);
  }
};
