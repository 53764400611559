import { IdentityResponse } from "./types/identity/IdentityResponse";
import { AppContextAccessor } from "services/appContext/AppContextAccessor";
import { RequestPasswordReset } from "services/apis/types/identity/RequestPasswordReset";
import { HttpClient } from "services/httpClient/HttpClient";
import { ResetPassword } from "services/apis/types/identity/ResetPassword";
import { ChangePasswordDto } from "services/apis/types/identity/ChangePasswordDto";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";
import queryString from "query-string";

export const IdentityApi = {
  login: async (username: string, password: string): Promise<IdentityResponse> => {
    let requestParams = queryString.stringify({
      grant_type: "password",
      username: username,
      password: password,
      scope: EnvironmentUtil.oAuthScope,
      client_id: EnvironmentUtil.oAuthClientId,
      client_secret: EnvironmentUtil.oAuthClientSecret
    });

    const response = await fetch(EnvironmentUtil.identityServerUrl("connect/token"), getRequestInit(requestParams));
    const json = await response.json();

    return json as IdentityResponse;
  },

  refreshAccessToken: async (): Promise<IdentityResponse> => {
    let requestParams = queryString.stringify({
      grant_type: "refresh_token",
      refresh_token: AppContextAccessor.getAppContext().localStorageInfo.authenticationInfo.refresh_token,
      client_id: EnvironmentUtil.oAuthClientId,
      client_secret: EnvironmentUtil.oAuthClientSecret
    });

    const response = await fetch(EnvironmentUtil.identityServerUrl("connect/token"), getRequestInit(requestParams));
    const identityResponse = (await response.json()) as IdentityResponse;

    return identityResponse;
  },

  requestPasswordReset: async (requestPasswordReset: RequestPasswordReset): Promise<Response> => {
    return HttpClient.post<Response>("account/requestpasswordreset", requestPasswordReset, false);
  },

  resetPassword: async (resetPassword: ResetPassword): Promise<Response> => {
    return HttpClient.post<Response>("account/resetpassword", resetPassword, false);
  },

  changePassword: async (changePasswordDto: ChangePasswordDto): Promise<string> => {
    return HttpClient.post<string>("account/changepassword", changePasswordDto, true, false);
  }
};

const getRequestInit = (body: string): RequestInit => {
  return {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: body
  };
};
