import { SvRequestStatus } from "components/orders/types/SvRequestStatus";

export type UpdateOrderStatusModel = {
    status: SvRequestStatus,
    rejectionReason: string | undefined,
    rejectionRemarks: string | undefined
};

export const UpdateOrderToSubmittedStatusModel: UpdateOrderStatusModel = {
    status: SvRequestStatus.Submitted,
    rejectionReason: undefined,
    rejectionRemarks: undefined
};

export const DefaultRejectCsrStatusModel: UpdateOrderStatusModel = {
    status: SvRequestStatus.CSRInvalid,
    rejectionReason: undefined,
    rejectionRemarks: undefined
};