import { useState } from "react";
import { Error, Errors } from "components/framework/errorHandling/ErrorUtil";

export const useErrors = (): ErrorState => {
  const [errors, setErrors] = useState<Errors>({});

  const setErrorsWrapper = (key: string) => {
    setErrors(prevState => {
      const errors = { ...prevState };
      errors[key] = [];
      return errors;
    });
  };

  const getErrorHandler = (key: string): ErrorHandler => {
    const clear = () => setErrorsWrapper(key);
    const getErrors = () => errors[key];

    return { clear, getErrors };
  };

  return { setErrors, getErrorHandler };
};

export type ErrorHandler = {
  clear: () => void;
  getErrors: () => Error[];
};

export type ErrorState = {
  setErrors: React.Dispatch<React.SetStateAction<Record<string, Error[]>>>;
  getErrorHandler: (key: string) => ErrorHandler;
};
