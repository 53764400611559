import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function SignupVerify() {
  return (
    <div className="text-center text-muted mb-4">
      <small className="font-weight-bold">
        <FormattedMessage id="account.signup.verifytitle" />
      </small>

      <p className="mt-4">
        <FormattedMessage id="account.signup.verifydescription" />
      </p>

      <img
        alt="PortControl"
        className="mt-4"
        src={require("assets/img/email_plane.svg")}
      />
    </div>
  );
}