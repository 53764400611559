import React from "react";
import classNames from "classnames";
import {FormattedMessage} from "react-intl";
import { Alert } from "reactstrap";

export type NpacMaintenanceAlertProps = {
  classNames? : string | undefined;
}

export default function NpacMaintenanceAlert(props: NpacMaintenanceAlertProps) {
  var npacMaintenancePdfUrl = "https://f.hubspotusercontent20.net/hubfs/7553926/Client%20Success/PortControl/NPAC%20-%20SSU%20Maintenance%20Task%20Document.pdf?utm_campaign=PortControl%20Client%20Updates&utm_medium=email&_hsmi=107715346&_hsenc=p2ANqtz-87qORPcOKLa7J_574dXK_00aireR_0JKe75F3Xjn450fuDt5FmFpiw02__2LfEIPewNBpOlseBAv0tz3irKeEdD14FmQ&utm_content=107715346&utm_source=hs_email";
  return(
    <Alert color="warning" className={classNames("alert-text p-0 pl-1 col-md-8", props.classNames)}>
      <span className="alert-icon">
        <i className="far fa-exclamation-circle"></i>
      </span>
      <a href={npacMaintenancePdfUrl} target="_blank" rel="noopener noreferrer">
        <FormattedMessage id="orders.portIn.stepTwo.hasConflictsWithNpacMaintenanceWindow" />
      </a>
    </Alert>
  );
}