import React from "react";
import { OrderCardStatus, OrderCardStatuses } from "../types/OrderCardStatus";
import classNames from "classnames";

type Props = {
  status: OrderCardStatus;
};

export default function OrderCardStatusIcon(props: Props) {
  return (
    <>
      {props.status !== OrderCardStatus.NotSubmitted &&
        props.status !== OrderCardStatus.DocumentRemoved && (
          <i
            className={classNames("fas ", {
              "fa-check-circle text-success": props.status === OrderCardStatus.Created,
              "fa-exclamation-circle text-danger": props.status === OrderCardStatus.HasErrors,
              "fa-exclamation-triangle pc-order-status-warning":
                props.status === OrderCardStatus.DocumentsUploadFailed,
              "fa-file-plus text-primary": props.status === OrderCardStatus.NewDocumentsAdded
            })}
            data-toggle="tooltip"
            title={OrderCardStatuses[props.status]}
          />
        )}
    </>
  );
}
