import { ApiError } from "services/apis/types/ApiError";

export const AddError = (
  errors: Errors,
  key: string,
  message: string,
  hasTranslation: boolean = true
) => {
  let error: Error = { message, hasTranslation };

  if (key in errors) {
    errors[key].push(error);
  } else {
    errors[key] = [error];
  }
};

export const HasErrors = (errors: Errors): boolean => {
  return Object.keys(errors).length > 0;
};

export const HasFieldErrors = (errors: Errors | ApiError): boolean =>
  errors.fieldErrors !== undefined && Object.keys(errors.fieldErrors).length !== 0;

export type Error = {
  message: string;
  hasTranslation: boolean;
};

export type Errors = Record<string, Error[]>;

export const getParameterCaseInsensitive = (object: Errors, key: string) => {
  if (object) {
    const key2: string | undefined = Object.keys(object).find(
      (k) => k.toLowerCase() === key.toLowerCase()
    );

    if (key2) {
      return object[key2];
    }
  }

  return [];
};
