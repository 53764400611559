export type WebhookLogsListFilter = {
    status: string;
    fromDate: string;
    toDate: string;
}

export const DefaultWebhookLogsListFilter: WebhookLogsListFilter = {
    status: "",
    fromDate: "",
    toDate: ""
}