import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "reactstrap";
import FormateDate from "components/framework/date/FormateDate";

type Props = {
    id: string;
    isVerified: boolean;
    verifiedDate: any;
};

export default function VerifiedCell(props: Props) {
    const [verifiedDateTooltipOpen, setVerifiedDateTooltipOpen] = useState(false);
    return (
        <>
            <div className="d-flex justify-content-center">
                {props.isVerified && (
                    <i className="fa fa-badge-check fa-lg text-success " id={props.verifiedDate ? "verifiedDateBadge"+props.id : ""}>
                        {props.verifiedDate && <Tooltip
                            placement="right"
                            isOpen={verifiedDateTooltipOpen}
                            target={"verifiedDateBadge"+props.id}
                            toggle={() => setVerifiedDateTooltipOpen(!verifiedDateTooltipOpen)}>
                            <FormattedMessage
                                id="tppModal.verifiedBadgeLabel"
                                values={{
                                    date: (
                                        <FormateDate
                                            utcDate={
                                                props.verifiedDate
                                            }
                                        />
                                    )
                                }}
                            />
                        </Tooltip>}
                    </i>
                )}
            </div>
        </>
    );
}
