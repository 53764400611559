import React from 'react';

export default function PolygonLoader() {
  return (
    <>
      <div className="spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      </div>
    </>
  )
}