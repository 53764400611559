import React, { useMemo } from "react";
import { useIntl, IntlShape } from "react-intl";
import { getValueOrEmpty } from "services/util/StringUtil";
import { AuditNumberStatus } from "services/apis/types/audit/AuditNumberStatus";
import FailedSpListCell from "./FailedSpListCell";
import ReactTable from "components/framework/table/ReactTable";
import FormatDateTime from "components/framework/date/FormatDateTime";
import { getFormattedUtcDate } from "services/util/DateUtil";

type Props = {
  phoneNumbers: AuditNumberStatus[];
};

export default function AuditNumbersCard(props: Props) {
  const intl = useIntl();
  const columns = useMemo(() => getColumns(intl), [intl]);

  return (
    <div className="card-wrapper">
      <ReactTable
        title={intl.formatMessage({ id: "audits.details.table.title" })}
        data={props.phoneNumbers}
        columns={columns}
        showExport={true}
        filterMapper={filterMapper}
      />
    </div>
  );
}

const getColumns = (intl: IntlShape) => [
  {
    Header: intl.formatMessage({ id: "audits.details.table.phoneNumber" }),
    accessor: "phoneNumber",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "audits.details.table.status" }),
    accessor: "status",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "audits.details.table.auditDate" }),
    accessor: "auditDate",
    Cell: (cell: any) => <FormatDateTime utcDate={cell.cell.value} />
  },
  {
    Header: intl.formatMessage({ id: "audits.details.table.failedSpList" }),
    Cell: (cell: any) => <FailedSpListCell failedSpList={cell.cell.row.original.failedSpList} />
  }
];

const filterMapper = (auditNumberStatus: AuditNumberStatus) => {
  return { ...auditNumberStatus, auditDate: getFormattedUtcDate(auditNumberStatus.auditDate) };
};
