import { PortInAccountModel } from "components/orders/types/PortInAccountModel";
import React from "react";
import { UncontrolledAlert } from "reactstrap";
import { ApiErrorType } from "components/common/types/ApiErrorType";

type Props = {
  accountModels: PortInAccountModel[];
  errorMessage: string;
};

export const PortInHeaderError = (props: Props) => {
  return (
    <>
      {props.accountModels.some(
        (accountModel) =>
          (accountModel.apiErrors?.length as number) > 0 &&
          accountModel.orderCards.some((orderCard) => !orderCard.orderId)
      ) && (
        <UncontrolledAlert
          color={ApiErrorType.Danger}
          className="d-flex p-0 pl-1 mb-1 ml-3 min-width-45 align-items-center">
          <span className="alert-icon">
            <i className="far fa-exclamation-circle"></i>
          </span>
          <span className="alert-text mr-5 ml-1 flex-basis-95">{props.errorMessage}</span>
        </UncontrolledAlert>
      )}
    </>
  );
};
