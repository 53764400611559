import { CompanyDto, DefaultCompanyDto } from "services/apis/types/company/CompanyDto";

export type Company = {
  id: string;
  companyDto: CompanyDto;
};

export const DefaultCompany: Company = {
  id: "",
  companyDto: DefaultCompanyDto
};
