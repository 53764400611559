export const WebhookLogsStatus = [
    { key: "Status", value: "" },
    { key: "Success", value: "Success" },
    { key: "Failed", value: "Failed" },
    { key: "Retried", value: "Retried"}
];

export const WebhookEmailStatus = [
    { key: "Status", value: "" },
    { key: "Sent", value: "Sent" },
];

export const buildQueryParams = (params) => {
    return new URLSearchParams(
        Object.entries(params)
            .filter(([, value]) => value !== undefined && value !== "")
            .map(([key, value]) => [key, String(value)])
    ).toString();
};