import React, { useState, useContext } from "react";
import { FormGroup, Input } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleFieldErrors } from "services/util/ApiUtil";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { ManageContext } from "../Manage";
import { CompanyInfo } from "services/apis/types/company/CompanyInfo";
import { CompanyApi } from "services/apis/CompanyApi";
import ConfirmationModal from "components/framework/modals/ConfirmationModal";

type Props = {
  spId: string;
  availableCompanies: CompanyInfo[];
  closeModal: () => void;
};

export default function ServiceProviderDeleteModal(props: Props) {
  const intl = useIntl();
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [companyId, setCompanyId] = useState<string>(props.availableCompanies[0] ? props.availableCompanies[0].id : "");
  const manageContext = useContext(ManageContext);
  const isMounted = useIsMounted();

  const handleDelete = () => {
    if (isMounted.current) {
      setShowLoadingIndicator(true);
    }

    CompanyApi.deleteSpId(companyId, props.spId)
      .then(() => {
        showInfoNotification(intl.formatMessage({ id: "manage.serviceProvider.delete.deleteNotification" }));
        if (isMounted.current) {
          manageContext.serviceProviderCallback();
          props.closeModal();
        }
      })
      .catch(error => handleFieldErrors(error))
      .finally(() => {
        if (isMounted.current) {
          setShowLoadingIndicator(false);
        }
      });
  };

  return (
    <ConfirmationModal
      confirmCallback={handleDelete}
      closeModal={props.closeModal}
      title={intl.formatMessage({ id: "manage.serviceProvider.delete.deleteModalTitle" })}
      confirmButtonText={intl.formatMessage({ id: "manage.serviceProvider.delete.submitDelete" })}
      showLoadingIndicator={showLoadingIndicator}
      hideConfirmButton={props.availableCompanies.length <= 0}>
      {props.availableCompanies.length === 0 && (
        <p>
          <FormattedMessage id="manage.serviceProvider.delete.unavailable" />
        </p>
      )}
      {props.availableCompanies.length === 1 && (
        <p>
          <FormattedMessage
            id="manage.serviceProvider.delete.deleteQuestionSingleOption"
            values={{ spId: props.spId, organization: props.availableCompanies[0].name }}
          />
        </p>
      )}
      {props.availableCompanies.length > 1 && (
        <div>
          <p>
            <FormattedMessage id="manage.serviceProvider.delete.deleteQuestion" values={{ spId: props.spId }} />
          </p>
          <FormGroup>
            <label className="form-control-label">
              <FormattedMessage id="manage.serviceProvider.delete.companyId" />
            </label>
            <Input
              type="select"
              onChange={event => setCompanyId(event.target.value)}
              value={companyId}
              className="form-control form-control-sm">
              {props.availableCompanies.map(x => (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              ))}
            </Input>
          </FormGroup>
        </div>
      )}
    </ConfirmationModal>
  );
}
