import { HttpClient } from "services/httpClient/HttpClient";
import { ApiError } from "./types/ApiError";
import { ClaimDto } from "./types/user/ClaimDto";
import { PortControlIdentityUser } from "./types/user/PortControlIdentityUser";
import { NewUserDto } from "./types/user/NewUserDto";
import { UpdateUserDto } from "./types/user/UpdateUserDto";
import { UserEmailDto } from "./types/user/UserEmailDto";

export const UserApi = {
  list: (): Promise<PortControlIdentityUser[]> => {
    return HttpClient.get<PortControlIdentityUser[]>("user");
  },
  add: (user: NewUserDto): Promise<ApiError> => {
    return HttpClient.post<ApiError>("user", user);
  },
  modify: (id: string, user: UpdateUserDto): Promise<ApiError> => {
    return HttpClient.put<ApiError>(`user/${id}`, user);
  },
  delete: (userId: string): Promise<ApiError> => {
    return HttpClient.delete<ApiError>(`user/${userId}`, undefined);
  },
  getAllClaims: (): Promise<ClaimDto[]> => {
    return HttpClient.get<ClaimDto[]>("user/claims");
  },
  getUserClaims: (userId: string): Promise<ClaimDto[]> => {
    return HttpClient.get<ClaimDto[]>(`user/${userId}/claims`);
  },
  getUsersInOrganization: (): Promise<UserEmailDto[]> => {
    return HttpClient.get<UserEmailDto[]>("user/myorganization", true);
  }
};
