import { PortOutModifyModel, DefaultPortOutModifyModel } from "./PortOutModifyModel";

export type PortOutModify = {
  phoneNumberRange: string;
  portOutModifyModel: PortOutModifyModel;
};

export const DefaultPortOutModify: PortOutModify = {
  phoneNumberRange: "",
  portOutModifyModel: DefaultPortOutModifyModel
};
