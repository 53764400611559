import { getColumnSettings } from "./../../../services/uiSettings/UiSettingsService";
import { TableIdentifiers } from "services/uiSettings/TableIdentifiers";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "services/appContext/AppContext";
import { DataTableColumn } from "./types/DataTableColumn";
import { getTableSettings } from "services/uiSettings/UiSettingsService";

export const useUiTableSettings = (
  configureSettings: boolean | undefined,
  tableIdentifier: TableIdentifiers | undefined,
  columns: Array<DataTableColumn>
) => {
  const [userHiddenColumnsAccessors, setUserHiddenColumnsAccessors] = useState<Array<string>>([]);
  const { appContext } = useContext(AppContext);

  useEffect(() => {
    if (configureSettings && tableIdentifier) {
      const tableSettings = getTableSettings(tableIdentifier);

      if (tableSettings) {
        const hiddenColumns = columns.filter(
          (column) =>
            column.identifier && !(getColumnSettings(tableIdentifier, column.identifier)?.isVisible ?? true)
        );
        setUserHiddenColumnsAccessors(hiddenColumns.map((x) => x.accessor));
      }
    }
  }, [appContext.localStorageInfo.user, columns, configureSettings, tableIdentifier]);

  return { userHiddenColumnsAccessors };
};
