import { SupplementType } from "services/apis/types/order/SupplementType";
import { OrderHandlerType } from "services/apis/types/port/OrderHandlerType";
import { Moment } from "moment";
import { CustomerDetailsDto, DefaultCustomerDetailsDto } from "services/apis/types/port/CustomerDetailsDto";
import { getUtcDate } from "services/util/DateUtil";

export type SupplementModel = {
  supplementType: SupplementType;
  dueDate: Moment;
  customerDetails: CustomerDetailsDto;
  orderHandler: OrderHandlerType;
};

export const DefaultSupplementModel = {
  supplementType: SupplementType.Cancel,
  dueDate: getUtcDate(),
  customerDetails: DefaultCustomerDetailsDto,
  orderHandler: OrderHandlerType.PortingDotCom
};
