import React, { useMemo } from "react";
import { ConfiguredParameter } from "services/apis/types/settings/ConfiguredParameter";
import { ErrorHandler } from "services/customHooks/useErrors";
import { ConfiguredParameterInputConfiguration } from "./types/ConfiguredParameterInputConfiguration";
import { useIntl } from "react-intl";
import { getTranslation } from "translations/TranslationService";

type Props = {
  value?: string;
  required?: boolean;
  handleInputChange: (value: string) => void;
  errorHandler?: ErrorHandler;
  parameterInfo: ConfiguredParameter;
  inputConfiguration: ConfiguredParameterInputConfiguration;
};

export default function ConfiguredParameterInput(props: Props) {
  const intl = useIntl();

  const memoizedOptions = useMemo(
    () =>
      props.inputConfiguration.dropdownOptions?.map((x) => ({
        value: x.value,
        key: getTranslation(intl, x.keyTranslationId)
      })) ?? [],
    [intl, props.inputConfiguration.dropdownOptions]
  );

  return (
    <>
      {props.inputConfiguration.renderInput({
        labelTranslationId: props.inputConfiguration.labelId,
        placeholderTranslationId: props.inputConfiguration.placeholderId,
        appendValueTranslationId: props.inputConfiguration.appendValueId,
        required: true,
        options: memoizedOptions,
        handleInputChange: props.handleInputChange,
        value: props.value,
        errorHandler: props.errorHandler
      })}
    </>
  );
}
