import React, { useState, useContext, useEffect, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import DisconnectModifyModal from "./DisconnectModifyModal";
import { NumberStatusDto } from "services/apis/types/order/NumberStatusDto";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { handleError } from "services/util/ApiUtil";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { getValueOrEmpty } from "services/util/StringUtil";
import { OrderType } from "services/apis/types/order/OrderType";
import PortInModifyModal from "./PortInModifyModal";
import PortOutModifyModal from "./PortOutModifyModal";
import ConfirmationModal from "components/framework/modals/ConfirmationModal";
import { PortService } from "services/portControl/PortService";
import { OrderNumberActionType } from "services/portControl/types/OrderNumberActionType";
import { OrderDetailsContext } from "./OrderDetailsContainer";

type Props = {
  numberStatusDto: NumberStatusDto;
  action: OrderNumberActionType;
  confirmationMessageId?: string;
  skipConfirmation?: boolean;
  closeModal: () => void;
  onActionExecuted: (value) => void;
};

export default function ExecuteNumberAction(props: Props) {
  const intl = useIntl();
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const orderDetailsContext = useContext(OrderDetailsContext);
  const isMounted = useIsMounted();
  const [actionCompleted, setActionCompleted] = useState(false)

  const closeModal = props.closeModal;

  const onAfterActionExecution = useCallback(() => {
    showInfoNotification(
      intl.formatMessage({ id: "orders.orderDetails.actionExecutedSuccesfuly" })
    );
    const newActionExecutedValue = !actionCompleted
    props.onActionExecuted(newActionExecutedValue)
    orderDetailsContext.fetchOrder();
    closeModal();
  }, [intl, orderDetailsContext, closeModal, actionCompleted]);

  const onFinally = useCallback(() => {
    if (isMounted.current) {
      setShowLoadingIndicator(false);
    }
  }, [isMounted]);

  const handlePortingNumberAction = useCallback(() => {
    setShowLoadingIndicator(true);
    const newActionExecutedValue = !actionCompleted
    props.onActionExecuted(newActionExecutedValue)
    PortService.executeAction(props.numberStatusDto.phoneNumber, props.action)
      .then(() => {
        onAfterActionExecution();
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => onFinally());
  }, [onAfterActionExecution, onFinally, props.action, props.numberStatusDto.phoneNumber]);

  useEffect(() => {
    if (props.skipConfirmation) {
      handlePortingNumberAction();
      closeModal();
    }
  }, [handlePortingNumberAction, closeModal, props.skipConfirmation]);

  const confirmationMessageId = getValueOrEmpty(props.confirmationMessageId);

  return (
    <>
      {props.action === OrderNumberActionType.Modify ? (
        orderDetailsContext.order.orderType === OrderType.PortIn ? (
          <PortInModifyModal
            order={orderDetailsContext.order}
            closeModal={props.closeModal}
            numberStatus={props.numberStatusDto}
          />
        ) : orderDetailsContext.order.orderType === OrderType.PortOut ? (
          <PortOutModifyModal
            order={orderDetailsContext.order}
            closeModal={props.closeModal}
            numberStatus={props.numberStatusDto}
          />
        ) : (
          <DisconnectModifyModal
            orderId={orderDetailsContext.order.orderId}
            tenant={orderDetailsContext.order.tenant}
            closeModal={props.closeModal}
            numberStatus={props.numberStatusDto}
          />
        )
      ) : (
        !props.skipConfirmation && (
          <ConfirmationModal
            confirmCallback={() => handlePortingNumberAction()}
            closeModal={props.closeModal}
            title={intl.formatMessage({
              id: "orders.orderDetails.portingNumber.table.confirmActionModalTitle"
            })}
            confirmButtonText={intl.formatMessage({
              id: "orders.orderDetails.portingNumber.table.confirmAction"
            })}
            showLoadingIndicator={showLoadingIndicator}>
            {confirmationMessageId && (
              <p>
                <FormattedMessage
                  id={confirmationMessageId}
                  values={{ number: props.numberStatusDto.phoneNumber }}
                />
              </p>
            )}
          </ConfirmationModal>
        )
      )}
    </>
  );
}
