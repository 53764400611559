import { LrnResult } from "./types/lrn/LrnResult";
import { LrnNumbers } from "./types/lrn/LrnNumbers";
import { HttpClient } from "services/httpClient/HttpClient";
import { ApiError } from "./types/ApiError";

export const LrnApi = {
  get: (lrn: string): Promise<LrnResult> => {
    return HttpClient.get<LrnResult>(`lrn/${lrn}`);
  },
  getNumbers: (lrn: string): Promise<LrnNumbers> => {
    return HttpClient.get<LrnNumbers>(`lrn/${lrn}/numbers`);
  },
  create: (lrn: string, npacRegion: string): Promise<ApiError> => {
    return HttpClient.put<ApiError>(`lrn/${lrn}`, { npacRegion });
  },
  delete: (lrn: string, npacRegion: string | undefined): Promise<ApiError> => {
    return HttpClient.delete<ApiError>(`lrn/${lrn}`, { npacRegion });
  }
};
