import React, { useState } from "react";
import { useIntl } from "react-intl";
import { DocumentModel } from "components/orders/types/DocumentModel";
import { OrderApi } from "services/apis/OrderApi";
import { handleError } from "services/util/ApiUtil";
import classNames from "classnames";
import FileDownload from "components/common/FileDownload";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import DeleteDocumentConfirmationModal from "components/orders/DeleteDocumentConfirmationModal";

type Props = {
  orderId?: string;
  document: DocumentModel;
  deleteDocument: (document: DocumentModel) => void;
  disabled?: boolean;
};

export default function Document(props: Props) {
  const [documentUrl, setDocumentUrl] = useState<string>();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const intl = useIntl();

  const deleteFile = () => {
    if (props.orderId && props.document.documentId) {
      setShowLoadingIndicator(true);
      OrderApi.deleteDocument(props.orderId, props.document.documentId)
        .then(() => {
          props.deleteDocument(props.document);
          showInfoNotification(
            intl.formatMessage({ id: "orders.documents.deleteSuccessfulMessage" })
          );
          setShowDeleteConfirmationModal(false);
        })
        .catch((error) => {
          handleError(error);
        })
        .finally(() => setShowLoadingIndicator(false));
    } else {
      props.deleteDocument(props.document);
      setShowDeleteConfirmationModal(false);
    }
  };

  const downloadDocument = () => {
    if (props.document.documentId && props.orderId) {
      OrderApi.getDocument(props.orderId, props.document.documentId)
        .then((x) => {
          if (x instanceof Blob) {
            setDocumentUrl(URL.createObjectURL(x));
          }
        })
        .catch((error) => {
          handleError(error);
        });
    }
  };

  return (
    <div className="d-flex justify-content-between">
      <span>
        <i className="far fa-file-upload"></i>
        {documentUrl && (
          <FileDownload
            url={documentUrl}
            fileName={props.document.fileName}
            callback={() => setDocumentUrl(undefined)}
          />
        )}
        <small
          className={classNames("ml-2", {
            "text-underline cursor-pointer": props.document.documentId
          })}
          onClick={() => !props.disabled && downloadDocument()}>
          {props.document.fileName}
        </small>
      </span>
      <i
        className={classNames("fa fa-trash", {
          "text-danger cursor-pointer": !props.document.deleteDisabled
        })}
        onClick={() =>
          !props.disabled && !props.document.deleteDisabled && setShowDeleteConfirmationModal(true)
        }
      />
      {showDeleteConfirmationModal && (
        <DeleteDocumentConfirmationModal
          documentName={props.document.fileName}
          showLoadingIndicator={showLoadingIndicator}
          confirmCallback={deleteFile}
          closeModal={() => setShowDeleteConfirmationModal(false)}
        />
      )}
    </div>
  );
}
