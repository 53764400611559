import {
  getPhoneNumberListItems,
  replaceWhitespaceWithComma,
  isRange,
  removeDashes,
  getPhoneNumberList
} from "services/util/StringUtil";

export const getFormattedNumbers = (numbers: string): string =>
  getPhoneNumberListItems(replaceWhitespaceWithComma(numbers))
    .map((x) => (isRange(x) ? x : removeDashes(x)))
    .join(",");

export const numberIsInRange = (number: string, range: string) =>
  getPhoneNumberList(range).includes(number);
