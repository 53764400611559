import React, { useContext } from "react";
import { OrderNumberActionType } from "services/portControl/types/OrderNumberActionType";
import { OrderDto } from "services/apis/types/order/OrderDto";
import AddSupplementModal from "./AddSupplementModal";
import ApproveSupplementModal from "./ApproveSupplementModal";
import RejectSupplementModal from "./RejectSupplementModal";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";
import SupplementWarningModal from "./SupplementWarningModal";
import { AppContext } from "services/appContext/AppContext";
import { PermissionType } from "services/authorization/PermissionType";

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork

type Props = {
  order: OrderDto;
  action: OrderNumberActionType;
  confirmationMessageId?: string;
  closeModal: () => void;
  onActionExecuted: () => void;
  isWithIn24Hrs: boolean;
};

export default function ExecuteSupplementAction(props: Props) {
  const { appContext } = useContext(AppContext);
  const isUserAdmin = appContext.localStorageInfo.user?.permissions.find(value => value === PermissionType.Admin);
  const hasSvIdCreated = props.order.svId !== 0;

  return (
    <>
      {props.action === OrderNumberActionType.PreOrderSupplement ? (
        (isMixNetworkUrl && hasSvIdCreated && !isUserAdmin && props.isWithIn24Hrs) ?
          <SupplementWarningModal closeModal={props.closeModal} /> :
          <AddSupplementModal
            order={props.order}
            closeModal={props.closeModal}
            onActionExecuted={props.onActionExecuted}
          />
      ) : props.action === OrderNumberActionType.PreOrderApprove ? (
        <ApproveSupplementModal
          order={props.order}
          closeModal={props.closeModal}
          onActionExecuted={props.onActionExecuted}
        />
      ) : (
        <RejectSupplementModal
          order={props.order}
          confirmationMessageId={props.confirmationMessageId}
          closeModal={props.closeModal}
          onActionExecuted={props.onActionExecuted}
        />
      )}
    </>
  );
}
