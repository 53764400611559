import { NpacRegion } from "services/apis/types/number/NpacRegion";

export type LrnModel = {
  lrn: string;
  npacRegion: keyof typeof NpacRegion;
};

export const DefaultLrnModel: LrnModel = {
  lrn: "",
  npacRegion: "MW"
};
