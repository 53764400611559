import { AuditNumberStatus } from "./AuditNumberStatus";
import { AuditStatus } from "components/audits/types/AuditStatus";

export type NpacAuditReply = {
  auditId: string;
  name: string;
  status: AuditStatus; // TODO make string
  phoneNumbers: AuditNumberStatus[];
};

export const DefaultNpacAuditReply: NpacAuditReply = {
  auditId: "",
  name: "",
  status: AuditStatus.ActionRequired,
  phoneNumbers: []
};
