import React from "react";
import { Card, CardBody, Row, CardTitle, Col } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { SvRequestStatusConfigurations } from "./configuration/SvRequestStatusConfigurations";
import { OrderDto } from "services/apis/types/order/OrderDto";
import { OrderTypeConfigurations } from "./configuration/OrderTypeConfigurations";
import { getNumberStatus } from "services/apis/types/order/NumberStatus";

type Props = {
  order: OrderDto;
};

export default function OrderStatusCard(props: Props) {
  const activationDate = props.order.autoActivation + (props.order.activationTimeZone ? " " + props.order.activationTimeZone : "");
  return (
    <Card
      className={classNames(
        SvRequestStatusConfigurations[props.order.status]?.backgroundColor ||
        OrderTypeConfigurations[props.order.orderType]?.backgroundColor,
        "flex-grow-1"
      )}>
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle tag="h5" className="text-uppercase text-white text-muted mb-0">
              <FormattedMessage id="orders.orderDetails.status" />
            </CardTitle>
            <span className="h2 font-weight-bold text-white mb-0">{getNumberStatus(props.order.status)}</span>
          </div>
          {SvRequestStatusConfigurations[props.order.status]?.iconClass && (
            <Col className="col-auto">
              <div className="icon icon-shape bg-white rounded-circle shadow">
                <i className={`${SvRequestStatusConfigurations[props.order.status]?.iconClass}`} />
              </div>
            </Col>
          )}
        </Row>
        <Row>
          <div className="col mlb-2">
          {props.order.autoActivation && (
            <span className="h5 text-white mb-0">
              <FormattedMessage
                id="orders.orderDetails.activationDate"
                values={{activationDate: activationDate}}
              />
            </span>
          )}
         </div>
        </Row>
        {SvRequestStatusConfigurations[props.order.status]?.messageId && (
          <p className="mt-3 mb-0 text-sm">
            <span className="text-white">
              <FormattedMessage id={SvRequestStatusConfigurations[props.order.status]?.messageId} />
            </span>
          </p>
        )}
      </CardBody>
    </Card>
  );
}
