import { UpdateSettingModel } from "./types/settings/UpdateSettingModel";
import { HttpClient } from "../httpClient/HttpClient";
import { ConfiguredParameter } from "./types/settings/ConfiguredParameter";
import { ApiVersion } from "services/httpClient/ApiVersion";

export const ServiceProviderSettingsApi = {
  get: (spId: string): Promise<ConfiguredParameter[]> => {
    return HttpClient.get<ConfiguredParameter[]>(`settings/${spId}`, true, true, "", ApiVersion.V2);
  },

  update: (spId: string, updateSettingsModels: UpdateSettingModel[]) => {
    return HttpClient.post<ConfiguredParameter[]>(
      `settings/${spId}`,
      updateSettingsModels,
      true,
      true,
      "",
      ApiVersion.V2
    );
  }
};
