import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { handleError } from "services/util/ApiUtil";
import { AppContext } from "services/appContext/AppContext";
import { IntlShape, useIntl } from "react-intl";
import { getValueOrEmpty } from "services/util/StringUtil";
import FormatDateTime from "components/framework/date/FormatDateTime";
import { NumberApi } from "services/apis/NumberApi";
import { SPIDDetails } from "services/apis/types/number/SPIDDetailsDto";
import ReactTable from "components/framework/table/ReactTable";
import ToggleCheckbox from "components/common/ToggleCheckbox";
import { InventoryTableColumnIdentifiers } from "../types/InventoryColumnIdentifier";

type ColumnFilterSort = {
  isSorted?: boolean;
  isSortedDesc?: boolean;
}

type Props = {
  search: string;
  loadTable: boolean;
  isDNO: boolean;
  isInventory: () => void;
  isPooled: () => void;
};

export default function InventoryTable(props: Props) {
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [spidDetails, setSPIDDetails] = useState<SPIDDetails[]>();
  const intl = useIntl();
  const isMounted = useIsMounted();
  const { appContext } = useContext(AppContext);
  const columns = useMemo(() => getColumns(intl), [intl]);
  const definedColumns = getCol(columns);
  const [pageIndex, setPageIndex] = useState(0);
  const [count, setCount] = useState(0);
  const [defaultCount, setDefaultCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const [orderByColumn, setOrderByColumn] = useState("");
  const [orderDirection, setOrderDirection] = useState("");
  const [columnFilterSort, setColumnFilterSort] = useState<{[key:string]:ColumnFilterSort}>({});

  const handlePageChange = (pageIndex) => {
      setPageIndex(pageIndex);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event);
    setPageIndex(0);
    
  };
  
  const getSpiddetails = (
    currentPage: number,
    pageSize: number,
    searchKey: string,
    orderByColumn:string,
    orderDirection:string,
  ) => {
    setShowLoadingIndicator(true);
    NumberApi.querySPIDDetials(
      currentPage,
      pageSize,
      searchKey ? searchKey : "",
      orderByColumn,
      orderDirection,
    )
      .then((result) => {
        if (isMounted && result) {
          if(result.status === 204) {
            setSPIDDetails([]);
            setCount(0);
          }
          if (result.rows) {
            setSPIDDetails(result.rows);
            setCount(result.totalRows);
          }
        }
      })
      .catch((error) => handleError(error))
      .finally(() => {
        if (isMounted) {
          setShowLoadingIndicator(false);
        }
      });
  };



useEffect(() => {
  setShowLoadingIndicator(true);
  props.isInventory();
  props.isPooled();
  getSpiddetails(pageIndex + 1, pageSize, searchKey ? searchKey : "", orderByColumn, orderDirection);
}, [isMounted, pageIndex, pageSize, searchKey, props.loadTable]);


  useEffect(() => {
    setPageIndex(0);
    setPageSize(10);
    setSearchKey(props.search);
  }, [
    props.search
  ]);

  useEffect(() => {
    if (pageIndex !== 0 || pageSize !== 10) {
      setPageIndex(0);
      setPageSize(10);
      return;
    }
    setShowLoadingIndicator(true);
    NumberApi.querySPIDDetials(1, 10, searchKey ? searchKey : "",orderByColumn, orderDirection)
      .then((result) => {
        if (isMounted && result) {
          if (result.rows) {
            setSPIDDetails(result.rows);
            setCount(result.totalRows);
            setDefaultCount(result.totalRows);
          }
          else if (result.status === 204) {
            setSPIDDetails([]);
            setCount(0);
          }
        }
      })
      .catch((error) => handleError(error))
      .finally(() => {
        if (isMounted) {
          setShowLoadingIndicator(false);
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isMounted, appContext.localStorageInfo.selectedProfile
  ]);

  const sortableColumns = [
    InventoryTableColumnIdentifiers.PhoneNumber,
    InventoryTableColumnIdentifiers.AltSpid,
    InventoryTableColumnIdentifiers.Type,
    InventoryTableColumnIdentifiers.NpacRegion,
    InventoryTableColumnIdentifiers.LastModifiedDate, 
    InventoryTableColumnIdentifiers.Lrn,
  ];
  
  //Handle Event for sorting
const handleColumnSorted = useCallback(
  (column) => {
    const columnSortOption = columnFilterSort[column.identifier];
    if (!sortableColumns.includes(column.identifier)) return;

    if (!columnSortOption || !columnSortOption.isSorted) {
      // Column is not sorted or in an unsorted state, set it to ascending
      setColumnFilterSort((prevSortOptions) => ({
        ...prevSortOptions,
        [column.identifier]: {
          isSorted: true,
          isSortedDesc: false,
        },
      }));
      setOrderDirection("asc"); // Set order direction
      setOrderByColumn(column.identifier); // Set orderByColumn
      getSpiddetails(pageIndex +1 , pageSize, props.search, column.identifier, "asc"); // Use pageSize
    } else if (columnSortOption.isSorted && !columnSortOption.isSortedDesc) {
      // Column is sorted in ascending order, toggle to descending
      setColumnFilterSort((prevSortOptions) => ({
        ...prevSortOptions,
        [column.identifier]: {
          isSorted: true,
          isSortedDesc: true,
        },
      }));
      setOrderDirection("desc"); // Set order direction
      setOrderByColumn(column.identifier); // Set orderByColumn
      getSpiddetails(pageIndex+1, pageSize, props.search, column.identifier, "desc"); // Use pageSize
    } else {
      // Column is sorted in descending order, toggle to unsorted
      setColumnFilterSort((prevSortOptions) => ({
        ...prevSortOptions,
        [column.identifier]: {
          isSorted: false,
          isSortedDesc: false,
        },
      }));
      setOrderDirection(""); // Clear order direction
      setOrderByColumn(""); // Clear orderByColumn
      getSpiddetails(pageIndex +1, pageSize, props.search, "", ""); 
    }
  },
  [setColumnFilterSort, columnFilterSort,pageIndex, pageSize, props.search]
);
  

  return showLoadingIndicator ? (
    <LoadingIndicator white />
  ) : (
    <ReactTable
      titleComponent={getTitleComponent(intl.formatMessage({ id: "spids.table.title" }), count)}
      data={spidDetails}
      columns={props.isDNO ? columns : definedColumns}
      showExport={true}
      queryPageSize={pageSize}
      queryPageIndex={pageIndex}
      queryCount={count}
      handlePageChange={handlePageChange}
      handlePageSizeChange={handlePageSizeChange}
      hideSearch={true}
      handleColumnSorted = {handleColumnSorted}
      columnSortOptions={columnFilterSort}
    />
  );
}

const getColumns = (intl: IntlShape) => [
  {
    Header: intl.formatMessage({ id: "spids.table.phoneNumber" }),
    accessor: "phoneNumber",
    identifier: InventoryTableColumnIdentifiers.PhoneNumber,
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "spids.table.spId" }),
    accessor: "spId",
    identifier: InventoryTableColumnIdentifiers.spid,
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "spids.table.altSpId" }),
    accessor: "altSpId",
    identifier: InventoryTableColumnIdentifiers.AltSpid,
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "spids.table.type" }),
    accessor: "type",
    identifier: InventoryTableColumnIdentifiers.Type,
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "spids.table.status" }),
    accessor: "status",
    identifier: InventoryTableColumnIdentifiers.Status,
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "spids.table.providerName" }),
    accessor: "providerName",
    identifier: InventoryTableColumnIdentifiers.ProviderName,
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "spids.table.ocn" }),
    accessor: "ocn",
    identifier: InventoryTableColumnIdentifiers.Ocn,
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "spids.table.npacRegion" }),
    accessor: "npacRegion",
    identifier: InventoryTableColumnIdentifiers.NpacRegion,
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "spids.table.lata" }),
    accessor: "lata",
    identifier: InventoryTableColumnIdentifiers.Lata,
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "spids.table.state" }),
    accessor: "state",
    identifier: InventoryTableColumnIdentifiers.State,
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "spids.table.rateCenter" }),
    accessor: "rateCenter",
    identifier: InventoryTableColumnIdentifiers.RateCenter,
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "spids.table.lrn" }),
    accessor: "lrn",
    identifier: InventoryTableColumnIdentifiers.Lrn,
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "spids.table.activityDate" }),
    accessor: "activityDate",
    identifier: InventoryTableColumnIdentifiers.LastModifiedDate,
    Cell: (cell: any) => <FormatDateTime utcDate={cell.cell.value} />
  },
  {
    Header: intl.formatMessage({ id: "somos.donot.originate" }),
    accessor: "actions",
    Cell: (cell: any) => (
        <ToggleCheckbox data={cell.cell.row.original}/>
    )
  }
];

const getCol = (columns: any) => {
  const col = columns.filter((x: any) => {
    return x.Header !== "Do Not Originate"
  })
  return col
}

const getTitleComponent = (title, count)=>{
  return <div className="flex-fill">
          <h3 className="mb-2">{title}</h3>
          <h3 className="mb-0">My Active Numbers: {count}</h3>
         </div>
};
