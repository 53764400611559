import DropdownFormInput from "components/framework/forms/DropdownFormInput";
import { ServiceProviderConfiguredParameter } from "../types/ServiceProviderConfiguredParamter";
import { ConfiguredParameterInputConfiguration } from "components/common/types/ConfiguredParameterInputConfiguration";
import NumberFormInput from "components/framework/forms/NumberFormInput";
import TimeInput24Hrs from "components/framework/forms/TimeInput24Hrs";

export const ServiceProviderConfiguredParameterConfigurations: Record<
  ServiceProviderConfiguredParameter,
  ConfiguredParameterInputConfiguration
> = {
  [ServiceProviderConfiguredParameter.MediumTimerSupport]: {
    labelId: "manage.serviceProvider.modifyParameters.mediumTimerSupport",
    dropdownOptions: [
      {
        value: "N",
        keyTranslationId: "general.no"
      },
      {
        value: "Y",
        keyTranslationId: "general.yes"
      }
    ],
    renderInput: DropdownFormInput
  },
  [ServiceProviderConfiguredParameter.SvTypeSupport]: {
    labelId: "manage.serviceProvider.modifyParameters.svTypeSupport",
    dropdownOptions: [
      {
        value: "N",
        keyTranslationId: "general.no"
      },
      {
        value: "Y",
        keyTranslationId: "general.yes"
      }
    ],
    renderInput: DropdownFormInput
  },
  [ServiceProviderConfiguredParameter.NpacConnectionType]: {
    labelId: "manage.serviceProvider.modifyParameters.npacConnectionType",
    dropdownOptions: [
      {
        value: "SECONDARY",
        keyTranslationId: "manage.serviceProvider.modifyParameters.npacConnectionType.secondary"
      },
      {
        value: "GRANTOR",
        keyTranslationId: "manage.serviceProvider.modifyParameters.npacConnectionType.delegate"
      }
    ],
    renderInput: DropdownFormInput
  },
  [ServiceProviderConfiguredParameter.AutomatedActivationTime]: {
    labelId: "manage.serviceProvider.modifyParameters.automatedActivationHour",
    renderInput: TimeInput24Hrs
  },
  [ServiceProviderConfiguredParameter.AutomatedArchivingDelay]: {
    labelId: "manage.serviceProvider.modifyParameters.automatedArchivingDelay",
    appendValueId: "general.days",
    renderInput: NumberFormInput
  },
  [ServiceProviderConfiguredParameter.LSRRejectedCancel]: {
    labelId: "manage.serviceProvider.modifyParameters.automatedLSRRejectToCancel",
    appendValueId: "general.days",
    renderInput: NumberFormInput
  }
};
