import React, { useMemo, useCallback, useState } from "react";
import { useIntl, IntlShape } from "react-intl";
import UserActionsCell from "./UserActionsCell";
import { CompanyInfo } from "services/apis/types/company/CompanyInfo";
import { PortControlIdentityUser } from "services/apis/types/user/PortControlIdentityUser";
import ReactTable from "components/framework/table/ReactTable";

type Props = {
  availableCompanies: CompanyInfo[];
  users: PortControlIdentityUser[];
  userOrganization: Object[];
};

export default function UsersCard(props: Props) {
  const intl = useIntl();
  const columns = useMemo(() => getColumns(intl, props.availableCompanies), [intl, props.availableCompanies]);

  const data = props.users.map((x, index) => ({ ...x, userOrganization: props.userOrganization[index] }));

  const exportColumns = useMemo(() => {
    const updColumns = [...columns];
    updColumns.splice(columns.findIndex(c => c.accessor === "actions"), 1);
    return updColumns;
  }, [columns]);

  return <ReactTable
    title={intl.formatMessage({ id: "manage.user.title" })}
    data={data}
    columns={columns}
    exportColumns={exportColumns}
    showExport
  />;
}

const getColumns = (intl: IntlShape, availableCompanies: CompanyInfo[]) => [
  {
    Header: intl.formatMessage({ id: "manage.user.table.email" }),
    accessor: "email"
  },
  {
    Header: intl.formatMessage({ id: "manage.user.table.name" }),
    accessor: "fullName"
  },
  {
    Header: intl.formatMessage({ id: "manage.user.table.lastLogin" }),
    accessor: "lastLoginTime"
  },
  {
    Header: intl.formatMessage({ id: "manage.user.table.organization" }),
    accessor: "companyName"
  },
  {
    Header: intl.formatMessage({ id: "manage.user.table.actions" }),
    Cell: (cell: any) => <UserActionsCell user={cell.cell.row.original} availableCompanies={availableCompanies} />
  }
];
