import { useCallback, useState } from "react";
import { PortInAccountModel } from "../../components/orders/types/PortInAccountModel";

export const useActiveAccount = (
  accountModels: PortInAccountModel[],
  setAccountModels: React.Dispatch<React.SetStateAction<Array<PortInAccountModel>>>
) => {
  const [activeAccountIndex, setActiveAccountIndex] = useState(0);

  const setActiveAccountModel = useCallback(
    (newAccountModel: PortInAccountModel) => {
      setAccountModels((previousState) =>
        previousState.map((oldAccountModel, index) => {
          if (index === activeAccountIndex) {
            return newAccountModel;
          }

          return oldAccountModel;
        })
      );
    },
    [activeAccountIndex, setAccountModels]
  );

  return {
    activeAccountIndex,
    setActiveAccountIndex,
    setActiveAccountModel,
    activeAccount: accountModels[activeAccountIndex]
  };
};
