export type User = {
  id: string;
  email: string;
  lockoutEnd: Date;
  zoneInfo: string;
  givenName: string;
  familyName: string;
  locale: string;
  companyId: string;
  password: string;
  confirmPassword: string;
  fullname: string;
};

export const DefaultUser: User = {
  id: "",
  email: "",
  lockoutEnd: new Date(),
  zoneInfo: "",
  givenName: "",
  familyName: "",
  locale: "",
  companyId: "",
  password: "",
  confirmPassword: "",
  fullname: ""
};
