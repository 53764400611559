import { ModifyModel, DefaultModifyModel } from "services/apis/types/port/ModifyModel";

export type Modify = {
  phoneNumberRange: string;
  modifyModel: ModifyModel;
};

export const DefaultModify: Modify = {
  phoneNumberRange: "",
  modifyModel: DefaultModifyModel
};
