import React, { useState } from "react";
import { useOnClickOutside } from "services/customHooks/useOnClickOutside";
import { FormattedMessage } from "react-intl";

type Props = {
  labelText: string;
  text: string;
  onSetText: (newText: string) => Promise<boolean>;
};

export default function InlineEdit(props: Props) {
  const [isInputActive, setIsInputActive] = useState(false);
  const [inputValue, setInputValue] = useState(props.text);

  const handleEnter = () => {
    props.onSetText(inputValue).then((successful) => {
      if (successful) {
        setIsInputActive(false);
      }
    });
  };

  const handleEscape = () => {
    setInputValue(props.text);
    setIsInputActive(false);
  };

  const handleInputKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleEnter();
    }
    if (e.key === "Escape") {
      handleEscape();
    }
  };

  const ref = useOnClickOutside(() => {
    if (isInputActive) {
      handleEscape();
    }
  });

  const textIsEmpty = (): boolean => {
    return props.text === undefined || props.text === ""
  };

  return (
    <span className="inline-text" ref={ref}>
      {textIsEmpty() && <span><FormattedMessage id="orders.orderDetails.metadata.projectId" />: </span>}
      {!isInputActive && (
        <>
          {textIsEmpty() && <span>-</span>}
          <span className={`inline-text_copy inline-text_copy--active`}>{props.text}</span>
          <i className="ml-2 fa fa-edit" onClick={() => setIsInputActive(true)}></i>
        </>
      )}
      {isInputActive && (
        <input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyUp={(e) => handleInputKeyUp(e)}
          className="inline-text_input inline-text_input--active"
        />
      )}
    </span>
  );
}
