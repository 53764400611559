import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Modal } from "reactstrap";
import CloseButton from "components/framework/modals/CloseButton";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { FormattedMessage } from "react-intl";
import ReactTable from "components/framework/table/ReactTable";
import { IntlShape, useIntl } from "react-intl";
import { getValueOrEmpty } from "services/util/StringUtil";
import { TradingPartnerApi } from "services/apis/TradingPartnerApi";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { TppHistory, DefaultTppHistory } from "services/apis//types/tpp/TppHistory";
import { handleError } from "services/util/ApiUtil";
import FormatDateTime from "components/framework/date/FormatDateTime";

type Props = {
  closeModal: () => void;
  users: any;
};

export default function CustomerInventoryUsersModal(props: Props) {
  const [history, setHistory] = useState<TppHistory[]>([DefaultTppHistory]);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const intl = useIntl();
  const columns = useMemo(() => getColumns(intl), [intl]);
  const isMounted = useIsMounted();

  return (
    <>
      <Modal className="pc-modal-lg modal-dialog-centered" isOpen={true} zIndex={2147483648}>
        <div className="modal-header border-bottom">
          <h4 className="modal-title">
            <FormattedMessage id={"customerInventory.users.title"} />
          </h4>
          <CloseButton close={props.closeModal} />
        </div>
       {props.users ? (
          <ReactTable
            data={props.users}
            columns={columns}
            hideSearch={true}
          />) : "No Users Found"
        
        }
        </Modal>

    </>
  );
}

const getColumns = (intl: IntlShape) => [
  {
    Header: intl.formatMessage({ id: "customerInventory.users.table.name" }),
    accessor: "userName",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "customerInventory.users.table.email" }),
    accessor: "userEmail",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "customerInventory.users.table.lastLogin" }),
    accessor: "lastLogIn",
    Cell: (cell: any) => <FormatDateTime utcDate={cell.cell.value}/>
  }
]