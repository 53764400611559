import { TppStatus } from "components/networkExplorer/types/TppStatus";
import React, { useState, useCallback } from "react";
import { authorize } from "services/authorization/AuthorizationService";
import { PermissionType } from "services/authorization/PermissionType";
import { TradingPartnerApi } from "services/apis/TradingPartnerApi";
import { handleError } from "services/util/ApiUtil";
import classNames from "classnames";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { useIntl } from "react-intl";

type Props = {
    action: string;
    tppId: string;
    spId: string
    onActionExecuted: () => void;
};
export const TradingPartnerActionCell = (props: Props) => {
    const [userIsAdmin] = useState(authorize(PermissionType.Admin));
    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
    const intl = useIntl();
    const { action, tppId, spId, onActionExecuted } = { ...props };

    const updateTradingPartnerStatus = useCallback((status: TppStatus) => {
        setShowLoadingIndicator(true);
        if (status === TppStatus.TppRequestToTrade) {
            TradingPartnerApi.add(spId)
              .then(() => {
                onActionExecuted();
                showInfoNotification(
                  intl.formatMessage({ id: "tppModal.buttons.requestToTrade.successMessage" })
                );
              })
              .catch((errors) => handleError(errors))
              .finally(() => setShowLoadingIndicator(false));
        } else {
            TradingPartnerApi.updateStatus(tppId, {status: status})
                .then(() => {
                    onActionExecuted();
                    showInfoNotification(
                        intl.formatMessage({ id: "tppModal.buttons.requestToTrade.successMessage" })
                    )
                })
                .catch((error) => handleError(error))
                .finally(() => setShowLoadingIndicator(false));
        }
    }, [ tppId, spId, intl, onActionExecuted]);

    return (
        <div className="d-flex ">
            {showLoadingIndicator ? (
                <i
                    className={classNames("fas fa-spinner fa-spin")} />) : (
                <>
                    {action && action.length > 0 && action.toLowerCase() === TppStatus.TppSubmitted.toLowerCase() && userIsAdmin ? (
                        <>
                            <img
                                alt="PortControl"
                                src={require("assets/img/icons/actions/accept_trade.svg")}
                                className="accept-trade-icon cursor-pointer"
                                onClick={() => updateTradingPartnerStatus(TppStatus.TppAprroved)} />
                            <img
                                alt="PortControl"
                                src={require("assets/img/icons/actions/decline_trade.svg")}
                                className="accept-trade-icon cursor-pointer"
                                onClick={() => updateTradingPartnerStatus(TppStatus.TppDenied)} />
                        </>
                    ) : action && action.length > 0 && action.toLowerCase() === TppStatus.TppRequestToTrade.toLowerCase() && (
                        <img
                            alt="PortControl"
                            src={require("assets/img/icons/actions/trade_icon.svg")}
                            className="trade-action-icon cursor-pointer"
                            onClick={() => updateTradingPartnerStatus(TppStatus.TppRequestToTrade)} />
                    )}
                </>)}
        </div>
    );
};
