import { KeyValuePair } from "services/types/KeyValuePair";

export const NpacRegion: Record<string, string> = {
  MW: "Midwest",
  MA: "Midatlantic",
  NE: "Northeast",
  SE: "Southeast",
  SW: "Southwest",
  WE: "Western",
  WC: "Westcoast",
  CA: "Canada"
};

export const npacRegionDropdownOptions: KeyValuePair<string, string>[] = Object.keys(NpacRegion).map(x => {
  return { key: NpacRegion[x], value: x };
});
