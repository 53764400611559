import React, { useMemo, useCallback } from "react";
import { useIntl } from "react-intl";
import { CompanyInfo, CompanyType } from "services/apis/types/company/CompanyInfo";
import ReactTable from "components/framework/table/ReactTable";
import OrganizationActionsCell from "./OrganizationActionsCell";
import { isValidZipCode } from "services/util/StringUtil";


type Props = {
  companies: CompanyInfo[];
};

export default function OrganizationsCard(props: Props) {
  const intl = useIntl();
  const transformedData = transformToAccountHierarchyData(props.companies);
  const data = transformedData.map(x => ({...x, parentName: x.name}))

  data.forEach((obj) => {
    if (obj.subRows) {
      obj.subRows = obj.subRows.map((row) => ({
        ...row,
        parentName: obj.name,
      }));
    }
  });
  
  const columns = useMemo(() => [
    {
      accessor: "companyType",
      Header: "",
      canFilter: false,
      Cell: (cell: any) => (
        <OrganizationCompanyTypeIcon cell={cell} />
      )
    },
    {
      Header: intl.formatMessage({ id: "manage.organization.table.organization" }),
      accessor: "name"
    },
    {
      Header: intl.formatMessage({ id: "manage.organization.table.parentSpid" }),
      accessor: "parentSpId",
      Cell: ({ row }) => (
        <span>{row.original.parentSpId?.join(", ")}</span>
      )
    },
    {
      Header: intl.formatMessage({ id: "manage.organization.table.associatedSpids" }),
      accessor: "associatedSpId",
      Cell: (cell: any) => <span className={"pc-manage-organization-associated-spids"}>{cell.cell.value.join(", ")}</span>
    },
    {
      Header: intl.formatMessage({ id: "manage.organization.table.parentName" }),
      accessor: "parentName",
    },
    {
      id: 'expander',
      Header: intl.formatMessage({ id: "manage.organization.table.actions" }),
      Cell: ({ row }) => (
        <>
          <OrganizationActionsCell company={row.original} availableCompanies={props.companies} key={row.original.id} />
          {row.subRows.length > 0 && (<OrganizationRowExpanderAction row={row} />)}
        </>
      )
    },
  ], [props.companies, intl]);

  const exportColumns = useMemo(() => {
    var updColumns = [...columns];
    updColumns.splice(columns.findIndex(c => c.accessor === "actions" ), 1);
    return updColumns;
  }, [columns]);

  const updateHiddenColumns = useCallback(() => {
    const excludedColumnsForWholesale = ["parentName"];
    return excludedColumnsForWholesale.map((c) => c as string);
  }, []);

  return (
    <ReactTable
      title={intl.formatMessage({ id: "manage.organization.title" })}
      data={data}
      columns={columns}
      hasExpandedData={true}
      filterMapper={filterMapper}
      hiddenColumns={updateHiddenColumns}
      exportColumns={exportColumns}
      showExport
    />
  );
}

const filterMapper = (companyInfo: ParentChildAccountRow) => {
  return {
    ...companyInfo,
    associatedSpId: companyInfo?.associatedSpId?.join(", "),
    parentSpId: companyInfo.parentSpId?.join(", ")
  };
};

const transformToAccountHierarchyData = (companies: CompanyInfo[]) => {
  return filterParentWithChildSubRows(companies)
    .concat(filterStandAloneAccounts(companies));
}

const filterParentWithChildSubRows = (companies: CompanyInfo[]) => {
  const parentCompanies = companies.filter((p) => p.companyType === CompanyType.Parent);
  return parentCompanies.map((p) => {
    const validChildCompanies = companies.filter(
      (c) =>
        c.companyType === CompanyType.Child &&
        c.parentId === p.id
    );

    return {
      id: p.id,
      parentId: p.parentId,
      name: p.name,
      nonSpId: p.nonSpId,
      notificationEmail: p.notificationEmail,
      addressLine1: p.addressLine1,
      addressLine2: p.addressLine2,
      city: p.city,
      state: p.state,
      zipCode: p.zipCode,
      primaryContact: p.primaryContact,
      created: p.created,
      billingId: p.billingId,
      parent: p.parent,
      primaryContactId: p.primaryContactId,
      companyType: p.companyType,
      parentSpId: p.associatedSpId,
      associatedSpId: validChildCompanies
        .map((c) => c.associatedSpId)
        .reduce((ag, i, a) => ag.concat(i), []) // flatten spid array
        .filter((v, i, a) => i === a.findIndex((v2) => v2 === v)), // return distinct results


      subRows: validChildCompanies
        .map(m => {
          return {
            id: m.id,
            name: m.name,
            nonSpId: m.nonSpId,
            addressLine1: m.addressLine1,
            addressLine2: m.addressLine2,
            city: m.city,
            state: m.state,
            zipCode: m.zipCode,
            primaryContactId: m.primaryContactId,
            created: m.created,
            notificationEmail: m.notificationEmail,
            parentId: m.parentId,
            billingId: m.billingId,
            parent: m.parent,
            primaryContact: m.primaryContact,
            companyType: m.companyType,
            parentSpId: undefined,
            associatedSpId: m.associatedSpId,
            depth: 1,
          } as ParentChildAccount
        })
    } as ParentChildAccountRow
  });
}

const filterStandAloneAccounts = (companies: CompanyInfo[]) => {
  return companies.filter(sa => sa.companyType === CompanyType.StandAlone)
    .map((sa) => {
      return {
        id: sa.id,
        name: sa.name,
        nonSpId: sa.nonSpId,
        csrEnabled: sa.csrEnabled,
        tenantId: sa.tenantId,
        addressLine1: sa.addressLine1,
        addressLine2: sa.addressLine2,
        city: sa.city,
        state: sa.state,
        zipCode: sa.zipCode,
        primaryContactId: sa.primaryContactId,
        created: sa.created,
        notificationEmail: sa.notificationEmail,
        billingId: sa.billingId,
        parent: sa.parent,
        primaryContact: sa.primaryContact,
        parentId: sa.parentId,
        companyType: sa.companyType,
        parentSpId: undefined,
        associatedSpId: sa.associatedSpId,
        clientType:sa.clientType,
        depth: 0,
        subRows: []
      } as ParentChildAccountRow
    });
}

const OrganizationCompanyTypeIcon = (props: { cell: any }) => {
  var accountIdentifierText =
    props.cell.cell.value === CompanyType.Parent ? "P" : props.cell.cell.value === CompanyType.Child
      ? "C"
      : "A";

  var accountIdentifierClassName = "pc-manage-organization-account-type-identifier";
  switch (props.cell.cell.value) {
    case CompanyType.Parent:
      accountIdentifierClassName += " pc-manage-organization-account-type-identifier-parent";
      break;

    case CompanyType.Child:
      accountIdentifierClassName += " pc-manage-organization-account-type-identifier-child";
      break;

    default:
      accountIdentifierClassName += " pc-manage-organization-account-type-identifier-stand-alone";
      break;
  }

  return (<span className={accountIdentifierClassName}>{accountIdentifierText}</span>)
}

interface ParentChildAccountRow extends ParentChildAccount {
  subRows: ParentChildAccount[]
}

interface ParentChildAccount extends CompanyInfo {
  parentSpId: string[] | undefined,
  associatedSpId: string[],
  depth: number
}


const OrganizationRowExpanderAction = (props: { row: any }) => {
  var expandClassName = "fal fa-chevron-right pc-manage-organization-row-expander-" +
    (props.row.canExpand && props.row.isExpanded
      ? "active"
      : "inactive");

  return (
    <span {...props.row.getToggleRowExpandedProps({ style: { paddingLeft: "20px" } })}><i className={expandClassName}></i></span>
  );
}

