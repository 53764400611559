import React, { useEffect, useState } from "react";
import ConfirmationModal from "components/framework/modals/ConfirmationModal";
import { useIntl, FormattedMessage } from "react-intl";
import { OrderApi } from "services/apis/OrderApi";
import moment, { Moment } from "moment";
import { DefaultOrder, OrderDto } from "services/apis/types/order/OrderDto";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleFieldOrApiErrors } from "services/util/ApiUtil";
import { useIsMounted } from "services/customHooks/useIsMounted";
import DateTimeFormInput from "components/framework/forms/DateTimeFormInput";
import { ApproveSupplementConfirmationText } from "./configuration/ApproveSupplementConfirmationText";
import { SupplementType } from "services/apis/types/order/SupplementType";

type Props = {
  order: OrderDto;
  closeModal: () => void;
  onActionExecuted: () => void;
};

export default function ApproveSupplementModal(props: Props) {
  const [dueDate, setDueDate] = useState<Moment | null>(moment(props.order.desiredDueDate));
  const [order, setOrder] = useState<OrderDto>(DefaultOrder);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const intl = useIntl();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (dueDate) {
      setOrder((prevOrder) => ({
        ...prevOrder,
        desiredDueDate: dueDate.toDate(),
      }));
    }
  }, [dueDate]);

  const confirmationMessageId = props.order.supplementType
    ? ApproveSupplementConfirmationText[props.order.supplementType]
    : "orders.order.actions.approveQuestion";

  const supplementType = props.order.supplementType ? props.order.supplementType : undefined;

  const approveSupplement = () => {
    setShowLoadingIndicator(true);

    OrderApi.approveSupplement(props.order.orderId, props.order.tenant, dueDate?.toDate(), supplementType)
      .then(() => {
        showInfoNotification(
          intl.formatMessage({ id: "orders.orderDetails.actionExecutedSuccesfuly" })
        );
        if (dueDate) {
          props.order.desiredDueDate = dueDate.toDate(); 
        }  
        setOrder((prevOrder) => ({
          ...prevOrder,
          desiredDueDate: dueDate?.toDate() || prevOrder.desiredDueDate,
        }));
        props.onActionExecuted();
        props.closeModal();
      })
      .catch((error) => handleFieldOrApiErrors(error))
      .finally(() => {
        if (isMounted.current) {
          setShowLoadingIndicator(false);
        }
      });
  };

  return (
    <ConfirmationModal
      confirmCallback={approveSupplement}
      closeModal={props.closeModal}
      title={intl.formatMessage({
        id: "orders.orderDetails.portingNumber.table.confirmActionModalTitle"
      })}
      confirmButtonText={intl.formatMessage({
        id: "orders.orderDetails.portingNumber.table.confirmAction"
      })}
      showLoadingIndicator={showLoadingIndicator}>
      <p>
        <FormattedMessage id={confirmationMessageId} values={{ number: props.order.orderId }} />
      </p>
      {props.order.supplementType !== SupplementType[SupplementType.Cancel] && (
        <DateTimeFormInput
          labelTranslationId="orders.supplement.dueDate"
          value={moment(order.desiredDueDate)} // Use the updated desiredDueDate from the state
          handleInputChange={(value: Moment | null) => setDueDate(value)}
        />
      )}
      {props.order.tnsInOtherOrders && !props.order.supplementType || props.order.supplementType !== SupplementType.Cancel && (
        <p className="text-danger font-weight-normal">
          {intl.formatMessage({id: "orders.orderDetails.portingNumber.table.confirmActionDuplicateNotifcation"})}
        </p>
      )}
    </ConfirmationModal>
  );
}