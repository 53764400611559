import React, { useState, useEffect, useMemo, useContext } from "react";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { AppContext } from "services/appContext/AppContext";
import { handleError } from "services/util/ApiUtil";
import { IntlShape, useIntl } from "react-intl";
import { getValueOrEmpty } from "services/util/StringUtil";
import FormatDateTime from "components/framework/date/FormatDateTime";
import { NumberApi } from "services/apis/NumberApi";
import ReactTable from "components/framework/table/ReactTable";
import escapeRegExp from "lodash/escapeRegExp";

type Props = {
  search: string;
  isPooled: () => void;
  isInventory: () => void;
};

export default function PooledTable(props: Props) {
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [spidPooledDetails, setSpidPooledDetails] = useState<any>();
  const [blockDashDetails, setBlockDashDetails] = useState<any>();
  const [totalBlockDashDetails, setTotalBlockDashDetails] = useState<any>();
  const [selectedBlockDashValue, setSelectedBlockDashValue] = useState<any>();
  const intl = useIntl();
  const isMounted = useIsMounted();
  const { appContext } = useContext(AppContext);
  const columns = useMemo(() => getColumns(intl), [intl]);
  const [searchKey, setSearchKey] = useState("");
  const [noContent, setNoContent] = useState(false);

  useEffect(() => {
    setShowLoadingIndicator(true);
    props.isPooled();
    props.isInventory();
    NumberApi.getPooledBlockDashBySpid()
      .then((result) => {
        if (isMounted && result) {
          if (result.status !== 204) {
            let array = [] as any;
            let totalArray = [] as any;
            result.forEach((obj: any) => {
              if (array.length <= 1000) {
                array.push({ value: obj, label: obj });
              }
              totalArray.push({ value: obj, label: obj });
            });
            setTotalBlockDashDetails(totalArray);
            setBlockDashDetails(array);
            let firstBlockDash = array[0];
            if(searchKey && selectedBlockDashValue) {
              blockDashHandleSelect(selectedBlockDashValue);
            }
            else {
              blockDashHandleSelect(firstBlockDash);
            }
          } else if (result.status === 204) {
            setSpidPooledDetails([]);
            setShowLoadingIndicator(false);
          }
        }
      })
      .catch((error) => handleError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, appContext.localStorageInfo.selectedProfile, searchKey]);

  useEffect(() => {
    setSearchKey(props.search);
  }, [props.search]);

  const blockDashHandleSelect = (event) => {
    setShowLoadingIndicator(true);
    setSelectedBlockDashValue(event);
    NumberApi.getpoolednumbersbyspid(event.value)
      .then((result) => {
        if (result.status === 204) {
          setNoContent(true);
          setSpidPooledDetails([]);
        }
        else if (searchKey.includes("-")) {
          if(searchKey.length === 15) {
            //range search starts
            const sum = Number(searchKey?.split("-")[0]?.slice(0, 6));
            const rangevalues: any = [searchKey?.split("-")[0], sum + searchKey?.split("-")[1]];
            const resultedList: any = [];
            for (let i = parseInt(rangevalues[0]); i <= parseInt(rangevalues[1]); i++) {
              resultedList.push(String(i));
            }
            const allRangeResults: any = [];
            for (let i = 0; i < resultedList.length; i++) {
              const searchResult = result.filter((data: any) => {
                return data.phoneNumber.toLowerCase().includes(resultedList[i]);
              });
              allRangeResults.push(searchResult);
            }
            const finalRangeObj = allRangeResults.flat();
            setSpidPooledDetails(finalRangeObj);
          } else {
            setSpidPooledDetails([]);
          }
        }
        //range search end
        //single value search start
        else if ([searchKey].length >= 1 && !searchKey.includes(",") && !searchKey.includes("-")) {
          const searchResult = result.filter((data: any) => {
            return data.phoneNumber.toLowerCase().includes(searchKey.toLowerCase());
          });
          setSpidPooledDetails(searchResult);
        }
        //single value search ends
        //comma seperator search start
        else if (searchKey.includes(",")) {
          const allFilteredVAlues: any = [];
          for (let i = 0; i < searchKey.split(",").length; i++) {
            const searchResult = result.filter((data: any) => {
              return data.phoneNumber.toLowerCase().includes(searchKey.split(",")[i]);
            });
            allFilteredVAlues?.push(searchResult);
          }
          const objFinal = allFilteredVAlues.flat();
          setSpidPooledDetails(objFinal);
        }

        //comma seperator search ends
        else {
          setSpidPooledDetails(result);
        }
      })
      .catch((error) => handleError(error))
      .finally(() => {
        if (isMounted) {
          setShowLoadingIndicator(false);
        }
      });
  };

  const onInputChange = (event) => {
    setBlockDashDetails(() => {
      const matchByStart = [] as any;
      const matchByInclusion = [] as any;
      const regByInclusion = new RegExp(escapeRegExp(event), "i");
      const regByStart = new RegExp(`^${escapeRegExp(event)}`, "i");

      for (const option of totalBlockDashDetails) {
        if (regByInclusion.test(option.label)) {
          if (regByStart.test(option.label)) {
            matchByStart.push(option);
          } else {
            matchByInclusion.push(option);
          }
        }
      }
      return [...matchByStart, ...matchByInclusion].slice(0, 1000);
    });
  };

  return showLoadingIndicator ? (
    <LoadingIndicator white />
  ) : (
    <ReactTable
      title={intl.formatMessage({ id: "pooled.table.title" })}
      options={blockDashDetails}
      onInputChange={onInputChange}
      selectedValue={selectedBlockDashValue}
      handleSelect={blockDashHandleSelect}
      data={spidPooledDetails}
      noContent={noContent}
      columns={columns}
      showDropDown={true}
      showExport={true}
    />
  );
}

const getColumns = (intl: IntlShape) => [
  {
    Header: intl.formatMessage({ id: "spids.table.phoneNumber" }),
    accessor: "phoneNumber",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "spids.table.spId" }),
    accessor: "spId",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "spids.table.altSpId" }),
    accessor: "altSpId",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "spids.table.type" }),
    accessor: "type",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "spids.table.status" }),
    accessor: "status",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "spids.table.providerName" }),
    accessor: "providerName",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "spids.table.ocn" }),
    accessor: "ocn",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "spids.table.npacRegion" }),
    accessor: "npacRegion",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "spids.table.lata" }),
    accessor: "lata",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "spids.table.state" }),
    accessor: "state",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "spids.table.rateCenter" }),
    accessor: "rateCenter",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "spids.table.lrn" }),
    accessor: "lrn",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "spids.table.activityDate" }),
    accessor: "activityDate",
    Cell: (cell: any) => <FormatDateTime utcDate={cell.cell.value} />
  }
];
