export type Orders = {
    orderType?: string;
    status?: string;
    gainingSpId?: string;
    loosingSpId?: string;
    orderTableCarrierName?: string;
    workedBy?: string;
    dueDate?: Array<any>;
    nextWorkDate?: Array<any>;
    pageSize?: number;
    pageIndex?: number;
    adminProjectId?: string;
};

export const DefaultOrderDetails: Orders = {
    orderType: "Any",
    status: "Any",
    gainingSpId: "Any",
    loosingSpId: "Any",
    orderTableCarrierName: "Any",
    workedBy: "Any",
    pageSize: 10,
    pageIndex: 1,
    adminProjectId: "Any"
};
