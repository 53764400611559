import { AccountType } from "services/apis/types/port/AccountType";

export type NumberCnamUpsertModel = {
  firstName: string;
  lastName: string;
  businessName: string;
  accountType: AccountType;
};

export const DefaultNumberCnamUpsertModel: NumberCnamUpsertModel = {
  firstName: "",
  lastName: "",
  businessName: "",
  accountType: AccountType.Residential
};
