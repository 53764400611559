import { Block } from "./types/block/Block";
import { BlockResult } from "./types/block/BlockResult";
import { ContaminatedNumber } from "./types/block/ContaminatedNumber";
import { HttpClient } from "../httpClient/HttpClient";
import { ApiError } from "./types/ApiError";

export const BlockApi = {
  get: (blockDash: string): Promise<BlockResult> => {
    return HttpClient.get<BlockResult>(`block/${blockDash}`);
  },

  getNumbers: (blockDash: string): Promise<ContaminatedNumber[]> => {
    return HttpClient.get<ContaminatedNumber[]>(`block/${blockDash}/numbers`);
  },

  addOrModify: (block: Block): Promise<ApiError> => {
    return HttpClient.put<ApiError>(`block/${block.blockDash}`, { ...block }, true, true);
  }
};
