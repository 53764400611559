import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { AppContext } from "services/appContext/AppContext";
import { authorize, authorizeAny } from "services/authorization/AuthorizationService";
import { PermissionType } from "services/authorization/PermissionType";

export default function Index() {

  const { appContext } = useContext(AppContext);
  const previousUrl = appContext.previousUrl === "/" ? '' : appContext.previousUrl;
  const hasTokenIdentifier = appContext.localStorageInfo.hasTokenIdentifier;
  return (
    <>
      {  previousUrl && !hasTokenIdentifier ? (
        <Redirect to={{ pathname: previousUrl}} />
      ) : authorize(PermissionType.Explore) ? (
        <Redirect to={{ pathname: "/NumberExplorer"}}/>
      ) : authorize(PermissionType.Network) ? (
        <Redirect to={{ pathname: "/NetworkExplorer" }} />
      ) : authorizeAny([PermissionType.SOARead, PermissionType.PreOrderFree], true) ? (
        <Redirect to={{ pathname: "/Orders" }} />
      ) : (
        <Redirect to={{ pathname: "/Unauthorized" }} />
      )}
    </>
  );
}
