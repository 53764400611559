import { Moment } from "moment";
import { getUtcDate, getUtcDateFirstDayOfMonth } from "services/util/DateUtil";

export type ExportModel = {
  from: Moment;
  to: Moment;
};

export const DefaultExportModel: ExportModel = {
  from: getUtcDateFirstDayOfMonth(),
  to: getUtcDate()
};
