import React from "react";
import { Label } from "reactstrap";
import { FormattedMessage } from "react-intl";

type Props = {
  labelTranslationId: string;
  required?: boolean;
};

export default function CardSectionHeader(props: Props) {
  return (
    <div className="mb-3 d-flex">
      <Label className="h4 p-0" lg="12">
        <FormattedMessage id={props.labelTranslationId} />
        {props.required && <span className="text-danger"> *</span>}
      </Label>
    </div>
  );
}
