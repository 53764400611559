import React from "react";
import { Label, FormGroup } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import Select2 from "react-select2-wrapper";

export default function Select2Dropdown(props) {
  const intl = useIntl();
  
  return (
    <FormGroup>
      <Label className="form-control-label">
        <FormattedMessage id="orders.portOut.stepOne.spId" />
      </Label>
      <Select2
        className="form-control form-control-sm"
        defaultValue="1"
        options={{
          placeholder: intl.formatMessage({ id: "orders.portOut.stepOne.spId.placeholder" })
        }}
        onChange={e => props.handleInputChange(e.currentTarget.value)}
        data={props.data}
        value={props.value}
      />
     {props.showErrorMessage &&
        <div className="text-red text-sm mt-1">
          <FormattedMessage id="orders.portOut.stepOne.spId.required" />
        </div>} 
    </FormGroup>
  );
}
