import React from "react";

type Props = {
  close: () => void;
};

export default function CloseButton(props: Props) {
  return (
    <button
      aria-label="Close"
      className="close m-3 position-absolute top-0 right-0 sticky-top p-0 pc-close-modal"
      data-dismiss="modal"
      type="button"
      onClick={() => props.close()}>
      <i className="far fa-times text-primary"></i>
    </button>
  );
}
