import React, { useState } from "react";
import NumberHistoryModal from "../numberHistoryModal/NumberHistoryModal";
import { PhoneNumberType } from "services/apis/types/number/PhoneNumberType";

type Props = {
  phoneNumber: string;
  phoneNumberType: keyof typeof PhoneNumberType;
};

export default function PhoneNumberCell(props: Props) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <span
        className="cursor-pointer text-primary text-underline"
        onClick={e => {
          e.preventDefault();
          setShowModal(true);
        }}>
        {props.phoneNumber}
      </span>
      {showModal && (
        <NumberHistoryModal
          setShowModal={x => setShowModal(x)}
          showModal={showModal}
          phoneNumber={props.phoneNumber}
          phoneNumberType={props.phoneNumberType}
        />
      )}
    </>
  );
}
