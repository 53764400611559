import { NpacRegion } from "../number/NpacRegion";
import { SvType } from "../block/SvType";
import { DpcSsnInfo, DefaultDpcSsnInfo } from "../block/DpcSsnInfo";
import { OptionalDataInfo, DefaultOptionalDataInfo } from "../number/OptionalDataInfo";
import { PortOutRejectionReason } from "components/orders/types/PortOutRejectionReason";
import { getUtcDate } from "services/util/DateUtil";

export type ModifyModel = {
  modifyType: string;
  portDirection: string;
  npacRegion?: keyof typeof NpacRegion;
  spId?: string;
  oldSpId?: string;
  phoneNumber?: string;
  dueDate?: Date;
  lrn?: string;
  svType?: keyof typeof SvType;
  billingId?: string;
  userLocationValue?: string;
  userLocationType?: string;
  networkData?: DpcSsnInfo;
  optionalData?: OptionalDataInfo;
  reject?: boolean;
  conflictCode?: keyof typeof PortOutRejectionReason;
  clientReference?: string;
  portToOrginal?: boolean;
  disconnectDate?: Date;
  releaseDate?: Date;
  dueTime?: Date;
  orderId?: string;
};

export const DefaultModifyModel: ModifyModel = {
  modifyType: "VSAC",
  portDirection: "",
  npacRegion: "MW",
  spId: "",
  oldSpId: "",
  phoneNumber: "",
  dueDate: getUtcDate().toDate(),
  lrn: "",
  svType: "wireline",
  billingId: "",
  userLocationValue: "",
  userLocationType: "",
  networkData: DefaultDpcSsnInfo,
  optionalData: DefaultOptionalDataInfo,
  reject: false,
  conflictCode: "",
  clientReference: "",
  portToOrginal: false,
  disconnectDate: new Date(),
  releaseDate: new Date(),
  orderId: ""
};
