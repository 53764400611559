import React, { useState } from "react";
import ConfirmationModal from "components/framework/modals/ConfirmationModal";
import { useIntl, FormattedMessage } from "react-intl";
import { OrderApi } from "services/apis/OrderApi";
import { OrderDto } from "services/apis/types/order/OrderDto";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleError, getFieldErrors } from "services/util/ApiUtil";
import { useIsMounted } from "services/customHooks/useIsMounted";
import DropdownFormInput from "components/framework/forms/DropdownFormInput";
import { SupplementRejectionReason } from "services/apis/types/order/SupplementRejectionReason";
import TextAreaFormInput from "components/framework/forms/TextAreaFormInput";
import { useErrors } from "services/customHooks/useErrors";
import {
  DefaultRejectSupplementModel,
  RejectSupplementModel
} from "services/apis/types/order/RejectSupplementModel";
import { AddError, Errors, HasErrors } from "components/framework/errorHandling/ErrorUtil";
import { nameOf } from "services/util/ObjectUtil";

type Props = {
  order: OrderDto;
  confirmationMessageId?: string;
  closeModal: () => void;
  onActionExecuted: () => void;
};

export default function RejectSupplementModal(props: Props) {
  const [rejectSupplementModel, setRejectSupplementModel] = useState<RejectSupplementModel>({
    ...DefaultRejectSupplementModel
  });
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const { getErrorHandler, setErrors } = useErrors();
  const intl = useIntl();
  const isMounted = useIsMounted();

  const rejectSupplement = () => {
    const errors = validate(rejectSupplementModel);

    if (HasErrors(errors)) {
      setErrors(errors);
    } else {
      setShowLoadingIndicator(true);

      OrderApi.rejectSupplement(props.order.orderId, props.order.tenant, rejectSupplementModel)
        .then(() => {
          showInfoNotification(
            intl.formatMessage({ id: "orders.orderDetails.actionExecutedSuccesfuly" })
          );
          props.onActionExecuted();
          props.closeModal();
        })
        .catch((error) => {
          handleError(error);
          if (isMounted.current) {
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
          }
        });
    }
  };

  return (
    <ConfirmationModal
      confirmCallback={rejectSupplement}
      closeModal={props.closeModal}
      title={intl.formatMessage({
        id: "orders.orderDetails.portingNumber.table.confirmActionModalTitle"
      })}
      confirmButtonText={intl.formatMessage({
        id: "orders.orderDetails.portingNumber.table.confirmAction"
      })}
      showLoadingIndicator={showLoadingIndicator}>
      {props.confirmationMessageId && (
        <>
          <p>
            <FormattedMessage
              id={props.confirmationMessageId}
              values={{ number: props.order.orderId }}
            />
          </p>
          <DropdownFormInput
            labelTranslationId="orders.supplement.rejectionReason"
            required
            value={rejectSupplementModel.rejectionReason}
            handleInputChange={(value: string) => {
              setRejectSupplementModel((prev) => ({
                ...prev,
                rejectionReason: value,
                rejectDescription: undefined
              }));
            }}
            options={Object.keys(SupplementRejectionReason).map((x) => {
              return { key: SupplementRejectionReason[x], value: x };
            })}
          />
          {rejectSupplementModel.rejectionReason === "OTHER" && (
            <TextAreaFormInput
              value={rejectSupplementModel.rejectDescription}
              handleInputChange={(value: string) =>
                setRejectSupplementModel((prev) => ({ ...prev, rejectDescription: value }))
              }
              rows={3}
              labelTranslationId="orders.supplement.rejectDescription"
              errorHandler={getErrorHandler(nameOf<RejectSupplementModel>("rejectDescription"))}
            />
          )}
        </>
      )}
    </ConfirmationModal>
  );
}

const validate = (rejectSupplementModel: RejectSupplementModel): Errors => {
  const errors: Errors = {};
  if (
    rejectSupplementModel.rejectionReason === "OTHER" &&
    !rejectSupplementModel.rejectDescription
  ) {
    AddError(
      errors,
      nameOf<RejectSupplementModel>("rejectDescription"),
      "orders.supplement.rejectDescription.required"
    );
  }

  return errors;
};
