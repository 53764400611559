import React from "react";
import { Container, Row, Col, InputGroup, InputGroupAddon } from "reactstrap";
import TextFormInput from "components/framework/forms/TextFormInput";
import { SpidFilterSettings } from "services/uiSettings/SpidFilterSettings";
import { AppContextAccessor } from "services/appContext/AppContextAccessor";
import CheckboxFormInput from "../forms/CheckboxFormInput";
import {CustomViewSettingBase, CustomViewSettingBaseProps} from "./CustomViewSettingsBase";
import { LocalStorageUser } from "services/appContext/LocalStorageUser";
import { v4 as uuidv4 } from 'uuid';

interface State {
  selectAllCheckbox : boolean;
  selectedSpidList : SpidFilterSettings[],
  spidLookup : SpidInfo[],
  searchValue : string,
  searchResults: SpidFilterSettings[]
}

interface SpidInfo {
  spId : string;
  name : string;
}

export default class SpidsCustomViewSettings extends React.Component<CustomViewSettingBaseProps> implements CustomViewSettingBase {
  
  ref: React.RefObject<CustomViewSettingBase> | undefined;
  settingIdentifier: string = "spidFilterSettings";
  
  state : State = { 
    selectAllCheckbox : false,
    selectedSpidList : [],
    spidLookup : [],
    searchResults : [],
    searchValue : ""
  };

  searchValue : string | undefined;
  

  componentDidMount(){
    
    var spidList = this.getInitialStateForSelectedSpids();
    var spidLookup = this.getUser().spIdProfiles.map((m)=> { return { spId: m.spId, name : m.name}});
    
    this.setState({selectedSpidList : spidList, spidLookup : spidLookup}, this.searchAndUpdateSearchResults);
    
  }

   getUser = () :  LocalStorageUser => {
    return AppContextAccessor.getAppContext().localStorageInfo.user as LocalStorageUser;
  }

  getSpidLabel(spId: string, spidName: string): string | undefined {
    var name = this.state.spidLookup.find(f=> f.name === spidName)?.name
    return spId + " - " + name;
  }

  searchAndUpdateSearchResults = (value?: string | undefined) => {
    
    // updating input 
    this.searchValue = value;

    var results = this.searchAndFilter(value);

    this.setState({searchResults: results});
  }

  searchAndFilter = (value: string | undefined) =>{
    var results : SpidFilterSettings[] = [];
      if (value === undefined || value ==="")
        {
          results = this.state.selectedSpidList;
        }
        else{
          var foundSpIds = this.state.spidLookup.filter((v2)=> 
          v2.name.toLocaleLowerCase().indexOf(value.toLocaleLowerCase() as string) > -1 
          || v2.spId.toLocaleLowerCase().indexOf(value.toLocaleLowerCase() as string) > -1).map(f => f.spId);
  
          results = this.state.selectedSpidList.filter((v) => foundSpIds.indexOf(v.spId) > -1);
      }
    return results;
  }
  

  render(){
    return (
      <>
      <Container>
      <Row>
          <Col className="col-12 d-inline-flex">
          <CheckboxFormInput
                      className="d-inline"
                       key={""}
                       value={""}
                       checked={this.state.selectAllCheckbox}
                       handleInputChange={(v)=>this.toggleSelectAllSearchedSpids(v)}
                    />
          <TextFormInput 
              formGroupClassName = "d-inline"
              value={this.searchValue}
              placeholderTranslationId="table.settingsModal.spidCustomViewSettings.spidSearch"
              handleInputChange = {this.searchAndUpdateSearchResults}
            />
          </Col>
      </Row>
      <Row>
          <InputGroup className="pl-3 pt-0 pc-react-table-custom-view-settings pre-scrollable">
            <InputGroupAddon addonType="prepend" style={{flexDirection: "column"}}>
            {this.state.searchResults.map((v,i,a)=> (
                    <CheckboxFormInput
                      key={v.id}
                      value={v.id}
                      checked={v.selected}
                      label={this.getSpidLabel(v.spId, v.name)}
                      disabled={false}
                      handleInputChange={() => this.toggleSpidSelection(v.id)}
                    />
                ))}
            </InputGroupAddon>
          </InputGroup>
        </Row>
      </Container>
      </>
    );
  }   
 
  getInitialStateForSelectedSpids = () : SpidFilterSettings[] => {
    const sortAlphabeticallyFn = (a,b)=> a.spId.localeCompare(b.spId, undefined, {numeric: false, sensitivity: 'base'});

      var localStorageUser = AppContextAccessor.getAppContext().localStorageInfo.user;
      
      var settings = localStorageUser?.settings.ui?.tableSettings?.find((s)=> s.identifier === this.props.tableIdentifier);
      if (localStorageUser){
        var spids: SpidFilterSettings[] = AppContextAccessor.getAppContext().localStorageInfo.user?.spIdProfiles
            .sort(sortAlphabeticallyFn)    
            .map(p=> { 
              var foundSelectedSpid = settings?.spidFilterSettings?.find(s=> s.spId === p.spId && s.companyId === p.companyId)?.selected ?? false;
              return {spId : p.spId, selected: foundSelectedSpid, name: p.name, id: uuidv4(), companyId: p.companyId}
            }) ?? [];

        return spids;
      }
      
      return [];
  };

  toggleSpidSelection = (spidId: string) : void =>{
    var spidIdx = this.state.selectedSpidList.findIndex(s=> s.id === spidId);
      if (spidIdx >= 0) 
      {
       this.setState(prev=>{
        var updated = [...prev["selectedSpidList"]];
        updated[spidIdx].selected = !updated[spidIdx].selected;
        return {selectedSpidList :  updated};
       });
      }
  };

  toggleSelectAllSearchedSpids = (value:boolean) :void =>{
    this.setState(prev=>{
      var updated = [...prev["selectedSpidList"]];
      this.state.searchResults.forEach(spid=>
      {
        var spidIdx = this.state.selectedSpidList.findIndex(f=> f.id === spid.id);
        if ( updated[spidIdx] !== undefined){
          updated[spidIdx].selected = value;
        }
      });

      return {selectedSpidList : updated, selectAllCheckbox : value};
    });
  }

  getSettings() : [string, any[]]{
    return [this.settingIdentifier , this.state.selectedSpidList];
  } 

}



