import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CardSectionHeader from "components/framework/cards/CardSectionHeader";
import { DocumentModel } from "components/orders/types/DocumentModel";
import { OrderApi } from "services/apis/OrderApi";
import { handleFieldOrApiErrors } from "services/util/ApiUtil";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import Document from "./Document";
import classNames from "classnames";
import { showErrorNotification } from "components/framework/notification/NotificationUtil";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork

type Props = {
  orderId?: string;
  documents: DocumentModel[];
  addDocument: (document: DocumentModel) => void;
  deleteDocument: (document: DocumentModel) => void;
  disabled?: boolean;
  fileInputMarginTop: boolean;
  label?: string;
};

export default function Documents(props: Props) {
  const intl = useIntl();

  const storeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && !props.documents.find((x) => x.fileName === file.name)) {
      if (file.size > 3145728 || file.type === "") {
        if (file.size > 3145728) {
          showErrorNotification(intl.formatMessage({ id: "orders.documents.sizeTooBigMessage" }));
        }
        if (file.type === "") {
          showErrorNotification(intl.formatMessage({ id: "orders.documents.noTypeMessage" }));
        }
      } else {
        const newDocument: DocumentModel = { fileName: file.name, file: file, type: file.type };
        if (props.orderId) {
          OrderApi.addDocument(props.orderId, file)
            .then(() => {
              newDocument.uploaded = true;
              props.addDocument(newDocument);
              showInfoNotification(
                intl.formatMessage({ id: "orders.documents.addSuccessfullMessage" })
              );
            })
            .catch((error) => handleFieldOrApiErrors(error));
        } else {
          props.addDocument(newDocument);
        }
      }
    } else {
      showErrorNotification(intl.formatMessage({ id: "orders.documents.duplicateUpload" }));
    }
    e.target.value = "";
  };

  return (
    <>
      <CardSectionHeader labelTranslationId={!isMixNetworkUrl ? "orders.documents" : "orders.loaDocument"} required={isMixNetworkUrl ? true : false}  />
      <div className="mb-3">
        {props.documents.length > 0 &&
          props.documents.map((x) => (
            <Document
              key={x.fileName}
              orderId={props.orderId}
              document={x}
              deleteDocument={props.deleteDocument}
              disabled={props.disabled}
            />
          ))}
      </div>
      <div
        className={classNames("custom-file", {
          "margin-top-29": props.documents.length === 0 && props.fileInputMarginTop === true
        })}>
        <input
          type="file"
          className="custom-file-input"
          lang="en"
          onChange={storeFile}
          disabled={props.disabled}
        />
        <label className="custom-file-label">
          <FormattedMessage id="orders.documents.placeholder" />
        </label>
      </div>
    </>
  );
}
