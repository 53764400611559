import { NoteDto } from "services/apis/types/order/NoteDto";
import React from "react";
import FormatDateTime from "components/framework/date/FormatDateTime";
import classNames from "classnames";
import { NoteOrigin } from "services/apis/types/order/NoteOrigin";
import { NoteVisibility } from "services/apis/types/order/NoteVisibility";

type Props = {
  noteDto: NoteDto;
};

export default function NoteBlock(props: Props) {
  return (
    <div className="timeline-block">
      <span className="timeline-step badge-success">
        <i
          className={classNames({
            "ni ni-bell-55": props.noteDto.origin === NoteOrigin.System,
            "far fa-comment-dots":
              props.noteDto.origin === NoteOrigin.User &&
              props.noteDto.visibility === NoteVisibility.Private,
            "fas fa-megaphone":
              props.noteDto.origin === NoteOrigin.User &&
              props.noteDto.visibility === NoteVisibility.Public
          })}
        />
      </span>
      <div className="timeline-content">
        <small className="text-muted font-weight-bold">
          <FormatDateTime utcDate={props.noteDto.createdAt} />
        </small>
        <h5 className="mt-3 mb-0">{props.noteDto.title}</h5>
        <p className="text-sm mt-1 mb-0">{props.noteDto.note}</p>
        <small className="mt-3 text-muted">- {props.noteDto.createdBy}</small>
      </div>
    </div>
  );
}
