import { NpaNxxResult } from "./types/npaNxx/NpaNxxResult";
import { HttpClient } from "../httpClient/HttpClient";
import { ApiError } from "./types/ApiError";
import { CreateNpxNxxModel } from "./types/npaNxx/CreateNpxNxxModel";

export const NpaNxxApi = {
  get: (code: string): Promise<NpaNxxResult> => {
    return HttpClient.get<NpaNxxResult>(`npanxx/${code}`);
  },
  delete: (npanxx: string): Promise<ApiError> => {
    return HttpClient.delete<ApiError>(`npanxx/${npanxx}`, {});
  },
  create: (npaNxx: string, npaNxxModel: CreateNpxNxxModel): Promise<ApiError> => {
    return HttpClient.put<ApiError>(`npanxx/${npaNxx}`, npaNxxModel, true, true);
  }
};
