import React , { useEffect } from "react";

export type Props = {
    portalId: string;
    ctaToken: string;
}

export function HubSpotCallToAction(props: Props) {
    useEffect(()=> {

      // loading script
      const script = document.createElement('script');
      script.src ='https://js.hscta.net/cta/current.js';
      script.async = true;
      document.body.appendChild(script);
      
      var hbspt = window['hbspt'];
      if (hbspt) {
        hbspt.cta.load(props.portalId, props.ctaToken, {"useNewLoader":"true","region":"na1"});
      }

      // remove script once component has been dismounted
      return ()=>{
        delete window['hbspt'];
        document.body.removeChild(script);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    return (
      <div className="pc-ad-container">
        <span
          className="hs-cta-wrapper"
          id={`hs-cta-wrapper-${props.ctaToken}`}
        >
          <span
            className={`hs-cta-node hs-cta-${props.ctaToken}`}
            id ={`hs-cta-${props.ctaToken}`}
          >
          <a
            href={`https://cta-redirect.hubspot.com/cta/redirect/${props.portalId}/${props.ctaToken}`} 
            target="_blank"
            rel="noopener"
          >
            <img
              className="hs-cta-img"
              style={{ borderWidth: 0}}
              id={`hs-cta-img-${props.ctaToken}`}
              src={`https://no-cache.hubspot.com/cta/default/${props.portalId}/${props.ctaToken}.png`}
              alt="Have you tried our new Vendor Port-Out Portal?"
            />
          </a>
          </span>
        </span>
      </div>
    );
  }