import React, { useState } from "react";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import ActivateDeactivateModal from "components/customerInventory/activateDeactivateModal";

type Props = {
  activation: any;
  customerName: string;
  customerId: string;
  onUpdateSuccess: () => void;
};
export default function SideToggle(props: Props) {

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(props.activation);
  const [showModal, setShowModal] = useState(false);


  const handleCheckboxChange = (e) => {
    setShowModal(true)
  };

  const handleToggleChange = (newActivation) => {
    if(newActivation !== props.activation) {
      props.onUpdateSuccess();
      setChecked(!checked)
    }
  };

  return (
    <>
    <label className="switch">
      <input type="checkbox" checked={checked} onChange={handleCheckboxChange}
      >
      </input>
      {loading &&
        <LoadingIndicator className="toggleLoader" small/>
      }
      <span className="slider"></span>
    </label>

    {showModal && (
      <ActivateDeactivateModal
        closeModal={() => setShowModal(false)}
        activation={props.activation}
        customerName={props.customerName}
        customerId={props.customerId}
        onUpdateToggle={handleToggleChange}
      />
    )}
    </>
  );
}
