import { DpcSsnInfo, DefaultDpcSsnInfo } from "../block/DpcSsnInfo";
import { OptionalDataInfo, DefaultOptionalDataInfo } from "./OptionalDataInfo";
import { NpacRegion } from "./NpacRegion";

export type NumberDetails = {
  phoneNumber: string;
  spId: string;
  originalSpId: string;
  providerName: string;
  type: string;
  status: string;
  ocn: string;
  npacRegion: keyof typeof NpacRegion;
  activationDate: string;
  lata: string;
  state: string;
  rateCenter: string;
  lrn: string;
  billingId: string;
  userLocationValue: string;
  userLocationType: string;
  networkData: DpcSsnInfo;
  optionalData: OptionalDataInfo;
};

export const DefaultNumberDetails: NumberDetails = {
  phoneNumber: "",
  spId: "",
  originalSpId: "",
  providerName: "",
  type: "",
  status: "",
  ocn: "",
  npacRegion: NpacRegion.MW,
  activationDate: "",
  lata: "",
  state: "",
  rateCenter: "",
  lrn: "",
  billingId: "",
  userLocationType: "",
  userLocationValue: "",
  networkData: DefaultDpcSsnInfo,
  optionalData: DefaultOptionalDataInfo
};
