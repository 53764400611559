import { KeyValuePair } from "services/types/KeyValuePair";

export const NoteVisibility: Record<string, string> = {
  Private: "Private",
  Public: "Public"
};

export const noteVisibilityDropdownOptions: KeyValuePair<string, string>[] = Object.keys(
  NoteVisibility
).map((x) => {
  return { key: NoteVisibility[x], value: NoteVisibility[x] };
});
