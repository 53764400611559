import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { ProvisionOrderListDto } from "services/apis/types/order/ProvisionOrderListDto";
import { AppContext } from "services/appContext/AppContext";

type Props = {
  order: ProvisionOrderListDto;
  handleInputChange?: any;
}

export default function ProvisionOrderSelect(props: Props) {
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;
  
  const intl = useIntl();
  const handleSelect = (e) => {
    props.handleInputChange({ ...props.order, selected: e.currentTarget.checked }, e.nativeEvent.shiftKey)
  }

  return (
    <div className="d-flex align-items-center mb-2">
      <input
        className={`order-checkbox ${theme === "light" ? "lblue-checkbox" : ""}`}
        id={props.order.phoneNumber}
        type="checkbox"
        onChange={(e) => handleSelect(e)}
        checked={props.order.selected}
      />
    </div>
  )
}