export enum InventoryTableColumnIdentifiers {
    PhoneNumber = "phoneNumber",
    spid = "spid",
    AltSpid = "altSpid",
    Type = "type",
    Status = "status",
    ProviderName ="providerName",
    NpacRegion = "npacRegion",
    Ocn = 'ocn',
    Lata = "lata",
    State = "state",
    RateCenter = "rateCenter",
    Lrn = "lrn",
    LastModifiedDate = "lastModifiedDate"
  }

