import React, { useContext, ReactNode } from "react";
import { Route, Redirect } from "react-router-dom";
import { AppContext } from "services/appContext/AppContext";
import { authorizeAny } from "services/authorization/AuthorizationService";
import { PermissionType } from "services/authorization/PermissionType";

type Props = {
  children: ReactNode;
  path?: string | string[];
  exact?: boolean;
  vpopDisabled?: boolean;
  nonSpidDisabled?: boolean;
  userPermissions?: PermissionType[];
  spidPermissions?: PermissionType[];
};

export default function PrivateRoute(props: Props) {
  const { appContext } = useContext(AppContext);
  var isAuthorized =
    (!props.userPermissions && !props.spidPermissions) ||
    (props.userPermissions && authorizeAny(props.userPermissions)) ||
    (props.spidPermissions && authorizeAny(props.spidPermissions, true));

  //VPOP user can have OrgAdmin permissions but still some pages might not be visisble
  if (appContext.localStorageInfo.selectedProfile?.external && props.vpopDisabled === true) {
    isAuthorized = false;
  }

  // Wholesale or other NonSpid user can be VPOP with various permissions but still some pages might not be visisble
  if (appContext.localStorageInfo.selectedProfile?.nonSpIdCompany && props.nonSpidDisabled === true){  
    isAuthorized = false;
  }

  return (
    <Route
      path={props.path}
      exact={props.exact}
      render={({ location }) =>
        appContext.localStorageInfo.authenticationInfo.authenticated ? (
          isAuthorized ? (
            <>{props.children}</>
          ) : (
            <Redirect to={{ pathname: "/Unauthorized", state: { from: location } }} />
          )
        ) : (
          <Redirect to={{ pathname: "/Login", state: { from: location } }} />
        )
      }
    />
  );
}
