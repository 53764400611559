import React, { ReactNode } from "react";
import PrivateRoute from "services/routing/PrivateRoute";
import AdminLayout from "components/framework/adminLayout/AdminLayout";
import { PermissionType } from "services/authorization/PermissionType";

type Props = {
  children: ReactNode;
  path?: string | string[];
  exact?: boolean;
  vpopDisabled?: boolean;
  nonSpidDisabled?: boolean;
  userPermissions?: PermissionType[];
  spidPermissions?: PermissionType[];
};

export function AdminRoute(props: Props) {
  return (
    <PrivateRoute {...props}>
      <AdminLayout>{props.children}</AdminLayout>
    </PrivateRoute>
  );
}
