import { OrderDto } from "services/apis/types/order/OrderDto";
import React, { useContext } from "react";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { FormattedMessage } from "react-intl";
import AccountDetails from "../AccountDetails";
import HandlingOverview from "./HandlingOverview";
import ServiceAddress from "../ServiceAddress";
import Documents from "../Documents";
import { OrderDetailsContext } from "./OrderDetailsContainer";
import CsrRequest from "../CsrRequest";
import { OrderType } from "services/apis/types/order/OrderType";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork

type Props = {
  order: OrderDto;
};

export default function OrderDetailsCard(props: Props) {
  const orderDetailsContext = useContext(OrderDetailsContext);

  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">
          <FormattedMessage id="orders.orderDetails.orderDetailsCard.cardTitle" />
        </h3>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={9}>
            <HandlingOverview order={props.order} />
            {props.order.orderType === OrderType.PortIn && 
            (<CsrRequest
                csrRequest={props.order.csrRequested}
                setCsrRequest={(()=>{})}
                disabled={true}
              />)}
            <AccountDetails customerDetails={props.order.customerDetails} orderType={props.order.orderType}/>
            <ServiceAddress serviceAddress={props.order?.customerDetails?.serviceAddress} />
          </Col>
          <Col md={3}>
            <Documents
              documents={props.order.documents}
              orderId={props.order.orderId}
              addDocument={orderDetailsContext.fetchOrder}
              deleteDocument={orderDetailsContext.fetchOrder}
              fileInputMarginTop={true}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
