import { DpcSsnInfo } from "./DpcSsnInfo";
import { OptionalDataInfo } from "../number/OptionalDataInfo";
import { SvType } from "./SvType";

export type Block = {
  blockDash: string;
  lrn: string;
  svType: keyof typeof SvType;
  networkData?: DpcSsnInfo;
  optionalData?: OptionalDataInfo;
};

export const DefaultBlock: Block = {
  blockDash: "",
  lrn: "",
  svType: SvType.Wireline
};
