import { AppContextAccessor } from "services/appContext/AppContextAccessor";
import { ApiError } from "../apis/types/ApiError";
import { IdentityService } from "../identity/IdentityService";
import { FieldError } from "../apis/types/FieldError";
import { EnvironmentUtil } from "../util/EnvironmentUtil";
import { getApiUrl } from "./HttpClientUtil";
import { ApiVersion } from "./ApiVersion";

export const HttpClient = {
  get: async <T>(
    url: string,
    addAuthorizationToken: boolean = true,
    addSelectedTenant: boolean = false,
    customTenant = "",
    version: ApiVersion = ApiVersion.V1
  ) => {
    await IdentityService.checkAndRenewAccessToken();

    const headersContent = getHeaders(addAuthorizationToken, addSelectedTenant, customTenant);

    return fetch(getApiUrl(url, version), {
      method: "GET",
      headers: headersContent
    }).then(handleResponse<T>());
  },

  getResource: async (
    url: string,
    addAuthorizationToken: boolean = true,
    addSelectedTenant: boolean = false,
    customTenant = "",
    version: ApiVersion = ApiVersion.V1
  ) => {
    await IdentityService.checkAndRenewAccessToken();

    const headersContent = getHeaders(addAuthorizationToken, addSelectedTenant, customTenant);

    return fetch(getApiUrl(url, version), {
      method: "GET",
      headers: headersContent
    }).then(handleResourceResponse());
  },

  post: async <T>(
    url: string,
    bodyObject: any,
    addAuthorizationToken: boolean = true,
    addSelectedTenant: boolean = false,
    customTenant: string = "",
    version: ApiVersion = ApiVersion.V1
  ): Promise<T> => {
    await IdentityService.checkAndRenewAccessToken();

    const headersContent = getHeaders(addAuthorizationToken, addSelectedTenant, customTenant);

    return fetch(getApiUrl(url, version), {
      method: "POST",
      headers: headersContent,
      body: JSON.stringify(bodyObject)
    }).then(handleResponse<T>());
  },

  postResource: async <T>(
    url: string,
    bodyObject: any,
    addAuthorizationToken: boolean = true,
    addSelectedTenant: boolean = false,
    customTenant: string = "",
    version: ApiVersion = ApiVersion.V1
  ): Promise<T> => {
    await IdentityService.checkAndRenewAccessToken();

    const headersContent = getHeaders(addAuthorizationToken, addSelectedTenant, customTenant, true);

    return fetch(getApiUrl(url, version), {
      method: "POST",
      headers: headersContent,
      body: bodyObject
    }).then(handleResponse<T>());
  },

  delete: async <T>(
    url: string,
    bodyObject: any,
    addAuthorizationToken: boolean = true,
    addSelectedTenant: boolean = true,
    customTenant: string = "",    
    version: ApiVersion = ApiVersion.V1
  ): Promise<T> => {
    await IdentityService.checkAndRenewAccessToken();

    const headersContent = getHeaders(addAuthorizationToken, addSelectedTenant, customTenant);

    return fetch(getApiUrl(url, version), {
      method: "DELETE",
      headers: headersContent,
      body: JSON.stringify(bodyObject)
    }).then(handleResponse<T>());
  },

  put: async <T>(
    url: string,
    bodyObject: any = {},
    addAuthorizationToken: boolean = true,
    addSelectedTenant: boolean = true,
    customTenant: string = "",    
    version: ApiVersion = ApiVersion.V1
  ): Promise<T> => {
    await IdentityService.checkAndRenewAccessToken();

    const headersContent = getHeaders(addAuthorizationToken, addSelectedTenant, customTenant);

    return fetch(getApiUrl(url, version), {
      method: "PUT",
      headers: headersContent,
      body: JSON.stringify(bodyObject)
    }).then(handleResponse<T>());
  },

  putResource: async <T>(
    url: string,
    bodyObject: any = {},
    addAuthorizationToken: boolean = true,
    addSelectedTenant: boolean = true,
    customTenant: string = ""
  ): Promise<T> => {
    await IdentityService.checkAndRenewAccessToken();

    const headersContent = getHeaders(addAuthorizationToken, addSelectedTenant, customTenant, true);

    return fetch(EnvironmentUtil.apiV1Url(url), {
      method: "PUT",
      headers: headersContent,
      body: bodyObject
    }).then(handleResponse<T>());
  },

  patch: async <T>(
    url: string,
    bodyObject: any = {},
    addAuthorizationToken: boolean = true,
    addSelectedTenant: boolean = true,
    customTenant: string = "",
    version: ApiVersion = ApiVersion.V1
  ): Promise<T> => {
    await IdentityService.checkAndRenewAccessToken();

    const headersContent = getHeaders(addAuthorizationToken, addSelectedTenant, customTenant);

    return fetch(getApiUrl(url, version), {
      method: "PATCH",
      headers: headersContent,
      body: JSON.stringify(bodyObject)
    }).then(handleResponse<T>());
  }
};

const handleResponse = <T>(): ((value: Response) => Promise<T>) => {
  return async (response) => {
    if (response.ok) {
      try {
        const json = await response.json();

        return json as T;
      } catch (error) {
        return (response as unknown) as T;
      }
    } else {
      return await handleErrorResponse(response);
    }
  };
};

const handleResourceResponse = (): ((value: Response) => Promise<Blob | ApiError>) => {
  return async (response) => {
    if (response.ok) {
      try {
        return await response.blob();
      } catch (error) {
        throw response;
      }
    } else {
      return await handleErrorResponse(response);
    }
  };
};

const handleErrorResponse = async (response: Response) => {
  let json: {
    fieldErrors?: { [id: string]: FieldError[] };
    message?: string;
    error?: number;
  } = {};

  try {
    json = await response.json();
  } catch (error) {
    //ignore
  }

  const apiError: ApiError = { ...json, error: response.status };

  throw apiError;
};

const getHeaders = (
  addAuthorizationToken: boolean,
  addSelectedTenant: boolean,
  customTenant: string,
  hasFile?: boolean
) => {
  let headersContent: Record<string, string> = {
    Accept: "text/plain",
    "Cache-Control": "no-cache",
    Pragma: "no-cache"
  };

  if (!hasFile) {
    headersContent["Content-Type"] = "application/json-patch+json";
  }

  if (customTenant || addSelectedTenant) {
    const spId = customTenant || getSpId();

    if (spId) {
      headersContent["X-PortControl-Tenant"] = spId;
    }
  }

  if (addAuthorizationToken) {
    headersContent["Authorization"] = `Bearer ${
      AppContextAccessor.getAppContext().localStorageInfo.authenticationInfo.access_token
    }`;
  }

  return headersContent;
};

const getSpId = () => {
  const appContext = AppContextAccessor.getAppContext();

  return appContext.localStorageInfo.selectedProfile
    ? appContext.localStorageInfo.selectedProfile.spId
    : "";
};
