import { HttpClient } from "services/httpClient/HttpClient";
import { CompanyDto } from "./types/company/CompanyDto";
import { CompanyInfo } from "./types/company/CompanyInfo";
import { ApiError } from "./types/ApiError";
import { ServiceProviderDto } from "./types/company/ServiceProviderDto";

export const CompanyApi = {
  list: (): Promise<CompanyInfo[]> => {
    return HttpClient.get<CompanyInfo[]>("company");
  },
  add: (company: CompanyDto): Promise<ApiError> => {
    return HttpClient.post<ApiError>("company", company, true, true);
  },
  modify: (id: string, company: CompanyDto): Promise<ApiError> => {
    return HttpClient.put<ApiError>(`company/${id}`, company, true, true);
  },
  delete: (companyId: string): Promise<ApiError> => {
    return HttpClient.delete<ApiError>(`company/${companyId}`, {});
  },
  get: (companyId: string): Promise<CompanyInfo> => {
    return HttpClient.get<CompanyInfo>(`company/${companyId}`);
  },
  addSpId: (serviceProvider: ServiceProviderDto): Promise<ApiError> => {
    return HttpClient.put<ApiError>(
      `company/${serviceProvider.companyId}/spid/${serviceProvider.spId}`,
      { type: parseInt(serviceProvider.type), mediumTimer: serviceProvider.mediumTimer },
      true,
      true
    );
  },
  deleteSpId: (companyId: string, spId: string): Promise<ApiError> => {
    return HttpClient.delete<ApiError>(`company/${companyId}/spid/${spId}`, {});
  }
};
