import React, { useState } from "react";
import ActionDropdown from "components/networkExplorer/common/ActionDropdown";
import ActionDropdownItem from "components/networkExplorer/common/ActionDropdownItem";
import ServiceProviderDeleteModal from "./ServiceProviderDeleteModal";
import { CompanyInfo } from "services/apis/types/company/CompanyInfo";
import ModifyServiceProviderParametersModal from "./ModifyServiceProviderParametersModal";

type Props = {
  spId: string;
  availableCompanies: CompanyInfo[];
};

export default function ServiceProviderActionsCell(props: Props) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showModifyModal, setShowModifyModal] = useState(false);
  const [actionsOpen, setActionsOpen] = useState(false);

  return (
    <>
      <ActionDropdown
        actionsOpen={actionsOpen}
        actionsToggle={() => setActionsOpen((prevState) => !prevState)}>
        <ActionDropdownItem
          handleAction={() => setShowModifyModal(true)}
          iconClassName="fa fa-edit"
          labelId="manage.serviceProvider.table.actions.modify"
        />
        <ActionDropdownItem
          handleAction={() => setShowDeleteModal(true)}
          iconClassName="fa fa-trash-alt"
          labelId="manage.serviceProvider.table.actions.delete"
        />
      </ActionDropdown>
      {showModifyModal && (
        <ModifyServiceProviderParametersModal
          spId={props.spId}
          closeModal={() => setShowModifyModal(false)}
        />
      )}
      {showDeleteModal && (
        <ServiceProviderDeleteModal
          spId={props.spId}
          closeModal={() => setShowDeleteModal(false)}
          availableCompanies={props.availableCompanies}
        />
      )}
    </>
  );
}
