import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Card, CardHeader, CardBody, CardFooter } from "reactstrap";
import { NoteDto as NotesDto } from "services/apis/types/order/NoteDto";
import { OrderApi } from "services/apis/OrderApi";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { handleError } from "services/util/ApiUtil";
import NoteBlock from "./NoteBlock";
import NoteForm from "./NoteForm";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { getUtcDate } from "services/util/DateUtil";
import { OrderDto } from "services/apis/types/order/OrderDto";

type Props = {
  order: OrderDto;
};

export default function NotesCard(props: Props) {
  const [noteDtos, setNoteDtos] = useState<NotesDto[]>();
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const isMounted = useIsMounted();

  useEffect(() => {
    setShowLoadingIndicator(true);

    OrderApi.getNotes(props.order.orderId)
      .then((result) => {
        if (isMounted) {
          setNoteDtos(
            result.sort(
              (a, b) =>
                getUtcDate(b.createdAt).toDate().getTime() -
                getUtcDate(a.createdAt).toDate().getTime()
            )
          );
        }
      })
      .catch((error) => handleError(error))
      .finally(() => {
        if (isMounted) {
          setShowLoadingIndicator(false);
        }
      });
  }, [props.order, isMounted, triggerRefresh]);

  return (
    <Card className="height-810">
      <CardHeader>
        <h3 className="mb-0">
          <FormattedMessage id="orders.orderDetails.notes.title" />
        </h3>
      </CardHeader>
      <CardBody className="pc-timeline-container">
        {showLoadingIndicator ? (
          <LoadingIndicator />
        ) : (
          <div
            className="timeline timeline-one-side"
            data-timeline-axis-style="dashed"
            data-timeline-content="axis">
            {noteDtos &&
              noteDtos.map((x) => <NoteBlock noteDto={x} key={x.createdAt.toString()} />)}
          </div>
        )}
      </CardBody>
      <CardFooter>
        <NoteForm
          orderId={props.order.orderId}
          triggerRefresh={() => setTriggerRefresh((p) => !p)}
        />
      </CardFooter>
    </Card>
  );
}
