export type ServiceAddressDto = {
  directionPrefix: string;
  streetName: string;
  streetNameSuffix: string;
  directionSuffix: string;
  number: string;
  addressLine2: string;
  city: string;
  zipCode: string;
  state: string;
};

export const DefaultServiceAddressDto = {
  directionPrefix: "",
  streetName: "",
  streetNameSuffix: "",
  directionSuffix: "",
  number: "",
  addressLine2: "",
  city: "",
  zipCode: "",
  state: ""
};
