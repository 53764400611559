export enum AuditStatus {
  ActionRequired = "ActionRequired",
  Pending = "Pending",
  Authorized = "Authorized",
  Approved = "Approved",
  Active = "Active",
  Sending = "Sending",
  Conflict = "Conflict",
  Old = "Old",
  Failed = "Failed",
  PartialFailure = "PartialFailure",
  Canceled = "Canceled",
  CancelPending = "CancelPending",
  DisconnectPending = "DisconnectPending",
  ActivatePending = "ActivatePending"
}
