import { Row, Container } from "reactstrap";
import React from "react";
import { FormattedMessage } from "react-intl";
import BreadcrumbHeader from "components/common/BreadcrumbHeader";
import BillingTransactions from "./BillingTransactions";
import PendingReports from "./PendingReports";

export default function AdminReports() {

  return (
    <Container fluid>
      <Row>
        <BreadcrumbHeader>
          <FormattedMessage id="reports.adminReports.subtitle" />
        </BreadcrumbHeader>
        <BillingTransactions />
        <PendingReports />
      </Row>
    </Container>
  );
}
