import { OrdersTableColumnIdentifiers } from "../../components/orders/types/OrdersTableColumnIdentifiers";
import { TableSettings } from "services/uiSettings/TableSettings";
import { TableIdentifiers } from "services/uiSettings/TableIdentifiers";
import { WorkQueueTableColumnIdentifiers } from "components/workQueue/types/WorkQueueTableColumnIdentifiers";

export const DefaultTableSettings: Record<TableIdentifiers, TableSettings> = {
  [TableIdentifiers.Orders]: {
    identifier: TableIdentifiers.Orders,
    columnSettings: [
      { identifier: OrdersTableColumnIdentifiers.ProjectId, isVisible: true },
      { identifier: OrdersTableColumnIdentifiers.DueDate, isVisible: true },
      { identifier: OrdersTableColumnIdentifiers.OrderType, isVisible: true },
      { identifier: OrdersTableColumnIdentifiers.Status, isVisible: true },
      { identifier: OrdersTableColumnIdentifiers.GainingSpid, isVisible: true },
      { identifier: OrdersTableColumnIdentifiers.LoosingSpid, isVisible: true },
      { identifier: OrdersTableColumnIdentifiers.NextWorkDate, isVisible: false },
      { identifier: OrdersTableColumnIdentifiers.WorkedBy, isVisible: false },
      { identifier: OrdersTableColumnIdentifiers.NumberCount, isVisible: true },
      { identifier: OrdersTableColumnIdentifiers.Actions, isVisible: true }
    ],

    spidFilterSettings: []
  },

  [TableIdentifiers.WorkQueue]: {
    identifier: TableIdentifiers.WorkQueue,
    columnSettings: [
      { identifier: WorkQueueTableColumnIdentifiers.ProjectId, isVisible: true },
      { identifier: WorkQueueTableColumnIdentifiers.DueDate, isVisible: true },
      { identifier: WorkQueueTableColumnIdentifiers.OrderType, isVisible: true },
      { identifier: WorkQueueTableColumnIdentifiers.Status, isVisible: true },
      { identifier: WorkQueueTableColumnIdentifiers.Organization, isVisible: true },
      { identifier: WorkQueueTableColumnIdentifiers.GainingSpId, isVisible: true },
      { identifier: WorkQueueTableColumnIdentifiers.LoosingSpId, isVisible: true },
      { identifier: WorkQueueTableColumnIdentifiers.NextWorkDate, isVisible: false },
      { identifier: WorkQueueTableColumnIdentifiers.WorkedBy, isVisible: false },
      { identifier: WorkQueueTableColumnIdentifiers.NumberCount, isVisible: true },
      { identifier: WorkQueueTableColumnIdentifiers.Actions, isVisible: true }
    ],
    
    spidFilterSettings: []
  },

  [TableIdentifiers.TradingPartner]: {
    identifier: TableIdentifiers.TradingPartner,
  },
  [TableIdentifiers.CustomerInventory]: {
    identifier: TableIdentifiers.CustomerInventory,
    columnSettings: undefined,
    spidFilterSettings: undefined
  },
  [TableIdentifiers.WebhookLogs]: {
    identifier: TableIdentifiers.WebhookLogs,
  }
};
