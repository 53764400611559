
export type PendingReportsDto = {
  orderStatusCrossCheck: string[],
  portControl_Tenant: string,
  portControl_TenantType: string,
  portControl_SvId: 0,
  portControl_PhoneNumber: string,
  portControl_OrderId: string,
  portControl_OrderType: string,
  portControl_NewSpId: string,
  portControl_OldSpId: string,
  portControl_NewDueDate: Date,
  portControl_OldDueDate: Date,
  portControl_Status: string,
  portControl_Authorized: boolean,
  portControl_ModifiedDate: Date,
  npac_VersionId: string,
  npac_NewSpId: string,
  npac_OldSpId: string,
  npac_Status: string,
  npac_NewDueDate: Date,
  npac_OldDueDate: Date,
  npac_Authorized: boolean,
  npac_CompletionDate: Date
};

export const DefaultPendingReportsDto: PendingReportsDto = {
  orderStatusCrossCheck:[],
  portControl_Tenant: "",
  portControl_TenantType: "",
  portControl_SvId: 0,
  portControl_PhoneNumber: "",
  portControl_OrderId: "",
  portControl_OrderType: "",
  portControl_NewSpId: "",
  portControl_OldSpId: "",
  portControl_NewDueDate: new Date(),
  portControl_OldDueDate: new Date(),
  portControl_Status: "",
  portControl_Authorized: false,
  portControl_ModifiedDate: new Date(),
  npac_VersionId: "",
  npac_NewSpId: "",
  npac_OldSpId: "",
  npac_Status: "",
  npac_NewDueDate: new Date(),
  npac_OldDueDate: new Date(),
  npac_Authorized: false,
  npac_CompletionDate: new Date()
};
