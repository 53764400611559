import { PortInModifyModel, DefaultPortInModifyModel } from "./PortInModifyModel";

export type PortInModify = {
  phoneNumberRange: string;
  portInModifyModel: PortInModifyModel;
};

export const DefaultPortInModify: PortInModify = {
  phoneNumberRange: "",
  portInModifyModel: DefaultPortInModifyModel
};
