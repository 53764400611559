import React, { useMemo, useState, useEffect, useContext, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { handleError } from "services/util/ApiUtil";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import SpIdCell from "components/common/SpIdCell";
import { SinchOrderList } from "services/apis/types/order/OrderListDto";
import { useIsMounted } from "services/customHooks/useIsMounted";
import ReactTable from "components/framework/table/ReactTable";
import { getValueOrEmpty } from "services/util/StringUtil";
import { AppContext } from "services/appContext/AppContext";
import { OrderApi } from "services/apis/OrderApi";
import {
    dateRangefilterFunction
} from "components/framework/table/DateRangeColumnFilter";
import { getUtcDate, isBetween, sort } from "services/util/DateUtil";
import { OrderActionCell } from "components/common/actions/OrderActionCell";
import { OrdersTableColumnIdentifiers } from "components/orders/types/OrdersTableColumnIdentifiers";
import { TableIdentifiers } from "services/uiSettings/TableIdentifiers";
import SpidsCustomViewSettings from "components/framework/table/SpidsCustomViewSettings";
import ColumnsCustomViewSettings from "components/framework/table/ColumnsCustomViewSettings";
import OrderLink from "components/orders/ordersList/orderTable/OrderLink";
import { getNumberStatus } from "services/apis/types/order/NumberStatus";
import RestoreTableColumnFilter from "components/framework/table/RestoreTableColumnFilter";
import { getUniqueElements } from "services/util/ArrayUtil";
import moment from "moment";
import DuplicateTNNotifier from "components/common/DuplicateTNNotifier";
import { RestoreFilterService } from "services/restoreFilters/RestoreFilterService";
import OrderSelect from "components/common/OrderSelect";
import { OrderNumberActionType } from "services/portControl/types/OrderNumberActionType";
import { OrderDto } from "services/apis/types/order/OrderDto";
import { OrderService } from "services/portControl/OrderService";
import { OrderType } from "services/apis/types/order/OrderType";
import PortInModifyModal from "components/orders/orderDetails/PortInModifyModal";
import PortOutModifyModal from "components/orders/orderDetails/PortOutModifyModal";
import DisconnectModifyModal from "components/orders/orderDetails/DisconnectModifyModal";
import { PermissionType } from "services/authorization/PermissionType";
import { Container } from "reactstrap";
import { showErrorNotification, showInfoNotification } from "components/framework/notification/NotificationUtil";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";
import { SpIdProfileDto } from "services/apis/types/account/SpIdProfileDto";
import ConfirmationModal from "components/framework/modals/ConfirmationModal";
import { OrderNumberActionConfigurations } from "components/orders/orderDetails/configuration/OrderNumberActionConfigurations";
import { CompanyClientType } from "components/manage/serviceProvider/CompanyClientType";
import { SinchApi } from "services/apis/SinchApi";
import SinchUpdateOrderModal from "components/orders/portCreate/portIn/SinchUpdateOrderModal";
import { TnOrderRequestDto } from "services/apis/types/searchAndProvision/TnOrderRequestDto";

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork

type ColumnFilterSort = {
    isSorted?: boolean;
    isSortedDesc?: boolean;
}

type Props = {
    search: string;
};

export default function SinchOrderTable(props: Props) {
    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
    const [triggerRefresh, setTriggerRefresh] = useState(false);
    const [orders, setOrders] = useState<SinchOrderList[]>();
    const [ordersFiltered, setOrdersFiltered] = useState<SinchOrderList[]>();
    const [columnFiltersChanged, setColumnsFiltersChanged] = useState(undefined);
    const [columnFiltersOptions, setColumnFiltersOptions] = useState({});
    const [columnFilterSort, setColumnFilterSort] = useState<{ [key: string]: ColumnFilterSort }>({});
    const [searchFilterTermValue, setSearchFilterTermValue] = useState<string>("");
    const [tableData, setTableData] = useState<SinchOrderList[]>();
    const [selectedOrders, setSelectedOrders] = useState<Array<any>>([]);
    const [selectedActionOrders, setSelectedActionOrders] = useState<Array<any>>([]);
    const [selectedCount, setSelectedCount] = useState(0);
    const [showOrderOptions,] = useState(true);
    const [orderActions, setOrderActions] = useState<Array<any>>([])
    const [bulkActionSelected, setbulkActionSelected] = useState(false);
    const [bulkActionOrderId, setBulkActionOrderId] = useState<Array<any>>([]);
    const [modifyOrder, setModifyOrder] = useState<OrderDto>();
    const [isFoundCount, setIsFoundCount] = useState(-1);
    const [showModal, setShowModal] = useState(false);
    const [isAutoActivationSelected, setIsAutoActivationSelected] = useState(false);
    const [actionSelected, setActionSelected] = useState("");
    const [isCheckAutoActivation, setIsCheckAutoActivation] = useState(localStorage.getItem('isCheckAutoActivation') === 'true');
    const [isCheckAttentionRequired, setIsCheckAttentionRequired] = useState(localStorage.getItem('isCheckAttentionRequired') === 'true');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    const confirmationMessageId = actionSelected && OrderNumberActionConfigurations[actionSelected]?.Order.confirmMessageId;

    const updateAndSetOrders = useCallback((orders: SinchOrderList[]) => {
        orders.forEach(o => { o.status = getNumberStatus(o.status); o.selected = false });
        setOrders(orders);

        if (orders && orders.length > 0) {
            setOrdersFiltered(() => filterAndSort([...orders]));
            setColumnFiltersOptions(() => extractColumnFiltersOptions([...orders]));
            setDefaultPagination();
        } else {
            setOrdersFiltered([]);
        }

        // eslint-disable-next-line
    }, []);
    const intl = useIntl();
    const isMounted = useIsMounted();
    const { appContext } = useContext(AppContext);
    const theme = appContext.theme;
    const [tableColumnList, setTableColumnList] = useState<any[]>([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const isUserAdmin = appContext.localStorageInfo.user?.permissions.find(value => value === PermissionType.Admin)
    const isUserCompanyAdmin = appContext.localStorageInfo.user?.permissions.find(value => value === PermissionType.CompanyAdmin)
    const handlePageChange = (pageIndex) => {
        setPageIndex(pageIndex);
    };

    const handlePageSizeChange = (event) => {
        setPageSize(event);
        setPageIndex(0);
    };

    const handleResetFilterChange = (event) => {
        setOrders(orders);
        setOrdersFiltered(() => applyOrdersColumnFilter(orders));
        setPageSize(10);
        setColumnFilterSort({});
    }

    const handleColumnSorted = useCallback((column) => {

        // adding or updating column sorting options
        var sortOptions = { ...columnFilterSort };
        if (!sortOptions[column.identifier]) {
            sortOptions = {
                [column.identifier]: {
                    isSorted: true,
                    isSortedDesc: false
                }
            };
        } else { // toggling sort direction
            sortOptions[column.identifier].isSortedDesc = !sortOptions[column.identifier].isSortedDesc;
        }

        setColumnFilterSort(() => sortOptions);
    }, [setColumnFilterSort, columnFilterSort]);

    const applySearchKeywordsFilter = useCallback((orders: SinchOrderList[]) => {
        var filteredBySearchTerm = [...orders ?? [] as SinchOrderList[]];

        if (searchFilterTermValue !== undefined && searchFilterTermValue !== "") {

            var columnsNames = Object.values(OrdersTableColumnIdentifiers) as string[];
            columnsNames.push("orderId");
            setIsFoundCount(0);
            columnsNames.forEach(cn => {
                var found = filteredBySearchTerm.some(r => r[cn] && r[cn].toLowerCase
                    ? r[cn].toLowerCase().indexOf(searchFilterTermValue.toLowerCase()) > -1
                    : false);
                if (found) {
                    setIsFoundCount((prevCount => prevCount + 1))
                    filteredBySearchTerm = filteredBySearchTerm.filter(r => r[cn] && r[cn].toLowerCase().indexOf(searchFilterTermValue.toLowerCase()) > -1);
                    return;
                }
            })
            if (isFoundCount === 0) {
                setOrdersFiltered([]);
            }
        }

        return filteredBySearchTerm;
    }, [searchFilterTermValue, isFoundCount]);

    useEffect(() => {
        if (isFoundCount === 0) {
            setOrdersFiltered([]);
        }
    }, [isFoundCount]);


    const applyOrdersColumnFilter = useCallback((orders: SinchOrderList[] | undefined) => {

        var filteredData: SinchOrderList[] = [...(orders ?? [{} as SinchOrderList])];
        const columnFilterNames = Object.keys(appContext.localStorageInfo?.orders ?? {});
        if (columnFilterNames) {
            var allColumnFilter = appContext.localStorageInfo?.orders;

            columnFilterNames.forEach(fn => {
                var excluded = ["pageIndex", "pageSize"];
                if (allColumnFilter && !excluded.find(f => f === fn)) {
                    var equalFilter = allColumnFilter[fn];
                    var isAny = allColumnFilter[fn] === "Any";
                    var isRange = Array.isArray(allColumnFilter[fn]);
                    var isAnyRange = (isRange && allColumnFilter[fn] && allColumnFilter[fn].length === 0);

                    if (!isAny && !isRange) {
                        filteredData = filteredData.filter(fd => fd[fn] === equalFilter);
                    }
                    else if (isRange && !isAnyRange) {
                        var dateRangeFilterValues = allColumnFilter[fn];
                        let min = getUtcDate(dateRangeFilterValues.length ? (sort(dateRangeFilterValues as Array<Date>)[0] as Date) : undefined);
                        let max = getUtcDate(dateRangeFilterValues.length ? (sort(dateRangeFilterValues as Array<Date>, true)[0] as Date) : undefined);

                        filteredData = filteredData.filter(fd => isBetween(getUtcDate(fd[fn]), min, max));
                    }
                }
            });

        }

        return filteredData;
        // eslint-disable-next-line
    }, [tableColumnList]);

    const applyColumnSorting = useCallback((orders: SinchOrderList[] | undefined) => {

        let sortedOrders: SinchOrderList[] | undefined = [...orders as SinchOrderList[]];

        var hasColumnSort = columnFilterSort && Object.keys(columnFilterSort).length > 0;
        var firstSortedColumnId = Object.keys(columnFilterSort).find(k => columnFilterSort[k].isSorted) as string;
        var firstSortedColumnIdNormalized = normalizeFieldName(firstSortedColumnId);

        const distinctFn = (v, i, a) => a.findIndex(v1 => v1 === v) === i;
        const isNumericField = orders && orders?.map(f => f[firstSortedColumnIdNormalized])
            .filter(distinctFn)
            .map(v => Number.isInteger(v))
            .filter(v => v === false).length === 0;

        if (hasColumnSort) {

            // sorting records by column identifier
            if (firstSortedColumnId && firstSortedColumnId.toLowerCase().indexOf("date") > 0) {
                sortedOrders = columnFilterSort[firstSortedColumnId].isSortedDesc
                    ? orders?.sort((a, b) => getUtcDate(b[firstSortedColumnIdNormalized]).diff(getUtcDate(a[firstSortedColumnIdNormalized])))
                    : orders?.sort((a, b) => getUtcDate(a[firstSortedColumnIdNormalized]).diff(getUtcDate(b[firstSortedColumnIdNormalized])));
            }
            else if (firstSortedColumnId && isNumericField) {
                sortedOrders = columnFilterSort[firstSortedColumnId].isSortedDesc ?
                    orders?.sort((a, b) => a[firstSortedColumnIdNormalized] && b[firstSortedColumnIdNormalized] ? b[firstSortedColumnIdNormalized] - a[firstSortedColumnIdNormalized] : 0) :
                    orders?.sort((a, b) => a[firstSortedColumnIdNormalized] && b[firstSortedColumnIdNormalized] ? a[firstSortedColumnIdNormalized] - b[firstSortedColumnIdNormalized] : 0);
            }
            else {
                sortedOrders = columnFilterSort[firstSortedColumnId].isSortedDesc ?
                    orders?.sort((a, b) => a[firstSortedColumnIdNormalized] && b[firstSortedColumnIdNormalized] ? b[firstSortedColumnIdNormalized].localeCompare(a[firstSortedColumnIdNormalized]) : false) :
                    orders?.sort((a, b) => a[firstSortedColumnIdNormalized] && b[firstSortedColumnIdNormalized] ? a[firstSortedColumnIdNormalized].localeCompare(b[firstSortedColumnIdNormalized]) : false);
            }
        }

        return sortedOrders;

    }, [columnFilterSort]);

    const filterAndSort = useCallback((orders: SinchOrderList[] | undefined) => {
        if (orders && orders.length > 0) {

            const filtered = applyOrdersColumnFilter([...orders]);
            const sorted = applyColumnSorting([...filtered]);
            let filteredBySearchKeywords = applySearchKeywordsFilter([...sorted as SinchOrderList[]]);
            return filteredBySearchKeywords;
        }

        return [] as SinchOrderList[];
    }, [applyOrdersColumnFilter, applyColumnSorting, applySearchKeywordsFilter, isCheckAutoActivation, isCheckAttentionRequired])

    const extractColumnFiltersOptions = (orders: SinchOrderList[]) => {
        var lookup = {};
        const columnFilterNames = Object.values(OrdersTableColumnIdentifiers);
        if (columnFilterNames) {
            columnFilterNames.forEach(fn => {
                var excluded = ["pageIndex", "pageSize"];
                var allColumnFilter = appContext.localStorageInfo?.orders;
                if (allColumnFilter && orders.length > 0 && !excluded.find(f => f === fn)) {
                    var isRange = Array.isArray(allColumnFilter[fn]) || fn.toLowerCase().indexOf("date") > 0;
                    var isAnyRange = (isRange && allColumnFilter[fn] && allColumnFilter[fn].length === 0);

                    if (isRange && !isAnyRange) {
                        var truncatedDates = getUniqueElements(orders.map(o => {
                            var date = new Date(o[fn]);
                            return new Date(date.getFullYear(), date.getMonth(), date.getDate()).toDateString();
                        }));

                        var dates = truncatedDates.map(d => moment(d).toDate());
                        lookup[fn] = [...dates];
                    }
                    else {
                        var normalizedFieldName = normalizeFieldName(fn);
                        lookup[normalizedFieldName] = [...getUniqueElements(orders.map(o => o[normalizedFieldName]))];
                    }

                }
            });
        }


        return lookup;
    };

    const normalizeFieldName = (fn) => {
        // normalizing field names mapping from "OrdersTableColumnIdentifiers.ts" field names to "SinchOrderList.ts" field names
        if (fn && (fn.toLocaleLowerCase() === "gainingspid" || fn.toLocaleLowerCase() === "losingspid" || fn.toLocaleLowerCase() === "loosingspid")) {
            const altFn = fn.toLocaleLowerCase() === "gainingspid" ? "gainingSpId" : "loosingSpId";
            return altFn;
        }
        return fn;
    }

    const [selectedSpids] = useState(() => {
        var localStorageUser = appContext.localStorageInfo.user;
        var selected: string[] = [];
        if (localStorageUser) {
            var orderSettings = localStorageUser?.settings.ui?.tableSettings?.find(f => f.identifier === TableIdentifiers.Orders);
            var prevSelected = orderSettings?.spidFilterSettings?.filter(f => f.selected).map(f => f.spId) ?? [];
            var accountSpIds = localStorageUser?.spIdProfiles.map(p => p.spId);

            selected = prevSelected.length > 0
                ? accountSpIds.filter(spId => prevSelected.find(prevSpId => prevSpId === spId))
                : accountSpIds;
        }

        return selected;
    });

    const [isNonSpidProfileSelected] = useState(() => appContext.localStorageInfo.selectedProfile?.nonSpIdCompany);

    const dueDateFilter = useCallback(dateRangefilterFunction, [dateRangefilterFunction]);

    const toggleRefresh = useCallback(() => {
        setTriggerRefresh((x) => !x);
        setSelectedOrders([]);
        setSelectedCount(0);
    }, []);

    const [getHiddenColumns, setHiddenColumns] = useState<Array<string> | undefined>(undefined);

    const updateHiddenColumns = useCallback((): void => {
        const excludedColumnsForSpid = [OrdersTableColumnIdentifiers.CarrierName];
        const excludedColumnsForWholesale = [OrdersTableColumnIdentifiers.GainingSpid, OrdersTableColumnIdentifiers.LoosingSpid];

        const noTableSettingsConfigured = appContext.localStorageInfo.user?.settings.ui?.tableSettings === undefined;

        // setting hidden columns
        switch (isNonSpidProfileSelected) {
            case true:  // excluding columns
                setHiddenColumns(excludedColumnsForWholesale.map(c => c as string));
                break;

            case false:
                if (noTableSettingsConfigured) { // hide excluded columns by default 
                    setHiddenColumns(excludedColumnsForSpid.map(c => c as string))
                }
                else {
                    // no need to set hidden columns, as tableSettings will be handled by the ReactTable
                    setHiddenColumns(undefined);
                }
                break;
        }
    }, [appContext.localStorageInfo.user, isNonSpidProfileSelected]);

    const setDefaultPagination = () => {
        var allColumnFilter = appContext.localStorageInfo?.orders;
        if (allColumnFilter) {
            const pageSize = allColumnFilter["pageSize"] ?? 0;
            if (pageSize > 0) {
                setPageSize(pageSize);
            }
        }
    }

    const columns = useMemo(() => {
        const propsWithonChangeObject = (props) => {
            const onChange = { onChange: (propsEvent) => setColumnsFiltersChanged(() => props) };
            const filterOptions = { columnFilterOptions: columnFiltersOptions && columnFiltersOptions[props.column.id] as [] };
            return { ...onChange, ...filterOptions, ...props }
        };
        const unfilteredList = [
            {
                Header: "",
                accessor: "selected",
                identifier: OrdersTableColumnIdentifiers.Selected,
                notConfigurable: true,
                Cell: (cell: any) => (
                    <OrderSelect order={cell.cell.row.original} handleInputChange={handleChangeOrder} />
                )
            },
            {
                Header: intl.formatMessage({ id: "orders.order.table.id" }),
                accessor: "projectId",
                identifier: OrdersTableColumnIdentifiers.ProjectId,
                notConfigurable: true,
                Cell: (cell: any) => (
                    <OrderLink urlRoutePath="/SinchOrders" order={cell.cell.row.original} value={cell.cell.value} tenant={cell.cell.row.original.tenant} />
                )
            },
            {
                Header: intl.formatMessage({ id: "orders.order.table.type" }),
                accessor: "orderType",
                identifier: OrdersTableColumnIdentifiers.OrderType,
                filter: "equals",
                Filter: (props) => <RestoreTableColumnFilter {...propsWithonChangeObject(props)} />,
                Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
            },            
            {
                Header: intl.formatMessage({ id: "orders.order.table.carrierName" }),
                accessor: "carrierName",
                identifier: OrdersTableColumnIdentifiers.CarrierName,
                filter: "equals",
                Filter: (props) => <RestoreTableColumnFilter {...propsWithonChangeObject(props)} />,
                Cell: (cell: any) => cell.cell.value
            },
            {
                Header: intl.formatMessage({ id: "orders.order.table.status" }),
                accessor: "status",
                identifier: OrdersTableColumnIdentifiers.Status,
                filter: "equals",
                Filter: (props) => <RestoreTableColumnFilter {...propsWithonChangeObject(props)} />,
                Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
            },
            {
                Header: intl.formatMessage({ id: "orders.order.table.workedBy" }),
                accessor: "workedBy",
                identifier: OrdersTableColumnIdentifiers.WorkedBy,
                Filter: (props) => <RestoreTableColumnFilter {...propsWithonChangeObject(props)} />,
                filter: "equals",
                Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
            },
            {
                Header: intl.formatMessage({ id: "orders.order.table.tn" }),
                accessor: "numberCount",
                identifier: OrdersTableColumnIdentifiers.NumberCount,
                Cell: (cell: any) => (<DuplicateTNNotifier order={cell.cell.row.original} columnValue="numberCount" />)
            },
            {
                Header: intl.formatMessage({ id: "orders.order.table.operations" }),
                accessor: "action",
                identifier: OrdersTableColumnIdentifiers.Operations,
                Cell: (cell: any) => (
                    <OrderActionCell order={cell.cell.row.original} onActionExecuted={toggleRefresh} bulkActionSelected={bulkActionSelected} bulkActionOrderId={bulkActionOrderId} />
                )
            }
        ];

        const filteredList = unfilteredList.filter(x => x.accessor !== "adminProjectId");

        const columnsList = appContext.localStorageInfo.user && appContext.localStorageInfo.user.isAdminProjectIdAccessible && (isUserAdmin || isUserCompanyAdmin)
            ? (!isMixNetworkUrl ? unfilteredList.filter(x => x.accessor !== "activationDate") : unfilteredList.filter(x => x.accessor !== "gainingSpId" && x.accessor !== "loosingSpId"))
            : (!isMixNetworkUrl ? filteredList.filter(x => x.accessor !== "activationDate") : unfilteredList.filter(x => x.accessor !== "adminProjectId" && x.accessor !== "gainingSpId" && x.accessor !== "loosingSpId" && x.accessor !== "carrierName" && x.accessor !== "nextWorkDate" && x.accessor !== "workedBy"));
        setTableColumnList(columnsList);
        updateHiddenColumns();
        return columnsList;
    },
        // eslint-disable-next-line
        [appContext.localStorageInfo.orders, columnFiltersOptions, orders, setColumnsFiltersChanged, intl, dueDateFilter, updateHiddenColumns, bulkActionSelected, theme, isCheckAttentionRequired]);

    useEffect(() => {
        setShowLoadingIndicator(true);
        if (props.search && props.search !== "" && props.search.length > 1) {
            RestoreFilterService.clearFilter(TableIdentifiers.Orders);
        }
        let spid: any = []
        if (props.search.length === 23 && props.search.includes("-")) {
            let spidValue = props.search.slice(0, 4).toUpperCase().split(",");
            spid.push(spidValue);
        }
        let selectedAllSpids = [...selectedSpids, ...spid]
        let uniqueSpids = selectedAllSpids.filter((value, index, self) => self.indexOf(value) === index);
        const wholeSaleSpid = "505B"
        const wholesaleSpidArray: SpIdProfileDto[] | undefined = appContext.localStorageInfo.user?.spIdProfiles.filter((spid) => spid.spId === wholeSaleSpid);
        const isValidWholeSaleAdmin = wholesaleSpidArray?.find((x) => x.companyId === appContext.localStorageInfo.user?.companyId);
        const wholesaleSpidCompanyId = !isUserAdmin && isValidWholeSaleAdmin
            ? `${wholeSaleSpid}_${appContext.localStorageInfo.user?.companyId}`
            : "";
        const isWholesaleClient = appContext.localStorageInfo.selectedProfile?.clientType === CompanyClientType.WHOLESALE;
          SinchApi.sinchList(props.search, uniqueSpids, wholesaleSpidCompanyId)
          .then((result) => {
              if (isMounted && result.length > 0) {
              updateAndSetOrders(result);
              } else {
              updateAndSetOrders([]);
              }
          })
          .catch((error) => handleError(error))
          .finally(() => {
              if (isMounted) {
              setShowLoadingIndicator(false);
              }
          });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted, triggerRefresh, props.search, selectedSpids]);

    const customViewSettingsTabs = useMemo(() => {
        var tabs = [(<ColumnsCustomViewSettings viewName={"Columns"} tableIdentifier={TableIdentifiers.Orders} columns={columns} />)];
        if (!isNonSpidProfileSelected) {
            tabs.push((<SpidsCustomViewSettings viewName={"SPIDs"} tableIdentifier={TableIdentifiers.Orders} />));
        }
        if(!isNonSpidProfileSelected && isMixNetworkUrl) {
            tabs.pop();
        }
        return tabs;
    }, [isNonSpidProfileSelected, columns]);

    useEffect(() => {
        setSelectedOrders([]);
        setSelectedCount(0);
        const orders = ordersFiltered && [...ordersFiltered];
        orders && orders.forEach(o => { o.status = getNumberStatus(o.status); o.selected = false });
        setOrdersFiltered(orders)
        // eslint-disable-next-line
    }, [pageIndex, pageSize])

    useEffect(() => {
        setTableData(ordersFiltered?.slice(pageIndex * pageSize, (pageIndex * pageSize) + pageSize))
    }, [ordersFiltered, pageIndex, pageSize, setTableData])

    useEffect(() => {
        if ((props.search.length === 23) && props.search.includes("-")) {
            setTableData(ordersFiltered)
        } else {
            setTableData(ordersFiltered?.slice(pageIndex * pageSize, (pageIndex * pageSize) + pageSize))
        }
    }, [ordersFiltered, pageIndex, pageSize, props.search])

    useEffect(() => {
        setSelectedCount(selectedOrders.length);
        const actions = [].concat(...selectedOrders.map(obj => obj.availableActions));
        const actionCounts = actions.reduce((acc, action) => {
            acc[action] = (acc[action] || 0) + 1;
            return acc;
        }, {} as { [key: string]: number });
        const filteredActions = actions.filter(
            action => (action === OrderNumberActionType.Modify && actionCounts[action] === 1) || action !== OrderNumberActionType.Modify && action !== OrderNumberActionType.ModifyActivation
        );
        setOrderActions(Array.from(new Set(filteredActions)));
    }, [selectedOrders])

    const handleChangeOrder = useCallback((updateOrder: any, shiftKeySelected: boolean) => {
        if (ordersFiltered && shiftKeySelected) {
            ordersFiltered && setOrdersFiltered(prevState => {
                const selectedElementIndex = prevState ? prevState.findIndex(x => x.orderId === updateOrder.orderId) : -1;
                const lastIndex = prevState ? prevState.findIndex(x => x.selected === true) : -1;
                if (lastIndex !== -1 && prevState) {
                    const startIndex = Math.min(lastIndex, selectedElementIndex);
                    const endIndex = Math.max(lastIndex, selectedElementIndex);
                    const updatedArray = prevState.map((item, index) => {
                        if (index >= startIndex && index <= endIndex) {
                            if (updateOrder.selected) {
                                setSelectedOrders(prev => (prev.findIndex(x => x.orderId === item.orderId) === -1) ? [...prev, { ...item, selected: updateOrder.selected }] : prev)
                                return { ...item, selected: updateOrder.selected };
                            } else {
                                setSelectedOrders(prev => prev.filter(x => x.orderId !== item.orderId))
                                return { ...item, selected: updateOrder.selected };
                            }
                        }
                        return item;
                    })
                    return updatedArray
                } else {
                    if (updateOrder.selected) {
                        setSelectedOrders(prev => [...prev, updateOrder]);
                    } else {
                        setSelectedOrders(prev => prev && prev.filter(x => (x.orderId !== updateOrder.orderId) && x));
                    }
                    return prevState && prevState.map(x => x.orderId === updateOrder.orderId ? { ...x, selected: updateOrder.selected } : x);
                }
            })
        } else {
            ordersFiltered && setOrdersFiltered(prevState => prevState && prevState.map(x => {
                return x.orderId === updateOrder.orderId ? { ...x, selected: updateOrder.selected } : x
            }));
            if (updateOrder.selected) {
                setSelectedOrders(prev => [...prev, updateOrder]);
            } else {
                setSelectedOrders(prev => prev && prev.filter(x => (x.orderId !== updateOrder.orderId) && x));
            }
        }

    }, [ordersFiltered, setOrdersFiltered, setSelectedOrders]);

    const handleSelectAll = useCallback(() => {
        if (selectedOrders.length > 0 && tableData) {
            setOrdersFiltered(prevState => prevState && prevState.map(x => {
                return { ...x, selected: false }
            }));
            setSelectedOrders([]);
            setSelectedCount(0);

        } else if (tableData) {
            setOrdersFiltered(prevState => prevState && prevState.map(x => {
                if (tableData.findIndex(y => y.orderId === x.orderId) > -1)
                    return { ...x, selected: true }
                else
                    return x
            }));
            setSelectedOrders(tableData);
            setSelectedCount(tableData.length);
        }
    }, [setOrdersFiltered, selectedOrders, tableData]);

    const convertActionsToEnum = (actions: string[]): OrderNumberActionType[] => {
        return actions.map((action) => OrderNumberActionType[action]);
    };

    const updateOrder = useCallback((updatedOrder: OrderDto, action: string) => {
        if (ordersFiltered && ordersFiltered.length > 0) {
            if (action === OrderNumberActionType.Archive || action === OrderNumberActionType.SinchArchive) {
                setOrdersFiltered(prevState => prevState && prevState.filter(x => x.orderId !== updatedOrder.orderId))
                setSelectedOrders(prevState => prevState && prevState.filter(x => x.orderId !== updatedOrder.orderId))
            } else {
                setOrdersFiltered(prevState => {
                    return prevState && prevState.map(x => {
                        if (x.orderId === updatedOrder.orderId) {
                            return { ...x, availableActions: convertActionsToEnum(updatedOrder.availableActions), status: updatedOrder.status }
                        }
                        return x
                    })
                })
            }
            setbulkActionSelected(false);
        }
    }, [ordersFiltered, setSelectedOrders, setOrdersFiltered]);

    const handleSinchUpdateOrder = () => {
        setShowLoading(true);
        
        let successCount = 0;
        let failureCount = 0;
        
        const updatePromises = selectedOrders.map(order => {
            const tnOrderRequest: TnOrderRequestDto = {
                requestId: order.requestId,
                tnOrder: {
                    tnList: {
                        tnItem: order?.numbers.map(tn => ({
                            tn,
                            trunkGroup: "",
                            routingOption: ""
                        }))
                    }
                }
            };
    
            return OrderService.updateSinch(order.orderId, tnOrderRequest)
                .then((result) => {
                    if (result.statusCode === "432") {
                        failureCount++;
                        showErrorNotification(
                            intl.formatMessage({ id: result.result.toString() })
                        );
                    } else {
                        successCount++;
                    }
                })
                .catch((error) => {
                    failureCount++;
                    handleError(error);
                });
        });
    
        Promise.all(updatePromises)
            .then(() => {
                if (successCount === selectedOrders.length) {
                    showInfoNotification(
                        intl.formatMessage({ id: "orders.orderDetails.allOrdersPassed" })
                    );
                } else if (failureCount === selectedOrders.length) {
                    showErrorNotification(
                        intl.formatMessage({ id: "orders.orderDetails.allOrdersFailed" })
                    );
                } else {
                    showInfoNotification(
                        intl.formatMessage({ id: "orders.orderDetails.someOrdersFailed" })
                    );
                }
    
                toggleRefresh();
            })
            .catch((error) => {
                handleError(error);
            })
            .finally(() => {
                setShowModal(false);
                setShowLoading(false);
            });
    };

    const fetchOrder = useCallback((orderId, tenant, action) => {
        if (action === OrderNumberActionType.Restore) {
            OrderApi.getDR(orderId, tenant)
            .then((result) => {
                if (isMounted.current) {
                    updateOrder(result, action)
                }
            })
            .catch((error) => handleError(error))
            .finally(() => {
                if (isMounted) {
                    setbulkActionSelected(false);
                }
            });
        } else if (action === OrderNumberActionType.SinchArchive) {
            SinchApi.getSinch(orderId, tenant)
            .then((result) => {
                if (isMounted.current) {
                    updateOrder(result, action)
                }
            })
            .catch((error) => handleError(error))
            .finally(() => {
                if (isMounted) {
                    setbulkActionSelected(false);
                }
            });
        } 
        else {
            OrderApi.get(orderId, tenant)
            .then((result) => {
                if (isMounted.current) {
                    if (action === OrderNumberActionType.Modify) {
                        setModifyOrder(result);
                    } else {
                        updateOrder(result, action)
                    }
                }
            })
            .catch((error) => handleError(error))
            .finally(() => {
                if (isMounted) {
                    setbulkActionSelected(false);
                }
            });
        }        
    }, [isMounted, updateOrder, setModifyOrder])

    const setAction = useCallback((order, tenant, action) => {
        setBulkActionOrderId(prev => [...prev, order.orderId])
        setbulkActionSelected(true);
        if (action === OrderNumberActionType.Modify) {
            fetchOrder(order.orderId, tenant, action);
        } else {
            OrderService.executeAction(order.orderId, tenant, action)
                .then(() => {
                    fetchOrder(order.orderId, tenant, action);
                })
                .catch(error => {
                    handleError(error);
                })
                .finally(() => setbulkActionSelected(false));
        }
    }, [fetchOrder])

    const handleActionSelected = useCallback((selectedAction) => {
        setActionSelected(selectedAction)
        setBulkActionOrderId([]);
        if (selectedAction === OrderNumberActionType.ScheduleActivation || selectedAction === OrderNumberActionType.ModifyActivation || selectedAction === OrderNumberActionType.CancelActivation) {
            setIsAutoActivationSelected(!isAutoActivationSelected);
            setShowModal(!showModal);
        }        
        else if (selectedAction === OrderNumberActionType.Submit) {
            setShowModal(!showModal);
        }
        else if (selectedAction === OrderNumberActionType.Activate || selectedAction === OrderNumberActionType.Cancel) {
            setShowConfirmationModal(true);
        }
        else if (Object.values(OrderNumberActionType).includes(selectedAction)) {
            selectedOrders.forEach(order => {
                if (order.availableActions && order.availableActions.includes(selectedAction)) {
                    setAction(order, order.tenant, selectedAction)
                }
            }
            )
        }
    }, [selectedOrders, setAction])

    const onAfterActionExecutionModal = useCallback(() => {
        showInfoNotification(
          intl.formatMessage({ id: "orders.orderDetails.actionExecutedSuccesfuly" })
        );
        toggleRefresh();
        setActionSelected('');
    }, [intl]);

    const handleBulkAction = useCallback(() => {
        setShowLoading(true);

        const actionPromises = selectedOrders.map(order => {
            return setAction(order, order.tenant, actionSelected);
        });

        Promise.all(actionPromises)
          .then((response) => {
                if(response.length === selectedOrders.length) {
                  onAfterActionExecutionModal();
                } else {
                    showErrorNotification(
                      intl.formatMessage({ id: "orders.orderDetails.actionNotExecutedSuccesfuly" })
                    );
                }
            })
            .catch(error => {
                handleError(error);
            })
            .finally(() => {
                setShowConfirmationModal(false);
                setShowLoading(false);
            });
    }, [selectedOrders, actionSelected, setAction, onAfterActionExecutionModal, handleError]);

    const getOrdersFilteredCount = useCallback(() => ordersFiltered?.length, [ordersFiltered]);

    const onAfterActionExecution = useCallback(() => {
        showInfoNotification(
            intl.formatMessage({ id: "orders.orderDetails.actionExecutedSuccesfuly" })
        );
    }, [intl]);

    const toggleAutoActivationCheckbox = (checked: boolean | undefined) => {
        checked ? setIsCheckAutoActivation(true) : setIsCheckAutoActivation(false);
    };

    useEffect(() => {
        setPageIndex(0);
        setOrdersFiltered(() => filterAndSort(orders));

        // eslint-disable-next-line
    }, [columnFiltersChanged, filterAndSort, setOrdersFiltered, setPageIndex]);


    useEffect(() => {
        if (orders && orders?.length > 0) {
            setOrdersFiltered(() => filterAndSort([...orders as SinchOrderList[]]));
        }
        // eslint-disable-next-line 
    }, [searchFilterTermValue]);

    useEffect(() => {

        if (orders && orders?.length > 0) {
            setOrdersFiltered(() => filterAndSort([...orders as SinchOrderList[]]));
        }

        // eslint-disable-next-line 
    }, [columnFilterSort, setOrdersFiltered, filterAndSort]);

    return (
        <>
            {showLoadingIndicator ? (
                <LoadingIndicator white />
            ) : (
                <>
                    {orders && (
                        <ReactTable
                            showViewSettings
                            identifier={TableIdentifiers.Orders}
                            title={intl.formatMessage({ id: "orders.order.table.tableTitle" })}
                            columns={columns}
                            useColumnFilters
                            hiddenColumns={getHiddenColumns}
                            handleSearchFilterKeywordChange={setSearchFilterTermValue}
                            filterMapper={filterMapper}
                            onRefresh={toggleRefresh}
                            customViewSettingsComponents={customViewSettingsTabs}
                            data={tableData}
                            showOrderOptions={showOrderOptions}
                            handleSelectAll={handleSelectAll}
                            handleActionSelected={handleActionSelected}
                            orderActions={orderActions}
                            selectedCount={selectedCount}
                            selectedOrders={selectedOrders}
                            selectedActionOrders={selectedActionOrders}
                            queryPageSize={pageSize}
                            queryPageIndex={pageIndex}
                            queryCount={getOrdersFilteredCount()}
                            handlePageChange={handlePageChange}
                            handlePageSizeChange={handlePageSizeChange}
                            handleResetFilterChange={handleResetFilterChange}
                            handleColumnSorted={handleColumnSorted}
                            columnSortOptions={columnFilterSort}
                            isCheckAutoActivation={isCheckAutoActivation}
                            toggleAutoActivationCheckbox={toggleAutoActivationCheckbox}
                            isCheckAttentionRequired={isCheckAttentionRequired}
                            setIsCheckAttentionRequired={setIsCheckAttentionRequired}
                        />
                    )}
                </>
            )}
            {modifyOrder &&
                (modifyOrder.orderType === OrderType.PortIn ? (
                    <PortInModifyModal order={modifyOrder} closeModal={() => setModifyOrder(undefined)} />
                ) : (modifyOrder.orderType === OrderType.PortOut ? (
                    <PortOutModifyModal order={modifyOrder} closeModal={() => setModifyOrder(undefined)} />
                ) : (
                    <DisconnectModifyModal orderId={modifyOrder.orderId} tenant={modifyOrder.tenant} closeModal={() => setModifyOrder(undefined)} />
                )))
            }
            <Container fluid>
                {showModal && actionSelected === OrderNumberActionType.Submit && (
                    <SinchUpdateOrderModal
                        closeModal={() => {
                            setShowModal(false);
                        }}
                        onSubmit={handleSinchUpdateOrder}
                        showLoadingIndicator={showLoading}
                    />
                )}
                {showConfirmationModal && (
                    <ConfirmationModal
                        confirmCallback={() => handleBulkAction()}
                        closeModal={() => {
                            setShowConfirmationModal(false);
                            setActionSelected('');
                        }}
                        title={intl.formatMessage({
                            id: "orders.orderDetails.portingNumber.table.confirmActionModalTitle"
                        })}
                        confirmButtonText={intl.formatMessage({
                            id: "orders.orderDetails.portingNumber.table.confirmAction"
                        })}
                        showLoadingIndicator={showLoading}>
                        {confirmationMessageId && (
                            <p>
                                <FormattedMessage
                                  id={confirmationMessageId}
                                />
                            </p>
                        )}
                    </ConfirmationModal>
                )}
            </Container>
        </>
    );
}

const filterMapper = (SinchOrderList: SinchOrderList) => {
    return {
        ...SinchOrderList,
        projectId: SinchOrderList.orderId,
    };
};