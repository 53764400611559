export const getUniqueElements = (elements: any[]) => Array.from(new Set(elements));

export const distinctFilter = (v: any, i: number, a: any[]) => i === a.findIndex(v2 => stringifyLimited(v2) === stringifyLimited(v));


const stringifyLimited = (obj, depth = 10) => {
    const replacer = (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (depth <= 0) return undefined;
            depth--;
        }
        return value;
    };
    return JSON.stringify(obj, replacer);
};

export const deepCloneWithCircular = (obj, clonedObjects = new WeakMap()) => {
    // Check if the object has already been cloned
    if (clonedObjects.has(obj)) {
        return clonedObjects.get(obj);
    }
    // Handle non-object types
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }
    // Create a new object or array to clone into
    const clone = Array.isArray(obj) ? [] : {};
    // Add the object to the clonedObjects map before cloning its properties
    clonedObjects.set(obj, clone);
    // Clone each property recursively
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            clone[key] = deepCloneWithCircular(obj[key], clonedObjects);
        }
    }
    return clone;
};