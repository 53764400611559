import { DisconnectModifyModel, DefaultDisconnectModifyModel } from "services/apis/types/port/DisconnectModifyModel";

export type DisconnectModify = {
  phoneNumberRange: string;
  disconnectModifyModel: DisconnectModifyModel;
};

export const DefaultDisconnectModify: DisconnectModify = {
  phoneNumberRange: "",
  disconnectModifyModel: DefaultDisconnectModifyModel
};
