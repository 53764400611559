import React, { useCallback, useState, useRef, useEffect } from "react";

export const usePrint = (
  setHiddenColumns: React.Dispatch<React.SetStateAction<Array<string>>>,
  userHiddenColumnsAccessors: Array<string>
) => {
  const [readyForPrint, setReadyForPrint] = useState(false);
  const resolveOnBeforePrint = useRef<() => void>();

  const onAfterPrint = useCallback(() => {
    setHiddenColumns((prevColumns) => prevColumns.concat(userHiddenColumnsAccessors));
    setReadyForPrint(false);
    resolveOnBeforePrint.current = undefined;
  }, [setHiddenColumns, userHiddenColumnsAccessors]);

  const onBeforePrint = useCallback(
    () =>
      new Promise((resolve) => {
        setHiddenColumns((prevColumns) =>
          prevColumns.filter((x) => !userHiddenColumnsAccessors.includes(x))
        );
        setReadyForPrint(true);
        resolveOnBeforePrint.current = resolve as (()=> void);
      }),
    [setHiddenColumns, userHiddenColumnsAccessors]
  );

  useEffect(() => {
    if (readyForPrint && resolveOnBeforePrint.current) {
      resolveOnBeforePrint.current();
    }
  }, [readyForPrint, resolveOnBeforePrint]);

  return { onAfterPrint, onBeforePrint };
};
