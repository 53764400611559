import { Moment } from "moment";
import { getUtcDate } from "services/util/DateUtil";

export type Disconnect = {
  phoneNumbers: string;
  disconnectDate: Moment;
  releaseDate: Moment;
};

export const DefaultDisconnect: Disconnect = {
  phoneNumbers: "",
  disconnectDate: getUtcDate(),
  releaseDate: getUtcDate()
};
