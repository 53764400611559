import React from "react";
import { Card, CardBody, Row, Col, CardTitle } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

type Props = {
    count: number;
    titeTranslationId: string;
    descriptionTranslationId?: string;
    iconClass: string;
    iconBackgroundColor: string;
};

export default function CountCard(props: Props) {
    return (
        <Card className="pc-count-card">
            <CardBody>
                <Row>
                    <Col className="col-auto">
                        <div
                            className={classNames(
                                "text-muted"
                            )}>
                            <i className={classNames("fa", props.iconClass)} />
                        </div>
                    </Col>
                    <Col>
                        <CardTitle tag="h5" className="text-uppercase mb-0">
                            <FormattedMessage id={props.titeTranslationId} />
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{props.count}</span>
                        {props.descriptionTranslationId && (
                            <p className="mb-0 text-sm text-muted">
                                <span>
                                    <FormattedMessage id={props.descriptionTranslationId} />
                                </span>
                            </p>
                        )}
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}