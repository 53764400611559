import { Moment } from "moment";
import { getUtcDateTomorrow } from "services/util/DateUtil";

export type NpaNxxModel = {
  npaNxx: string;
  npacRegion: string;
  effectiveDate: Moment;
};

export const DefaultNpaNxxModel: NpaNxxModel = {
  npaNxx: "",
  npacRegion: "MW",
  effectiveDate: getUtcDateTomorrow()
};
