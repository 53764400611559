import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Card, CardHeader, CardBody, CardFooter } from "reactstrap";
import { OrderApi } from "services/apis/OrderApi";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { handleError } from "services/util/ApiUtil";
import TaskForm from "./TaskForm";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { getUtcDate } from "services/util/DateUtil";
import { TaskDto } from "services/apis/types/order/TaskDto";
import TaskListItem from "./TaskListItem";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";

type Props = {
  orderId: string;
};

export default function TasksCard(props: Props) {
  const [taskDtos, setTaskDtos] = useState<TaskDto[]>();
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const isMounted = useIsMounted();
  const intl = useIntl();

  useEffect(() => {
    setShowLoadingIndicator(true);

    OrderApi.getTasks(props.orderId)
      .then((result) => {
        if (isMounted) {
          setTaskDtos(
            result.sort(
              (a, b) =>
                getUtcDate(a.nextWorkDate).toDate().getTime() -
                getUtcDate(b.nextWorkDate).toDate().getTime()
            )
          );
        }
      })
      .catch((error) => handleError(error))
      .finally(() => {
        if (isMounted) {
          setShowLoadingIndicator(false);
        }
      });
  }, [props.orderId, isMounted, triggerRefresh]);

  const toggleTask = (taskId: string, completed: boolean) => {
    setTaskDtos(
      taskDtos?.map((task) => {
        if (task.taskId === taskId) {
          return { ...task, completed };
        }

        return task;
      })
    );
  };

  const onCheck = (task: TaskDto) => {
    const completed = !task.completed;
    toggleTask(task.taskId, completed);
    OrderApi.updateTask(props.orderId, task.taskId, completed)
      .then(() => {
        const actionMessageTranslationId = completed
          ? "orders.orderDetails.tasks.completed.markedAsCompletedMessage"
          : "orders.orderDetails.tasks.completed.markedAsUncompletedMessage";
        showInfoNotification(intl.formatMessage({ id: actionMessageTranslationId }));
      })
      .catch((error) => {
        handleError(error);
        toggleTask(task.taskId, !completed);
      });
  };

  return (
    <Card className="height-810">
      <CardHeader>
        <h3 className="mb-0">
          <FormattedMessage id="orders.orderDetails.tasks.cardTitle" />
        </h3>
      </CardHeader>
      <CardBody className="pc-timeline-container">
        {showLoadingIndicator ? (
          <LoadingIndicator />
        ) : (
          <ul className="list-group list-group-flush">
            {taskDtos &&
              taskDtos.map((x) => (
                <TaskListItem taskDto={x} key={x.taskId} onCheck={() => onCheck(x)} />
              ))}
          </ul>
        )}
      </CardBody>
      <CardFooter>
        <TaskForm taskId={props.orderId} triggerRefresh={() => setTriggerRefresh((p) => !p)} />
      </CardFooter>
    </Card>
  );
}
