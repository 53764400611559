import { AppContextAccessor } from "../appContext/AppContextAccessor";
import { AppContext } from "../appContext/AppContext";
import { PermissionType } from "./PermissionType";
import { Permissions } from "./Permissions";

export const authorize = (
  permission: keyof typeof PermissionType,
  useSpid: boolean = false
): boolean => {
  const appContext = AppContextAccessor.getAppContext();

  if (
    appContext.localStorageInfo.authenticationInfo.authenticated &&
    ((useSpid && selectedSpidIsAuthorized(appContext, permission)) ||
      (!useSpid && userIsAuthorized(appContext, permission)))
  ) {
    return true;
  }

  return false;
};

export const authorizeAny = (permissions: PermissionType[], useSpid: boolean = false): boolean => {
  return permissions && permissions.findIndex((x) => authorize(x, useSpid)) > -1;
};

const selectedSpidIsAuthorized = (appContext: AppContext, permission: string): boolean => {
  return (
    appContext.localStorageInfo.selectedProfile !== undefined &&
    (appContext.localStorageInfo.selectedProfile.permissions.includes(permission) ||
      appContext.localStorageInfo.selectedProfile.permissions.includes(PermissionType.Admin))
  );
};

const userIsAuthorized = (appContext: AppContext, permission: string): boolean => {
  return (
    appContext.localStorageInfo.user !== undefined &&
    appContext.localStorageInfo.user.permissions &&
    (appContext.localStorageInfo.user.permissions.includes(permission) ||
      appContext.localStorageInfo.user.permissions.includes(PermissionType.Admin))
  );
};

export const hasAnyPermissions = () => {
  const appContext = AppContextAccessor.getAppContext();

  return (
    Object.keys(Permissions).findIndex(
      (x) => selectedSpidIsAuthorized(appContext, x) || userIsAuthorized(appContext, x)
    ) > -1
  );
};
