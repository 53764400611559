import React from "react";

type Props = {
  phoneNumber: string;
  deletePhoneNumberHandler: (phoneNumber: string) => void;
};

export default function PhoneNumberListItem(props: Props) {
  return (
    <div className="d-flex pc-list-group-item">
      <span className="mr-2">{props.phoneNumber}</span>
      <span
        aria-hidden={true}
        className="pc-hide-button"
        onClick={() => props.deletePhoneNumberHandler(props.phoneNumber)}>
        ×
      </span>
    </div>
  );
}
