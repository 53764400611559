import React, { useEffect, useRef } from "react";

type Props = {
  url: string;
  fileName: string;
  callback: () => void;
};

export default function FileDownload(props: Props) {
  const ref = useRef<HTMLAnchorElement>(null);
  const { callback } = { ...props };

  useEffect(() => {
    if (ref.current) {
      ref.current?.click();
      callback();
    }
  }, [callback]);
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <a href={props.url} ref={ref} download={props.fileName} hidden></a>;
}
