import { Moment } from "moment";
import {
  CustomerDetailsDto,
  DefaultCustomerDetailsDto
} from "services/apis/types/port/CustomerDetailsDto";
import { OrderCardModel } from "./OrderCardModel";
import { ApiError } from "components/common/types/ApiError";
import { PreviousOrderDto } from "services/apis/types/order/OrderDto";

export type PortInAccountModel = {
  previousOrder?: PreviousOrderDto;
  apiErrors: ApiError[] | undefined;
  orderCards: OrderCardModel[];
  dueDate: Moment | null;
  activationDate: Moment | null;
  projectId: string;
  customerDetails: CustomerDetailsDto;
  csrRequested: boolean;
  hasErrors: boolean;
  adminProjectId: string | undefined;
  autoActivationDate: string;
  timeZone: string;
};

export const DefaultPortInAccountModel: PortInAccountModel = {
  apiErrors: undefined,
  orderCards: [],
  dueDate: null, 
  activationDate: null, 
  customerDetails: { ...DefaultCustomerDetailsDto },
  projectId: "",
  csrRequested: false,
  hasErrors: false,
  adminProjectId: "",
  autoActivationDate: "",
  timeZone: "",
};
