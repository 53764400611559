export const SupplementRejectionReason: Record<string, string> = {
  ACCN: "Incorrect Account Number",
  AUCN: "Incorrect Authorized Name",
  PIN: "PIN required or incorrect",
  BILL: "Billing Telephone Number",
  EUSA: "Incorrect Service Address",
  PEND: "Pending Order",
  FREZ: "Freeze on Account",
  FEOA: "Feature on Account",
  TNMI: "TN mismatch",
  OTHER: "Other"
};
