import React from "react";
import { Alert } from "reactstrap";
import { ApiError } from "components/common/types/ApiError";
import { useIntl } from "react-intl";

type Props = {
  errors: ApiError[];
  onDismiss?: (error: ApiError) => void;
  duplicateNumbers?: any;
  portProposals?: any;
  currentStatusValue?: boolean;
};

export default function ErrorList(props: Props) {
  const intl = useIntl();
  return (
    <>
      {props.errors.map((error) => (
        <Alert
          key={error.message}
          color={error.type}
          className={"d-flex p-0 pl-1 mb-1 align-items-center bg-white text-danger border-white"}
          toggle={() => {
            if (props?.onDismiss) (props.onDismiss as ((error: ApiError) => void))(error);
          }}
        >
          <span className="alert-icon">
            <i className="far fa-exclamation-circle"></i>
          </span>
          <span className="alert-text flex-basis-95">
            {props.duplicateNumbers ?
              <span>
                {
                  props.portProposals.gainingSpidIsExternal || (props.portProposals.gainingSpidIsExternal && props.currentStatusValue) ?
                  `${error.message}. ${intl.formatMessage({ id: "orders.orderDetails.portingNumber.table.vpopDuplicateErrorNotificationMessage" })}` :
                  `${error.message}. ${intl.formatMessage({ id: "orders.orderDetails.portingNumber.table.duplicateErrorNotificationMessage" })}`
                }
              </span> :
              error.message}
          </span>
        </Alert>
      ))}
    </>
  );
}
