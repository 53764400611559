import React from "react";
import { Row, Col } from "reactstrap";
import { ReportDto } from "services/apis/types/reports/ReportDto";
import CountCard from "../common/countCard/CountCard";

type Props = {
  reportDto: ReportDto;
};

export default function CountsCards(props: Props) {
  return (
    <Row className="d-flex">
      <Col className="pc-count-card-container">
        <CountCard
          count={props.reportDto.exploreCount}
          titeTranslationId="reports.exploreTransactions"
          iconClass="fa-search"
          iconBackgroundColor="bg-gradient-info"
        />
      </Col>
      <Col className="pc-count-card-container ">
        <CountCard
          count={props.reportDto.cnamCount}
          titeTranslationId="reports.cnamLookups"
          iconClass="fa-id-card"
          iconBackgroundColor="bg-gradient-orange"
        />
      </Col>
      <Col className="pc-count-card-container ">
        <CountCard
          count={props.reportDto.historyCount}
          titeTranslationId="reports.historyLookups"
          iconClass="fa-history"
          iconBackgroundColor="bg-gradient-success"
        />
      </Col>
      <Col className="pc-count-card-container ">
        <CountCard
          count={props.reportDto.portInCount}
          titeTranslationId="reports.portInCount"
          iconClass="fa-arrow-left"
          iconBackgroundColor="bg-gradient-pc-blue"
        />
      </Col>
      <Col className="pc-count-card-container ">
        <CountCard
          count={props.reportDto.portOutCount}
          titeTranslationId="reports.portOutCount"
          iconClass="fa-arrow-right"
          iconBackgroundColor=" bg-gradient-pc-orange"
        />
      </Col>
      <Col className="pc-count-card-container">
        <CountCard
          count={props.reportDto.portInPortControlCount}
          titeTranslationId="reports.portInPortControlCount"
          iconClass="fa-arrow-left"
          iconBackgroundColor="bg-gradient-pc-blue"
        />
      </Col>
      <Col className="pc-count-card-container">
        <CountCard
          count={props.reportDto.portOutPortControlCount}
          titeTranslationId="reports.portOutPortControlCount"
          iconClass="fa-arrow-right"
          iconBackgroundColor=" bg-gradient-pc-orange"
        />
      </Col>
    </Row>
  );
}
