import { useCallback } from "react";
import { DocumentModel } from "components/orders/types/DocumentModel";
import { OrderCardStatus } from "components/orders/types/OrderCardStatus";
import { PortInAccountModel } from "../../components/orders/types/PortInAccountModel";
import { useIntl } from "react-intl";
import { getTranslation } from "translations/TranslationService";
import { OrderCardModel } from "../../components/orders/types/OrderCardModel";
import { HasFieldErrors } from "components/framework/errorHandling/ErrorUtil";
import { AddDocumentSuccessModel } from "services/apis/types/order/AddDocumentSuccessModel";
import { OrderApi } from "services/apis/OrderApi";
import { getErrors } from "services/util/ApiUtil";
import { ApiErrorType } from "components/common/types/ApiErrorType";

export const useDocuments = (
  accountIndex: number,
  accountModel: PortInAccountModel,
  setAccountModel: (overviewModel: PortInAccountModel, accountIndex: number) => void,
  setAccountModels: React.Dispatch<React.SetStateAction<PortInAccountModel[]>>
) => {
  const intl = useIntl();
  const deleteDocument = useCallback(
    (document: DocumentModel) => {
      setAccountModel(
        {
          ...accountModel,
          orderCards: accountModel.orderCards.map((orderCard) => ({
            ...orderCard,
            status:
              orderCard.status !== OrderCardStatus.HasErrors
                ? OrderCardStatus.DocumentRemoved
                : orderCard.status,
            documents: orderCard.documents.filter((x) => x.fileName !== document.fileName)
          }))
        },
        accountIndex
      );
    },
    [accountModel, setAccountModel, accountIndex]
  );

  const addDocument = useCallback(
    (document: DocumentModel) => {
      if (document.file) {
        setAccountModel(
          {
            ...accountModel,
            orderCards: accountModel.orderCards.map((orderCard) => ({
              ...orderCard,
              status:
                orderCard.status !== OrderCardStatus.HasErrors
                  ? OrderCardStatus.NewDocumentsAdded
                  : orderCard.status,
              documents: [...orderCard.documents, { ...document }]
            }))
          },
          accountIndex
        );
      }
    },
    [accountModel, setAccountModel, accountIndex]
  );

  const removeDocumentsThatFailedToUpload = useCallback(
    (accountIndex: number) => {
      setAccountModels((previous) => {
        return previous.map((accountModel, index) => {
          if (index === accountIndex && accountModel.orderCards.length) {
            let newOrderCards = accountModel.orderCards;
            const fileNamesToDelete = newOrderCards[0].documents
              .filter((document) =>
                newOrderCards.every(
                  (x) =>
                    x.documents.find(
                      (orderDocument) => orderDocument.fileName === document.fileName
                    )?.uploadFailed
                )
              )
              .map((x) => x.fileName);
            const newApiErrors = fileNamesToDelete.length
              ? accountModel.apiErrors?.concat(
                  fileNamesToDelete.map((x) => {
                    return {
                      message: getTranslation(
                        intl,
                        "orders.documents.documentFailedForAllOrders",
                        x
                      ),
                      type: ApiErrorType.Danger
                    };
                  })
                ) ??
                fileNamesToDelete.map((x) => {
                  return {
                    message: getTranslation(intl, "orders.documents.documentFailedForAllOrders", x),
                    type: ApiErrorType.Danger
                  };
                })
              : accountModel.apiErrors
              ? accountModel.apiErrors
              : [];

            newOrderCards = newOrderCards.map((x) => ({
              ...x,
              documents: x.documents.filter((doc) => !fileNamesToDelete.includes(doc.fileName))
            }));

            return {
              ...accountModel,
              orderCards: newOrderCards,
              apiErrors: newApiErrors ? Array.from(
                new Map(
                  newApiErrors.map((item) => [
                    item["message"],
                    item
                  ])
                ).values()
              ) : newApiErrors
            };
          }
          return accountModel;
        });
      });
    },

    [setAccountModels, intl]
  );

  const uploadDocument = useCallback(
    async (order: OrderCardModel, document: DocumentModel, accountIndex: number) => {
      if (order.orderId && document.file) {
        try {
          const { id: documentId } = (await OrderApi.addDocument(
            order.orderId,
            document.file
          )) as AddDocumentSuccessModel;

          setAccountModels((previous) => {
            return previous.map((accountModel, index) => {
              if (index === accountIndex) {
                const newOrderCards = accountModel.orderCards;
                const currentOrder = newOrderCards.find(
                  (x) =>
                    x.loosingSpid === order.loosingSpid &&
                    x.lata === order.lata &&
                    x.lrn === order.lrn &&
                    x.portToOrginal === order.portToOrginal
                );
                if (currentOrder) {
                  const currentDocument = currentOrder.documents.find(
                    (x) => x.fileName === document.fileName
                  );
                  if (currentDocument) {
                    currentDocument.uploaded = true;
                    currentDocument.documentId = documentId;
                  }
                }

                return {
                  ...accountModel,
                  orderCards: newOrderCards
                };
              }
              return accountModel;
            });
          });
        } catch (error) {
          const documentErrors = HasFieldErrors(error)
            ? getErrors(error.fieldErrors)
            : [error.message];

          setAccountModels((previous) => {
            return previous.map((accountModel, index) => {
              if (index === accountIndex) {
                const newApiErrors =
                  accountModel.apiErrors?.concat(
                    documentErrors.map((err) => {
                      return {
                        message: intl.formatMessage(
                          { id: "orders.documents.uploadFailedMessage" },
                          { item: document.file?.name, message: err }
                        ),
                        type: ApiErrorType.Danger
                      };
                    })
                  ) ?? accountModel.apiErrors;
                const newOrderCards = accountModel.orderCards;
                const currentOrder = newOrderCards.find(
                  (x) =>
                    x.loosingSpid === order.loosingSpid &&
                    x.lata === order.lata &&
                    x.lrn === order.lrn &&
                    x.portToOrginal === order.portToOrginal
                );
                if (currentOrder) {
                  currentOrder.status = OrderCardStatus.DocumentsUploadFailed;
                  const currentDocument = currentOrder.documents.find(
                    (x) => x.fileName === document.fileName
                  );
                  if (currentDocument) {
                    currentDocument.uploadFailed = true;
                  }
                }

                return {
                  ...accountModel,
                  orderCards: newOrderCards,
                  apiErrors: newApiErrors
                };
              }
              return accountModel;
            });
          });
        }
      }
    },
    [setAccountModels, intl]
  );

  const uploadOrderDocuments = useCallback(
    (order: OrderCardModel, accountIndex: number) =>
      Promise.all(
        order.documents
          .filter((x) => !x.uploaded)
          .map((document) => uploadDocument(order, document, accountIndex))
      ),

    [uploadDocument]
  );

  return { deleteDocument, addDocument, removeDocumentsThatFailedToUpload, uploadOrderDocuments };
};
