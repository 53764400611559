import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CnamInfo } from "services/apis/types/number/CnamInfo";
import CnamModify from "./CnamModify";
import Authorize from "components/framework/authorization/Authorize";
import { PermissionType } from "services/authorization/PermissionType";

type Props = {
  phoneNumber: string;
  cnam: CnamInfo;
  modifyCallback: () => void;
};

export default function CnamDetails(props: Props) {
  const intl = useIntl();
  const [isInputActive, setIsInputActive] = useState(false);
  const [cnamName, setCnamName] = useState<string>();
  const [fraudScoreColorClassName, setFraudScoreColorClassName]= useState<string>();

  useEffect(() => {
    if (props.cnam.name) {
      setCnamName(props.cnam.name);
    }
  }, [props.cnam.name]);

  useEffect(()=>
  {
    const lookup = [
      { className: "success", match: (score: number)=>  score >= 1 && score <= 3},
      { className: "warning", match: (score: number) => score === 4},
      { className: "danger", match: (score: number)=>  score === 5},
    ]
    
    setFraudScoreColorClassName("text-" + lookup.find(rule=> rule.match(props.cnam.fraudScore))?.className);

  },[props.cnam.fraudScore]);

  const fraudText = `${props.cnam.fraudScoreDescription} (${props.cnam.fraudScore})`;
  const cnamText = isInputActive
    ? intl.formatMessage({ id: "explore.cnamModal.title" })
    : `${intl.formatMessage({ id: "explore.cnamModal.title" })}: ${cnamName}`;

  const modifyCallback = () => {
    setIsInputActive(false);
    props.modifyCallback();
  };

  return (
    <>
      <h5 className={`mb-0 ${fraudScoreColorClassName} text-capitalize`}>{fraudText}</h5>
      <h5 className="mb-0 ">
        <span className="text-gray">{cnamText}</span>
        <Authorize userPermissions={[PermissionType.CNAMUpdate]}>
          {isInputActive ? (
            <CnamModify
              cnamName={cnamName}
              phoneNumber={props.phoneNumber}
              modifyCallback={modifyCallback}
              cancelCallback={() => setIsInputActive(false)}
            />
          ) : (
            <i
              className="ml-2 fa fa-edit cursor-pointer"
              onClick={() => setIsInputActive(true)}></i>
          )}
        </Authorize>
      </h5>
    </>
  );
}
