import React from "react";
import { Row, Col } from "reactstrap";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import TradingPartnerProfileCard from "components/common/serviceProviderCard/TradingPartnerProfileCard";
import useServiceProvider from "services/customHooks/useServiceProvider";

type Props = {
  spId: string;
};

export default function ServiceProviderDetails(props: Props) {
  const { serviceProvider, isLoading, fetchServiceProvider } = useServiceProvider(props.spId);

  return (
    <>
      {isLoading ? (
        <LoadingIndicator white />
      ) : (
        <>
          {serviceProvider && (
            <Row>
              <Col lg="12" md="12">
                <TradingPartnerProfileCard
                  serviceProviderResult={serviceProvider}
                  fetchServiceProvider={fetchServiceProvider}
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
}
