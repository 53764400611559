import React, { useMemo } from "react";
import { useIntl, IntlShape } from "react-intl";
import { getValueOrEmpty } from "services/util/StringUtil";
import { OptionalDataInfo } from "services/apis/types/number/OptionalDataInfo";
import ReactTable from "components/framework/table/ReactTable";
import { hasAnyValue } from "services/util/ObjectUtil";

type Props = {
  optionalData: OptionalDataInfo;
};

export default function OptionalDataTable(props: Props) {
  const intl = useIntl();
  const columns = useMemo(() => getColumns(intl), [intl]);
  const data = hasAnyValue(props.optionalData) ? [props.optionalData] : [];

  return (
    <ReactTable
      title={intl.formatMessage({ id: "explore.optionalData.table.title" })}
      data={data}
      columns={columns}
      hideSearch={true}
    />
  );
}

const getColumns = (intl: IntlShape) => [
  {
    Header: intl.formatMessage({ id: "explore.optionalData.table.altSpid" }),
    accessor: "altSpId",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.optionalData.table.lastAltSpid" }),
    accessor: "lastAltSpId",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.optionalData.table.altBillingId" }),
    accessor: "altBillingId",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.optionalData.table.userLocationValue" }),
    accessor: "altUserLocationValue",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.optionalData.table.userLocationType" }),
    accessor: "altUserLocationType",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.optionalData.table.mmsUri" }),
    accessor: "mmsUri",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.optionalData.table.smsUri" }),
    accessor: "smsUri",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "explore.optionalData.table.voiceUri" }),
    accessor: "voiceUri",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  }
];
