import React, { useMemo, useState, useEffect, useContext, useCallback, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { handleError } from "services/util/ApiUtil";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { useIsMounted } from "services/customHooks/useIsMounted";
import ReactTable from "components/framework/table/ReactTable";
import { getValueOrEmpty } from "services/util/StringUtil";
import { AppContext } from "services/appContext/AppContext";
import {
    dateRangefilterFunction
} from "components/framework/table/DateRangeColumnFilter";
import { getUtcDate } from "services/util/DateUtil";
import { OrdersTableColumnIdentifiers } from "components/orders/types/OrdersTableColumnIdentifiers";
import { TableIdentifiers } from "services/uiSettings/TableIdentifiers";
import SpidsCustomViewSettings from "components/framework/table/SpidsCustomViewSettings";
import ColumnsCustomViewSettings from "components/framework/table/ColumnsCustomViewSettings";
import { getUniqueElements } from "services/util/ArrayUtil";
import moment from "moment";
import { Col, Container, Row } from "reactstrap";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";
import BreadcrumbHeader from "components/common/BreadcrumbHeader";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ProvisionOrderListDto } from "services/apis/types/order/ProvisionOrderListDto";
import { ProvisionOrdersTableColumnIdentifiers } from "../types/ProvisionOrdersTableColumnIdentifiers";
import ProvisionOrderSelect from "components/common/ProvisionOrderSelect";
import { SinchApi } from "services/apis/SinchApi";
import { TnReserveRequestDto } from "services/apis/types/searchAndProvision/TnReserveRequestDto";
import { TnReserveResponseDto } from "services/apis/types/searchAndProvision/TnReserveResponseDto";
import { ReservedOrders } from "./ReservedOrders";
import TimerCompleteModal from "components/common/TimerCompleteModal";

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork;

type ColumnFilterSort = {
    isSorted?: boolean;
    isSortedDesc?: boolean;
}

type Props = {
    orders?: ProvisionOrderListDto[];
};

export default function ProvisionOrderTable(props: Props) {
    const [showLoadingIndicator, setShowLoadingIndicator] = useState(true);
    const [triggerRefresh, setTriggerRefresh] = useState(false);
    const { appContext, setAppContext } = useContext(AppContext);
    const [provisionOrders, setProvisionOrders] = useState<ProvisionOrderListDto[]>([]);
    const [provisionOrdersFiltered, setProvisionOrdersFiltered] = useState<ProvisionOrderListDto[]>();
    const [reservedOrders, setReservedOrders] = useState<TnReserveResponseDto>();
    const [hasReservedOrders, setHasReservedOrders] = useState(false);
    const [isOneStepBackward, setIsOneStepBackward] = useState(true);

    const [columnFiltersChanged, setColumnsFiltersChanged] = useState(undefined);
    const [columnFiltersOptions, setColumnFiltersOptions] = useState({});
    const [columnFilterSort, setColumnFilterSort] = useState<{ [key: string]: ColumnFilterSort }>({});
    const [searchFilterTermValue, setSearchFilterTermValue] = useState<string>("");
    const [tableData, setTableData] = useState<ProvisionOrderListDto[]>()
    const [selectedOrders, setSelectedOrders] = useState<Array<any>>([]);
    const [selectedCount, setSelectedCount] = useState(0);
    const [showOrderOptions,] = useState(true);
    const [isFoundCount, setIsFoundCount] = useState(-1);
    const theme = appContext.theme;
    const [timerCompleted, setTimerCompleted] = useState(false);
    const history = useHistory();
    const intl = useIntl();
    const isMounted = useIsMounted();
    const [tableColumnList, setTableColumnList] = useState<any[]>([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const location = useLocation(); // useLocation to get current route
    const prevLocation = useRef(location.pathname);
        
    const handlePageChange = (pageIndex) => {
        setPageIndex(pageIndex);
    };

    const handlePageSizeChange = (event) => {
        setPageSize(event);
        setPageIndex(0);
    };

    const handleResetFilterChange = (event) => {
        setProvisionOrders(provisionOrders);
        setProvisionOrdersFiltered(() => applyOrdersColumnFilter(provisionOrders));
        setPageSize(10);
        setColumnFilterSort({});
    }

    const handleColumnSorted = useCallback((column) => {

        // adding or updating column sorting options
        var sortOptions = { ...columnFilterSort };
        if (!sortOptions[column.identifier]) {
            sortOptions = {
                [column.identifier]: {
                    isSorted: true,
                    isSortedDesc: false
                }
            };
        } else { // toggling sort direction
            sortOptions[column.identifier].isSortedDesc = !sortOptions[column.identifier].isSortedDesc;
        }

        setColumnFilterSort(() => sortOptions);
    }, [setColumnFilterSort, columnFilterSort]);

    const applySearchKeywordsFilter = useCallback((orders: ProvisionOrderListDto[]) => {
        var filteredBySearchTerm = [...orders ?? [] as ProvisionOrderListDto[]];

        if (searchFilterTermValue !== undefined && searchFilterTermValue !== "") {

            var columnsNames = Object.values(ProvisionOrdersTableColumnIdentifiers) as string[];
            // columnsNames.push("orderId");
            setIsFoundCount(0);
            columnsNames.forEach(cn => {
                var found = filteredBySearchTerm.some(r => r[cn] && r[cn].toLowerCase
                    ? r[cn].toLowerCase().indexOf(searchFilterTermValue.toLowerCase()) > -1
                    : false);
                if (found) {
                    setIsFoundCount((prevCount => prevCount + 1))
                    filteredBySearchTerm = filteredBySearchTerm.filter(r => r[cn] && r[cn].toLowerCase().indexOf(searchFilterTermValue.toLowerCase()) > -1);
                    return;
                }
            })
            if (isFoundCount === 0) {
                setProvisionOrdersFiltered([]);
            }
        }

        return filteredBySearchTerm;
    }, [searchFilterTermValue, isFoundCount]);

    useEffect(() => {
        if (isFoundCount === 0) {
            setProvisionOrdersFiltered([]);
        }
    }, [isFoundCount]);


    const applyOrdersColumnFilter = useCallback((orders: ProvisionOrderListDto[] | undefined) => {

        var filteredData: ProvisionOrderListDto[] = [...(orders ?? [{} as ProvisionOrderListDto])];
        return filteredData;
        // eslint-disable-next-line
    }, [tableColumnList]);

    const applyColumnSorting = useCallback((orders: ProvisionOrderListDto[] | undefined) => {

        let sortedOrders: ProvisionOrderListDto[] | undefined = [...orders as ProvisionOrderListDto[]];

        var hasColumnSort = columnFilterSort && Object.keys(columnFilterSort).length > 0;
        var firstSortedColumnId = Object.keys(columnFilterSort).find(k => columnFilterSort[k].isSorted) as string;
        var firstSortedColumnIdNormalized = normalizeFieldName(firstSortedColumnId);

        const distinctFn = (v, i, a) => a.findIndex(v1 => v1 === v) === i;
        const isNumericField = orders && orders?.map(f => f[firstSortedColumnIdNormalized])
            .filter(distinctFn)
            .map(v => Number.isInteger(v))
            .filter(v => v === false).length === 0;

        if (hasColumnSort) {

            // sorting records by column identifier
            if (firstSortedColumnId && firstSortedColumnId.toLowerCase().indexOf("date") > 0) {
                sortedOrders = columnFilterSort[firstSortedColumnId].isSortedDesc
                    ? orders?.sort((a, b) => getUtcDate(b[firstSortedColumnIdNormalized]).diff(getUtcDate(a[firstSortedColumnIdNormalized])))
                    : orders?.sort((a, b) => getUtcDate(a[firstSortedColumnIdNormalized]).diff(getUtcDate(b[firstSortedColumnIdNormalized])));
            }
            else if (firstSortedColumnId && isNumericField) {
                sortedOrders = columnFilterSort[firstSortedColumnId].isSortedDesc ?
                    orders?.sort((a, b) => a[firstSortedColumnIdNormalized] && b[firstSortedColumnIdNormalized] ? b[firstSortedColumnIdNormalized] - a[firstSortedColumnIdNormalized] : 0) :
                    orders?.sort((a, b) => a[firstSortedColumnIdNormalized] && b[firstSortedColumnIdNormalized] ? a[firstSortedColumnIdNormalized] - b[firstSortedColumnIdNormalized] : 0);
            }
            else {
                sortedOrders = columnFilterSort[firstSortedColumnId].isSortedDesc ?
                    orders?.sort((a, b) => a[firstSortedColumnIdNormalized] && b[firstSortedColumnIdNormalized] ? b[firstSortedColumnIdNormalized].localeCompare(a[firstSortedColumnIdNormalized]) : false) :
                    orders?.sort((a, b) => a[firstSortedColumnIdNormalized] && b[firstSortedColumnIdNormalized] ? a[firstSortedColumnIdNormalized].localeCompare(b[firstSortedColumnIdNormalized]) : false);
            }
        }

        return sortedOrders;

    }, [columnFilterSort]);

    const filterAndSort = useCallback((orders: ProvisionOrderListDto[] | undefined) => {
        if (orders && orders.length > 0) {

            const filtered = applyOrdersColumnFilter([...orders]);
            const sorted = applyColumnSorting([...filtered]);
            let filteredBySearchKeywords = applySearchKeywordsFilter([...sorted as ProvisionOrderListDto[]]);
            return filteredBySearchKeywords;
        }

        return [] as ProvisionOrderListDto[];
    }, [applyOrdersColumnFilter, applyColumnSorting, applySearchKeywordsFilter])

    const extractColumnFiltersOptions = (orders: ProvisionOrderListDto[]) => {
        var lookup = {};
        const columnFilterNames = Object.values(ProvisionOrdersTableColumnIdentifiers);
        if (columnFilterNames) {
            columnFilterNames.forEach(fn => {
                var excluded = ["pageIndex", "pageSize"];
                var allColumnFilter = appContext.localStorageInfo?.orders;
                if (allColumnFilter && orders.length > 0 && !excluded.find(f => f === fn)) {
                    var isRange = Array.isArray(allColumnFilter[fn]) || fn.toLowerCase().indexOf("date") > 0;
                    var isAnyRange = (isRange && allColumnFilter[fn] && allColumnFilter[fn].length === 0);

                    if (isRange && !isAnyRange) {
                        var truncatedDates = getUniqueElements(orders.map(o => {
                            var date = new Date(o[fn]);
                            return new Date(date.getFullYear(), date.getMonth(), date.getDate()).toDateString();
                        }));

                        var dates = truncatedDates.map(d => moment(d).toDate());
                        lookup[fn] = [...dates];
                    }
                    else {
                        var normalizedFieldName = normalizeFieldName(fn);
                        lookup[normalizedFieldName] = [...getUniqueElements(orders.map(o => o[normalizedFieldName]))];
                    }

                }
            });
        }


        return lookup;
    };

    const normalizeFieldName = (fn) => {
        // normalizing field names mapping from "OrdersTableColumnIdentifiers.ts" field names to "OrderListDto.ts" field names
        if (fn && (fn.toLocaleLowerCase() === "gainingspid" || fn.toLocaleLowerCase() === "losingspid" || fn.toLocaleLowerCase() === "loosingspid")) {
            const altFn = fn.toLocaleLowerCase() === "gainingspid" ? "gainingSpId" : "loosingSpId";
            return altFn;
        }
        return fn;
    }

    const [isNonSpidProfileSelected] = useState(() => appContext.localStorageInfo.selectedProfile?.nonSpIdCompany);

    const dueDateFilter = useCallback(dateRangefilterFunction, [dateRangefilterFunction]);

    const toggleRefresh = useCallback(() => {
        setTriggerRefresh((x) => !x);
        setSelectedOrders([]);
        setSelectedCount(0);
    }, []);

    const [getHiddenColumns, setHiddenColumns] = useState<Array<string> | undefined>(undefined);

    const setDefaultPagination = () => {
        var allColumnFilter = appContext.localStorageInfo?.provisionOrders;
        if (allColumnFilter) {
            const pageSize = allColumnFilter["pageSize"] ?? 0;
            if (pageSize > 0) {
                setPageSize(pageSize);
            }
        }
    }

    const columns = useMemo(() => {
        const columnsList = [
            {
                Header: "",
                accessor: "selected",
                identifier: ProvisionOrdersTableColumnIdentifiers.Selected,
                className: "width-50",
                notConfigurable: true,
                Cell: (cell: any) => (
                    <ProvisionOrderSelect order={cell.cell.row.original} handleInputChange={handleChangeOrder} />
                )
            },
            {
                Header: intl.formatMessage({ id: "orders.provisionOrder.table.phoneNumbers" }),
                accessor: "phoneNumber",
                identifier: ProvisionOrdersTableColumnIdentifiers.PhoneNumber,
                className: "width-200",
                Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
            }
        ];
        setTableColumnList(columnsList);
        return columnsList;
    },
        // eslint-disable-next-line
        [appContext.localStorageInfo.provisionOrders, triggerRefresh, columnFiltersOptions, provisionOrders, setColumnsFiltersChanged, intl, dueDateFilter]);

    const customViewSettingsTabs = useMemo(() => {
        var tabs = [(<ColumnsCustomViewSettings viewName={"Columns"} tableIdentifier={TableIdentifiers.Orders} columns={columns} />)];
        if (!isNonSpidProfileSelected) {
            tabs.push((<SpidsCustomViewSettings viewName={"SPIDs"} tableIdentifier={TableIdentifiers.Orders} />));
        }
        if(!isNonSpidProfileSelected && isMixNetworkUrl) {
            tabs.pop();
        }
        return tabs;
    }, [isNonSpidProfileSelected, columns]);

    useEffect(() => {
        setSelectedOrders([]);
        setSelectedCount(0);
        const orders = provisionOrdersFiltered && [...provisionOrdersFiltered];
        orders && orders.forEach(o => { o.selected = false });
        setProvisionOrdersFiltered(orders)
        // eslint-disable-next-line
    }, [pageIndex, pageSize])

    useEffect(() => {
        setTableData(provisionOrdersFiltered?.slice(pageIndex * pageSize, (pageIndex * pageSize) + pageSize))
    }, [provisionOrdersFiltered, pageIndex, pageSize, setTableData])

    useEffect(() => {
        setSelectedCount(selectedOrders.length);
    }, [selectedOrders])

    const handleChangeOrder = useCallback((updateOrder: any, shiftKeySelected: boolean) => {
        if (provisionOrdersFiltered && shiftKeySelected) {
            provisionOrdersFiltered && setProvisionOrdersFiltered(prevState => {
                const selectedElementIndex = prevState ? prevState.findIndex(x => x.orderId === updateOrder.orderId) : -1;
                const lastIndex = prevState ? prevState.findIndex(x => x.selected === true) : -1;
                if (lastIndex !== -1 && prevState) {
                    const startIndex = Math.min(lastIndex, selectedElementIndex);
                    const endIndex = Math.max(lastIndex, selectedElementIndex);
                    const updatedArray = prevState.map((item, index) => {
                        if (index >= startIndex && index <= endIndex) {
                            if (updateOrder.selected) {
                                setSelectedOrders(prev => (prev.findIndex(x => x.orderId === item.orderId) === -1) ? [...prev, { ...item, selected: updateOrder.selected }] : prev)
                                return { ...item, selected: updateOrder.selected };
                            } else {
                                setSelectedOrders(prev => prev.filter(x => x.orderId !== item.orderId))
                                return { ...item, selected: updateOrder.selected };
                            }
                        }
                        return item;
                    })
                    return updatedArray
                } else {
                    if (updateOrder.selected) {
                        setSelectedOrders(prev => [...prev, updateOrder]);
                    } else {
                        setSelectedOrders(prev => prev && prev.filter(x => (x.orderId !== updateOrder.orderId) && x));
                    }
                    return prevState && prevState.map(x => x.orderId === updateOrder.orderId ? { ...x, selected: updateOrder.selected } : x);
                }
            })
        } else {
            provisionOrdersFiltered && setProvisionOrdersFiltered(prevState => prevState && prevState.map(x => {
                return x.orderId === updateOrder.orderId ? { ...x, selected: updateOrder.selected } : x
            }));
            if (updateOrder.selected) {
                setSelectedOrders(prev => [...prev, updateOrder]);
            } else {
                setSelectedOrders(prev => prev && prev.filter(x => (x.orderId !== updateOrder.orderId) && x));
            }
        }

    }, [provisionOrdersFiltered, setProvisionOrdersFiltered, setSelectedOrders]);

    const handleSelectAll = useCallback(() => {
        if (selectedOrders.length > 0 && tableData) {
            setProvisionOrdersFiltered(prevState => prevState && prevState.map(x => {
                return { ...x, selected: false }
            }));
            setSelectedOrders([]);
            setSelectedCount(0);

        } else if (tableData) {
            setProvisionOrdersFiltered(prevState => prevState && prevState.map(x => {
                if (tableData.findIndex(y => y.orderId === x.orderId) > -1)
                    return { ...x, selected: true }
                else
                    return x
            }));
            setSelectedOrders(tableData);
            setSelectedCount(tableData.length);
        }
    }, [setProvisionOrdersFiltered, selectedOrders, tableData]);

    const getOrdersFilteredCount = useCallback(() => provisionOrdersFiltered?.length, [provisionOrdersFiltered]);

    const handleProvisionOrders = () => {
        setShowLoadingIndicator(true);
        const requestId = selectedOrders[0]?.requestId;
        const tnList: string[] = [];
        selectedOrders.map(o => tnList.push(o.phoneNumber));
        const formattedOrders : TnReserveRequestDto = {requestId, tnList};

        SinchApi.tnReserve(formattedOrders)
          .then((result) => {
            if (isMounted && result) {
                setReservedOrders(result);
                setHasReservedOrders(true);
                setIsOneStepBackward(false);
            }
          })
          .catch((error) => {
            handleError(error);
          })
          .finally(() => {
            if (isMounted.current) {
              setShowLoadingIndicator(false);
            }
          });
    };

    const handleOneStepBackward = () => {
        setIsOneStepBackward(true);
        setHasReservedOrders(false);
        setReservedOrders(undefined);
        toggleRefresh();
    }

    const onTimerComplete = () => {
        setTimerCompleted(true);
        console.warn("timercompleted")
      }

    const handleTimeComplete = () => {
      history.push("/SinchOrders");
    }

    useEffect(() => {
        const unlisten = history.listen((newLocation) => {
            console.log("newLocation", newLocation)
            if (prevLocation.current !== newLocation.pathname) {
                setAppContext({
                    ...appContext,
                    localStorageInfo: {
                        ...appContext.localStorageInfo,
                        provisionOrders: [],
                        startTime: "",
                        duration: ""
                    }
                });
            }
            prevLocation.current = newLocation.pathname;
        });

        return () => {
            unlisten();
        };
    }, [history, appContext, setAppContext]);

    useEffect(() => {
        if(appContext.isLoggedOut) {
            handleClearContext()
        }
    }, [appContext.isLoggedOut])

    useEffect(() => {
        setShowLoadingIndicator(true);
        const storedOrders = appContext.localStorageInfo?.provisionOrders;
        if (storedOrders && storedOrders.length > 0) {
            setProvisionOrders(storedOrders);
        }
        setShowLoadingIndicator(false);
    }, [appContext.localStorageInfo.provisionOrders]);

    const handleClearContext = () => {
        setAppContext({
            ...appContext,
            localStorageInfo: {
                ...appContext.localStorageInfo,
                provisionOrders: [],
                startTime: "",
                duration: ""
            }
        });
    };

    useEffect(() => {
        const fetchProvisionOrders = () => {
            setShowLoadingIndicator(true);
            const storedOrders = appContext.localStorageInfo.provisionOrders;
    
            if (storedOrders && storedOrders.length > 0) {
                setProvisionOrders(storedOrders);
            }
    
            setTimeout(() => {
                setShowLoadingIndicator(false);
            }, 500);
        };
    
        fetchProvisionOrders();
    }, [triggerRefresh]);

    useEffect(() => {
        setPageIndex(0);
        setProvisionOrdersFiltered(() => filterAndSort(provisionOrders));

        // eslint-disable-next-line
    }, [columnFiltersChanged, filterAndSort, setProvisionOrdersFiltered, setPageIndex]);


    useEffect(() => {
        if (provisionOrders && provisionOrders?.length > 0) {
            setProvisionOrdersFiltered(() => filterAndSort([...provisionOrders as ProvisionOrderListDto[]]));
        }
        // eslint-disable-next-line 
    }, [searchFilterTermValue]);

    useEffect(() => {

        if (provisionOrders && provisionOrders?.length > 0) {
            setProvisionOrdersFiltered(() => filterAndSort([...provisionOrders as ProvisionOrderListDto[]]));
        }

        // eslint-disable-next-line 
    }, [columnFilterSort, setProvisionOrdersFiltered, filterAndSort]);

    return (
        <>
            <BreadcrumbHeader>
                <Link to={"/SinchOrders"}>
                    <span className={`${theme === "light" ? "lblue-color" : "text-white"}`}>
                        <FormattedMessage id="orders.title" />
                    </span>
                </Link>
                {!hasReservedOrders ? (
                    <span className={`${theme === "light" ? "lblue-color" : "text-white"}`}>
                        <FormattedMessage id="orders.search" />
                    </span>
                ) : (
                    <>                        
                        <Link to="/Orders/Provison/ProvisionOrders" onClick={handleOneStepBackward}>
                            <span className={`${theme === "light" ? "lblue-color" : "text-white"}`}>
                                <FormattedMessage id="orders.search" />
                            </span>
                        </Link>
                        <span className="mx-2">-</span>
                        <span className={`${theme === "light" ? "lblue-color" : "text-white"}`}>
                            <FormattedMessage id="orders.provision" />
                        </span>
                    </>
                )}
            </BreadcrumbHeader>
            {showLoadingIndicator ? (
                <LoadingIndicator white />
            ) : (
                <Container fluid>
                    <Row>
                        <Col md="12">
                            {provisionOrders && !hasReservedOrders && isOneStepBackward && (
                                <ReactTable
                                    showViewSettings
                                    isProvisionOrders={true}
                                    title={intl.formatMessage({ id: "orders.provisionOrder.table.tableTitle" })}
                                    columns={columns}
                                    useColumnFilters
                                    hiddenColumns={getHiddenColumns}
                                    handleSearchFilterKeywordChange={setSearchFilterTermValue}
                                    filterMapper={filterMapper}
                                    onRefresh={toggleRefresh}
                                    customViewSettingsComponents={customViewSettingsTabs}
                                    data={tableData}
                                    showOrderOptions={showOrderOptions}
                                    handleSelectAll={handleSelectAll}
                                    selectedCount={selectedCount}
                                    selectedOrders={selectedOrders}
                                    queryPageSize={pageSize}
                                    queryPageIndex={pageIndex}
                                    queryCount={getOrdersFilteredCount()}
                                    handlePageChange={handlePageChange}
                                    handlePageSizeChange={handlePageSizeChange}
                                    handleResetFilterChange={handleResetFilterChange}
                                    handleColumnSorted={handleColumnSorted}
                                    columnSortOptions={columnFilterSort}
                                    handleProvisionOrders={handleProvisionOrders}
                                    onTimerComplete={onTimerComplete}
                                    setTimerCompleted={setTimerCompleted}
                                />
                            )}
                            {hasReservedOrders && !isOneStepBackward && <ReservedOrders reservedOrders={reservedOrders} />}
                        </Col>
                    </Row>
                </Container>
            )}
            {timerCompleted && (
                <TimerCompleteModal
                onSubmit={handleTimeComplete}
                />
            )}
        </>
    );
}

const filterMapper = (orderListDto: ProvisionOrderListDto) => {
    return {
        ...orderListDto,
    };
};