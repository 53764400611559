import { Moment } from "moment";
import { getUtcDate } from "services/util/DateUtil";

export type DisconnectModifyModel = {
  modifyType: string;
  portDirection: string;
  disconnectDate: Moment;
  releaseDate: Moment;
};

export const DefaultDisconnectModifyModel: DisconnectModifyModel = {
  modifyType: "VSDP",
  portDirection: "OUT",
  disconnectDate: getUtcDate(),
  releaseDate: getUtcDate()
};
