import React, { useState } from "react";
import ServiceProviderModal from "./ServiceProviderModal";
import { Button } from "reactstrap";
import CustomerInventoryUsersModal from "./CustomerInventoryUsersModal";

type Props = {
  users: any;
  labelColor?: string;
  isTpp? : boolean;
  onActionExecuted?: () => void;
  usersCount: number;
};

export default function UsersModalCell(props: Props) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {props.users && (
        <>
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              setShowModal(true);
            }}>
            {props.usersCount}
          </a>
          {showModal && (
            <CustomerInventoryUsersModal closeModal={() => setShowModal(false)} users={props.users}/>
          )}
        </>
      )}
    </>
  );
}
