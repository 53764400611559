export enum WorkQueueTableColumnIdentifiers {
        Id = "id", // will be a derived value based on either 'ProjectId', or 'OrderId'
        ProjectId = "projectId",
        DueDate = "dueDate",
        CreationDate = "creationDate",
        OrderType = "orderType",
        Status = "status",
        Organization =  "organization",
        GainingSpId = "gainingSpId",
        LoosingSpId = "loosingSpId",
        NextWorkDate = "nextWorkDate",
        WorkedBy = "workedBy",
        NumberCount =  "numberCount",
        Actions = "actions"
}