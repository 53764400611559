export enum TradingPartnerTableColumnIdentifiers {
    RequestingSpId = "requestingSpid",
    TargetSpId = "targetSpid",
    ServiceProvider = "serviceProvider",
    IsVerified = "isVerified",
    IsTouchlessPreOrder = "isTouchlessPreorder",
    VpopCustomer = "vpopCustomer",
    IxcRequired = "ixcRequired",
    Status = "status",
    Actions = "actions",
    VerifiedDate = "verifiedDate"
  }



  export enum TradingPartnerAdminTableColumnIdentifiers {
    RequestingSpId = "requestingSpid",
    TargetSpId = "targetSpid",
    ServiceProvider = "serviceProvider",
    IsVerified = "isVerified",
    IsTouchlessPreOrder = "isTouchlessPreorder",
    VpopCustomer = "vpopCustomer",
    IxcRequired = "ixcRequired",
    Status = "status",
    Actions = "actions",
    VerifiedDate = "verifiedDate"
  }