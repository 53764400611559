import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import TextRow from "components/networkExplorer/common/TextRow";
import { BlockResult } from "services/apis/types/block/BlockResult";
import { useIntl, FormattedMessage } from "react-intl";

type Props = {
  block: BlockResult;
};

export default function NpacDetailsCard(props: Props) {
  const intl = useIntl();

  return (
    <div className="card-wrapper">
      <Card>
        <CardHeader>
          <h3 className="mb-0">
            <FormattedMessage id="network.block.npacDetails.title" />
          </h3>
        </CardHeader>
        <CardBody className="text-xs">
          <table>
            <tbody>
              <TextRow name={intl.formatMessage({ id: "network.block.npacDetails.spId" })}>{props.block.spId}</TextRow>
              <TextRow name={intl.formatMessage({ id: "network.block.npacDetails.spIdName" })}>
                {props.block.providerName}
              </TextRow>
              <TextRow name={intl.formatMessage({ id: "network.block.npacDetails.altSpId" })}>
                {props.block.altSpId}
              </TextRow>
              <TextRow name={intl.formatMessage({ id: "network.block.npacDetails.lastAltSpId" })}>
                {props.block.lastAltSpId}
              </TextRow>
            </tbody>
          </table>
        </CardBody>
      </Card>
    </div>
  );
}
