import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmationModal from "components/framework/modals/ConfirmationModal";

type Props = {
  deleteOrder: () => void;
  closeModal: () => void;
  isDelete: () => void;
};

export default function DeleteOrderCardModal(props: Props) {
  const intl = useIntl();

  const handleDelete = () => {
    props.deleteOrder();
    props.isDelete();
    props.closeModal();
  };

  return (
    <ConfirmationModal
      confirmCallback={handleDelete}
      closeModal={props.closeModal}
      title={intl.formatMessage({ id: "orders.portIn.stepTwo.deleteModal.title" })}
      confirmButtonText={intl.formatMessage({
        id: "orders.portIn.stepTwo.deleteModal.submitDelete"
      })}>
      <p>
        <FormattedMessage id="orders.portIn.stepTwo.deleteModal.text" />
      </p>
    </ConfirmationModal>
  );
}
