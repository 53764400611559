import queryString from "query-string";
import { CustomerInventoryDto } from "./types/customerInventory/CustomerInventoryDto";
import { ApiVersion } from "services/httpClient/ApiVersion";
import { HttpClient } from "services/httpClient/HttpClient";
import { ApiError } from "./types/ApiError";
import { InternalId } from "./types/customerInventory/CustomerInventoryInternalId";

export const CustomerInventoryApi = {
  customerInventoryList: (search: string, tenant: string | undefined = undefined): Promise<CustomerInventoryDto[]> => {
    let url = queryString.stringifyUrl({
        url: "wholesalecustomer/customerlist",
        query: {
          searchKey: search && search.length > 0 ? search : undefined,
        },
      }, {
      arrayFormat: 'comma'
    });

    return HttpClient.get<CustomerInventoryDto[]>(url, true, true, tenant, ApiVersion.V3);
  },
  manageCustomer: (companyId: string | undefined = undefined, activity: any,  tenant: string | undefined = undefined): Promise<CustomerInventoryDto[]> => {
    let url = queryString.stringifyUrl({
        url: "wholesalecustomer/managecustomer",
        query: {
          companyId: companyId,
          isActivated: activity
        },
      }, {
      arrayFormat: 'comma'
    });

    return HttpClient.post<CustomerInventoryDto[]>(url, true, true, true, activity, ApiVersion.V3);
  },
  updateCustomer: (companyId: string, internalId: string): Promise<ApiError> => {
    let url = queryString.stringifyUrl({
      url: "wholesalecustomer/updatecustomerdetails",
      query: {
        companyId: companyId,
        internalId: internalId
      },
    }, {
      arrayFormat: 'comma'
    });
    return HttpClient.post<ApiError>(url, undefined, true, true, "", ApiVersion.V3);
  }
};
