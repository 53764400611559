import React, { useState, useEffect, useContext, useMemo } from "react";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import ReactTable from "components/framework/table/ReactTable";
import { NpacQueryRequestDto } from "services/apis/types/query/NpacQueryRequestDto";
import { QueryApi } from "services/apis/QueryApi";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { handleError } from "services/util/ApiUtil";
import { AppContext } from "services/appContext/AppContext";
import { IntlShape, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { getValueOrEmpty } from "services/util/StringUtil";
import FormatDateTime from "components/framework/date/FormatDateTime";
import { nameOf } from "services/util/ObjectUtil";
import { getFormattedUtcDate } from "services/util/DateUtil";

type Props = {
  triggerRefresh: boolean;
  searchValue: string;
};

export default function QuerySvTable(props: Props) {
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [querySvs, setQuerySvs] = useState<NpacQueryRequestDto[]>();
  const intl = useIntl();
  const isMounted = useIsMounted();
  const { appContext } = useContext(AppContext);
  const columns = useMemo(() => getColumns(intl), [intl]);

  useEffect(() => {
    setShowLoadingIndicator(true);

    QueryApi.list(props.searchValue)
      .then((result) => {
        if (isMounted) {
          setQuerySvs(result);
        }
      })
      .catch((error) => handleError(error))
      .finally(() => {
        if (isMounted) {
          setShowLoadingIndicator(false);
        }
      });
  }, [
    isMounted,
    props.triggerRefresh,
    props.searchValue,
    appContext.localStorageInfo.selectedProfile
  ]);

  return showLoadingIndicator ? (
    <LoadingIndicator white />
  ) : (
    <ReactTable
      title={intl.formatMessage({ id: "querySv.table.title" })}
      data={querySvs}
      columns={columns}
      showExport={true}
      filterMapper={filterMapper}
    />
  );
}

const getColumns = (intl: IntlShape) => [
  {
    Header: intl.formatMessage({ id: "querySv.table.name" }),
    accessor: nameOf<NpacQueryRequestDto>("name"),
    Cell: (cell: any) => (
      <Link to={`/QuerySV/${cell.cell.row.original.id}`} className="text-underline">
        {cell.cell.value}
      </Link>
    )
  },
  {
    Header: intl.formatMessage({ id: "querySv.table.phoneNumber" }),
    accessor: "phoneNumber",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "querySv.table.regionCode" }),
    accessor: "regionCode",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "querySv.table.requestedDate" }),
    accessor: "requestedDate",
    Cell: (cell: any) => <FormatDateTime utcDate={cell.cell.value} />
  },
  {
    Header: intl.formatMessage({ id: "querySv.table.completionDate" }),
    accessor: "completionDate",
    Cell: (cell: any) => <FormatDateTime utcDate={cell.cell.value} />
  }
];

const filterMapper = (npacQueryRequestDto: NpacQueryRequestDto) => {
  return {
    ...npacQueryRequestDto,
    requestedDate: getFormattedUtcDate(npacQueryRequestDto.requestedDate),
    completionDate: getFormattedUtcDate(npacQueryRequestDto.completionDate)
  };
};
