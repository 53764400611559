import React, { useState, useCallback, useRef } from "react";
import { Tooltip } from "reactstrap";

type Props = {
  tooltip: string | React.ReactNode;
};

export default function InfoTooltip(props: Props) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const toggleTooltip = useCallback(() => setIsTooltipOpen((prev) => !prev), []);
  const iconRef = useRef<HTMLElement | null>(null);

  return (
    <>
      <i className="fas fa-info-circle text-info ml-2" ref={iconRef} />

      <Tooltip
        placement="right"
        target={iconRef}
        isOpen={isTooltipOpen}
        toggle={toggleTooltip}
        innerClassName="bg-info p-2"
        arrowClassName="info-tooltip-arrow">
        {props.tooltip}
      </Tooltip>
    </>
  );
}
