import { UserSettingsDto } from "./UserSettingsDto";

export type UpdateAccountDto = {
  email: string;
  firstName: string;
  lastName: string;
  locale: string;
  zoneInfo: string;
  userSettings: UserSettingsDto;
};

export const DefaultUpdateAccountDto: UpdateAccountDto = {
  email: "",
  firstName: "",
  lastName: "",
  locale: "",
  zoneInfo: "",
  userSettings: {}
};
