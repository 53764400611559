import React, { useState } from "react";
import NotificationPauseConfirmationModal from "./NotificationPauseConfirmationModal";
import { NotificationListItemDto } from "services/apis/types/webhooks/NotificationListItemDto";

type Props = { notificationDto: NotificationListItemDto };

export default function NotificationPauseAction(props: Props) {
  const [showPauseModal, setShowPauseModal] = useState(false);

  return (
    <>
      <i
        className={`fas fa-pause mr-2 cursor-pointer`}
        onClick={() => setShowPauseModal(true)}
        data-toggle="tooltip"
        data-placement="top"
        title="Pause"
      />
      {showPauseModal && (
        <NotificationPauseConfirmationModal
          id={props.notificationDto.connectionId}
          notificationName={props.notificationDto.name}
          closeModal={() => setShowPauseModal(false)}
        />
      )}
    </>
  );
}
