import React, { useState } from "react";
import { WebhookLogsApi } from "services/apis/WebhookLogsApi";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleError } from "services/util/ApiUtil";
import { useIntl } from "react-intl";
import { useIsMounted } from "services/customHooks/useIsMounted";
import classNames from "classnames";

type Props = {
  id: string;
  retryCount: number;
  status: string;
  onActionExecuted: () => void;
};

export default function UrlNotificationActionsCell(props: Props) {
  const intl = useIntl();
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const isMounted = useIsMounted();

  const onActionSubmitted = () => {
    setShowLoadingIndicator(true);
    WebhookLogsApi.retryWebhookLog(props.id)
      .then(() => {
        props.onActionExecuted();
        showInfoNotification(intl.formatMessage({ id: "notifications.retry.retryNotification" }));
      })
      .catch((error) => handleError(error))
      .finally(() => {
        if (isMounted.current) {
          setShowLoadingIndicator(false);
        }
      });
  }

  return (
    <>
    {showLoadingIndicator ? (
      <i className={classNames("fas fa-spinner fa-spin")} />
    )
    : props.status === "Failed" ?
    <i
      className={`fas fa-retweet mr-2 cursor-pointer`}
      onClick={() => onActionSubmitted()}
      data-toggle="tooltip"
      data-placement="top"
      title={"Resend Notification"}
    /> : ""
    }
  </>
  );
}
