import React from "react";
import {Row,Col,Container} from "reactstrap"
import { TableIdentifiers } from "services/uiSettings/TableIdentifiers";
import CheckboxFormInput from "../forms/CheckboxFormInput";
import { DataTableColumn } from "./types/DataTableColumn";
import { getColumnSettings } from "services/uiSettings/UiSettingsService";
import {CustomViewSettingBase, CustomViewSettingBaseProps } from "./CustomViewSettingsBase";

interface ColumnsCustomViewSettingsProps extends CustomViewSettingBaseProps{
  viewName: string;
  columns : Array<DataTableColumn>;
}

interface State {
  columnsSettingsFormState: InitialFormState[];
}

type InitialFormState = {
    isVisible : boolean;
    label: string;
    identifier: string;
    disabled: boolean;
}



export default class ColumnsCustomViewSettings extends React.Component<ColumnsCustomViewSettingsProps> implements CustomViewSettingBase {
  
  ref: React.RefObject<CustomViewSettingBase> | undefined;
  settingIdentifier: string = "columnSettings";
  
  state : State = { 
      columnsSettingsFormState: [],
    };

    componentDidMount(){
      var settings = this.getInitialFormState((this.props as ColumnsCustomViewSettingsProps).columns, this.props.tableIdentifier);
        this.setState({columnsSettingsFormState: settings}); 
    }

    render(){
       return (
       <Container>
           <Row>
              <Col>
              <div>
                {this.state.columnsSettingsFormState.map((x) => (
                  <CheckboxFormInput
                    key={x.identifier}
                    value={x.identifier}
                    checked={x.isVisible}
                    label={x.label}
                    disabled={x.disabled}
                    handleInputChange={() => this.toggleIsVisible(x.identifier)}
                  />
                ))}
              </div>
              </Col>
           </Row>
       </Container>)
    }

    getInitialFormState = (
        columns: Array<DataTableColumn>,
        tableIdentifier: TableIdentifiers
      ) : InitialFormState[]=> {
        return columns
          .filter((x) => !!x.identifier)
          .map((column) => {
            let isVisible : boolean;
            if(column.identifier === "id") {
              isVisible = true;
            } else {
              isVisible = getColumnSettings(tableIdentifier, column.identifier)?.isVisible ?? false;
            }
            return {
              isVisible : isVisible,
              label: column.Header,
              identifier: column.identifier,
              disabled: column.notConfigurable
            } as InitialFormState;
        });
      };
    
    toggleIsVisible = (identifier: string) => {
      var columnIdentifier = this.state.columnsSettingsFormState.findIndex(s=> s.identifier === identifier);
      if (columnIdentifier > 0){
        this.setState(prev=>{
          var updated = [...prev["columnsSettingsFormState"]];
          updated[columnIdentifier].isVisible = !updated[columnIdentifier].isVisible;
          return {columnsSettingsFormState :  updated};
        });
      }
    };   
    
    getSettings() : [string, any[]]{
      return [this.settingIdentifier , this.state.columnsSettingsFormState];
    }
  
}