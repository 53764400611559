import React from "react";
import { ErrorHandler } from "services/customHooks/useErrors";
import { useIntl, FormattedMessage } from "react-intl";
import { FormGroup, Label, Input } from "reactstrap";
import classNames from "classnames";
import InputError from "../errorHandling/InputError";

export type NumberFormInputProps = {
  readonly?: boolean;
  labelTranslationId?: string;
  value?: string;
  errorHandler?: ErrorHandler;
  formGroupClassName?: string;
  inputClassName?: string;
  placeholderTranslationId?: string;
  lg?: boolean;
  handleInputChange?: (value: string) => void;
  onClick?: (value: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  appendValueTranslationId?: string;
};

export default function NumberFormInput(props: NumberFormInputProps) {
  const intl = useIntl();
  const errors = props.errorHandler ? props.errorHandler.getErrors() : [];
  const placeholder =
    props.readonly || !props.placeholderTranslationId
      ? ""
      : intl.formatMessage({ id: props.placeholderTranslationId });

  const appendValue = props.appendValueTranslationId
    ? intl.formatMessage({ id: props.appendValueTranslationId })
    : "";

  return (
    <FormGroup className={classNames("mb-2", props.formGroupClassName)}>
      {props.labelTranslationId && (
        <Label className="form-control-label">
          <FormattedMessage id={props.labelTranslationId} />
        </Label>
      )}
      {props.appendValueTranslationId ? (
        <div
          className={classNames("input-group", {
            "input-group-sm": !props.lg
          })}>
          <Input
            readOnly={props.readonly}
            type="number"
            className={classNames("form-control", props.inputClassName, {
              "pc-invalid-input": errors && errors.length > 0,
              "form-control-sm": !props.lg
            })}
            placeholder={placeholder}
            value={props.value ? props.value : ""}
            onChange={(e) => {
              if (props.errorHandler) props.errorHandler.clear();
              if (props.handleInputChange) props.handleInputChange(e.currentTarget.value);
            }}
            onClick={props.onClick}
          />
          <div className="input-group-append ">
            <span className="input-group-text">{appendValue}</span>
          </div>
        </div>
      ) : (
        <Input
          readOnly={props.readonly}
          type="number"
          className={classNames("form-control", props.inputClassName, {
            "pc-invalid-input": errors && errors.length > 0,
            "form-control-sm": !props.lg
          })}
          placeholder={placeholder}
          value={props.value ? props.value : ""}
          onChange={(e) => {
            if (props.errorHandler) props.errorHandler.clear();
            if (props.handleInputChange) props.handleInputChange(e.currentTarget.value);
          }}
          onClick={props.onClick}
        />
      )}
      {props.errorHandler && <InputError errors={props.errorHandler.getErrors()} />}
    </FormGroup>
  );
}
