import { SvQueryDetailsDto } from "./SvQueryDetailsDto";

export type NpacQueryResultDto = {
  id: string;
  name: string;
  requestedDate: Date;
  completionDate: Date;
  numberDetails: SvQueryDetailsDto[];
};

export const DefaultNpacQueryResultDto: NpacQueryResultDto = {
  id: "",
  name: "",
  requestedDate: new Date(),
  completionDate: new Date(),
  numberDetails: []
};
