import React, { useContext, useState } from "react";
import { Modal, Button } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import CloseButton from "components/framework/modals/CloseButton";
import { handleError } from "services/util/ApiUtil";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { AppContext } from "services/appContext/AppContext";
import { DnoApi } from "services/apis/DnoApi";

type Props = {
  closeModal: () => void;
  checked: boolean;
  data: any;
  setIschecked: (checked: boolean) => void;
};

export default function DnoSwitchModal(props: Props) {
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;
  const intl = useIntl();
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);

  const handleToggleUpdate = () => {
    setShowLoadingIndicator(true);
    let number = new Array(props.data.phoneNumber);
    DnoApi.setDnoFlag(number, !props.checked)
      .then(() => {
        props.setIschecked(!props.checked);
        showInfoNotification(
          intl.formatMessage({ id: "somos.number.update.dno" })
        );
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setShowLoadingIndicator(false);
        props.closeModal();
      });
  }

  return (
    <Modal className="modal-dialog-centered modal-sm" isOpen={true} toggle={props.closeModal}>
      <div className="modal-header pb-0">
        <h4 className="modal-title" id="modal-title-default">
          <FormattedMessage id={"customerInventory.activation.confirm"} />
        </h4>
        <CloseButton close={props.closeModal} />
      </div>
      <div className="modal-body pb-0">
        <FormattedMessage id={"dno.activation.message"} />
      </div>
      <div className="modal-footer">
        <Button color="link" type="button" className={`${theme === "light" ? "lblue-color" : ""}`} onClick={() => props.closeModal()}>
            <FormattedMessage id="orders.modify.edit.cancelButton"/>
        </Button>
        <Button
            color="primary"
            onClick={handleToggleUpdate}
            className={`ml-auto ${theme === "light" ? "bg-lblue no-border" : ""}`}
            disabled={showLoadingIndicator}>
            {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            <FormattedMessage id="orders.modify.edit.submitButton" />
        </Button>
        </div>
    </Modal>
  );
}
