import { ExcelTemplateLrnPtoGroup } from "./ExcelTemplateLrnPtoGroup";
import {OrderHandlerType} from "services/apis/types/port/OrderHandlerType";
import { getUtcDateTomorrow } from "services/util/DateUtil";
import moment, { Moment } from "moment";
import { PortInConfiguration } from "./PortInConfiguration";
export type ExcelTemplateAccount = {
    lrnPtoGroupNumbers: ExcelTemplateLrnPtoGroup[];
    projectId: string;
    dueDate: string;
    dueTime: string;
    accountName: string;
    accountNumber: string;
    accountPin: string;
    accountType: string;
    authorizedName: string;
    billingPhoneNumber: string;
    city: string;
    addressLine2?: string;
    directionPrefix: string;
    directionSuffix: string;
    number: string;
    state: string;
    streetName: string;
    streetNameSuffix: string;
    zipCode: string;
    csrRequest: boolean;
    handler: OrderHandlerType;
  };

  export const DefaultExcelTemplateAccount: ExcelTemplateAccount = {
    lrnPtoGroupNumbers: [],
    projectId: "",
    dueDate: "",
    dueTime: "",
    accountName: "",
    accountNumber: "",
    accountPin: "",
    accountType: "",
    authorizedName: "",
    billingPhoneNumber: "",
    city: "",
    addressLine2: "",
    directionPrefix: "",
    directionSuffix: "",
    number: "",
    state: "",
    streetName: "",
    streetNameSuffix: "",
    zipCode: "",
    csrRequest: false,
    handler: OrderHandlerType.SelectOne
  };

  export const getDueDateFromExcelAccount = (excelAccount: ExcelTemplateAccount): Moment => {
    let orderCardDueDate = getUtcDateTomorrow();

    if (excelAccount.dueDate !== "") {
        const datePortion = moment.utc(excelAccount.dueDate);

        if (excelAccount.dueTime !== "") {
            const timePortion = moment.utc(excelAccount.dueTime, PortInConfiguration.excelTemplateTimeFormat);
    
            datePortion.set({
                hours: timePortion.hours(),
                minutes: timePortion.minutes(),
                seconds: timePortion.seconds(),
                milliseconds: 0
            });
        }

        if (datePortion.isValid()) {
            orderCardDueDate = datePortion;
        }
    }

    return orderCardDueDate;
};