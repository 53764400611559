import React from "react";
import classNames from "classnames";
import { KeyValuePair } from "services/types/KeyValuePair";

type Props = {
  disabled?: boolean;
  sm: boolean;
  className?: string;
  value?: string;
  options: KeyValuePair<string, string>[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

export default function (props: Props) {
  return (
    <select
      disabled={props.disabled}
      className={classNames("form-control", props.className, {
        "form-control-sm": props.sm
      })}
      onChange={props.onChange}
      value={props.value ? props.value : ""}>
      {props.options.map((x) => (
        <option key={x.key} value={x.value}>
          {x.key}
        </option>
      ))}
    </select>
  );
}
