import React, { useState, useEffect, useMemo } from "react";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import ReactTable from "components/framework/table/ReactTable";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { handleError } from "services/util/ApiUtil";
import { IntlShape, useIntl } from "react-intl";
import { getValueOrEmpty, truncateAndAppendEllipsis } from "services/util/StringUtil";
import FormatDateTime from "components/framework/date/FormatDateTime";
import { nameOf } from "services/util/ObjectUtil";
import { NotificationListItemDto } from "services/apis/types/webhooks/NotificationListItemDto";
import { WebhookApi } from "services/apis/WebhookApi";
import { getFormattedUtcDate } from "services/util/DateUtil";
import NotificationNameCell from "./NotificationNameCell";
import NotificationActionsCell from "./NotificationActionsCell";
import { NotificationStatus } from "services/apis/types/webhooks/NotificationStatus";

type Props = {
  triggerRefresh: boolean;
  searchValue: string;
};

export default function NotificationTable(props: Props) {
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [notifications, setNotifications] = useState<NotificationListItemDto[]>();
  const intl = useIntl();
  const isMounted = useIsMounted();
  const columns = useMemo(() => getColumns(intl), [intl]);

  useEffect(() => {
    setShowLoadingIndicator(true);

    WebhookApi.list(props.searchValue)
      .then((result) => {
        if (isMounted) {
          setNotifications(result);
        }
      })
      .catch((error) => handleError(error))
      .finally(() => {
        if (isMounted) {
          setShowLoadingIndicator(false);
        }
      });
  }, [isMounted, props.triggerRefresh, props.searchValue]);

  return showLoadingIndicator ? (
    <LoadingIndicator white />
  ) : (
    <ReactTable
      title={intl.formatMessage({ id: "notifications.table.title" })}
      data={notifications}
      columns={columns}
      showExport={true}
      filterMapper={filterMapper}
    />
  );
}

const getColumns = (intl: IntlShape) => [
  {
    Header: intl.formatMessage({ id: "notifications.table.name" }),
    accessor: nameOf<NotificationListItemDto>("name"),
    Cell: (cell: any) => <NotificationNameCell notification={cell.cell.row.original} />
  },
  {
    Header: intl.formatMessage({ id: "notifications.table.url" }),
    accessor: nameOf<NotificationListItemDto>("uri"),
    Cell: (cell: any) => (
      <span title={getValueOrEmpty(cell.cell.value)}>
        {truncateAndAppendEllipsis(cell.cell.value)}
      </span>
    )
  },
  {
    Header: intl.formatMessage({ id: "notifications.table.status" }),
    accessor: nameOf<NotificationListItemDto>("enabled"),
    Cell: (cell: any) => (cell.cell.value ? NotificationStatus.Active : NotificationStatus.Paused)
  },
  {
    Header: intl.formatMessage({ id: "notifications.table.creationDate" }),
    accessor: "createdDate",
    Cell: (cell: any) => <FormatDateTime utcDate={cell.cell.value} />
  },
  {
    Header: intl.formatMessage({ id: "notifications.table.actions" }),
    accessor: "actions",
    Cell: (cell: any) => <NotificationActionsCell notificationDto={cell.cell.row.original} />
  }
];

const filterMapper = (notification: NotificationListItemDto) => {
  return {
    ...notification,
    enabled: notification.enabled ? NotificationStatus.Active : NotificationStatus.Paused,
    createdDate: getFormattedUtcDate(notification.createdDate)
  };
};
