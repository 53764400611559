export enum OrderType {
  PortIn = "port-in",
  PortOut = "port-out",
  Disconnect = "disconnect",
  ModifyActive = "modifyactive",
  CsrOnly = "csr-only",
  DisasterRecovery = "Disaster Recovery",
  Restore = "Restore",
  NewNumber = "New Number"
}
