import React, { useState } from "react";
import { Card, CardHeader, CardBody, Row } from "reactstrap";
import TextRow from "components/networkExplorer/common/TextRow";
import { useIntl, FormattedMessage } from "react-intl";
import { NpaNxxResult } from "services/apis/types/npaNxx/NpaNxxResult";
import FormatDateTime from "components/framework/date/FormatDateTime";
import ActionDropdown from "../common/ActionDropdown";
import ActionDropdownItem from "../common/ActionDropdownItem";
import NpaNxxDeleteModal from "./NpaNxxDeleteModal";
import Authorize from "components/framework/authorization/Authorize";
import { PermissionType } from "services/authorization/PermissionType";

type Props = {
  npaNxx: NpaNxxResult;
};

export default function NpaNxxCard(props: Props) {
  const intl = useIntl();
  const [actionsOpen, setActionsOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const actionsToggle = () => setActionsOpen((prevState) => !prevState);

  const onDelete = () => {
    setShowDeleteModal(true);
  };

  return (
    <>
      <div className="card-wrapper">
        <Card>
          <CardHeader>
            <Row>
              <h3 className="mb-0 col-8">
                <FormattedMessage id="network.npaNxx.title" />
              </h3>
              <Authorize spidPermissions={[PermissionType.NetworkWrite]}>
                <ActionDropdown actionsOpen={actionsOpen} actionsToggle={actionsToggle}>
                  <ActionDropdownItem
                    handleAction={onDelete}
                    iconClassName="fal fa-trash-alt"
                    labelId="network.npaNxx.delete"
                  />
                </ActionDropdown>
              </Authorize>
            </Row>
          </CardHeader>
          <CardBody className="text-xs">
            <table>
              <tbody>
                <TextRow name={intl.formatMessage({ id: "network.npaNxx.code" })}>
                  <>{props.npaNxx.npaNxx}</>
                </TextRow>
                <TextRow name={intl.formatMessage({ id: "network.npaNxx.assignedDate" })}>
                  <FormatDateTime utcDate={props.npaNxx.assignedDate} />
                </TextRow>
                <TextRow name={intl.formatMessage({ id: "network.npaNxx.spId" })}>
                  <>{props.npaNxx.spId}</>
                </TextRow>
                <TextRow name={intl.formatMessage({ id: "network.npaNxx.ocn" })}>
                  <>{props.npaNxx.ocn}</>
                </TextRow>
                <TextRow name={intl.formatMessage({ id: "network.npaNxx.state" })}>
                  <>{props.npaNxx.state}</>
                </TextRow>
                <TextRow name={intl.formatMessage({ id: "network.npaNxx.rateCenter" })}>
                  <>{props.npaNxx.rateCenter}</>
                </TextRow>
                <TextRow name={intl.formatMessage({ id: "network.npaNxx.lata" })}>
                  <>{props.npaNxx.lata}</>
                </TextRow>
              </tbody>
            </table>
          </CardBody>
        </Card>
      </div>
      {showDeleteModal && (
        <NpaNxxDeleteModal
          closeModal={() => setShowDeleteModal(false)}
          npaNxx={props.npaNxx.npaNxx}
        />
      )}
    </>
  );
}
