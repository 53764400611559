import React from "react";
import Select, { createFilter, components } from 'react-select';
import { FormattedMessage } from "react-intl";

export default function DropDown(props) {

  return (
    <>
    <div className="d-flex align-items-center ml-2 w-25">
      <h4 className="font-weight-bold ml-3 mt-2">
        <FormattedMessage id="table.block" />
      </h4>
      <Select
        className="w-75 p-1 ml-1"
        filterOption={createFilter({ ignoreAccents: false })}
        options={props.options}
        components={{Option: CustomOption}}
        onInputChange={props.onInputChange}
        value={props.selectedValue}
        onChange={props.handleSelect}
      />
    </div>
    </>
  );
}

class CustomOption extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    const {innerProps, isFocused, ...otherProps} = this.props;
        const {onMouseMove, onMouseOver, ...otherInnerProps} = innerProps;
        const newProps = {innerProps: {...otherInnerProps}, ...otherProps};
        return (
            <components.Option {...newProps}>{this.props.children}
            </components.Option>
        );
  }
}
