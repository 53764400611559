import { HttpClient } from "services/httpClient/HttpClient";
import { ApiError } from "./types/ApiError";

export const DnoApi = {
  setDnoFlag: (number: string[], dnoFlag: boolean): Promise<ApiError> => {
    return HttpClient.post<ApiError>(`realnumberdno/setDno?dnoFlag=${dnoFlag}`, number);
  },
  bulkDnoFlag: (numbers: string[], dnoFlag: boolean): Promise<ApiError> => {
    return HttpClient.post<ApiError>(`realnumberdno/setBulkDno?dnoFlag=${dnoFlag}`, numbers);
  }
};
