import React, { useState, useContext } from "react";
import { LrnResult } from "services/apis/types/lrn/LrnResult";
import { FormGroup, Input } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { LrnApi } from "services/apis/LrnApi";
import { NetworkExplorerContext } from "../NetworkExplorer";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleFieldErrors } from "services/util/ApiUtil";
import ConfirmationModal from "components/framework/modals/ConfirmationModal";

type Props = {
  lrn: LrnResult;
  closeModal: () => void;
};

export default function LrnDeleteModal(props: Props) {
  const intl = useIntl();
  const [npacRegion, setNpacRegion] = useState<string | undefined>(props.lrn.npacRegions[0]);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const isMounted = useIsMounted();
  const networkExplorerContext = useContext(NetworkExplorerContext);

  const handleDelete = () => {
    if (isMounted.current) {
      setShowLoadingIndicator(true);
    }

    LrnApi.delete(props.lrn.lrn, npacRegion)
      .then(() => {
        showInfoNotification(intl.formatMessage({ id: "network.lrn.delete.deleteNotification" }));
        props.closeModal();

        if (props.lrn.npacRegions.length <= 1 && isMounted.current) {
          networkExplorerContext.clearSearch();
        }
      })
      .catch(error => handleFieldErrors(error))
      .finally(() => {
        if (isMounted.current) {
          setShowLoadingIndicator(false);
        }
      });
  };

  return (
    <ConfirmationModal
      confirmCallback={handleDelete}
      closeModal={props.closeModal}
      title={intl.formatMessage({ id: "network.lrn.delete.deleteModalTitle" })}
      confirmButtonText={intl.formatMessage({ id: "network.lrn.delete.submitDelete" })}
      showLoadingIndicator={showLoadingIndicator}>
      <p>
        <FormattedMessage id="network.lrn.delete.deleteQuestion" values={{ lrn: props.lrn.lrn }} />
      </p>
      {props.lrn.npacRegions.length > 1 && (
        <FormGroup>
          <label className="form-control-label">
            <FormattedMessage id="network.lrn.delete.npacRegion" />
          </label>
          <Input
            type="select"
            onChange={event => setNpacRegion(event.target.value)}
            value={npacRegion}
            className="form-control form-control-sm">
            {props.lrn.npacRegions.map(x => (
              <option key={x} value={x}>
                {x}
              </option>
            ))}
            ;
          </Input>
        </FormGroup>
      )}
    </ConfirmationModal>
  );
}
