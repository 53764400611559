import React, { useMemo, useCallback } from "react";
import { FormGroup, Label } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";
import moment, { Moment } from "moment";
import { ErrorHandler } from "services/customHooks/useErrors";
import InputError from "../errorHandling/InputError";
import DateTimeInput from "./DateTimeInput";
import { AppConfiguration } from "AppConfiguration";

export type DateTimeFormInputProps = {
  disabled?: boolean;
  labelTranslationId: string;
  placeholderTranslationId?: string;
  value?: string;
  errorHandler?: ErrorHandler;
  className?: string;
  handleInputChange?: (value: string) => void;
};

export default function TimeInput24Hrs(props: DateTimeFormInputProps) {
  const intl = useIntl();
  const errors = props.errorHandler?.getErrors() || [];
  const placeholder =
    props.disabled || !props.placeholderTranslationId
      ? ""
      : intl.formatMessage({ id: props.placeholderTranslationId });

  const parsedValue = useMemo(() => moment.utc(props.value, AppConfiguration.timeFormat24H), [
    props.value
  ]);

  const formatValue = useCallback((value: Moment) => {
    return value.format(AppConfiguration.timeFormat24H);
  }, []);

  return (
    <FormGroup className={classNames("mb-2", props.className ? props.className : "")}>
      <Label className="form-control-label">
        <FormattedMessage id={props.labelTranslationId} />
      </Label>
      <DateTimeInput
        disabled={props.disabled}
        className={classNames({
          "pc-invalid-input": errors && errors.length > 0
        })}
        placeholder={placeholder}
        value={parsedValue}
        onChange={(value) => {
          props.errorHandler?.clear();
          props.handleInputChange && props.handleInputChange(formatValue(value));
        }}
        isTimeInput
      />
      <InputError errors={errors} />
    </FormGroup>
  );
}
