import React, { useState, FormEvent, useCallback, useContext } from "react";
import { Modal, Form, Button, Row } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useErrors } from "services/customHooks/useErrors";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { HasErrors, Errors, AddError } from "components/framework/errorHandling/ErrorUtil";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleError, getFieldErrors } from "services/util/ApiUtil";
import CloseButton from "components/framework/modals/CloseButton";
import { nameOf } from "services/util/ObjectUtil";
import TextFormInput from "components/framework/forms/TextFormInput";
import CheckboxFormInput from "components/framework/forms/CheckboxFormInput";
import { useHistory } from "react-router-dom";
import { ProvisionOrderListDto } from "services/apis/types/order/ProvisionOrderListDto";
import { AppContext } from "services/appContext/AppContext";
import { DefaultTnInventoryRequestDto, TnInventoryRequestDto } from "services/apis/types/searchAndProvision/TnInventoryRequestDto";
import { SinchApi } from "services/apis/SinchApi";
import { isNumericRegex, isQtyNumericRegex } from "services/util/StringUtil";
import ErrorList from "components/framework/errorHandling/ErrorList";
import { ApiError } from "components/common/types/ApiError";
import { ApiErrorType } from "components/common/types/ApiErrorType";

type Props = {
  closeModal: () => void;
};

export default function SearchAndProvisionModal(props: Props) {
  const { setErrors, getErrorHandler } = useErrors();
  const { appContext, setAppContext } = useContext(AppContext);
  const [searchAndProvision, setSearchAndProvision] = useState<TnInventoryRequestDto>(DefaultTnInventoryRequestDto);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const intl = useIntl();
  const isMounted = useIsMounted();
  const history = useHistory();
  const [tnInventoryErrors, setTnInventoryErrors] = useState<ApiError[]>([]);
  const theme = appContext.theme;

  const closeModal = useCallback(props.closeModal, []);
  // const onPaste = usePastedNumberFormatter(searchAndProvision.phoneNumbers || "", (formattedValue) =>
  //   setSearchAndProvision({ ...searchAndProvision, phoneNumbers: formattedValue })
  // );

  // const phoneNumbersErrorsErrorHandler = getErrorHandler(nameOf<TnInventoryRequestDto>("phoneNumbers"));
  // const phoneNumbersErrors = phoneNumbersErrorsErrorHandler.getErrors();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setShowLoadingIndicator(true);
    const errors = validateSearchAndProvision(searchAndProvision);

    if (HasErrors(errors)) {
      setErrors(errors);
      if (isMounted.current) {
        setShowLoadingIndicator(false);
      }
    } else if (!searchAndProvision.npa && !searchAndProvision.nxx && !searchAndProvision.line) {
      setTnInventoryErrors([{
        message: `${intl.formatMessage({ id: "orders.searchAndProvision.validation.all" })}`,
        type: ApiErrorType.Danger
      }]);
      setShowLoadingIndicator(false);
    } else {
      SinchApi.tnInventory(searchAndProvision)
        .then((result) => {
          if (result.statusCode === "430"){
            setTnInventoryErrors([{
              message: `${result.status}`,
              type: ApiErrorType.Danger
            }]);
            return;
          }
          const provisionOrders : ProvisionOrderListDto[] = result.tnResult?.map((item) => {
            return {
              orderId: item.telephoneNumber,
              phoneNumber: item.telephoneNumber,
              selected: false,
              countryCode: item.countryCode,
              requestId: result.requestId
            }
          }) || [];

          if (isMounted && result) {
            setAppContext({
              ...appContext,
              localStorageInfo: {...appContext.localStorageInfo, provisionOrders: provisionOrders}
            });
          } else {
            setAppContext({
              ...appContext,
              localStorageInfo: {...appContext.localStorageInfo, provisionOrders: []}
            });
          }
          showInfoNotification(
            intl.formatMessage({ id: "orders.searchAndProvision.successNotificationMessage" })
          );
          closeModal();
          history.push("/Orders/Provison/ProvisionOrders");
        })
        .catch((error) => {
          handleError(error);
          if (isMounted.current) {
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
          }
        });
     }
  };

  return (
    <>
      <Modal className="modal-dialog-centered modal-md" isOpen={true}>
        <div className="modal-header">
          <h5 className="modal-title">
            <FormattedMessage id="orders.searchAndProvision.title" />
          </h5>
          <CloseButton close={props.closeModal} />
        </div>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <div className="modal-body">
            {/* <Row>
              <DropdownFormInput
                className="col-lg-12"
                labelTranslationId="orders.searchAndProvision.country"
                value={searchAndProvision.country}
                handleInputChange={(value: string) => setSearchAndProvision({...searchAndProvision, country: value})}
                errorHandler={getErrorHandler(nameOf<TnInventoryRequestDto>("country"))}
                options={Object.keys(Country).map((x) => {
                  return { key: x, value: Country[x] };
                })}
              />
            </Row> */}
            <Row>
              <TextFormInput
                formGroupClassName="col-lg-4"
                labelTranslationId="orders.searchAndProvision.npa"
                value={searchAndProvision.npa}
                handleInputChange={(value: string) => setSearchAndProvision({ ...searchAndProvision, npa: value })}
                errorHandler={getErrorHandler(nameOf<TnInventoryRequestDto>("npa"))}
              />
              <TextFormInput
                formGroupClassName="col-lg-4"
                labelTranslationId="orders.searchAndProvision.nxx"
                value={searchAndProvision.nxx}
                handleInputChange={(value: string) => setSearchAndProvision({ ...searchAndProvision, nxx: value })}
                errorHandler={getErrorHandler(nameOf<TnInventoryRequestDto>("nxx"))}
              />
              <TextFormInput
                formGroupClassName="col-lg-4"
                labelTranslationId="orders.searchAndProvision.line"
                value={searchAndProvision.line}
                handleInputChange={(value: string) => setSearchAndProvision({ ...searchAndProvision, line: value })}
                errorHandler={getErrorHandler(nameOf<TnInventoryRequestDto>("line"))}
              />
            </Row>
            <Row>
              <TextFormInput
                required
                formGroupClassName="col-lg-4"
                labelTranslationId="orders.searchAndProvision.quantity"
                value={searchAndProvision.quantity}
                handleInputChange={(value: string) => setSearchAndProvision({ ...searchAndProvision, quantity: value })}
                errorHandler={getErrorHandler(nameOf<TnInventoryRequestDto>("quantity"))}
              />
            </Row>
            <Row>
              <CheckboxFormInput
                value={intl.formatMessage({ id: "orders.searchAndProvision.consecutive" })}
                label={intl.formatMessage({ id: "orders.searchAndProvision.consecutive" })}
                handleInputChange={(value: boolean) => setSearchAndProvision({ ...searchAndProvision, consecutive: value })}
                checked={searchAndProvision.consecutive || false}
                className="font-weight-bold mx-2"
              />
            </Row>
            {/* <Row>
              <FormGroup className="mb-2 col-lg-12 pt-2">
                <Label className="form-control-label">
                  <FormattedMessage id="orders.searchAndProvision.numberRange" />
                </Label>
                <Input
                  className={classNames("input-group-merge pc-search-container mb-0 h-180", {
                    focused: focusedSearchInput,
                    "pc-invalid-input": phoneNumbersErrors && phoneNumbersErrors.length > 0
                  })}
                  rows="6"
                  type="textarea"
                  value={searchAndProvision.phoneNumbers}
                  onChange={(e) => {
                    phoneNumbersErrorsErrorHandler.clear();
                    setSearchAndProvision({ ...searchAndProvision, phoneNumbers: e.currentTarget.value });
                  }}
                  onFocus={() => setFocusedSearchInput(true)}
                  onBlur={() => setFocusedSearchInput(false)}
                  onPaste={onPaste}
                  placeholder={intl.formatMessage({
                    id: "orders.searchAndProvision.enterNumbersMessage"
                  })}
                />
                <InputError errors={phoneNumbersErrors} />
              </FormGroup>
            </Row> */}
          </div>
          <div className="modal-footer justify-content-end">
            <Button color="primary" type="button" className={`btn ${theme === "light" ? "btn-outline-lblue" : "btn-outline-primary"}`} onClick={() => props.closeModal()}>
              <FormattedMessage id="orders.searchAndProvision.cancel" />
            </Button>
            <Button color="primary" className={`${theme === "light" ? "bg-lblue no-border" : ""}`} type="submit" disabled={showLoadingIndicator}>
              {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
              <FormattedMessage id="orders.searchAndProvision.search" />
            </Button>
          </div>
          {tnInventoryErrors && <div className="pb-4">
            <ErrorList errors={tnInventoryErrors} /> 
          </div>}
        </Form>
      </Modal>
    </>
  );
}

const validateSearchAndProvision = (searchAndProvision: TnInventoryRequestDto) => {
  const errors: Errors = {};

  if (searchAndProvision.npa && (searchAndProvision.npa.length > 3 || searchAndProvision.npa.length < 3 || !isNumericRegex(searchAndProvision.npa))){
    AddError(errors, nameOf<TnInventoryRequestDto>("npa"), "orders.searchAndProvision.validation.npa")
  }

  if (searchAndProvision.nxx && (searchAndProvision.nxx.length > 3 || searchAndProvision.nxx.length < 3 || !isNumericRegex(searchAndProvision.nxx))){
    AddError(errors, nameOf<TnInventoryRequestDto>("nxx"), "orders.searchAndProvision.validation.nxx")
  }

  if (searchAndProvision.line && (searchAndProvision.line.length > 4 || searchAndProvision.line.length < 4 || !isNumericRegex(searchAndProvision.line))){
    AddError(errors, nameOf<TnInventoryRequestDto>("line"), "orders.searchAndProvision.validation.line")
  }

  if (!searchAndProvision.quantity){
    AddError(errors, nameOf<TnInventoryRequestDto>("quantity"), "orders.searchAndProvision.validation.quantity")
  }

  if (searchAndProvision.quantity && (!isQtyNumericRegex(searchAndProvision.quantity))){
    AddError(errors, nameOf<TnInventoryRequestDto>("quantity"), "orders.searchAndProvision.validation.quantity.isNumber")
  }

  return errors;
};
