export type TnInventoryRequestDto = {
  npa?: string;
  nxx?: string;
  line?: string;
  quantity?: string;
  consecutive?: boolean;
};

export const DefaultTnInventoryRequestDto: TnInventoryRequestDto = {
  npa: "",
  nxx: "",
  line: "",
  quantity: "",
  consecutive: false
};
