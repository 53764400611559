import React from "react";
import { Button } from "reactstrap";
import { FormattedMessage } from "react-intl";

type Props = {
  closeToast?: () => void;
  closeNotificationCallback: () => void;
};

export default function NotificationReloadButtons(props: Props) {
  const onCancel = () => {
    props.closeNotificationCallback();

    if (props.closeToast) {
      props.closeToast();
    }
  };

  return (
    <>
      <Button type="button" className="ml-auto btn-sm" onClick={() => window.location.reload()}>
        <FormattedMessage id="framework.notifications.version.buttons.reload" />
      </Button>
      <Button type="button" className="ml-auto btn-sm" onClick={onCancel}>
        <FormattedMessage id="framework.notifications.version.buttons.cancel" />
      </Button>
    </>
  );
}
