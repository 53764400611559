export type RequestAuditModel = {
  phoneNumberRange: string;
  name: string;
  activatedFrom?: Date;
  activatedTo?: Date;
  spId: string;
};

export const DefaultRequestAuditModel: RequestAuditModel = {
  phoneNumberRange: "",
  name: "",
  spId: ""
};
