import React, { useState } from "react";
import ConfirmationModal from "components/framework/modals/ConfirmationModal";
import { FormattedMessage, useIntl } from "react-intl";
import { OrderApi } from "services/apis/OrderApi";
import { OrderDto } from "services/apis/types/order/OrderDto";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleFieldOrApiErrors } from "services/util/ApiUtil";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { UpdateOrderToSubmittedStatusModel } from "services/apis/types/order/UpdateOrderStatusModel";
import { SvRequestStatus } from "../types/SvRequestStatus";

type Props = {
  order: OrderDto;
  status: SvRequestStatus;
  confirmationMessageId?: string;
  closeModal: () => void;
  onActionExecuted: () => void;
};

export default function UpdateOrderStatusModal(props: Props) {
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const intl = useIntl();
  const isMounted = useIsMounted();

  const submit = () => {
    setShowLoadingIndicator(true);

    var update = UpdateOrderToSubmittedStatusModel;
    update.status = props.status;

    OrderApi.updateOrderStatus(props.order.orderId, props.order.tenant, update)
      .then(() => {
        showInfoNotification(
          intl.formatMessage({ id: "orders.orderDetails.actionExecutedSuccesfuly" })
        );

        props.onActionExecuted();
        props.closeModal();
      })
      .catch((error) => handleFieldOrApiErrors(error))
      .finally(() => {
        if (isMounted.current) {
          setShowLoadingIndicator(false);
        }
      });
  };

  return (
    <>
      <ConfirmationModal
        confirmCallback={submit}
        closeModal={props.closeModal}
        title={intl.formatMessage({
          id: "orders.orderDetails.portingNumber.table.confirmActionModalTitle"
        })}
        confirmButtonText={intl.formatMessage({
          id: "orders.orderDetails.portingNumber.table.confirmAction"
        })}
        showLoadingIndicator={showLoadingIndicator}>

        {props.confirmationMessageId && (
          <p>
            <FormattedMessage
              id={props.confirmationMessageId}
              values={{ number: props.order.orderId }}

            />
          </p>
        )}

      </ConfirmationModal>
    </>
  );
}
