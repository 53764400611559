import { Authorized } from "./Authorized";
import { PortOutRejectionReason } from "./PortOutRejectionReason";
import { Moment } from "moment";
import { getUtcDateTomorrow } from "services/util/DateUtil";

export type PortOutModel = {
  phoneNumbers: string[];
  dueDate: Moment;
  authorized: keyof typeof Authorized;
  rejectionReason: keyof typeof PortOutRejectionReason;
  newSpId: string;
  newProviderName: string;
  clientReference: string;
  hasErrors: boolean;
};

export const DefaultPortOutModel: PortOutModel = {
  phoneNumbers: [],
  dueDate: getUtcDateTomorrow(),
  authorized: "YES",
  rejectionReason: "",
  newSpId: "",
  newProviderName: "",
  clientReference: "",
  hasErrors: false
};
