import { AccountType } from "./AccountType";
import { ServiceAddressDto, DefaultServiceAddressDto } from "./ServiceAddressDto";

export type CustomerDetailsDto = {
  accountType: AccountType;
  accountNumber: string;
  accountName: string;
  accountPin: string;
  authorizedName: string;
  billingPhoneNumber: string;
  serviceAddress: ServiceAddressDto;
};

export const DefaultCustomerDetailsDto: CustomerDetailsDto = {
  accountType: AccountType.Residential,
  accountNumber: "",
  accountName: "",
  accountPin: "",
  authorizedName: "",
  billingPhoneNumber: "",
  serviceAddress: DefaultServiceAddressDto
};
