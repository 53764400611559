import { useEffect, useState } from "react";
import { replaceWhitespaceWithComma } from "services/util/StringUtil";
import { getFormattedNumbers } from "services/portControl/PhoneNumberService";

export const usePastedNumberFormatter = (
  inputValue: string,
  handlePaste: (formattedValue: string) => void
) => {
  const [pastedValue, setPastedValue] = useState("");
  useEffect(() => {
    if (
      pastedValue &&
      replaceWhitespaceWithComma(inputValue).indexOf(replaceWhitespaceWithComma(pastedValue)) > -1
    ) {
      handlePaste(getFormattedNumbers(inputValue));
      setPastedValue("");
    }
  }, [handlePaste, inputValue, pastedValue]);

  const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) =>
    setPastedValue(e.clipboardData.getData("text"));

  return onPaste;
};
