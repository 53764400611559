import React from "react";
import { TaskDto } from "services/apis/types/order/TaskDto";
import { Modal, Row, Col } from "reactstrap";
import { FormattedMessage } from "react-intl";
import CloseButton from "components/framework/modals/CloseButton";
import TextFormInput from "components/framework/forms/TextFormInput";
import TextAreaFormInput from "components/framework/forms/TextAreaFormInput";
import { AppConfiguration } from "AppConfiguration";
import { getUtcDate } from "services/util/DateUtil";

type Props = {
  taskDto: TaskDto;
  closeModal: () => void;
};

export default function TaskModal(props: Props) {
  return (
    <Modal className="modal-dialog-centered modal-lg" isOpen>
      <div className="modal-header">
        <h5 className="modal-title">
          <FormattedMessage id="orders.orderDetails.tasks.modal.modalTitle" />
        </h5>
        <CloseButton close={props.closeModal} />
      </div>
      <div className="modal-body">
        <Row>
          <Col>
            <TextFormInput
              readonly
              labelTranslationId="orders.orderDetails.tasks.modal.taskTitle"
              value={props.taskDto.title}
            />
            <TextAreaFormInput
              rows={6}
              disabled
              labelTranslationId="orders.orderDetails.tasks.modal.description"
              value={props.taskDto.description}
            />
            <TextFormInput
              readonly
              labelTranslationId="orders.orderDetails.tasks.modal.assignedTo"
              value={props.taskDto.assignedTo}
            />
            <TextFormInput
              readonly
              labelTranslationId="orders.orderDetails.tasks.modal.nextWorkDate"
              value={getUtcDate(props.taskDto.nextWorkDate).format(AppConfiguration.dateFormat)}
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
}
