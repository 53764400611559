import React, { useState, useEffect, useMemo, useCallback, useContext } from "react";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { IntlShape, useIntl } from "react-intl";
import { getValueOrEmpty } from "services/util/StringUtil";
import ReactTable from "components/framework/table/ReactTable";
import SpIdCell from "components/common/SpIdCell";
import { handleError } from "services/util/ApiUtil";
import { AppContext } from "services/appContext/AppContext";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { TableIdentifiers } from "services/uiSettings/TableIdentifiers";
import { CustomerInventoryTableColumnIdentifiers } from "./types/customerInventoryIdentifier";
import { CustomerInventoryDto } from "services/apis/types/customerInventory/CustomerInventoryDto";
import UsersModalCell from "components/common/UsersModalCell";
import SideToggle from "components/common/SideToggle";
import CustomerInventoryActionCell from "./customerInventoryActionCell";
import { CustomerInventoryApi } from "services/apis/CustomerInventoryApi";

type ColumnFilterSort = {
  isSorted?: boolean;
  isSortedDesc?: boolean;
}

type Props = {
  search: string;
};

export default function CustomerInventoryTable(props: Props) {
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const { appContext } = useContext(AppContext);
  const theme = appContext.theme;
  const isMounted = useIsMounted();
  const intl = useIntl();
  const [count, setCount] = useState(0);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [customerInventoryList, setCustomerInventoryList] = useState<CustomerInventoryDto[]>([]);
  const [columnFilterSort, setColumnFilterSort] = useState<{ [key: string]: ColumnFilterSort }>({});

  useEffect(() => {
    fetchCustomerInventoryData()
  }, [
    isMounted,
    props.search,
    appContext.localStorageInfo.selectedProfile, triggerRefresh
  ]);

  const fetchCustomerInventoryData = () => {
    setShowLoadingIndicator(true);
    CustomerInventoryApi.customerInventoryList(props.search)
      .then((result) => {
        const updatedData = result.map((x) => ({
          ...x,
          usersCount: x.users?.length,
          activateInt: Number(x.activation)
        })
        )
        setCustomerInventoryList(updatedData);
      })
      .catch((error) => {
        handleError(error);
        setCustomerInventoryList([]);
      })
      .finally(() => {
        setShowLoadingIndicator(false);
      });
  };

  const handleToggleSuccess = () => {
    fetchCustomerInventoryData();
  };

  const toggleRefresh = useCallback(() => {
    if (isMounted.current) {
      setTriggerRefresh((x) => !x);
    }
  }, [isMounted]);

  const columns = useMemo(() => {
    const columnsList = getColumns(intl, toggleRefresh, handleToggleSuccess, theme);
    return columnsList;
  }, [intl, toggleRefresh]);

  return showLoadingIndicator ? (
    <LoadingIndicator white />
  ) : (
    <ReactTable
      titleComponent={getTitleComponent(intl.formatMessage({ id: "customerInventory.table.title" }))}
      identifier={TableIdentifiers.CustomerInventory}
      data={customerInventoryList}
      columns={columns}
      showExport={false}
      useColumnFilters
      onRefresh={toggleRefresh}
      hideSearch={true}
      queryCount={count}
      columnSortOptions={columnFilterSort}
    />
  );
}

const getTitleComponent = (title) => {
  return (
    <div className="flex-fill">
      <h3 className="mb-2">{title}</h3>
    </div>
  );
};

const getColumns = (
  intl: IntlShape,
  toggleRefresh,
  handleToggleSuccess,
  theme
) => [
    {
      Header: intl.formatMessage({ id: "customerInventory.table.customerName" }),
      accessor: "customerName",
      identifier: CustomerInventoryTableColumnIdentifiers.CustomerName,
      Cell: (cell: any) => getValueOrEmpty(cell.cell.row.original.customerName)
    },
    {
      Header: intl.formatMessage({ id: "customerInventory.table.customerContactName" }),
      accessor: "customerContactName",
      identifier: CustomerInventoryTableColumnIdentifiers.CustomerContactName,
      Cell: (cell: any) => getValueOrEmpty(cell.cell.row.original.contactName)
    },
    {
      Header: intl.formatMessage({ id: "customerInventory.table.AltSpid" }),
      accessor: "altSpid",
      identifier: CustomerInventoryTableColumnIdentifiers.AltSpid,
      Cell: (cell: any) => <SpIdCell spId={cell.cell.row.original.altSpid} labelColor={`${theme === "light" ? "bg-lblue" : ""}`} />
    },
    {
      Header: intl.formatMessage({ id: "customerInventory.table.internalId" }),
      accessor: "internalId",
      identifier: CustomerInventoryTableColumnIdentifiers.InternalId,
      Cell: (cell: any) => (
        getValueOrEmpty(cell.cell.row.original.internalId)
      )
    },
    {
      Header: intl.formatMessage({ id: "customerInventory.table.users" }),
      accessor: "usersCount",
      identifier: CustomerInventoryTableColumnIdentifiers.Users,
      Cell: (cell: any) => (
        <UsersModalCell users={cell.cell.row.original.users} usersCount={cell.cell.row.original.usersCount}/>
      )
    },
    {
      Header: intl.formatMessage({ id: "customerInventory.table.activation" }),
      accessor: "activateInt",
      identifier: CustomerInventoryTableColumnIdentifiers.Activation,
      Cell: (cell: any) => (
        <SideToggle activation={cell.cell.row.original.activateInt} customerName={cell.cell.row.original.customerName} customerId={cell.cell.row.original.customerId} onUpdateSuccess={handleToggleSuccess}/>
      )
    },
    {
      Header: intl.formatMessage({ id: "customerInventory.table.actions" }),
      accessor: "actions",
      identifier: CustomerInventoryTableColumnIdentifiers.Actions,
      Cell: (cell: any) => <CustomerInventoryActionCell internalId={cell.cell.row.original.internalId} customerId={cell.cell.row.original.customerId} onUpdateSuccess={handleToggleSuccess}/>
    }
  ];
