import { ApiError } from "services/apis/types/ApiError";
import { PortApi } from "services/apis/PortApi";
import { OrderNumberActionType } from "./types/OrderNumberActionType";

export const PortService = {
  executeAction: (phoneNumber: string, action: OrderNumberActionType): Promise<ApiError> => {
    const methods: Record<string, (phoneNumber: string) => Promise<ApiError>> = {
      [OrderNumberActionType.Activate]: PortApi.activate,
      [OrderNumberActionType.Cancel]: PortApi.cancel,
      [OrderNumberActionType.RemoveFromConflict]: PortApi.removeFromConflict,
      [OrderNumberActionType.UndoCancel]: PortApi.undoCancel
    };

    return methods[action](phoneNumber);
  }
};
