import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { BlockResult } from "services/apis/types/block/BlockResult";
import { BlockApi } from "services/apis/BlockApi";
import NumbersCard from "./NumbersCard";
import { ContaminatedNumber } from "services/apis/types/block/ContaminatedNumber";
import BlockDetailsCard from "./BlockDetailsCard";
import NpacDetailsCard from "./NpacDetailsCard";
import GeographicDetailsCard from "./GeographicDetailsCard";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { handleError } from "services/util/ApiUtil";

type Props = {
  blockDash: string;
};

export default function BlockDetails(props: Props) {
  const [block, setBlock] = useState<BlockResult | undefined>(undefined);
  const [numbers, setNumbers] = useState<ContaminatedNumber[]>([]);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);

  useEffect(() => {
    let isMounted = true;
    setShowLoadingIndicator(true);
    setBlock(undefined);

    BlockApi.get(props.blockDash)
      .then(result => {
        if (isMounted) {
          setBlock(result);
        }
      })
      .catch(error => handleError(error))
      .finally(() => {
        if (isMounted) {
          setShowLoadingIndicator(false);
        }
      });

    BlockApi.getNumbers(props.blockDash)
      .then(result => {
        if (isMounted) {
          setNumbers(result);
        }
      })
      .catch(error => handleError(error));

    return () => {
      isMounted = false;
    };
  }, [props.blockDash]);

  return (
    <>
      {showLoadingIndicator ? (
        <LoadingIndicator white />
      ) : (
        <>
          {block && numbers && (
            <Row>
              <Col lg="4">
                <BlockDetailsCard block={block} numbers={numbers} />
                <NpacDetailsCard block={block} />
                <GeographicDetailsCard block={block} />
              </Col>
              <Col lg="8">
                <NumbersCard numbers={numbers} />
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
}
