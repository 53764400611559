import { SignalRClient } from "services/util/SignalRClient";
import { NotificationDto } from "./types/notification/NotificationDto";

export const NotificationApi = {
  getLatestNotifications: (count: number): Promise<NotificationDto[]> => {
    return SignalRClient.invoke<NotificationDto[]>("GetLatestNotifications", count);
  },

  markAsRead: (notificationId: string): Promise<void> => {
    return SignalRClient.invoke<void>("MarkAsRead", notificationId);
  },

  onNewNotification: (method: (...args: any[]) => void) => {
    SignalRClient.on("NewNotification", method);
  }
};
