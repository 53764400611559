import React from "react";
import { FormattedDate } from "react-intl";

type Props = {
  utcDate: string | Date | undefined;
};

export default function FormatDate(props: Props) {
  if (props.utcDate === undefined) {
    return <></>;
  }

  const localDate = new Date(props.utcDate);

  return <FormattedDate value={localDate} />;
}
