import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";
import InputError from "components/framework/errorHandling/InputError";
import { ErrorHandler } from "services/customHooks/useErrors";
import { usePastedNumberFormatter } from "services/customHooks/usePastedNumberFormatter";

type Props = {
  readonly?: boolean;
  labelTranslationId: string;
  value?: string | number;
  errorHandler?: ErrorHandler;
  formGroupClassName?: string;
  inputClassName?: string;
  placeholderTranslationId?: string;
  handleInputChange?: (value: string) => void;
  onClick?: (value: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
};

export default function PhoneNumberInput(props: Props) {
  const onPaste = usePastedNumberFormatter(
    props.value ? props.value.toString() : "",
    (formattedValue) => handleChange(formattedValue)
  );
  const intl = useIntl();
  const errors = props.errorHandler ? props.errorHandler.getErrors() : [];
  const placeholder =
    props.readonly || !props.placeholderTranslationId
      ? ""
      : intl.formatMessage({ id: props.placeholderTranslationId });

  const handleChange = (value: string) => {
    if (props.errorHandler) props.errorHandler.clear();
    if (props.handleInputChange) props.handleInputChange(value);
  };

  return (
    <FormGroup className={classNames("mb-2", props.formGroupClassName)}>
      <Label className="form-control-label">
        <FormattedMessage id={props.labelTranslationId} />
      </Label>
      <Input
        readOnly={props.readonly}
        type="text"
        className={classNames("form-control form-control-sm", props.inputClassName, {
          "pc-invalid-input": errors && errors.length > 0
        })}
        placeholder={placeholder}
        value={props.value ? props.value : ""}
        onChange={(e) => handleChange(e.currentTarget.value)}
        onClick={props.onClick}
        onPaste={onPaste}
      />
      {props.errorHandler && <InputError errors={props.errorHandler.getErrors()} />}
    </FormGroup>
  );
}
