import React, { useMemo } from "react";
import { useIntl, IntlShape } from "react-intl";
import { ContaminatedNumber } from "services/apis/types/block/ContaminatedNumber";
import FormatDate from "components/framework/date/FormateDate";
import ReactTable from "components/framework/table/ReactTable";
import { getFormattedUtcDate } from "services/util/DateUtil";

type Props = {
  numbers: ContaminatedNumber[];
};

export default function NumbersCard(props: Props) {
  const intl = useIntl();
  const columns = useMemo(() => getColumns(intl), [intl]);

  return (
    <ReactTable
      title={intl.formatMessage({ id: "network.block.table.title" })}
      data={props.numbers}
      columns={columns}
      showExport={true}
      filterMapper={filterMapper}
    />
  );
}

const getColumns = (intl: IntlShape) => [
  {
    Header: intl.formatMessage({ id: "network.block.table.telephoneNumber" }),
    accessor: "phoneNumber"
  },
  {
    Header: intl.formatMessage({ id: "network.block.table.provider" }),
    accessor: "providerName"
  },
  {
    Header: intl.formatMessage({ id: "network.block.table.spId" }),
    accessor: "spId"
  },
  {
    Header: intl.formatMessage({ id: "network.block.table.status" }),
    accessor: "status"
  },
  {
    Header: intl.formatMessage({ id: "network.block.table.lastEventDate" }),
    accessor: "lastActivtyDate",
    Cell: (cell: any) => FormatDate({ utcDate: cell.cell.value })
  }
];

const filterMapper = (number: ContaminatedNumber) => {
  return {
    ...number,
    lastActivtyDate: getFormattedUtcDate(number.lastActivtyDate)
  };
};
