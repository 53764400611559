import { HttpClient } from "services/httpClient/HttpClient";
import { ApiError } from "./types/ApiError";
import { NotificationListItemDto } from "./types/webhooks/NotificationListItemDto";
import { SubscriptionDto } from "./types/webhooks/SubscriptionDto";
import { NotificationDto } from "./types/webhooks/NotificationDto";

export const WebhookApi = {
  list: (searchValue: string): Promise<NotificationListItemDto[]> => {
    return HttpClient.get<NotificationListItemDto[]>(`webhook/?search=${searchValue}`, true, true);
  },

  details: (webhookId: string): Promise<NotificationDto> => {
    return HttpClient.get<NotificationDto>(`webhook/${webhookId}`, true, true);
  },

  create: (notificationDto: NotificationDto): Promise<ApiError> => {
    return HttpClient.post<ApiError>("webhook/", notificationDto, true, true);
  },

  modify: (id: string, notificationDto: NotificationDto): Promise<ApiError> => {
    return HttpClient.put<ApiError>(`webhook/${id}`, notificationDto, true, true);
  },

  delete: (id: string): Promise<ApiError> => {
    return HttpClient.delete<ApiError>(`webhook/${id}`, {}, true, true);
  },

  suspend: (id: string): Promise<ApiError> => {
    return HttpClient.post<ApiError>(`webhook/${id}/suspend`, {}, true, true);
  },

  resume: (id: string): Promise<ApiError> => {
    return HttpClient.post<ApiError>(`webhook/${id}/resume`, {}, true, true);
  },

  getSubscriptions: (): Promise<SubscriptionDto[]> => {
    return HttpClient.get<SubscriptionDto[]>("webhook/subscriptions", true, true);
  }
};
