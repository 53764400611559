import React, { useContext, ReactNode } from "react";
import { Route, Redirect } from "react-router-dom";
import { AppContext } from "services/appContext/AppContext";

type Props = {
  children: ReactNode;
  path?: string | string[];
  exact?: boolean;
};

export default function PublicRoute(props: Props) {
  const { appContext } = useContext(AppContext);

  return (
    <Route
      path={props.path}
      exact={props.exact}
      render={({ location }) =>
        appContext.localStorageInfo.authenticationInfo.authenticated && appContext.localStorageInfo.user ? (
          <Redirect to={{ pathname: "/", state: { from: location } }} />
        ) : (
          <>{props.children}</>
        )
      }
    />
  );
}
