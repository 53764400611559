import { KeyValuePair } from "services/types/KeyValuePair";

export const userLocales: KeyValuePair<string, string>[] = [{ key: "US English", value: "en_US" }];

export const userZoneInfos: KeyValuePair<string, string>[] = [
  { key: "UTC", value: "UTC" },
  { key: "Eastern Standard Time", value: "Eastern Standard Time" },
  { key: "Central Standard Time", value: "Central Standard Time" },
  { key: "Mountain Standard Time", value: "Mountain Standard Time" },
  { key: "Pacific Standard Time", value: "Pacific Standard Time" },
  { key: "Alaskan Standard Time", value: "Alaskan Standard Time" },
  { key: "Hawaiian Standard Time", value: "Hawaiian Standard Time" }
];
