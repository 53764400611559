import { HttpClient } from "services/httpClient/HttpClient";
import { UserDto } from "./types/account/UserDto";
import { UpdateAccountDto } from "./types/account/UpdateAccountDto";
import { ApiError } from "./types/ApiError";
import { SignUpRequestDto } from "./types/account/SignUpRequestDto";
import { VerifyTokenDto } from "./types/account/VerifyTokenDto";

export const AccountApi = {
  info: (): Promise<UserDto> => {
    return HttpClient.get<UserDto>("account/info");
  },

  updateInfo: (model: UpdateAccountDto): Promise<ApiError> => {
    return HttpClient.put<ApiError>(`account/info`, model, true, false);
  },

  signup: (model: SignUpRequestDto): Promise<ApiError> => {
    return HttpClient.post<ApiError>(`account/signup`, model, false, false);
  },

  verify: (model: VerifyTokenDto): Promise<ApiError> => {
    return HttpClient.post<ApiError>(`account/verify`, model, false, false);
  }
};
