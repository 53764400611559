import { getUtcDate } from "services/util/DateUtil";
import { Moment } from "moment";

export type TasksModel = {
  title: string;
  description: string;
  nextWorkDate: Moment;
  assignedTo: string;
};

export const DefaultTasksModel: TasksModel = {
  title: "",
  description: "",
  nextWorkDate: getUtcDate(),
  assignedTo: ""
};
