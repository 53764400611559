export function nameOf<T1>(key1: keyof T1): string;
export function nameOf<T1, T2>(key1: keyof T1, key2: keyof T2): string;
export function nameOf<T1, T2, T3>(key1: keyof T1, key2: keyof T2, key3: keyof T3): string;
export function nameOf<T1, T2, T3, T4>(
  key1: keyof T1,
  key2: keyof T2,
  key3: keyof T3,
  key4: keyof T4
): string;
export function nameOf<T1, T2, T3, T4>(
  key1: keyof T1,
  key2?: keyof T2,
  key3?: keyof T3,
  key4?: keyof T4
): string {
  return key4
    ? `${key1}.${key2}.${key3}.${key4}`.toLowerCase()
    : key3
    ? `${key1}.${key2}.${key3}`.toLowerCase()
    : key2
    ? `${key1}.${key2}`.toLowerCase()
    : `${key1}`.toLowerCase();
}

export const hasAnyValue = (object: any) => {
  return object && Object.values(object).filter((x) => x).length;
};
