import { PortStatistic } from "./PortStatistic";

export type ReportDto = {
  exploreCount: number;
  cnamCount: number;
  historyCount: number;
  portInCount: number;
  portInData: PortStatistic[];
  portOutCount: number;
  portOutData: PortStatistic[];
  portInPortControlCount: number;
  portOutPortControlCount: number;
};

export const DefaultReportDto: ReportDto = {
  exploreCount: 0,
  cnamCount: 0,
  historyCount: 0,
  portInCount: 0,
  portInData: [],
  portOutCount: 0,
  portOutData: [],
  portInPortControlCount: 0,
  portOutPortControlCount: 0
};
