import React, { useState } from "react";
import TextFormInput from "components/framework/forms/TextFormInput";
import { nameOf } from "services/util/ObjectUtil";
import CardSectionHeader from "components/framework/cards/CardSectionHeader";
import classnames from "classnames";
import { isEmail, isEmailOrUrl } from "services/util/StringUtil";
import { ServiceProviderProfile } from "services/apis/types/serviceProvider/ServiceProviderProfile";
import { ErrorHandler } from "services/customHooks/useErrors";
import DropdownFormInput from "components/framework/forms/DropdownFormInput";
import { ProcessingTimeType } from "../types/ProcessingTimeType";
import CheckboxFormInput from "components/framework/forms/CheckboxFormInput";
import { useIntl, FormattedMessage } from "react-intl";
import { authorize } from "services/authorization/AuthorizationService";
import { PermissionType } from "services/authorization/PermissionType";

type Props = {
  readonly: boolean;
  serviceProvider: ServiceProviderProfile;
  isRespOrgId: boolean;
  setServiceProvider: (serviceProvider: ServiceProviderProfile) => void;
  getErrorHandler: (key: string) => ErrorHandler;
};

export default function LnpInformation(props: Props) {
  const intl = useIntl();
  const [isAdmin] = useState(authorize(PermissionType.Admin));
  const formatUrl = (link: string) => {
    const url = isEmail(link) ? `mailto:${link}` : link;

    return url;
  };

  const handleInputClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    if (props.readonly && isEmailOrUrl(e.currentTarget.value)) {
      window.open(formatUrl(e.currentTarget.value), "_blank");
    }
  };

  return (
    <>
      <CardSectionHeader labelTranslationId="tppModal.lnpInformation" />

      <div className="mb-3 d-flex">
        <div className="flex-grow-1 mr-2">
          {!props.isRespOrgId && (
            <>
              <div className="d-flex flex-direction-column">
                <TextFormInput
                  inputClassName={classnames({
                    "cursor-pointer":
                      props.readonly && isEmailOrUrl(props.serviceProvider.lnpTppLocation)
                  })}
                  readonly={props.readonly}
                  labelTranslationId="tppModal.lsrLocation"
                  placeholderTranslationId="tppModal.lsrLocation.placeholder"
                  value={props.serviceProvider.lsrLocation}
                  required={!props.readonly && !props.isRespOrgId}
                  handleInputChange={(value: string) =>
                    props.setServiceProvider({ ...props.serviceProvider, lsrLocation: value })
                  }
                  onClick={handleInputClick}
                  errorHandler={props.getErrorHandler(
                    nameOf<ServiceProviderProfile>("lsrLocation")
                  )}
                />
              </div>
              <div className="flex-grow-1">
                <div className="d-flex flex-direction-column">
                  <TextFormInput
                    inputClassName={classnames({
                      "cursor-pointer":
                        props.readonly && isEmailOrUrl(props.serviceProvider.lnpTppLocation)
                    })}
                    readonly={props.readonly}
                    labelTranslationId="tppModal.csrLocation"
                    placeholderTranslationId="tppModal.csrLocation.placeholder"
                    value={props.serviceProvider.csrLocation}
                    required={!props.readonly && !props.isRespOrgId}
                    handleInputChange={(value: string) =>
                      props.setServiceProvider({ ...props.serviceProvider, csrLocation: value })
                    }
                    onClick={handleInputClick}
                    errorHandler={props.getErrorHandler(
                      nameOf<ServiceProviderProfile>("csrLocation")
                    )}
                  />
                </div>
              </div>
              <div className="flex-grow-1">
                <div className="d-flex flex-direction-column">
                  <TextFormInput
                    inputClassName={classnames({
                      "cursor-pointer":
                        props.readonly && isEmailOrUrl(props.serviceProvider.lnpTppLocation)
                    })}
                    readonly={props.readonly}
                    labelTranslationId="tppModal.lnpTppLocation"
                    placeholderTranslationId="tppModal.lnpTppLocation.placeholder"
                    value={props.serviceProvider.lnpTppLocation}
                    required={!props.readonly && !props.isRespOrgId}
                    handleInputChange={(value: string) =>
                      props.setServiceProvider({ ...props.serviceProvider, lnpTppLocation: value })
                    }
                    onClick={handleInputClick}
                    errorHandler={props.getErrorHandler(
                      nameOf<ServiceProviderProfile>("lnpTppLocation")
                    )}
                  />
                </div>
              </div>
            </>
          )}
          <div className="flex-grow-1">
            <div className="d-flex flex-direction-column">
              <DropdownFormInput
                disabled={props.readonly}
                required
                labelTranslationId="tppModal.processingTime"
                value={props.serviceProvider.lnpSpeed}
                handleInputChange={(value: string) =>
                  props.setServiceProvider({ ...props.serviceProvider, lnpSpeed: value })
                }
                errorHandler={props.getErrorHandler(nameOf<ServiceProviderProfile>("lnpSpeed"))}
                options={Object.keys(ProcessingTimeType).map((x) => {
                  return { key: x, value: ProcessingTimeType[x] };
                })}
              />
            </div>
          </div>
          <div className="flex-grow-1">
            <div className="d-flex flex-direction-column">
              <TextFormInput
                readonly={props.readonly}
                labelTranslationId="tppModal.lnpPhoneNumber"
                placeholderTranslationId="tppModal.lnpPhoneNumber.placeholder"
                value={props.readonly && !props.serviceProvider.lnpPhoneNumber ? "No Phone Number Available" : props.serviceProvider.lnpPhoneNumber}
                required={!props.readonly && props.isRespOrgId}
                handleInputChange={(value: string) =>
                  props.setServiceProvider({ ...props.serviceProvider, lnpPhoneNumber: value })
                }
                onClick={handleInputClick}
                errorHandler={props.getErrorHandler(
                  nameOf<ServiceProviderProfile>("lnpPhoneNumber")
                )}
              />
            </div>
          </div>
          <div className="flex-grow-1">
            <div className="d-flex flex-direction-column">
              <TextFormInput
                inputClassName={classnames({
                  "cursor-pointer": props.readonly && isEmailOrUrl(props.serviceProvider.lnpEmail)
                })}
                readonly={props.readonly}
                labelTranslationId="tppModal.lnpEmail"
                placeholderTranslationId="tppModal.lnpEmail.placeholder"
                value={props.serviceProvider.lnpEmail}
                required={!props.readonly && props.isRespOrgId}
                handleInputChange={(value: string) =>
                  props.setServiceProvider({ ...props.serviceProvider, lnpEmail: value })
                }
                onClick={handleInputClick}
                errorHandler={props.getErrorHandler(nameOf<ServiceProviderProfile>("lnpEmail"))}
              />
            </div>
          </div>
          {isAdmin && <div className="flex-grow-1 font-weight-bold">
            <div className="d-flex flex-direction-column">
              <FormattedMessage id="tppModal.ixcRequiredLabel" />
            </div>
            <div className="d-flex flex-direction-column">
              <div className="row pl-3">
                <CheckboxFormInput
                  value={intl.formatMessage({ id: "tppModal.ixcRequired" })}
                  label={intl.formatMessage({ id: "tppModal.ixcRequired" })}
                  handleInputChange={(value: boolean) =>
                    props.setServiceProvider({ ...props.serviceProvider, ixcRequired: value })
                  }
                  checked={props.serviceProvider.ixcRequired}
                  disabled={props.readonly}
                  className="font-weight-bold"
                />
              </div>
            </div>
          </div>}
        </div>
      </div>
    </>
  );
}
