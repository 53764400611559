import React from "react";
import { Container } from "reactstrap";
import { FormattedMessage } from "react-intl";

export default function Unauthorized() {
  return (
    <Container className="mb-5 mt-3" fluid>
      <div className="header pt-lg-9">
        <div className="text-center">
          <span>
            <i className="fad fa-ban fa-3x text-danger text-center"></i>
          </span>
        </div>
        <h1 className="flex-grow-1 text-danger text-center">
          <FormattedMessage id="unauthorized.title" />
        </h1>
        <h2 className="flex-grow-1 text-danger text-center">
          <FormattedMessage id="unauthorized.message" />
        </h2>
      </div>
    </Container>
  );
}
