export enum WebhookLogsEmailTableColumnIdentifiers {
    OrderId = "orderId",
    EmailAddress = "emailAddress",
    Message = "message",
    DeliveryStatus = "deliveryStatus",
    CreationDate = "creationDate",
    Actions = "actions",
    Recipient = "recipient",
}

export enum WebhookLogsURLTableColumnIdentifiers {
    Url = "url",
    Message = "message",
    Status = "status",
    CreationDate = "creationDate",
    Actions = "actions",
    OrderId = "orderId"
}