import React, { useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleFieldErrors } from "services/util/ApiUtil";
import { useIsMounted } from "services/customHooks/useIsMounted";
import ConfirmationModal from "components/framework/modals/ConfirmationModal";
import { WebhookApi } from "services/apis/WebhookApi";
import { NotificationContext } from "./NotificationList";

type Props = {
  id: string;
  notificationName: string;
  closeModal: () => void;
};

export default function NotificationDeleteConfirmationModal(props: Props) {
  const intl = useIntl();
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const notificationContext = useContext(NotificationContext);
  const isMounted = useIsMounted();

  const handleDelete = () => {
    if (isMounted.current) {
      setShowLoadingIndicator(true);
    }

    WebhookApi.delete(props.id)
      .then(() => {
        showInfoNotification(intl.formatMessage({ id: "notifications.delete.deleteNotification" }));
        if (isMounted.current) {
          notificationContext.notificationCallback();
          props.closeModal();
        }
      })
      .catch((error) => handleFieldErrors(error))
      .finally(() => {
        if (isMounted.current) {
          setShowLoadingIndicator(false);
        }
      });
  };

  return (
    <ConfirmationModal
      confirmCallback={handleDelete}
      closeModal={props.closeModal}
      title={intl.formatMessage({ id: "notifications.delete.confirmationModalTitle" })}
      confirmButtonText={intl.formatMessage({ id: "notifications.delete.submit" })}
      showLoadingIndicator={showLoadingIndicator}>
      <p>
        <FormattedMessage
          id="notifications.delete.deleteQuestion"
          values={{ notificationName: props.notificationName }}
        />
      </p>
    </ConfirmationModal>
  );
}
