import { ModifyCsrType } from "services/apis/types/order/ModifyCsrType";
import { Moment } from "moment";
import { CustomerDetailsDto } from "services/apis/types/port/CustomerDetailsDto";

export type ModifyCsrModel = {
  modifyType: ModifyCsrType;
  dueDate?: Moment;
  customerDetails: CustomerDetailsDto;
};

export const DefaultModifyCsrModel = {
  modifyType: ModifyCsrType.DueDate,
  dueDate: null,
  customerDetails: null
};
