import { Moment } from "moment";
import { DpcSsnInfo, DefaultDpcSsnInfo } from "services/apis/types/block/DpcSsnInfo";
import {
  OptionalDataInfo,
  DefaultOptionalDataInfo
} from "services/apis/types/number/OptionalDataInfo";
import { getUtcDate } from "services/util/DateUtil";

export type PortInModifyModel = {
  modifyType: string;
  portDirection: string;
  spId: string;
  oldSpId: string;
  dueDate?: Moment;
  lrn: string;
  billingId: string;
  userLocationValue: string;
  userLocationType: string;
  networkData: DpcSsnInfo;
  optionalData?: OptionalDataInfo;
};

export const DefaultPortInModifyModel: PortInModifyModel = {
  modifyType: "VSPE",
  portDirection: "IN",
  spId: "",
  oldSpId: "",
  dueDate: getUtcDate(),
  lrn: "",
  billingId: "",
  userLocationValue: "",
  userLocationType: "",
  networkData: DefaultDpcSsnInfo,
  optionalData: DefaultOptionalDataInfo
};
