import { HubConnection, HubConnectionBuilder, HttpTransportType, LogLevel } from "@microsoft/signalr";
import { AppContextAccessor } from "../appContext/AppContextAccessor";
import { IdentityService } from "../identity/IdentityService";
import { EnvironmentUtil } from "./EnvironmentUtil";

let hubConnection: HubConnection;
let isConnected = false;

export const SignalRClient = {
  invoke: async <T>(methodName: string, ...args: any[]): Promise<T> => {
    return initialise().then(() => {
      if (hubConnection) {
        return hubConnection.invoke<T>(methodName, ...args);
      }
      return new Promise<T>(() => []);
    });
  },

  on: async (methodName: string, newMethod: (...args: any[]) => void) => {
    return initialise().then(() => hubConnection.on(methodName, newMethod));
  }
};

const initialise = async () => {
  if (await IdentityService.checkAndRenewAccessToken()) {
    if (!hubConnection) {
      hubConnection = new HubConnectionBuilder()
        .withUrl(EnvironmentUtil.signalRUrl, {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
          accessTokenFactory: () => AppContextAccessor.getAppContext().localStorageInfo.authenticationInfo.access_token
        })
        .configureLogging(LogLevel.Error)
        .withAutomaticReconnect()
        .build();
    }

    if (!isConnected) {
      await hubConnection
        .start()
        .then(() => (isConnected = true))
        .catch((err: any) => console.log("Error while establishing connection.", err));
    }
  }
};
