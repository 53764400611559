import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmationModal from "components/framework/modals/ConfirmationModal";

type Props = {
  discardPreviousData: () => void;
  closeModal: () => void;
};

export default function BackToStepOneModal(props: Props) {
  const intl = useIntl();

  const handleReturnToStepOne = () => {
    props.discardPreviousData();
    props.closeModal();
  };

  return (
    <ConfirmationModal
      confirmCallback={handleReturnToStepOne}
      closeModal={props.closeModal}
      title={intl.formatMessage({ id: "orders.portIn.stepTwo.backToStepOneModal.title" })}
      confirmButtonText={intl.formatMessage({
        id: "orders.portIn.stepTwo.backToStepOneModal.submitDiscard"
      })}>
      <p>
        <FormattedMessage id="orders.portIn.stepTwo.backToStepOneModal.text" />
      </p>
    </ConfirmationModal>
  );
}
