import React from "react";
import Select2 from "react-select2-wrapper";
import $ from 'jquery';

export default function Select2Dropdown(props) {
  // NOTE : hack for setting focus : see issue https://github.com/select2/select2/issues/5993
  const setFocusHack= ()=>{
    $(document).on('select2:open', () => {
      document.querySelector('.select2-search__field').focus();
    });
  }
  return (
    <>
      <div id="react-select2-wrapper"></div>
      <Select2
        className="form-control"
        defaultValue="1"
        options={{
          placeholder: "Select",
          dropdownParent: "#react-select2-wrapper"
        }}
        onChange={props.handleInputChange}
        data={props.data}
        value={props.value}
        onOpen={props.autofocus && setFocusHack}
        disabled={props.onOrderDetailsPage ? true : false}
      />
    </>
  );
}
