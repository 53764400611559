export type OptionalDataInfo = {
  altSpId: string;
  lastAltSpId: string;
  altBillingId: string;
  altUserLocationType: string;
  altUserLocationValue: string;
  voiceUri: string;
  mmsUri: string;
  smsUri: string;
};

export const DefaultOptionalDataInfo: OptionalDataInfo = {
  altSpId: "",
  lastAltSpId: "",
  altBillingId: "",
  altUserLocationType: "",
  altUserLocationValue: "",
  voiceUri: "",
  mmsUri: "",
  smsUri: ""
};
