import React from "react";
import { getFormattedUtcDate, getUtcDate } from "services/util/DateUtil";

type Props = {
  utcDate: string | Date | undefined;
  format?: string;
};

export default function FormatDateTime(props: Props) {
  return (
    <>
      {props.format
        ? getUtcDate(props.utcDate).format(props.format)
        : getFormattedUtcDate(props.utcDate)}
    </>
  );
}
