import React, { useContext, useState } from "react";
import { Card, CardBody, Row, CardTitle, Col } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import FormatDateTime from "components/framework/date/FormatDateTime";
import { OrderMetadataDto } from "services/apis/types/order/OrderMetadata";
import InlineEdit from "components/framework/forms/InlineEdit";
import { OrderApi } from "services/apis/OrderApi";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleError } from "services/util/ApiUtil";
import { OrderType } from "services/apis/types/order/OrderType";
import { AppContext } from "services/appContext/AppContext";
import { PermissionType } from "services/authorization/PermissionType";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork;

type Props = {
  orderId: string;
  orderMetadata: OrderMetadataDto;
  orderType: string;
};


export default function OrderMetadataCard(props: Props) {
  const isMounted = useIsMounted();
  const intl = useIntl();
  const [projectId, setProjectId] = useState(props.orderMetadata.projectId);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const { appContext } = useContext(AppContext);
  const isUserAdmin = appContext.localStorageInfo.user?.permissions.find(value => value === PermissionType.Admin)
  const isUserCompanyAdmin = appContext.localStorageInfo.user?.permissions.find(value => value === PermissionType.CompanyAdmin)
  const patchOrderProjectId = async (newProjectId: string) => {
    let successful = true;
    if (newProjectId !== projectId) {
      setShowLoadingIndicator(true);

      await OrderApi.patch(props.orderId, newProjectId)
        .then(() => {
          setProjectId(newProjectId);
          showInfoNotification(
            intl.formatMessage({ id: "orders.orderDetails.metadata.projectIdSaved" })
          );
        })
        .catch((error) => {
          handleError(error);
          successful = false;
        })
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
          }
        });
    }

    return successful;
  };

  return (
    <Card className="bg-gradient-white flex-grow-1 ">
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle tag="h5" className="text-uppercase text-default mb-0">
              <FormattedMessage id="orders.orderDetails.metadata.cardTitle" />
            </CardTitle>
            <span className="h2 font-weight-bold text-default mb-0">
              <InlineEdit
                text={projectId}
                labelText="orders.orderDetails.metadata.projectId"
                onSetText={patchOrderProjectId}
              />
              {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            </span>
          </div>
          <Col className="col-auto">
            <div className="shortcut-media avatar rounded-circle pc-text-info">
              <i className="fad fa-info-circle fa-3x" />
            </div>
          </Col>
        </Row>
        <p className="mb-0 text-sm">
          {(appContext.localStorageInfo.user?.isAdminProjectIdAccessible && props.orderMetadata.adminProjectId) && (isUserAdmin || isUserCompanyAdmin) && (
            <span className="text-muted pc-display-block">
              <FormattedMessage
                id="orders.orderDetails.metadata.adminProjectID"
                values={{
                  adminProjectId: props.orderMetadata.adminProjectId,
                }}
              />
            </span>
          )}
          <span className="text-muted pc-display-block">
            <FormattedMessage
              id="orders.orderDetails.metadata.createdBy"
              values={{
                createdBy: props.orderMetadata.createdBy,
                createdTimestamp: <FormatDateTime utcDate={props.orderMetadata.createdTimestamp} />
              }}
            />
          </span>
          <span className="text-muted pc-display-block">
            <FormattedMessage
              id="orders.orderDetails.metadata.lastModifiedBy"
              values={{
                modifiedBy: props.orderMetadata.modifiedBy,
                modifiedTimestamp: (
                  <FormatDateTime utcDate={props.orderMetadata.modifiedTimestamp} />
                )
              }}
            />
          </span>
        </p>
        {((!isMixNetworkUrl && (props.orderType === OrderType.PortOut || props.orderType === OrderType.PortIn) && props.orderMetadata.correspondingOrderId) ||
          (isMixNetworkUrl && isUserAdmin && (props.orderType === OrderType.PortOut || props.orderType === OrderType.PortIn) && props.orderMetadata.correspondingOrderId)) && (
            <div className="d-flex">
                <div className="h2">
                    <FormattedMessage id="orders.orderDetails.metadata.carrierPon" />
                </div>
                <div>
                    <span className="h2 ml-1 text-gray font-weight-normal">{props.orderMetadata.correspondingOrderId}</span>
                </div>
            </div>
        )}
      </CardBody>
    </Card>
  );
}
