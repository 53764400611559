import { OrderCardNumberModel } from "components/orders/types/OrderCardNumberModel";
import { OrderHandlerType } from "services/apis/types/port/OrderHandlerType";
import { DocumentModel } from "components/orders/types/DocumentModel";
import { OrderCardStatus } from "./OrderCardStatus";

export type OrderCardModel = {
  orderId?: string;
  lrn: string;
  portToOrginal: boolean;
  csrRequested: boolean;
  loosingProviderName: string;
  loosingSpid: string;
  united: boolean;
  lata: string;
  status: OrderCardStatus;
  orderHandler: OrderHandlerType;
  numbers: OrderCardNumberModel[];
  documents: DocumentModel[];
  showNetworkDetails: boolean;
};

export const DefaultOrderCardModel: OrderCardModel = {
  portToOrginal: false,
  csrRequested: false,
  lrn: "",
  loosingProviderName: "",
  loosingSpid: "",
  united: false,
  lata: "",
  status: OrderCardStatus.NotSubmitted,
  orderHandler: OrderHandlerType.SelectOne,
  numbers: [],
  documents: [],
  showNetworkDetails: false
};
