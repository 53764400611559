import React, { useState, useEffect, useCallback, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { handleError } from "services/util/ApiUtil";
import { showErrorNotification, showInfoNotification } from "components/framework/notification/NotificationUtil";
import { getValueOrEmpty } from "services/util/StringUtil";
import ConfirmationModal from "components/framework/modals/ConfirmationModal";
import { OrderService } from "services/portControl/OrderService";
import { OrderNumberActionType } from "services/portControl/types/OrderNumberActionType";
import { OrderType } from "services/apis/types/order/OrderType";
import PortInModifyModal from "./PortInModifyModal";
import PortOutModifyModal from "./PortOutModifyModal";
import DisconnectModifyModal from "./DisconnectModifyModal";
import { OrderDto, PreviousOrderDto } from "services/apis/types/order/OrderDto";
import ExecuteSupplementAction from "./ExecuteSupplementAction";
import UpdateOrderStatusModal from "./UpdateOrderStatusModal";
import { SvRequestStatus } from "../types/SvRequestStatus";
import RejectCsrModal from "./RejectCsrModal";
import ModifyCsrModal from "./ModifyCsrModal";
import { useHistory } from "react-router-dom";
import { OrderRequestType } from "services/apis/types/port/OrderRequestType";
import AutoActivationModal from "../portCreate/portIn/AutoActivationModal";
import CancelAutoActivationModal from "../portCreate/portIn/CancelAutoActivation";
import { OrderAutoActivationDto } from "services/apis/types/order/OrderAutoActivationDto";
import { CancelAutoActivationDto } from "services/apis/types/order/CancelAutoActivationDto";
import moment from "moment";
import { AppContext } from "services/appContext/AppContext";
import RestoreToOriginalModal from "../portCreate/portIn/RestoreToOriginalModal";
import { OrderApi } from "services/apis/OrderApi";
import SinchUpdateOrderModal from "../portCreate/portIn/SinchUpdateOrderModal";
import { TnOrderRequestDto } from "services/apis/types/searchAndProvision/TnOrderRequestDto";

type Props = {
  order: OrderDto;
  action: OrderNumberActionType;
  confirmationMessageId?: string;

  skipConfirmation?: boolean;
  closeModal: () => void;
  onActionExecuted: () => void;
};

export default function ExecuteOrderAction(props: Props) {
  const intl = useIntl();
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const isMounted = useIsMounted();
  const isCsrOnlyOrder = props.order.orderType === OrderType.PortOut && props.order.metadata.orderType === OrderRequestType.CSROnly;
  
  const { closeModal, onActionExecuted } = { ...props };
  const { appContext } = useContext(AppContext);

  const onAfterActionExecution = useCallback(() => {
    showInfoNotification(
      intl.formatMessage({ id: "orders.orderDetails.actionExecutedSuccesfuly" })
    );
    onActionExecuted();
    closeModal();
  }, [closeModal, intl, onActionExecuted]);

  const onFinally = useCallback(() => {
    if (isMounted.current) {
      setShowLoadingIndicator(false);
    }
  }, [isMounted]);

  const autoActivationDate: any = (time, record: OrderDto) => {
    const formattedDate = moment.utc(record.desiredDueDate).format('YYYY-MM-DD');
    const formattedTime = moment(time, 'hh:mm A').format('HH:mm:ss');
    const combinedDateTime = `${formattedDate}T${formattedTime}`;
    return combinedDateTime;
  }

  const handleSubmit = (time, timeZone) => {
    const builtScheduleActivation: OrderAutoActivationDto[] = [{
      orderId: props.order.orderId,
      modifiedBy: appContext.localStorageInfo.user?.email,
      modifiedDate: `${moment().format('YYYY-MM-DDTHH:mm:ssZ')}`,
      autoActivationDate: autoActivationDate(time, props.order),
      tnCount: props.order.numbers? props.order.numbers.length : "",
      dueDate: props.order.desiredDueDate,
      cancelledReason: "",
      timeZone: timeZone,
      orderActivitiy: props.action
    }]
    OrderService.scheduleActivation(builtScheduleActivation).then(() => {
      onAfterActionExecution();
    })
    .catch((error) => {
      handleError(error);
    })
    .finally(() => onFinally());
  }

  const handleCancelActivation = (reason) => {
    const builtCancelActivation: CancelAutoActivationDto[] = [{
      orderId: props.order.orderId,
      modifiedBy: appContext.localStorageInfo.user?.email,
      modifiedDate: `${moment().format('YYYY-MM-DDTHH:mm:ssZ')}`,
      autoActivationDate: `${moment().format('YYYY-MM-DDTHH:mm:ssZ')}`,
      tnCount: props.order.numbers? props.order.numbers.length : "",
      dueDate: props.order.desiredDueDate,
      cancelledReason: reason,
      timeZone: props.order.activationTimeZone || undefined,
      orderActivitiy: props.action
    }]
    OrderService.cancelActivation(builtCancelActivation).then(() => {
      onAfterActionExecution();
    })
    .catch((error) => {
      handleError(error);
    })
    .finally(() => onFinally());
  }

  const handleSinchUpdateOrder = () => {
    setShowLoadingIndicator(true);
    const tnOrderRequest : TnOrderRequestDto = {
      requestId: props.order.requestId,
      tnOrder: {
        tnList: {
          tnItem: props.order?.numbers.map(t => ({
            tn: t["phoneNumber"],
            trunkGroup: "",
            routingOption: ""
          }))
        }
      }
    };
    OrderService.updateSinch(props.order.orderId, tnOrderRequest).then((result) => {
      if (result.statusCode === "432") {
        showErrorNotification(
          intl.formatMessage({ id: result.result.toString() })
        );
        onActionExecuted();
        closeModal();
      } else {
        onAfterActionExecution();
      }
    })
    .catch((error) => {
      handleError(error);
    })
    .finally(() => onFinally());
  }

  const restoreDr = () => {
    setShowLoadingIndicator(true);
    OrderApi.drRestore(props.order.orderId, props.order.tenant).then(() => {
      onAfterActionExecution();
    })
    .catch((error) => {
      handleError(error);
    })
    .finally(() => onFinally());
  }

  const handlePortingNumberAction = useCallback(() => {
    setShowLoadingIndicator(true);

    OrderService.executeAction(props.order.orderId, props.order.tenant, props.action)
      .then(() => {
        onAfterActionExecution();
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => onFinally());
  }, [onAfterActionExecution, onFinally, props.action, props.order.orderId, props.order.tenant]);

  const [isCsrToLsrRequest] = useState(()=> props.action === OrderNumberActionType.CsrToLsr && props.order.orderType === OrderType.CsrOnly);
  const [isLsrSubmit] = useState(()=> props.action === OrderNumberActionType.LsrSubmit && props.order.orderType === OrderType.CsrOnly);

  const history = useHistory();
  useEffect(()=>{

    if (isCsrToLsrRequest || isLsrSubmit) {
      history.push({
        pathname: "/Orders/PortIn", 
        state: {
          previousOrder: {
            ...JSON.parse(JSON.stringify(props.order)),
            orderRequestType: OrderRequestType.PreOrderAndOrder
          } as PreviousOrderDto
        }
      });
    }
    
  },[history, props.order, isCsrToLsrRequest])

  useEffect(() => {
    if (props.skipConfirmation) {
      handlePortingNumberAction();
      closeModal();
    }
  }, [closeModal, handlePortingNumberAction, props.skipConfirmation]);

  const confirmationMessageId = getValueOrEmpty(props.confirmationMessageId);
  const isSupplementAction =
    props.action === OrderNumberActionType.PreOrderSupplement ||
    props.action === OrderNumberActionType.PreOrderApprove ||
    props.action === OrderNumberActionType.PreOrderReject;

  const calcLessThan24Hours = () => {
    if(props.action === OrderNumberActionType.PreOrderSupplement){
      const currentMillisUTC = Date.now();
      const autoActivationDate = props.order.autoActivation;
      const desiredDueDate = props.order.desiredDueDate;

      const formatDate = (dateStr) => {
        if (!dateStr) return null;
        let date;
        if (dateStr.toString().includes('T')) {
          date = new Date(dateStr);
        }
        else if (dateStr.includes('/')) {
          const [datePart, timePart, meridian] = dateStr.split(' ');
          const [month, day, year] = datePart.split('/').map(Number);
          let [hours, minutes, seconds] = timePart.split(':').map(Number);
          if (meridian === 'PM' && hours < 12) hours += 12;
          if (meridian === 'AM' && hours === 12) hours = 0;
          date = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
        }
        else if (dateStr.includes('-'))  {
          const [day, month, year, hours, minutes, seconds] = dateStr.split(/[- :]/);
          date = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
        }
        return date.getTime();
      };

      let dateInMillis;

      if (autoActivationDate) {
        dateInMillis = formatDate(autoActivationDate);
      }
      else {
        dateInMillis = formatDate(desiredDueDate);
      }

      if (dateInMillis !== null) {
        const diffInHrs = (dateInMillis - currentMillisUTC) / (1000 * 60 * 60);
        return diffInHrs > 0 && diffInHrs < 24;
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const isLessThan24Hours = calcLessThan24Hours()

  return (
    <>
      {props.action === OrderNumberActionType.Modify ? (
        props.order.orderType === OrderType.PortIn ? (
          <PortInModifyModal order={props.order} closeModal={props.closeModal} onActionExecuted={props.onActionExecuted}/>
        ) : props.order.orderType === OrderType.PortOut ? (
          <PortOutModifyModal order={props.order} closeModal={props.closeModal} />
        ) : (
          <DisconnectModifyModal
            orderId={props.order.orderId}
            tenant={props.order.tenant}
            closeModal={props.closeModal}
          />
        )
      ) : isSupplementAction ? (
        <ExecuteSupplementAction
          isWithIn24Hrs={isLessThan24Hours}
          order={props.order}
          action={props.action}
          confirmationMessageId={props.confirmationMessageId}
          closeModal={props.closeModal}
          onActionExecuted={props.onActionExecuted}
        />
      ) : props.action === OrderNumberActionType.PreOrderSubmit ? (
        <UpdateOrderStatusModal
          order={props.order}
          status={SvRequestStatus.Submitted}
          closeModal={props.closeModal}
          onActionExecuted={props.onActionExecuted} />
      ) : props.action === OrderNumberActionType.CsrRequested ? (
        <UpdateOrderStatusModal
          order={props.order}
          status={SvRequestStatus.CSRRequested}
          confirmationMessageId={props.confirmationMessageId}
          closeModal={props.closeModal}
          onActionExecuted={props.onActionExecuted} />
      ) : props.action === OrderNumberActionType.CsrValid ? (
        <UpdateOrderStatusModal
          order={props.order}
          status={SvRequestStatus.PreOrderPending}
          confirmationMessageId={isCsrOnlyOrder ? "orders.order.actions.csrOnlyValidQuestion" : props.confirmationMessageId}
          closeModal={props.closeModal}
          onActionExecuted={props.onActionExecuted} />
      ) : props.action === OrderNumberActionType.CsrInvalid ? (
        <RejectCsrModal
          order={props.order}
          closeModal={props.closeModal}
          confirmationMessageId={props.confirmationMessageId}
          onActionExecuted={props.onActionExecuted} />
      ) : props.action === OrderNumberActionType.CsrCancel ? (
        <UpdateOrderStatusModal
          order={props.order}
          status={SvRequestStatus.Canceled}
          confirmationMessageId={props.confirmationMessageId}
          closeModal={props.closeModal}
          onActionExecuted={props.onActionExecuted} />
      ) : props.action === OrderNumberActionType.CsrEscalate ? (
        <UpdateOrderStatusModal
          order={props.order}
          status={SvRequestStatus.CSREscalated}
          confirmationMessageId={props.confirmationMessageId}
          closeModal={props.closeModal}
          onActionExecuted={props.onActionExecuted} />
      ) : props.action === OrderNumberActionType.CsrModify ? (
        <ModifyCsrModal
          order={props.order}
          closeModal={props.closeModal}
          onActionExecuted={props.onActionExecuted}
        />
      ) : props.action === OrderNumberActionType.CsrReceived ? (
        <UpdateOrderStatusModal
          order={props.order}
          status={SvRequestStatus.CSRReceived}
          confirmationMessageId={props.confirmationMessageId}
          closeModal={props.closeModal}
          onActionExecuted={props.onActionExecuted} />
      ) : props.action === OrderNumberActionType.LsrSubmit && props.order.orderType != OrderType.CsrOnly ? (
        <UpdateOrderStatusModal
          order={props.order}
          status={SvRequestStatus.PreOrderPending}
          confirmationMessageId={props.confirmationMessageId}
          closeModal={props.closeModal}
          onActionExecuted={props.onActionExecuted} />
      ) : props.action === OrderNumberActionType.ScheduleActivation || props.action === OrderNumberActionType.ModifyActivation ? (
        <AutoActivationModal
          order={props.order}
          closeModal={props.closeModal}
          onSubmit={handleSubmit}
          isOrderDashboard={true}
        />
      ) : props.action === OrderNumberActionType.CancelActivation ? (
        <CancelAutoActivationModal
          closeModal={props.closeModal}
          onSubmit={handleCancelActivation}
        />
      ) : props.action === OrderNumberActionType.Restore ? (
        <RestoreToOriginalModal
          showLoadingIndicator={showLoadingIndicator}
          closeModal={props.closeModal}
          onSubmit={restoreDr}
        />
      ) : props.action === OrderNumberActionType.Submit ? (
        <SinchUpdateOrderModal
          showLoadingIndicator={showLoadingIndicator}
          closeModal={props.closeModal}
          onSubmit={handleSinchUpdateOrder}
        />
      ) : isCsrToLsrRequest || isLsrSubmit ? (
        <>
          {/* will be redirected to "/Orders/PortIn" */}
        </>
      ) : (!props.skipConfirmation && (
          <ConfirmationModal
            confirmCallback={() => handlePortingNumberAction()}
            closeModal={props.closeModal}
            title={intl.formatMessage({
              id: "orders.orderDetails.portingNumber.table.confirmActionModalTitle"
            })}
            confirmButtonText={intl.formatMessage({
              id: "orders.orderDetails.portingNumber.table.confirmAction"
            })}
            showLoadingIndicator={showLoadingIndicator}>
            {confirmationMessageId && (
              <p>
                <FormattedMessage
                  id={confirmationMessageId}
                  values={{ number: props.order.orderId }}
                />
              </p>
            )}
          </ConfirmationModal>
        )
        )}
    </>
  );
}
