import ConfirmationModal from "components/framework/modals/ConfirmationModal";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

type Props = {
  documentName: string;
  showLoadingIndicator: boolean;
  confirmCallback: () => void;
  closeModal: () => void;
};

export default function DeleteDocumentConfirmationModal(props: Props) {
  const intl = useIntl();

  return (
    <ConfirmationModal
      confirmCallback={props.confirmCallback}
      closeModal={props.closeModal}
      title={intl.formatMessage({
        id: "orders.documents.deleteConfirmActionModal.title"
      })}
      confirmButtonText={intl.formatMessage({
        id: "orders.documents.deleteConfirmActionModal.confirmButton"
      })}
      showLoadingIndicator={props.showLoadingIndicator}>
      <p>
        <FormattedMessage
          id="orders.documents.deleteConfirmActionModal.description"
          values={{ item: props.documentName }}
        />
      </p>
    </ConfirmationModal>
  );
}
