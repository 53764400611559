import IconWithTooltip from "components/common/IconWithTooltip";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { getTranslation } from "translations/TranslationService";
import CustomerInventoryEditModal from "./customerInventoryEditModal";

type Props = {
  internalId: string;
  customerId: string;
  onUpdateSuccess: (boolean) => void;
};

export default function CustomerInventoryActionCell(props: Props) {
  const [showModal, setShowModal] = useState(false);
  const intl = useIntl();

  return (
    <>
      <IconWithTooltip
        className="fa cursor-pointer fa-edit"
        tooltip={getTranslation(intl, "customerInventory.Action.Edit")}
        onClick={() => setShowModal(true)}
      />
      {showModal && (
        <CustomerInventoryEditModal closeModal={() => setShowModal(false)} internalId={props.internalId} customerId={props.customerId} onUpdateSuccess={props.onUpdateSuccess}/>
      )}
    </>
  );
}
