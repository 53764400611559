import React, { useMemo } from "react";
import CardSectionHeader from "components/framework/cards/CardSectionHeader";
import { AccountType, AccountTypes } from "services/apis/types/port/AccountType";
import TextFormInput from "components/framework/forms/TextFormInput";
import { ErrorHandler } from "services/customHooks/useErrors";
import { nameOf } from "services/util/ObjectUtil";
import { PortInAccountModel } from "./types/PortInAccountModel";
import { CustomerDetailsDto } from "services/apis/types/port/CustomerDetailsDto";
import DropdownFormInput from "components/framework/forms/DropdownFormInput";
import { Row, Col } from "reactstrap";
import { OrderType } from "services/apis/types/order/OrderType";

type Props = {
  customerDetails: CustomerDetailsDto;
  setCustomerDetails?: (customerDetails: CustomerDetailsDto) => void;
  getErrorHandler?: (key: string) => ErrorHandler;
  overrideRequiredFields?: string[] 
  disabled?: boolean;
  orderType?: OrderType;
};

export default function AccountDetails(props: Props) {
  const accountType = useMemo(() => {
    if (isNaN(Number(props.customerDetails.accountType))) {
      return props.customerDetails.accountType;
    }
    return AccountType[props.customerDetails.accountType];
  }, [props.customerDetails.accountType]);

  const defaultRequiredFields = useMemo(()=> props.orderType === OrderType.CsrOnly ? ["authorizedName","billingPhoneNumber"]:["authorizedName", "accountName", "billingPhoneNumber"],[props.orderType]);
  const isRequired = (fieldName: string) => {
    const requiredFieldsHasOverriden = props.overrideRequiredFields && props.overrideRequiredFields.length > 0;
    const found = (collection, value)=> collection.some(v=> v && v.toLocaleLowerCase() === value?.toLocaleLowerCase());
    
    if (requiredFieldsHasOverriden)
      return props.overrideRequiredFields && found(props.overrideRequiredFields, fieldName);
    
    return found(defaultRequiredFields, fieldName);
  };

  return (
    <div className="my-3">
      <CardSectionHeader labelTranslationId="orders.accountDetails" />
      <Row>
        <Col md={4} className="pc-account-details-input">
          <TextFormInput
            required={isRequired("accountName")}
            labelTranslationId="orders.accountDetails.accountName"
            value={props.customerDetails.accountName !== "N/A" ? props.customerDetails.accountName : ""}
            handleInputChange={(value: string) =>
              props.setCustomerDetails &&
              props.setCustomerDetails({
                ...props.customerDetails,
                accountName: value
              })
            }
            errorHandler={
              props.getErrorHandler &&
              props.getErrorHandler(
                nameOf<PortInAccountModel, CustomerDetailsDto>("customerDetails", "accountName")
              )
            }
            readonly={props.disabled}
          />
        </Col>
        <Col md={4} className="pc-account-details-input">
          <TextFormInput
            required={isRequired("accountNumber")}
            labelTranslationId="orders.accountDetails.accountNumber"
            value={props.customerDetails.accountNumber}
            handleInputChange={(value: string) =>
              props.setCustomerDetails &&
              props.setCustomerDetails({
                ...props.customerDetails,
                accountNumber: value
              })
            }
            errorHandler={
              props.getErrorHandler &&
              props.getErrorHandler(
                nameOf<PortInAccountModel, CustomerDetailsDto>("customerDetails", "accountNumber")
              )
            }
            readonly={props.disabled}
          />
        </Col>
        <Col md={4} className="pc-account-details-input">
          <DropdownFormInput
            labelTranslationId="orders.accountDetails.accountType"
            value={(accountType as unknown) as string}
            handleInputChange={(value: string) =>
              props.setCustomerDetails &&
              props.setCustomerDetails({
                ...props.customerDetails,
                accountType: (value as unknown) as AccountType
              })
            }
            options={Object.keys(AccountTypes).map((x) => {
              return { key: AccountTypes[x as keyof typeof AccountType], value: x };
            })}
            errorHandler={
              props.getErrorHandler &&
              props.getErrorHandler(
                nameOf<PortInAccountModel, CustomerDetailsDto>("customerDetails", "accountType")
              )
            }
            disabled={props.disabled}
          />
        </Col>
        <Col md={4} className="pc-account-details-input">
          <TextFormInput
            required={isRequired("accountPin")}
            labelTranslationId="orders.accountDetails.accountPin"
            value={props.customerDetails.accountPin}
            handleInputChange={(value: string) =>
              props.setCustomerDetails &&
              props.setCustomerDetails({
                ...props.customerDetails,
                accountPin: value
              })
            }
            errorHandler={
              props.getErrorHandler &&
              props.getErrorHandler(
                nameOf<PortInAccountModel, CustomerDetailsDto>("customerDetails", "accountPin")
              )
            }
            readonly={props.disabled}
          />
        </Col>
        <Col md={4} className="pc-account-details-input">
          <TextFormInput
            required={isRequired("authorizedName")}
            labelTranslationId="orders.accountDetails.authorizedName"
            value={props.customerDetails.authorizedName}
            handleInputChange={(value: string) =>
              props.setCustomerDetails &&
              props.setCustomerDetails({
                ...props.customerDetails,
                authorizedName: value
              })
            }
            errorHandler={
              props.getErrorHandler &&
              props.getErrorHandler(
                nameOf<PortInAccountModel, CustomerDetailsDto>("customerDetails", "authorizedName")
              )
            }
            readonly={props.disabled}
          />
        </Col>
        <Col md={4} className="pc-account-details-input">
          <TextFormInput
            required={isRequired("billingPhoneNumber")}
            labelTranslationId="orders.accountDetails.billingPhoneNumber"
            value={props.customerDetails.billingPhoneNumber}
            handleInputChange={(value: string) =>
              props.setCustomerDetails &&
              props.setCustomerDetails({
                ...props.customerDetails,
                billingPhoneNumber: value
              })
            }
            errorHandler={
              props.getErrorHandler &&
              props.getErrorHandler(
                nameOf<PortInAccountModel, CustomerDetailsDto>(
                  "customerDetails",
                  "billingPhoneNumber"
                )
              )
            }
            readonly={props.disabled}
          />
        </Col>
      </Row>
    </div>
  );
}
