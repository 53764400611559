import { HttpClient } from "services/httpClient/HttpClient";
import { ReportDto } from "./types/reports/ReportDto";
import { ExportDto } from "./types/reports/ExportDto";
import { BillingTransactionsDto } from "./types/reports/BillingTransactionsDto";
import queryString from "query-string";
import { PendingReportsDto } from "./types/reports/PendingReportsDto";

export const ReportsApi = {
  overview: (): Promise<ReportDto> => {
    return HttpClient.get<ReportDto>(`report`, true, true);
  },

  export: (from: Date, to: Date): Promise<ExportDto[]> => {
    let url = queryString.stringifyUrl({
      url: "report/transactions",
      query: {
        from: from.toISOString(),
        to: to.toISOString()
      }
    });

    return HttpClient.get<ExportDto[]>(url, true, true);
  },

  getInvoice: (from: Date, to: Date): Promise<BillingTransactionsDto[]> => {

    let url = queryString.stringifyUrl({
      url: "invoice",
      query: {
        from: from.toISOString(),
        to: to.toISOString()
      }
    });

    return HttpClient.get<BillingTransactionsDto[]>(url, true, true);
  },

  getPendingReports: (): Promise<PendingReportsDto[]> => {
    return HttpClient.get<PendingReportsDto[]>('report/pending-orders', true, true);
  }
};
