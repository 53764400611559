import { KeyValuePair } from "services/types/KeyValuePair";

export enum OrderHandlerType {
    SelectOne = "",
    ServiceProvider = "ServiceProvider",
    PortingDotCom = "PortingDotCom",
}

// use array to preserve order in dropdown list
export const OrderHandlerTypesList: Array<KeyValuePair<string, string>> = [
    {
        key: "Select one",
        value: OrderHandlerType.SelectOne
    },
    {
        key: "Service provider",
        value: OrderHandlerType.ServiceProvider
    },
    {
        key: "ATL Communications",
        value: OrderHandlerType.PortingDotCom
    }
];