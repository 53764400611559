import React, { useState } from "react";
import NotificationModal from "./NotificationModal";
import { ManageAction } from "../types/ManageAction";
import { NotificationListItemDto } from "services/apis/types/webhooks/NotificationListItemDto";
import { NotificationType } from "../types/NotificationType";
import { isEmail } from "services/util/StringUtil";

type Props = {
  notification: NotificationListItemDto;
};

export default function NotificationNameCell(props: Props) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <span
        className="cursor-pointer text-primary text-underline"
        onClick={(e) => {
          e.preventDefault();
          setShowModal(true);
        }}>
        {props.notification.name}
      </span>
      {showModal && (
        <NotificationModal
          closeModal={() => setShowModal(false)}
          notificationId={props.notification.connectionId}
          notificationType={
            isEmail(props.notification.uri)
              ? NotificationType.EmailSubscription
              : NotificationType.Webhook
          }
          manageAction={ManageAction.Modify}
        />
      )}
    </>
  );
}
