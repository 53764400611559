import React from "react";
import TextFormInput from "components/framework/forms/TextFormInput";
import { nameOf } from "services/util/ObjectUtil";
import CardSectionHeader from "components/framework/cards/CardSectionHeader";
import classnames from "classnames";
import { isEmail, isEmailOrUrl } from "services/util/StringUtil";
import { ServiceProviderProfile } from "services/apis/types/serviceProvider/ServiceProviderProfile";
import { ErrorHandler } from "services/customHooks/useErrors";

type Props = {
  readonly: boolean;
  serviceProvider: ServiceProviderProfile;
  setServiceProvider: (serviceProvider: ServiceProviderProfile) => void;
  getErrorHandler: (key: string) => ErrorHandler;
};

export default function ContactInformation(props: Props) {
  const formatUrl = (link: string) => {
    const url = isEmail(link) ? `mailto:${link}` : link;

    return url;
  };

  const handleInputClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    if (props.readonly && isEmailOrUrl(e.currentTarget.value)) {
      window.open(formatUrl(e.currentTarget.value), "_blank");
    }
  };

  return (
    <>
      <CardSectionHeader labelTranslationId="tppModal.contactInformation" />

      <div className="mb-3 d-flex">
        <div className="flex-grow-1">
          <div className="d-flex flex-direction-column">
            <TextFormInput
              readonly={props.readonly}
              labelTranslationId="tppModal.firstLevelSupport"
              placeholderTranslationId="tppModal.firstLevelSupport.name.placeholder"
              value={props.serviceProvider.firstLevelSupport}
              handleInputChange={(value: string) =>
                props.setServiceProvider({ ...props.serviceProvider, firstLevelSupport: value })
              }
              errorHandler={props.getErrorHandler(
                nameOf<ServiceProviderProfile>("firstLevelSupport")
              )}
            />
            <TextFormInput
              inputClassName={classnames({
                "cursor-pointer":
                  props.readonly && isEmailOrUrl(props.serviceProvider.firstLevelSupportEmail)
              })}
              readonly={props.readonly}
              placeholderTranslationId="tppModal.firstLevelSupport.email.placeholder"
              value={props.serviceProvider.firstLevelSupportEmail}
              handleInputChange={(value: string) =>
                props.setServiceProvider({
                  ...props.serviceProvider,
                  firstLevelSupportEmail: value
                })
              }
              onClick={handleInputClick}
              errorHandler={props.getErrorHandler(
                nameOf<ServiceProviderProfile>("firstLevelSupportEmail")
              )}
            />
            <TextFormInput
              readonly={props.readonly}
              placeholderTranslationId="tppModal.firstLevelSupport.phoneNumber.placeholder"
              value={props.serviceProvider.firstLevelSupportPhoneNumber}
              handleInputChange={(value: string) =>
                props.setServiceProvider({
                  ...props.serviceProvider,
                  firstLevelSupportPhoneNumber: value
                })
              }
              onClick={handleInputClick}
              errorHandler={props.getErrorHandler(
                nameOf<ServiceProviderProfile>("firstLevelSupportPhoneNumber")
              )}
            />
          </div>
          <div className="d-flex flex-direction-column">
            <TextFormInput
              readonly={props.readonly}
              labelTranslationId="tppModal.secondLevelSupport"
              placeholderTranslationId="tppModal.secondLevelSupport.name.placeholder"
              value={props.serviceProvider.secondLevelSupport}
              handleInputChange={(value: string) =>
                props.setServiceProvider({ ...props.serviceProvider, secondLevelSupport: value })
              }
              errorHandler={props.getErrorHandler(
                nameOf<ServiceProviderProfile>("secondLevelSupport")
              )}
            />
            <TextFormInput
              inputClassName={classnames({
                "cursor-pointer":
                  props.readonly && isEmailOrUrl(props.serviceProvider.secondLevelSupportEmail)
              })}
              readonly={props.readonly}
              placeholderTranslationId="tppModal.secondLevelSupport.email.placeholder"
              value={props.serviceProvider.secondLevelSupportEmail}
              handleInputChange={(value: string) =>
                props.setServiceProvider({
                  ...props.serviceProvider,
                  secondLevelSupportEmail: value
                })
              }
              onClick={handleInputClick}
              errorHandler={props.getErrorHandler(
                nameOf<ServiceProviderProfile>("secondLevelSupportEmail")
              )}
            />
            <TextFormInput
              readonly={props.readonly}
              placeholderTranslationId="tppModal.secondLevelSupport.phoneNumber.placeholder"
              value={props.serviceProvider.secondLevelSupportPhoneNumber}
              handleInputChange={(value: string) =>
                props.setServiceProvider({
                  ...props.serviceProvider,
                  secondLevelSupportPhoneNumber: value
                })
              }
              onClick={handleInputClick}
              errorHandler={props.getErrorHandler(
                nameOf<ServiceProviderProfile>("secondLevelSupportPhoneNumber")
              )}
            />
          </div>
          <div className="d-flex flex-direction-column">
            <TextFormInput
              readonly={props.readonly}
              labelTranslationId="tppModal.repairCenter"
              placeholderTranslationId="tppModal.repairCenter.name.placeholder"
              value={props.serviceProvider.repairCenter}
              handleInputChange={(value: string) =>
                props.setServiceProvider({ ...props.serviceProvider, repairCenter: value })
              }
              errorHandler={props.getErrorHandler(nameOf<ServiceProviderProfile>("repairCenter"))}
            />
            <TextFormInput
              inputClassName={classnames({
                "cursor-pointer":
                  props.readonly && isEmailOrUrl(props.serviceProvider.repairCenterEmail)
              })}
              readonly={props.readonly}
              placeholderTranslationId="tppModal.repairCenter.email.placeholder"
              value={props.serviceProvider.repairCenterEmail}
              handleInputChange={(value: string) =>
                props.setServiceProvider({
                  ...props.serviceProvider,
                  repairCenterEmail: value
                })
              }
              onClick={handleInputClick}
              errorHandler={props.getErrorHandler(
                nameOf<ServiceProviderProfile>("repairCenterEmail")
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
}
