export type TppListFilter = {
    tenant: string;
    tradingSpid: string;
    isVerified: string;
    isTouchlessPreorder: string;
    vpopCustomer: string;
    ixcRequired: string;
    status: string;
}

export const DefaultTppListFilter: TppListFilter = {
    tenant: "",
    tradingSpid: "",
    isVerified: "",
    isTouchlessPreorder: "",
    vpopCustomer: "",
    ixcRequired: "",
    status: "",
}