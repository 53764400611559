import { useCallback } from "react";
import { useHistory, useLocation } from "react-router";

export const useRefresh = () => {
    
    const history = useHistory();
    const location = useLocation();
    
    const navigateOutAndWait = useCallback(() => {
        history.push("/");
        var tid = setTimeout(() => {}, 10);
     return tid;
    },[history]);
    
    const reload = useCallback((routePath?: string) => {
        
        clearTimeout(navigateOutAndWait());
        
        // navigate in
        history.push(routePath ?? location.pathname);

    },[history, location, navigateOutAndWait]);

    return reload;
}