import { SpIdProfileDto } from "../apis/types/account/SpIdProfileDto";
import { UserSettings } from "../uiSettings/UserSettings";
import { DefaultUpdateAccountDto } from "services/apis/types/account/UpdateAccountDto";

export type LocalStorageUser = {
  userId: string;
  email: string;
  companyId: string;
  firstName: string;
  lastName: string;
  locale: string;
  zoneInfo: string;
  permissions: string[];
  spIdProfiles: SpIdProfileDto[];
  supportCode: string;
  settings: UserSettings;
  isAdminProjectIdAccessible: boolean;
};

export const DefaultLocalStorageUser : LocalStorageUser = {
  email: DefaultUpdateAccountDto.email, 
  firstName: DefaultUpdateAccountDto.firstName, 
  lastName: DefaultUpdateAccountDto.lastName, 
  locale: DefaultUpdateAccountDto.locale,
  userId: "",
  companyId: "",
  zoneInfo:"",
  spIdProfiles:[],
  permissions:[],
  supportCode:"",
  settings:{},
  isAdminProjectIdAccessible: true,
};

