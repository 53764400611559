import moment, { Moment } from "moment";
import { AppConfiguration } from "AppConfiguration";

export const getUtcDate = (date?: Date | string): Moment => {
  return date ? moment.utc(date) : moment.utc().milliseconds(0).seconds(0).minutes(0).hours(0);
};

export const getFormattedUtcDate = (date?: Date | string): string => {
  return (date && getUtcDate(date).format(AppConfiguration.fullDateFormat)) || "";
};

export const getUtcDateTomorrow = (date?: Date): Moment => {
  return getUtcDate(date).add(1, "days");
};

export const getUtcDateFirstDayOfMonth = (date?: Date): Moment => {
  return getUtcDate(date).startOf("month");
};

export const isAfterNextYearToday = (date: Moment): boolean => {
  return date.isAfter(getUtcDateNextYearToday());
};

export const isSunday = (date?: Moment | Date | null): boolean => {
    var day = moment.isDate(date) ? moment.utc(date).day() : date?.day()
  return day === 0;
};

export const isBetween = (
  value: Moment,
  min: Moment | string | Date,
  max: Moment | string | Date
) => value.isSameOrAfter(min) && value.isSameOrBefore(max);

export const isBefore = (
  value: Moment,
  comparer: Moment | string | Date
) => value.isSameOrBefore(comparer);

export const isAfter = (
  value: Moment,
  comparer: Moment | string | Date
) => value.isSameOrAfter(comparer);

export const sort = (dates: Array<Date | string>, desc?: boolean) => {
  return dates.sort((a, b) => {
    const aValue = getUtcDate(a).toDate().getTime();
    const bValue = getUtcDate(b).toDate().getTime();

    return desc ? bValue - aValue : aValue - bValue;
  });
};

export const isOddDayToday = () => new Date().getDate() % 2 === 1;

const getUtcDateNextYearToday = (date?: Date): Moment => {
  return getUtcDate(date).add(2, "years");
};
