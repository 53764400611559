import { AppContextAccessor } from "../appContext/AppContextAccessor";
import { NotificationApi } from "../apis/NotificationApi";
import { NotificationDto } from "../apis/types/notification/NotificationDto";

export const NotificationService = {
  initalise: async () => {
    await NotificationService.updateNotifications();

    NotificationApi.onNewNotification(NotificationService.newNotification);
  },

  markAsRead: async (notification: NotificationDto) => {
    if (!notification.isRead) {
      notification.isRead = true;
      AppContextAccessor.setAppContext(appContext => {
        return {
          ...appContext,
          notifications: appContext.notifications ? [...appContext.notifications] : []
        };
      });

      await NotificationApi.markAsRead(notification.notificationId);
    }
  },

  newNotification: (notificiation: NotificationDto) => {
    AppContextAccessor.setAppContext(appContext => {
      return {
        ...appContext,
        notifications: appContext.notifications ? [notificiation, ...appContext.notifications] : [notificiation]
      };
    });
  },

  updateNotifications: async () => {
    if (!AppContextAccessor.getAppContext().notifications) {
      await NotificationApi.getLatestNotifications(10).then(x => {
        AppContextAccessor.setAppContext(appContext => {
          return { ...appContext, notifications: x };
        });
      });
    }
  }
};
