import { isNumber } from "services/util/StringUtil";
import { FreeEmaildDomains } from "./FreeEmailDomains";

export const isValidLrn = (lrn: string) => lrn && lrn.length === 10 && isNumber(lrn);

export function isEmailFromFreeDomain(email: string) {
  //get domain part
  var domainPart = email.split('@');

  if (domainPart.length === 2) {
    return FreeEmaildDomains.domains.find(e => e === domainPart[1]);
  }

  return false;
}