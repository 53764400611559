import React from "react";

type Props = {
    value: boolean;
};

export default function ValidateCell(props: Props) {

    return (
        <>
            <div className="d-flex justify-content-center">
                {props.value && (
                    <i className="fa fa-check text-success fa-lg" />
                )}
            </div>
        </>
    );
}
