import React from "react";
import { PortInAccountModel } from "./types/PortInAccountModel";
import TextFormInput from "components/framework/forms/TextFormInput";
import { ErrorHandler } from "services/customHooks/useErrors";
import { nameOf } from "services/util/ObjectUtil";
import { CustomerDetailsDto } from "services/apis/types/port/CustomerDetailsDto";
import { ServiceAddressDto } from "services/apis/types/port/ServiceAddressDto";
import CardSectionHeader from "components/framework/cards/CardSectionHeader";
import { Row, Col } from "reactstrap";
import { useIntl } from "react-intl";
import { getTranslation } from "translations/TranslationService";

type Props = {
  serviceAddress: ServiceAddressDto;
  setServiceAddress?: (serviceAddress: ServiceAddressDto) => void;
  getErrorHandler?: (key: string) => ErrorHandler;
  disabled?: boolean;
};

export default function ServiceAddress(props: Props) {
  const intl = useIntl();

  return (
    <div className="my-3">
      <CardSectionHeader labelTranslationId="orders.accountDetails.serviceAddress" />
      <Row>
        <Col md={3} className="pc-account-details-input">
            <TextFormInput
              labelTranslationId="orders.accountDetails.serviceAddress.number"
              value={props.serviceAddress?.number}
              handleInputChange={(value: string) =>
                props.setServiceAddress &&
                props.setServiceAddress({
                  ...props.serviceAddress,

                  number: value
                })
              }
              errorHandler={
                props.getErrorHandler &&
                props.getErrorHandler(
                  nameOf<PortInAccountModel, CustomerDetailsDto, ServiceAddressDto>(
                    "customerDetails",
                    "serviceAddress",
                    "number"
                  )
                )
              }
              readonly={props.disabled}
            />
        </Col>
        <Col md={3} className="pc-account-details-input">
          <TextFormInput
            labelTranslationId="orders.accountDetails.serviceAddress.directionPrefix"
            value={props.serviceAddress?.directionPrefix}
            handleInputChange={(value: string) =>
              props.setServiceAddress &&
              props.setServiceAddress({
                ...props.serviceAddress,

                directionPrefix: value
              })
            }
            errorHandler={
              props.getErrorHandler &&
              props.getErrorHandler(
                nameOf<PortInAccountModel, CustomerDetailsDto, ServiceAddressDto>(
                  "customerDetails",
                  "serviceAddress",
                  "directionPrefix"
                )
              )
            }
            tooltip={
              <>
                <div>
                  {getTranslation(
                    intl,
                    "orders.accountDetails.serviceAddress.directionPrefix.tooltip"
                  )}
                </div>
                <div>
                  {getTranslation(
                    intl,
                    "orders.accountDetails.serviceAddress.directionPrefix.tooltip.example"
                  )}
                </div>
              </>
            }
            readonly={props.disabled}
          />
        </Col>
        <Col md={3} className="pc-account-details-input">
            <TextFormInput
              labelTranslationId="orders.accountDetails.serviceAddress.streetName"
              value={props.serviceAddress?.streetName}
              handleInputChange={(value: string) =>
                props.setServiceAddress &&
                props.setServiceAddress({
                  ...props.serviceAddress,

                  streetName: value
                })
              }
              errorHandler={
                props.getErrorHandler &&
                props.getErrorHandler(
                  nameOf<PortInAccountModel, CustomerDetailsDto, ServiceAddressDto>(
                    "customerDetails",
                    "serviceAddress",
                    "streetName"
                  )
                )
              }
              readonly={props.disabled}
            />
        </Col>
        <Col md={3} className="pc-account-details-input">
          <TextFormInput
            labelTranslationId="orders.accountDetails.serviceAddress.streetNameSuffix"
            value={props.serviceAddress?.streetNameSuffix}
            handleInputChange={(value: string) =>
              props.setServiceAddress &&
              props.setServiceAddress({
                ...props.serviceAddress,

                streetNameSuffix: value
              })
            }
            errorHandler={
              props.getErrorHandler &&
              props.getErrorHandler(
                nameOf<PortInAccountModel, CustomerDetailsDto, ServiceAddressDto>(
                  "customerDetails",
                  "serviceAddress",
                  "streetNameSuffix"
                )
              )
            }
            tooltip={
              <>
                <div>
                  {getTranslation(
                    intl,
                    "orders.accountDetails.serviceAddress.streetNameSuffix.tooltip"
                  )}
                </div>
                <div>
                  {getTranslation(
                    intl,
                    "orders.accountDetails.serviceAddress.streetNameSuffix.tooltip.example"
                  )}
                </div>
              </>
            }
            readonly={props.disabled}
          />
        </Col>
        <Col md={3} className="pc-account-details-input">
            <TextFormInput
              labelTranslationId="orders.accountDetails.serviceAddress.directionSuffix"
              value={props.serviceAddress?.directionSuffix}
              handleInputChange={(value: string) =>
                props.setServiceAddress &&
                props.setServiceAddress({
                  ...props.serviceAddress,

                  directionSuffix: value
                })
              }
              errorHandler={
                props.getErrorHandler &&
                props.getErrorHandler(
                  nameOf<PortInAccountModel, CustomerDetailsDto, ServiceAddressDto>(
                    "customerDetails",
                    "serviceAddress",
                    "directionSuffix"
                  )
                )
              }
              readonly={props.disabled}
            />
        </Col>
        <Col md={3} className="pc-account-details-input">
          <TextFormInput
            labelTranslationId="orders.accountDetails.serviceAddress.AddressLine2"
            value={props.serviceAddress?.addressLine2}
            handleInputChange={(value: string) =>
              props.setServiceAddress &&
              props.setServiceAddress({
                ...props.serviceAddress,
                addressLine2: value
              })
            }
            errorHandler={
              props.getErrorHandler &&
              props.getErrorHandler(
                nameOf<PortInAccountModel, CustomerDetailsDto, ServiceAddressDto>(
                  "customerDetails",
                  "serviceAddress",
                  "addressLine2"
                )
              )
            }
            readonly={props.disabled}
          />
        </Col>
        <Col md={3} className="pc-account-details-input">
          <TextFormInput
            labelTranslationId="orders.accountDetails.serviceAddress.city"
            value={props.serviceAddress?.city}
            handleInputChange={(value: string) =>
              props.setServiceAddress &&
              props.setServiceAddress({
                ...props.serviceAddress,

                city: value
              })
            }
            errorHandler={
              props.getErrorHandler &&
              props.getErrorHandler(
                nameOf<PortInAccountModel, CustomerDetailsDto, ServiceAddressDto>(
                  "customerDetails",
                  "serviceAddress",
                  "city"
                )
              )
            }
            readonly={props.disabled}
          />
        </Col>
        <Col md={3} className="pc-account-details-input">
            <TextFormInput
              labelTranslationId="orders.accountDetails.serviceAddress.state"
              value={props.serviceAddress?.state}
              handleInputChange={(value: string) =>
                props.setServiceAddress &&
                props.setServiceAddress({
                  ...props.serviceAddress,

                  state: value
                })
              }
              errorHandler={
                props.getErrorHandler &&
                props.getErrorHandler(
                  nameOf<PortInAccountModel, CustomerDetailsDto, ServiceAddressDto>(
                    "customerDetails",
                    "serviceAddress",
                    "state"
                  )
                )
              }
              readonly={props.disabled}
            />
        </Col>
        <Col md={3} className="pc-account-details-input">
          <TextFormInput
            labelTranslationId="orders.accountDetails.serviceAddress.zipCode"
            value={props.serviceAddress?.zipCode}
            handleInputChange={(value: string) =>
              props.setServiceAddress &&
              props.setServiceAddress({
                ...props.serviceAddress,

                zipCode: value
              })
            }
            errorHandler={
              props.getErrorHandler &&
              props.getErrorHandler(
                nameOf<PortInAccountModel, CustomerDetailsDto, ServiceAddressDto>(
                  "customerDetails",
                  "serviceAddress",
                  "zipCode"
                )
              )
            }
            readonly={props.disabled}
          />
        </Col>
      </Row>
    </div>
  );
}
