import React from "react";
import { Error } from "./ErrorUtil";
import { FormattedMessage } from "react-intl";

type Props = {
  errors: Error[];
};

export default function InputError(props: Props) {
  return (
    <>
      {props.errors &&
        props.errors.map((x, i) => (
          <div className="text-red text-sm mt-1" key={i}>
            {x.hasTranslation && <FormattedMessage id={x.message} />}
            {!x.hasTranslation && x.message}
          </div>
        ))}
    </>
  );
}
