import React, { useContext } from "react";
import { authorizeAny } from "services/authorization/AuthorizationService";
import { AppContext } from "services/appContext/AppContext";
import { PermissionType } from "services/authorization/PermissionType";

type Props = {
  userPermissions?: PermissionType[];
  spidPermissions?: PermissionType[];
  vpopDisabled?: boolean;
  nonSpidDisabled?: boolean;
  children: React.ReactNode;
};

export default function Authorize(props: Props) {
  const { appContext } = useContext(AppContext);

  var isAuthorized =
    (!props.userPermissions &&
      !props.spidPermissions &&
      appContext.localStorageInfo.authenticationInfo.authenticated) ||
    (props.userPermissions && authorizeAny(props.userPermissions)) ||
    (props.spidPermissions && authorizeAny(props.spidPermissions, true));

  //VPOP user can have OrgAdmin permissions but still some pages might not be visisble
  if (appContext.localStorageInfo.selectedProfile?.external && props.vpopDisabled === true) {
    isAuthorized = false;
  }

  // Wholesale or other NonSpid user can be VPOP with various permissions but still some pages might not be visisble
  if (appContext.localStorageInfo.selectedProfile?.nonSpIdCompany && props.nonSpidDisabled === true){  
    isAuthorized = false;
  }

  return <>{isAuthorized && props.children}</>;
}
