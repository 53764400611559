export enum OrderCardStatus {
  NotSubmitted = "NotSubmitted",
  Created = "Created",
  HasErrors = "HasErrors",
  DocumentsUploadFailed = "DocumentsUploadFailed",
  NewDocumentsAdded = "NewDocumentsAdded",
  DocumentRemoved = "DocumentRemoved"
}

export const OrderCardStatuses: Record<OrderCardStatus, string | undefined> = {
  Created: "Order created successfully and all documents uploaded.",
  DocumentsUploadFailed: "Order created but some documents were not uploaded successfully",
  HasErrors:
    "Some order data is missing or is incorrect, please check and correct it before submitting",
  NotSubmitted: "Not submitted yet",
  NewDocumentsAdded: 'New documents will be uploaded when you press "Create all"',
  DocumentRemoved: "DocumentRemoved"
};
