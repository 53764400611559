import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { NpaNxxApi } from "services/apis/NpaNxxApi";
import { NpaNxxResult } from "services/apis/types/npaNxx/NpaNxxResult";
import NpaNxxCard from "./NpaNxxCard";
import BlocksCard from "./BlocksCard";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { handleError } from "services/util/ApiUtil";

type Props = {
  code: string;
};

export default function NpaNxxDetails(props: Props) {
  const [npaNxx, setNpaNxx] = useState<NpaNxxResult | undefined>(undefined);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);

  useEffect(() => {
    let isMounted = true;
    setShowLoadingIndicator(true);
    setNpaNxx(undefined);

    NpaNxxApi.get(props.code)
      .then((result) => {
        if (isMounted) {
          setNpaNxx(result);
        }
      })
      .catch((error) => handleError(error))
      .finally(() => {
        if (isMounted) {
          setShowLoadingIndicator(false);
        }
      });

    return () => {
      isMounted = false;
    };
  }, [props.code]);

  return (
    <>
      {showLoadingIndicator ? (
        <LoadingIndicator white />
      ) : (
        <>
          {npaNxx && (
            <Row>
              <Col lg="2">
                <NpaNxxCard npaNxx={npaNxx} />
              </Col>
              <Col lg="10">
                <BlocksCard blocks={npaNxx.blockData} />
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
}
