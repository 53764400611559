import React, { useState } from "react";
import { NotificationListItemDto } from "services/apis/types/webhooks/NotificationListItemDto";
import NotificationResumeConfirmationModal from "./NotificationResumeConfirmationModal";

type Props = { notificationDto: NotificationListItemDto };

export default function NotificationResumeAction(props: Props) {
  const [showResumeModal, setShowResumeModal] = useState(false);

  return (
    <>
      <i
        className={`fas fa-play mr-2 cursor-pointer`}
        onClick={() => setShowResumeModal(true)}
        data-toggle="tooltip"
        data-placement="top"
        title="Resume"
      />
      {showResumeModal && (
        <NotificationResumeConfirmationModal
          id={props.notificationDto.connectionId}
          notificationName={props.notificationDto.name}
          closeModal={() => setShowResumeModal(false)}
        />
      )}
    </>
  );
}
