export const PortOutRejectionReason: Record<string, string> = {
  CC00: "cause_code_none",
  CC01: "NPAC Auto cancel",
  CC02: "NPAC Auto conflict",
  CC50: "LSR WPR not received",
  CC51: "FOC WPRR not issued",
  CC52: "Due date mismatch",
  CC53: "Vacant number port",
  CC54: "General conflict"
};
