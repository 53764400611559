import React, { useRef, useState, useCallback, useEffect } from "react";
import classNames from "classnames";
import { TaskDto } from "services/apis/types/order/TaskDto";
import { getUtcDate } from "services/util/DateUtil";
import { FormattedMessage } from "react-intl";
import { AppConfiguration } from "AppConfiguration";
import CheckboxFormInput from "components/framework/forms/CheckboxFormInput";
import { nameOf } from "services/util/ObjectUtil";
import TaskModal from "./TaskModal";

type Props = {
  taskDto: TaskDto;
  onCheck: () => void;
};

export default function TaskListItem(props: Props) {
  const nextWorkDate = getUtcDate(props.taskDto.nextWorkDate);
  const [descriptionOverflowing, setDescriptionOverflowing] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const descriptionRef = useRef<HTMLSpanElement | null>(null);

  const checkOverflowing = useCallback(() => {
    if (descriptionRef.current) {
      setDescriptionOverflowing(
        descriptionRef.current.clientHeight < descriptionRef.current.scrollHeight
      );
    }
  }, []);

  useEffect(() => {
    checkOverflowing();
    window.addEventListener("resize", checkOverflowing);

    return () => {
      window.removeEventListener("resize", checkOverflowing);
    };
  }, [checkOverflowing]);

  return (
    <>
      <li className="checklist-entry list-group-item flex-column align-items-start py-4 px-0">
        <div
          className={classNames("checklist-item w-100", {
            "checklist-item-success checklist-item-checked": props.taskDto.completed,
            "checklist-item-danger":
              !props.taskDto.completed && nextWorkDate.isSame(getUtcDate(), "day"),
            "checklist-item-warning":
              !props.taskDto.completed && nextWorkDate.isAfter(getUtcDate(), "day")
          })}>
          <div className="checklist-info w-100 ">
            <h5 className="checklist-title mb-0 w-100">{props.taskDto.title}</h5>
            <div className="d-flex flex-column w-100">
              <small className="pc-task-description w-100 pr-2" ref={descriptionRef}>
                {props.taskDto.description}
              </small>
              {descriptionOverflowing && (
                <small
                  className="text-primary cursor-pointer pc-task-description-show-more"
                  onClick={() => setShowModal(true)}>
                  <FormattedMessage id="orders.orderDetails.tasks.description.seeMore" />
                </small>
              )}
              <small>
                <FormattedMessage
                  id={"orders.orderDetails.tasks.nextWorkDate.text"}
                  values={{ date: nextWorkDate.format(AppConfiguration.dateFormat) }}
                />
              </small>
              <small>
                <FormattedMessage
                  id={"orders.orderDetails.tasks.assignedTo.text"}
                  values={{ email: props.taskDto.assignedTo }}
                />
              </small>
            </div>
          </div>
          <div className="ml-auto">
            <CheckboxFormInput
              className={classNames({
                "custom-checkbox-success": props.taskDto.completed,
                "custom-checkbox-danger":
                  !props.taskDto.completed && nextWorkDate.isSame(getUtcDate(), "day"),
                "custom-checkbox-warning":
                  !props.taskDto.completed && nextWorkDate.isAfter(getUtcDate(), "day")
              })}
              value={`${props.taskDto.taskId}-${nameOf<TaskDto>("completed")}`}
              handleInputChange={props.onCheck}
              checked={props.taskDto.completed}
            />
          </div>
        </div>
      </li>
      {showModal && <TaskModal taskDto={props.taskDto} closeModal={() => setShowModal(false)} />}
    </>
  );
}
