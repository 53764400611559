import React, { useContext, useEffect, useRef, useState } from 'react';
import { Row, Button, Card, CardHeader, CardBody, Col, Input } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { TnReserveResponseDto } from 'services/apis/types/searchAndProvision/TnReserveResponseDto';
import { AppContext } from 'services/appContext/AppContext';
import { TnOrderRequestDto } from 'services/apis/types/searchAndProvision/TnOrderRequestDto';
import { SinchApi } from 'services/apis/SinchApi';
import { getFieldErrors, handleError } from 'services/util/ApiUtil';
import { useIsMounted } from 'services/customHooks/useIsMounted';
import { useErrors } from 'services/customHooks/useErrors';
import { useHistory } from 'react-router-dom';
import { SaveForLaterRequestDto } from 'services/apis/types/searchAndProvision/SaveForLaterRequestDto';
import { showErrorNotification, showInfoNotification } from 'components/framework/notification/NotificationUtil';
import TimerCompleteModal from 'components/common/TimerCompleteModal';

type Props = {
    reservedOrders?: TnReserveResponseDto;
}

export const ReservedOrders = (props: Props) => {
  const { appContext, setAppContext } = useContext(AppContext);
  const theme = appContext.theme;
  const isMounted = useIsMounted();
  const { setErrors } = useErrors();
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [isSaveForLater, setIsSaveForLater] = useState(false);
  const history = useHistory();
  const intl = useIntl();
  const [timerCompleted, setTimerCompleted] = useState(false);

  const hasExcludedList = props.reservedOrders?.tnReserveResponse?.excludedTnList?.length ? props.reservedOrders.tnReserveResponse.excludedTnList.length > 0 : false;
  const excludedTnList = props.reservedOrders?.tnReserveResponse?.excludedTnList;
  const reservedList = props.reservedOrders?.reservedNumbers;

  const [formattedTime, setFormattedTime] = useState('00:00');
  const timeLeftRef = useRef(0);

  const formatTime = (time) => {
      const minutes = Math.floor(time / 60);
      const secondsLeft = time % 60;
      return `Time left ${minutes.toString().padStart(2, '0')}:${secondsLeft.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    const storedStartTime = appContext.localStorageInfo.startTime;
    const storedDuration = appContext.localStorageInfo.duration;

    if (storedStartTime && storedDuration) {
      const startTime = parseInt(storedStartTime, 10);
      const duration = parseInt(storedDuration, 10);
      const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
      const remainingTime = duration - elapsedTime;
        timeLeftRef.current = remainingTime;
    }

    setFormattedTime(formatTime(timeLeftRef.current));

    const timerId = setInterval(() => {
        if (timeLeftRef.current > 0) {
            timeLeftRef.current -= 1;
            setFormattedTime(formatTime(timeLeftRef.current));
        } else {
            clearInterval(timerId);
            setAppContext({
              ...appContext,
              localStorageInfo: {
                ...appContext.localStorageInfo,
                startTime: "",
                duration: ""
              }
            });
            setTimerCompleted(true);
        }
    }, 1000);

    return () => {
        clearInterval(timerId);
    };
  }, []);

  const handleTimeComplete = () => {
    history.push("/SinchOrders");
  }

  const handleSinchOrderCreate = () => {
    setShowLoadingIndicator(true);
    const tnOrderRequest : TnOrderRequestDto = {
      requestId: props.reservedOrders?.requestId,
      tnOrder: {
        tnList: {
          tnItem: props.reservedOrders?.reservedNumbers.map(tn => ({
            tn,
            trunkGroup: "",
            routingOption: ""
          }))
        }
      }
    };

    SinchApi.tnOrder(tnOrderRequest)
      .then((result) => {
        if (result.statusCode === "432") {
          showErrorNotification(
            intl.formatMessage({ id: result.result.toString() })
          );
        } else {
          showInfoNotification(
            intl.formatMessage({ id: "orders.reserved.order.create" })
          );
        }
        setAppContext({
            ...appContext,
            localStorageInfo: {
                ...appContext.localStorageInfo,
                provisionOrders: [],
                startTime: "",
                duration: ""
            }
        });
        history.push("/SinchOrders");
      })
      .catch((error) => {
        handleError(error);
        if (isMounted.current) {
          const errorsResult = getFieldErrors(error.fieldErrors);
          setErrors(errorsResult);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setShowLoadingIndicator(false);
        }
      });
  };

  const handleSaveForLater = () => {
    setShowLoadingIndicator(true);
    setIsSaveForLater(true);
    const saveForLaterRequest : SaveForLaterRequestDto = {
      requestId: props.reservedOrders?.requestId,
      phoneNumbers: props.reservedOrders?.reservedNumbers
    }

    SinchApi.saveForLater(saveForLaterRequest)
      .then(() => {
        showInfoNotification(
          intl.formatMessage({ id: "orders.reserved.order.saveForLater" })
        );
        setAppContext({
            ...appContext,
            localStorageInfo: {
                ...appContext.localStorageInfo,
                provisionOrders: [],
                startTime: "",
                duration: ""
            }
        });
        history.push("/SinchOrders");
      })
      .catch((error) => {
        handleError(error);
        if (isMounted.current) {
          const errorsResult = getFieldErrors(error.fieldErrors);
          setErrors(errorsResult);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setShowLoadingIndicator(false);
          setIsSaveForLater(false);
        }
      });
  };

  return (
    <>
      <Card>
        <CardHeader>
          <h5 className="h3 mb-0">
            <FormattedMessage id="orders.create.searchAndProvision" />
          </h5>
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="12">
            <h3 className="d-flex align-items-center custom-underline custom-underline-reserved">{formattedTime}</h3>
              <h3 className='mt-3'>
                <FormattedMessage id="orders.reserved.overview" />
              </h3>
            </Col>
            <Col md="6">
              <h3 className='lblue-color'>
                <FormattedMessage id="orders.reserved.successMessage" />
              </h3>
              <Input
                className={"input-group-merge pc-search-container mb-0 h-180"}
                rows="6"
                type="textarea"
                value={reservedList}
                disabled
              />
            </Col>
            <Col md="6">
              {hasExcludedList &&
                <>
                  <h3 className='lblue-color'>
                    <FormattedMessage id="orders.reserved.failedMessage" />
                  </h3>
                  <ul className='tn-list'>
                    {excludedTnList?.map(e => (
                      <li className='text-provision-confirmation'>
                        <span className='text-tn-disabled'>{e.tn}</span> - 
                        <span className='text-tn-reason-disabled'>{e.excludedReason}</span>
                      </li>
                    ))}
                  </ul>
                </>
              }
            </Col>
          </Row>
          <div className="d-flex justify-content-end mt-3">
            <Button color="primary" className={`${theme === "light" ? "bg-lblue no-border" : ""}`} onClick={handleSaveForLater} disabled={showLoadingIndicator && isSaveForLater} >
              {showLoadingIndicator && isSaveForLater && <i className="fas fa-spinner fa-spin mr-2" />}
              <FormattedMessage id="orders.provisionOrder.table.saveForLater" />
            </Button>
            <Button color="primary" className={`mr-0 ${theme === "light" ? "bg-lblue no-border" : ""}`} onClick={handleSinchOrderCreate} disabled={showLoadingIndicator && !isSaveForLater} >
              {showLoadingIndicator&& !isSaveForLater && <i className="fas fa-spinner fa-spin mr-2" />}
              <FormattedMessage id="orders.reserved.create" />
            </Button>
          </div>
        </CardBody>
      </Card>
      {timerCompleted && (
        <TimerCompleteModal
          onSubmit={handleTimeComplete}
        />
      )}
    </>
  );
};
