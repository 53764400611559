import { KeyValuePair } from "services/types/KeyValuePair";

export enum SupplementType {
  Cancel = "Cancel",
  DueDate = "DueDate",
  Other = "Other",
  OrderHandler = "OrderHandler",
  All = 'All'
}

// use array to preserve order in dropdown list
export const SupplementTypesList : Array<KeyValuePair<string, string>> = [
  {
    key: "Cancel complete order",
    value: SupplementType.Cancel
  },
  {
    key: "Update due date",
    value: SupplementType.DueDate
  },
  {
    key: "Change order handler",
    value: SupplementType.OrderHandler
  },
  {
    key: "Update other fields",
    value: SupplementType.Other

  }
];
