import React, { useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleError } from "services/util/ApiUtil";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { ManageContext } from "../Manage";
import { UserApi } from "services/apis/UserApi";
import ConfirmationModal from "components/framework/modals/ConfirmationModal";

type Props = {
  id: string;
  name: string;
  closeModal: () => void;
};

export default function UserDeleteModal(props: Props) {
  const intl = useIntl();
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const manageContext = useContext(ManageContext);
  const isMounted = useIsMounted();

  const handleDelete = () => {
    if (isMounted.current) {
      setShowLoadingIndicator(true);
    }

    UserApi.delete(props.id)
      .then(() => {
        showInfoNotification(intl.formatMessage({ id: "manage.user.delete.deleteNotification" }));
        if (isMounted.current) {
          manageContext.userCallback();
          props.closeModal();
        }
      })
      .catch(error => handleError(error))
      .finally(() => {
        if (isMounted.current) {
          setShowLoadingIndicator(false);
        }
      });
  };

  return (
    <ConfirmationModal
      confirmCallback={handleDelete}
      closeModal={props.closeModal}
      title={intl.formatMessage({ id: "manage.user.delete.deleteModalTitle" })}
      confirmButtonText={intl.formatMessage({ id: "manage.user.delete.submitDelete" })}
      showLoadingIndicator={showLoadingIndicator}>
      <p>
        <FormattedMessage id="manage.user.delete.deleteQuestion" values={{ userName: props.name }} />
      </p>
    </ConfirmationModal>
  );
}
