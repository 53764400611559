import { SupplementRejectionReason } from "./SupplementRejectionReason";

export type RejectSupplementModel = {
  rejectionReason: keyof typeof SupplementRejectionReason;
  rejectDescription?: string;
};

export const DefaultRejectSupplementModel: RejectSupplementModel = {
  rejectionReason: "ACCN"
};
