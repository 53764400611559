import React, { useState } from "react";
import { CompanyInfo } from "services/apis/types/company/CompanyInfo";
import ActionDropdown from "components/networkExplorer/common/ActionDropdown";
import ActionDropdownItem from "components/networkExplorer/common/ActionDropdownItem";
import OrganizationModal from "./OrganizationModal";
import OrganizationDeleteModal from "./OrganizationDeleteModal";
import { ManageAction } from "components/manage/types/ManageAction";

type Props = {
  company: CompanyInfo;
  availableCompanies: CompanyInfo[];
};

export default function OrganizationActionsCell(props: Props) {
  const [showModifyModal, setShowModifyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [actionsOpen, setActionsOpen] = useState(false);

  return (
    <>
      <ActionDropdown actionsOpen={actionsOpen} actionsToggle={() => setActionsOpen(prevState => !prevState)}>
        <ActionDropdownItem
          handleAction={() => setShowModifyModal(true)}
          iconClassName="fa fa-edit"
          labelId="manage.organization.table.actions.modify"
        />
        <ActionDropdownItem
          handleAction={() => setShowDeleteModal(true)}
          iconClassName="fa fa-trash-alt"
          labelId="manage.organization.table.actions.delete"
        />
      </ActionDropdown>
      {showModifyModal && (
        <OrganizationModal
          manageAction={ManageAction.Modify}
          companyId={props.company.id}
          availableCompanies={props.availableCompanies.filter(x => x.id !== props.company.id)}
          closeModal={() => setShowModifyModal(false)}
        />
      )}
      {showDeleteModal && (
        <OrganizationDeleteModal
          id={props.company.id}
          name={props.company.name}
          closeModal={() => setShowDeleteModal(false)}
        />
      )}
    </>
  );
}
