import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { LrnApi } from "services/apis/LrnApi";
import { LrnResult } from "services/apis/types/lrn/LrnResult";
import LrnDetailsCard from "./LrnDetailsCard";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { handleError } from "services/util/ApiUtil";

type Props = {
  lrn: string;
};

export default function LrnDetails(props: Props) {
  const [lrn, setLrn] = useState<LrnResult | undefined>(undefined);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);

  useEffect(() => {
    let isMounted = true;
    setShowLoadingIndicator(true);
    setLrn(undefined);

    LrnApi.get(props.lrn)
      .then(result => {
        if (isMounted) {
          setLrn(result);
        }
      })
      .catch(error => handleError(error))
      .finally(() => {
        if (isMounted) {
          setShowLoadingIndicator(false);
        }
      });

    return () => {
      isMounted = false;
    };
  }, [props.lrn]);

  return (
    <>
      {showLoadingIndicator ? (
        <LoadingIndicator white />
      ) : (
        <>
          {lrn && (
            <Row>
              <Col lg="4" md="8" className="offset-lg-4 offset-md-2">
                <LrnDetailsCard lrn={lrn} />
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
}
