import { ContactInfo } from "services/apis/types/company/ContactInfo";

export type CompanyInfo = {
  id: string,
  name: string,
  nonSpId: boolean,
  csrEnabled: boolean,
  addressLine1: string,
  addressLine2: string,
  city: string,
  state: string,
  zipCode: string,
  primaryContactId: string,
  created: Date,
  notificationEmail: string,
  billingId: string,
  associatedSpId: string[],
  parent: CompanyInfo,
  primaryContact: ContactInfo,
  parentId: string,
  companyType: CompanyType,
  tenantId: string;
  clientType: string;
  statusIssueReport?: boolean;
};

export enum CompanyType {
  StandAlone = "StandAlone",
  Parent = "Parent",
  Child = "Child"
}