import { Moment } from "moment";
import { Authorized } from "./Authorized";
import { PortOutRejectionReason } from "./PortOutRejectionReason";
import { getUtcDate } from "services/util/DateUtil";

export type PortOutModifyModel = {
  phoneNumber: string;
  modifyType: string;
  portDirection: string;
  dueDate?: Moment;
  authorized: keyof typeof Authorized;
  rejectionReason: keyof typeof PortOutRejectionReason;
};

export const DefaultPortOutModifyModel: PortOutModifyModel = {
  modifyType: "VSPE",
  portDirection: "OUT",
  phoneNumber: "",
  dueDate: getUtcDate(),
  authorized: "YES",
  rejectionReason: ""
};
