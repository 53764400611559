export enum StatusType {
    ActionRequired = "Action Required",
    Pending = "Pending",
    Authorized = "Authorized",
    Active = "Active",
    Sending = "Sending",
    Conflict = "Conflict",
    Old = "Old",
    Failed = "Failed",
    PartialFailure = "Partial Failure",
    Canceled = "Canceled",
    CancelPending = "Cancel Pending",
    DisconnectPending = "Disconnect Pending",
    ActivatePending = "Activate Pending",
    PreOrderPending = "LSR Pending",
    ReceivedFoc = "Received FOC",
    Rejected = "LSR Rejected",
    Submitted = "LSR Submitted",
    Supplemented = "LSR Supplemented",
    CSRPending = "CSR Pending",
    CSRRequested = "CSR Requested",
    CSRReceived = "CSR Received",
    CSRReview = "CSR Review",
    CSRModified = "CSR Modified",
    CSRInvalid = "CSR Invalid",
    CSREscalated = "CSR Escalated",
    CSRApproved = "CSR Approved"
}

export const getNumberStatus = (value: string | undefined): string => {
    var altStatus = StatusType[value as string];
    return altStatus ?? value;
}