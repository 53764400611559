import React, { useMemo } from "react";
import { useIntl, IntlShape } from "react-intl";
import { BlockResult } from "services/apis/types/block/BlockResult";
import FormatDate from "components/framework/date/FormateDate";
import { getValueOrEmpty } from "services/util/StringUtil";
import ReactTable from "components/framework/table/ReactTable";
import { getFormattedUtcDate } from "services/util/DateUtil";

type Props = {
  blocks: BlockResult[];
};

export default function BlocksCard(props: Props) {
  const intl = useIntl();
  const columns = useMemo(() => getColumns(intl), [intl]);

  return (
    <ReactTable
      data={props.blocks}
      columns={columns}
      hideSearch={true}
      filterMapper={filterMapper}
    />
  );
}

const getColumns = (intl: IntlShape) => [
  {
    Header: intl.formatMessage({ id: "network.npaNxx.table.block" }),
    accessor: "blockDash",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "network.npaNxx.table.spId" }),
    accessor: "spId",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "network.npaNxx.table.altSpId" }),
    accessor: "altSpId",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "network.npaNxx.table.lastAltSpId" }),
    accessor: "lastAltSpId",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "network.npaNxx.table.ocn" }),
    accessor: "ocn",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "network.npaNxx.table.effectiveDate" }),
    accessor: "effectiveDate",
    Cell: (cell: any) => <FormatDate utcDate={cell.cell.value} />
  },
  {
    Header: intl.formatMessage({ id: "network.npaNxx.table.lrn" }),
    accessor: "lrn",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "network.npaNxx.table.status" }),
    accessor: "status",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "network.npaNxx.table.contaminated" }),
    accessor: "contaminated"
  }
];

const filterMapper = (block: BlockResult) => {
  return {
    ...block,
    effectiveDate: getFormattedUtcDate(block.effectiveDate)
  };
};
