import React from "react";

type Props = {
  className: string;
  title: string;
  onClick?: () => void;
  actionCompleted?: boolean;
};

export default function Icon(props: Props) {
  return (
    <i
      className={`${props.className} ${ props.actionCompleted ? "icons_disabled" : ""}`}
      onClick={props.onClick}
      data-toggle="tooltip"
      data-placement="top"
      title={props.title}></i>
  );
}
