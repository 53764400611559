import { HttpClient } from "services/httpClient/HttpClient";
import { NpacQueryRequestDto } from "./types/query/NpacQueryRequestDto";
import { RequestQueryModel } from "./types/query/RequestQueryModel";
import { ApiError } from "./types/ApiError";
import { NpacQueryResultDto } from "./types/query/NpacQueryResultDto";

export const QueryApi = {
  list: (searchValue: string): Promise<NpacQueryRequestDto[]> => {
    return HttpClient.get<NpacQueryRequestDto[]>(`query/?search=${searchValue}`, true, true);
  },

  create: (query: RequestQueryModel): Promise<ApiError> => {
    return HttpClient.post<ApiError>("query", query, true, true);
  },

  details: (queryId: string): Promise<NpacQueryResultDto> => {
    return HttpClient.get<NpacQueryResultDto>(`query/${queryId}`, true, true);
  }
};
