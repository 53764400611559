import React, { useState } from "react";
import ExecuteNumberAction from "./ExecuteNumberAction";
import { NumberStatusDto } from "services/apis/types/order/NumberStatusDto";
import { OrderNumberActionType } from "services/portControl/types/OrderNumberActionType";
import { OrderNumberActionConfigurations } from "./configuration/OrderNumberActionConfigurations";
import Icon from "components/common/Icon";

type Props = {
  actions: OrderNumberActionType[];
  numberStatusDto: NumberStatusDto;
};

export default function PortingNumbersTableActionsCell(props: Props) {
  const [selectedAction, setSelectedAction] = useState<OrderNumberActionType>();
  const [actionCompleted, setActionCompleted] = useState(false);

  return (
    <>
      {props.actions && props.actions.length > 0 ? (
        props.actions.map((action) => (
          <span key={action}>
            {!OrderNumberActionConfigurations[action].Number.inactive && (
              <span>
                {selectedAction === action && (
                  <ExecuteNumberAction
                    numberStatusDto={props.numberStatusDto}
                    action={action}
                    confirmationMessageId={
                      OrderNumberActionConfigurations[action].Number.confirmMessageId
                    }
                    skipConfirmation={
                      OrderNumberActionConfigurations[action].Number.skipConfirmation
                    }
                    closeModal={() => setSelectedAction(undefined)}
                    onActionExecuted={setActionCompleted}
                  />
                )}
                <Icon
                  className={`${OrderNumberActionConfigurations[action].Number.iconClass} mr-2 cursor-pointer`}
                  title={action}
                  actionCompleted={actionCompleted}
                  onClick={() => setSelectedAction(action)}
                />
              </span>
            )}
          </span>
        ))
      ) : (
        <>-</>
      )}
    </>
  );
}
