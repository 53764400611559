import React from "react";
import DateTimeFormInput from "components/framework/forms/DateTimeFormInput";
import TextFormInput from "components/framework/forms/TextFormInput";
import { OrderDto } from "services/apis/types/order/OrderDto";
import { getUtcDate } from "services/util/DateUtil";
import CardSectionHeader from "../../framework/cards/CardSectionHeader";
import { OrderHandler } from "services/apis/types/port/OrderHandler";
import { Row, Col } from "reactstrap";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork

type Props = {
  order: OrderDto;
};

export default function HandlingOverview(props: Props) {
  return (
    <>
      <CardSectionHeader labelTranslationId="orders.orderDetails.orderDetailsCard.handlingOverview" />
      <Row>
        {!isMixNetworkUrl && <Col md={6} xl={3}>
          <TextFormInput
            labelTranslationId="orders.orderDetails.orderDetailsCard.handledBy"
            value={props.order.metadata.handledBy && OrderHandler[props.order.metadata.handledBy]}
          />
        </Col>}
        {!isMixNetworkUrl && <Col md={6} xl={3}>
          <TextFormInput
            labelTranslationId="orders.orderDetails.orderDetailsCard.assignedTo"
            // value={props.order.or newInport.projectId} //TODO add when it's returned by the API
          />
        </Col>}
        {!isMixNetworkUrl && <Col md={6} xl={3}>
          <DateTimeFormInput
            disabled
            labelTranslationId="orders.orderDetails.orderDetailsCard.desiredDueDate"
            value={props.order.desiredDueDate && getUtcDate(props.order.desiredDueDate)}
          />
        </Col>}
        {isMixNetworkUrl && <Col md={6} xl={3}>
          <DateTimeFormInput
            disabled
            labelTranslationId="orders.orderDetails.orderDetailsCard.activationDate"
            value={props.order.desiredDueDate && getUtcDate(props.order.desiredDueDate)}
          />
        </Col>}
        <Col md={6} xl={3}>
          <DateTimeFormInput
            disabled
            labelTranslationId="orders.orderDetails.orderDetailsCard.focDate"
            value={props.order.focDate && getUtcDate(props.order.focDate)}
          />
        </Col>
      </Row>
    </>
  );
}
