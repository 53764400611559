import { ExcelTemplateRow } from "./ExcelTemplateRow";

export const ExcelTemplateRowHeaders: Record<keyof ExcelTemplateRow, string> = {
  phoneNumber: "Porting Telephone Numbers",
  projectId: "Project ID",
  dueDate: "Due Date",
  dueTime: "Due Time",
  accountName: "Account Name",
  accountNumber: "Account Number",
  accountPin: "Account PIN/Password",
  accountType: "Account Type",
  authorizedName: "Authorization Name",
  billingPhoneNumber: "Billing Telephone Number",
  city: "City",
  addressLine2: "Address Line 2",
  directionPrefix: "Street Direction",
  directionSuffix: "Street Directional Suffix",
  number: "Street Address Number",
  state: "State",
  streetName: "Street Name",
  streetNameSuffix: "Street Type",
  zipCode: "Zip Code",
  portToOrginal: "Port To Original(y/n)",
  lrn: "LRN",
  csrRequest: "CSR Requested (y/n)",
  handler: "Handled By (ATLC/ServiceProvider)"
};
