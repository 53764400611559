import { HttpClient } from "services/httpClient/HttpClient";
import { TnInventoryRequestDto } from "./types/searchAndProvision/TnInventoryRequestDto";
import { TnInventoryResponseDto } from "./types/searchAndProvision/TnInventoryResponseDto";
import { ApiVersion } from "services/httpClient/ApiVersion";
import { TnReserveRequestDto } from "./types/searchAndProvision/TnReserveRequestDto";
import { TnReserveResponseDto } from "./types/searchAndProvision/TnReserveResponseDto";
import { TnOrderRequestDto } from "./types/searchAndProvision/TnOrderRequestDto";
import { TnOrderResponseDto } from "./types/searchAndProvision/TnOrderResponseDto";
import { SaveForLaterRequestDto } from "./types/searchAndProvision/SaveForLaterRequestDto";
import { SaveForLaterResponseDto } from "./types/searchAndProvision/SaveForLaterResponseDto";
import { SinchOrderList } from "./types/order/OrderListDto";
import { OrderDto } from "./types/order/OrderDto";
import { ApiError } from "components/common/types/ApiError";
import queryString from "query-string";

export const SinchApi = {
  tnInventory: (tnInventoryRequest: TnInventoryRequestDto, tenant: string | undefined = undefined) : Promise<TnInventoryResponseDto> => {
    return HttpClient.post<TnInventoryResponseDto>("sinch/tninventory", tnInventoryRequest, true, true, tenant, ApiVersion.V3);
  },

  tnReserve: (tnReserveRequest: TnReserveRequestDto, tenant: string | undefined = undefined) : Promise<TnReserveResponseDto> => {
    return HttpClient.post<TnReserveResponseDto>("sinch/tnreserve", tnReserveRequest, true, true, tenant, ApiVersion.V3)
  },

  tnOrder: (tnOrderRequest: TnOrderRequestDto, tenant: string | undefined = undefined) : Promise<TnOrderResponseDto> => {
    return HttpClient.post<TnOrderResponseDto>("sinch/tnorder", tnOrderRequest, true, true, tenant, ApiVersion.V3)
  },

  saveForLater: (saveForLaterRequest: SaveForLaterRequestDto, tenant: string | undefined = undefined) : Promise<SaveForLaterResponseDto> => {
    return HttpClient.post<SaveForLaterResponseDto>("sinch/saveforlater", saveForLaterRequest, true, true, tenant, ApiVersion.V3)
  },

  sinchList: (search: string, spIds: string[], companyId: string | undefined, tenant: string | undefined = undefined) : Promise<SinchOrderList[]> => {
    let url = queryString.stringifyUrl({
        url: "sinch",
        query: {
          search: search && search.length > 0 ? search : undefined,
          spIds: spIds && spIds.length > 0 ? spIds : undefined,
          companyId: companyId ? companyId : undefined
        },
      }, {
      arrayFormat: 'comma'
    });
    
    return HttpClient.get<SinchOrderList[]>(url, true, true, tenant, ApiVersion.V3);
  },

  getSinch: (orderId: string, tenant?: string): Promise<OrderDto> => {
    return HttpClient.get<OrderDto>(`sinch/${orderId}`, true, true, tenant, ApiVersion.V3);
  },

  archive: (orderId: string, tenant: string): Promise<ApiError> => {
    return HttpClient.post<ApiError>(`sinch/${orderId}/archive`, undefined, true, true, tenant, ApiVersion.V3);
  },
};
