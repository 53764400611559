export type WorkQueue = {
    orderType?: string;
    status?: string;
    organization?: string;
    gainingSpId?: string;
    loosingSpId?: string;
    workedBy?: string;
    dueDate?: Array<any>;
    nextWorkDate?: Array<any>;
    creationDate?: Array<any>;
    pageSize?: number;
    pageIndex?: number;
};

export const DefaultWorkQueueDetails: WorkQueue = {
    orderType: "Any",
    status: "Any",
    organization: "Any",
    gainingSpId: "Any",
    loosingSpId:"Any",
    workedBy: "Any",
    pageSize: 10,
    pageIndex: 1
};
