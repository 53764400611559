import React, { useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NpaNxxApi } from "services/apis/NpaNxxApi";
import { NetworkExplorerContext } from "../NetworkExplorer";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleFieldErrors } from "services/util/ApiUtil";
import ConfirmationModal from "components/framework/modals/ConfirmationModal";

type Props = {
  npaNxx: string;
  closeModal: () => void;
};

export default function NpaNxxDeleteModal(props: Props) {
  const intl = useIntl();
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const networkExplorerContext = useContext(NetworkExplorerContext);
  const isMounted = useIsMounted();

  const handleDelete = () => {
    if (isMounted.current) {
      setShowLoadingIndicator(true);
    }

    NpaNxxApi.delete(props.npaNxx)
      .then(() => {
        showInfoNotification(intl.formatMessage({ id: "network.npaNxx.delete.deleteNotification" }));
        if (isMounted.current) {
          networkExplorerContext.clearSearch();
        }
      })
      .catch(error => handleFieldErrors(error))
      .finally(() => {
        if (isMounted.current) {
          setShowLoadingIndicator(false);
        }
      });
  };

  return (
    <ConfirmationModal
      confirmCallback={handleDelete}
      closeModal={props.closeModal}
      title={intl.formatMessage({ id: "network.npaNxx.delete.deleteModalTitle" })}
      confirmButtonText={intl.formatMessage({ id: "network.npaNxx.delete.submitDelete" })}
      showLoadingIndicator={showLoadingIndicator}>
      <p>
        <FormattedMessage id="network.npaNxx.delete.deleteQuestion" values={{ npanxx: props.npaNxx }} />
      </p>
    </ConfirmationModal>
  );
}
