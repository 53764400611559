import React, { useContext } from "react";
import { Col, Row, Container } from "reactstrap";
import { FormattedMessage } from "react-intl";
import BreadcrumbHeader from "components/common/BreadcrumbHeader";
import ChangePasswordCard from "./ChangePasswordCard";
import ProfileCard from "./ProfileCard";
import { AppContext } from "services/appContext/AppContext";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";
import { PermissionType } from "services/authorization/PermissionType";

const isMixNetworkUrl = EnvironmentUtil.isMixNetwork;

export default function MyProfile() {
  const {appContext} = useContext(AppContext);
  const isUserAdmin = appContext.localStorageInfo.user?.permissions.find(value => value === PermissionType.Admin)
  const isUserCompanyAdmin = appContext.localStorageInfo.user?.permissions.find(value => value === PermissionType.CompanyAdmin)
  return (
    <>
      <BreadcrumbHeader>
        <FormattedMessage id="myProfile.title" />
      </BreadcrumbHeader>
      <Container fluid>
        <Row>
          <Col className={ (isMixNetworkUrl && !isUserAdmin && !isUserCompanyAdmin) ? "offset-lg-4 col-lg-4" : "offset-lg-2 col-lg-4"}>
            <ProfileCard />
          </Col>
          { (isMixNetworkUrl && !isUserAdmin && !isUserCompanyAdmin) ?
           <Col className="col-lg-4"/>
           :
           <Col className="col-lg-4">
              <ChangePasswordCard />
            </Col>
          }
        </Row>
      </Container>
    </>
  );
}
