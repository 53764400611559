import React, { useState, FormEvent, useContext, useEffect } from "react";
import BreadcrumbHeader from "components/common/BreadcrumbHeader";
import { FormattedMessage, useIntl } from "react-intl";
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from "reactstrap";
import classNames from "classnames";
import { AppContext } from "services/appContext/AppContext";
import CustomerInventoryTable from "./customerInventoryTable";
import IconWithTooltip from "components/common/IconWithTooltip";
import { getTranslation } from "translations/TranslationService";

export default function CustomerInventory() {
    const [searchValue, setSearchValue] = useState("");
    const [searchInputValue, setSearchInputValue] = useState("");
    const [focusedSearchInput, setFocusedSearchInput] = useState(false);
    const [errorMessageId, setErrorMessageId] = useState("");
    const intl = useIntl();
    const { appContext } = useContext(AppContext);
    const theme = appContext.theme;

    useEffect(() => { setSearchValue(''); setSearchInputValue(''); }, [appContext.localStorageInfo.selectedProfile]);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        const inputValue = searchInputValue.trim();
        const errorMessageIdResult = validateSearchValue();
        setErrorMessageId(errorMessageIdResult);

        if (!errorMessageIdResult) {
            setSearchValue(inputValue);
        }
    };

    const handleSearchChange = (searchValue: string) => {
        setErrorMessageId("");
        setSearchInputValue(searchValue);
    };

    return (
        <>
            <BreadcrumbHeader>
                <FormattedMessage id="customerInventory.title" />
            </BreadcrumbHeader>
            <Container className="mb-5">
                <Row>
                    <Col md="6" className="offset-md-3">
                        <h1 className={`display-3 ${theme === "light" ? "lblue-color" : "pc-network-explorer-bg"}`}>
                            <FormattedMessage id="customerInventory.title" />
                            <IconWithTooltip
                              className="fas fa-info-circle cursor-pointer mx-2 icon-font-size"
                              tooltip={getTranslation(intl, "general.info")}
                              tooltipClassName="custom-tooltip-background"
                            />
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col md="6" xs="6" className="mb-1 offset-md-3">
                        <Form onSubmit={handleSubmit}>
                            <FormGroup
                                className={classNames("input-group-merge pc-search-container mb-0", {
                                    focused: focusedSearchInput
                                })}>
                                <InputGroup className="input-group-merge">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-search" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        type="text"
                                        className="pc-search"
                                        placeholder={intl.formatMessage({ id: "customerInventory.search.placeholder" })}
                                        value={searchInputValue}
                                        onChange={(e) => handleSearchChange(e.currentTarget.value)}
                                        onFocus={() => setFocusedSearchInput(true)}
                                        onBlur={() => setFocusedSearchInput(false)}
                                    />
                                </InputGroup>
                                <Button color={`${theme === "dark" ? "warning" : ""}`} type="submit" className={`pc-btn ml-4 ${theme === "light" ? "bg-lblue" : ""}`}>
                                    <FormattedMessage id="tradingPartners.search.button" />
                                </Button>
                            </FormGroup>
                            {errorMessageId && (
                                <div className="text-red text-sm mt-1">
                                    <FormattedMessage id={errorMessageId} />
                                </div>
                            )}
                        </Form>
                    </Col>
                    <Col className="mb-1 mr-5">
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <CustomerInventoryTable search={searchValue}/>
            </Container>
            
        </>
    );
}

const validateSearchValue = (): string => {
    return "";
};