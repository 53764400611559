import React, { useEffect, useRef, useMemo } from "react";
import Chart from "chart.js";
import { ReportDto } from "services/apis/types/reports/ReportDto";
import moment from "moment";
import { getUtcDate } from "services/util/DateUtil";
import { PortStatistic } from "services/apis/types/reports/PortStatistic";
import { useIntl, IntlShape } from "react-intl";
import { AppConfiguration } from "AppConfiguration";

type Props = {
  reportDto: ReportDto;
};

export default function PortedNumbersChart(props: Props) {
  const intl = useIntl();
  const chartRef = useRef<any>();

  const days = useMemo(() => Array.from(Array(moment().toDate().getDate()), (_, i) => i + 1), []);
  const portInData = useMemo(() => getChartLineData(props.reportDto.portInData, days), [
    days,
    props.reportDto.portInData
  ]);
  const portOutData = useMemo(() => getChartLineData(props.reportDto.portOutData, days), [
    days,
    props.reportDto.portOutData
  ]);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      new Chart(ctx, getChartOptions(intl, days, portInData, portOutData));
    }
  }, [days, intl, portInData, portOutData]);

  return (
    <div className="chart">
      <canvas ref={chartRef}></canvas>
    </div>
  );
}

const getChartLineData = (portStatistics: PortStatistic[], days: number[]) => {
  return days.map((x) => {
    const chartPoint = portStatistics.find((y) => getUtcDate(y.date).toDate().getDate() === x);

    return chartPoint ? chartPoint.count : 0;
  });
};

const getDataSet = (data: number[], label: string, color: string): Chart.ChartDataSets => {
  return {
    fill: false,
    data: data,
    label: label,
    borderColor: color,
    borderWidth: 3,
    backgroundColor: color
  };
};

const getChartOptions = (
  intl: IntlShape,
  days: number[],
  portInData: number[],
  portOutData: number[]
): Chart.ChartConfiguration => {
  return {
    type: "line",
    data: {
      labels: [...days.map((x) => x.toString())],
      datasets: [
        getDataSet(portInData, intl.formatMessage({ id: "reports.chart.portInLabel" }), "blue"),
        getDataSet(portOutData, intl.formatMessage({ id: "reports.chart.portOutLabel" }), "orange")
      ]
    },
    options: {
      tooltips: {
        mode: "label",
        position: "nearest",
        enabled: true,
        titleAlign: "center",
        callbacks: {
          title: (item, data) => {
            return data.labels
              ? moment()
                .startOf("month")
                .add(data.labels.find((x) => x === item[0].label) as string, "days")
                .add(-1, "days")
                .format(AppConfiguration.dateFormat)
              : "";
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      legend: { display: false },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true
            }
          }
        ],
        xAxes: [
          {
            gridLines: {
              display: false
            }
          }
        ]
      }
    }
  };
};
