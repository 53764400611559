import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Modal } from "reactstrap";
import CloseButton from "components/framework/modals/CloseButton";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { FormattedMessage } from "react-intl";
import ReactTable from "components/framework/table/ReactTable";
import { IntlShape, useIntl } from "react-intl";
import { getValueOrEmpty } from "services/util/StringUtil";
import { TradingPartnerApi } from "services/apis/TradingPartnerApi";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { TppHistory, DefaultTppHistory } from "services/apis//types/tpp/TppHistory";
import { handleError } from "services/util/ApiUtil";

type Props = {
  closeModal: () => void;
  spId: string;
  serviceProvider: string;
  tppId: string;
};

export default function TradingPartnerHistoryModal(props: Props) {
  const [history, setHistory] = useState<TppHistory[]>([DefaultTppHistory]);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const intl = useIntl();
  const columns = useMemo(() => getColumns(intl), [intl]);
  const isMounted = useIsMounted();

  const fetchTradingPartnerHistory = useCallback(() => {
    if (props.tppId) {
      TradingPartnerApi.getTppHistory(props.tppId)
        .then((result) => {
          if (isMounted) {
            setHistory(result);
          }
        })
        .catch((error) => handleError(error))
        .finally(() => {
          if (isMounted) {
            setShowLoadingIndicator(false);
          }
        })
    }
  }, [isMounted, props.tppId]);

  useEffect(() => {
    setShowLoadingIndicator(true);
    fetchTradingPartnerHistory();
  }, [fetchTradingPartnerHistory])
  

  return (
    <>
      <Modal className="modal-dialog-centered modal-history" isOpen={true} zIndex={2147483648}>
        <div className="modal-header border-bottom">
          <h4 className="modal-title">
            <FormattedMessage id={"ttp.historyModal.title"} />
          </h4>
          <CloseButton close={props.closeModal} />
        </div>
        <div className="modal-header">
          <h5 className="modal-title">
            {props.spId} <span className="font-weight-normal">{props.serviceProvider}</span>
          </h5>
          
        </div>
        {showLoadingIndicator ? (
          <LoadingIndicator />
        ) :
          <ReactTable
            data={history}
            columns={columns}
            hideSearch={true}
          />}
        </Modal>

    </>
  );
}

const getColumns = (intl: IntlShape) => [
  {
    Header: intl.formatMessage({ id: "tradingPartners.table.status" }),
    accessor: "status",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  },
  {
    Header: intl.formatMessage({ id: "tradingPartners.table.date" }),
    accessor: "modifiedDate",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
  }
]