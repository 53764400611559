import React from "react";
import { FormGroup, Label } from "reactstrap";
import { useIntl } from "react-intl";
import classNames from "classnames";
import InputError from "components/framework/errorHandling/InputError";
import { ErrorHandler } from "services/customHooks/useErrors";
import { FormattedMessage } from "react-intl";

type Props = {
  disabled?: boolean;
  required?: boolean;
  labelTranslationId?: string;
  value?: string;
  errorHandler?: ErrorHandler;
  className?: string;
  placeholderTranslationId?: string;
  rows?: number;
  handleInputChange?: (value: string) => void;
  fixedSize?: boolean;
};

export default function TextAreaFormInput(props: Props) {
  const intl = useIntl();
  const errors = props.errorHandler ? props.errorHandler.getErrors() : [];
  const label = !props.labelTranslationId
    ? undefined
    : intl.formatMessage({ id: props.labelTranslationId });
  const placeholder = props.disabled
    ? ""
    : !props.placeholderTranslationId
    ? undefined
    : intl.formatMessage({ id: props.placeholderTranslationId });

  return (
    <>
    <FormGroup className={classNames("mb-2", props.className)}>
      {label && (
        <Label className={classNames("form-control-label", {"pc-required-label": props.required})}>
          <FormattedMessage id={label} />
        </Label>
      )}
      <textarea
        disabled={props.disabled}
        className={classNames("form-control form-control-sm", {
          "pc-invalid-input": errors && errors.length > 0,
          "pc-no-resize": props.fixedSize
        })}
        placeholder={placeholder ?? label ?? ""}
        value={props.value ? props.value : ""}
        onChange={(e) => {
          if (props.errorHandler) props.errorHandler.clear();
          if (props.handleInputChange) props.handleInputChange(e.currentTarget.value);
        }}
        rows={props.rows ?? 3}
      />
      {props.errorHandler && <InputError errors={props.errorHandler.getErrors()} />}
    </FormGroup>
    </>
  );
}
