import { LocalStorageInfo, DefaultLocalStorageInfo } from "./LocalStorageInfo";

const localStorageInfoKey = "portControl.info";

export const AppContextUtil = {
  saveToLocalStorage: (info: LocalStorageInfo) => {
    localStorage.setItem(localStorageInfoKey, JSON.stringify(info));
  },

  getFromLocalStorage: (): LocalStorageInfo => {
    const localStorageInfo = localStorage.getItem(localStorageInfoKey);

    if (localStorageInfo) {
      const user = JSON.parse(localStorageInfo);

      return user;
    }

    return DefaultLocalStorageInfo;
  }
};
