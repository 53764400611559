import React, { useState } from "react";
import { CardHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { OrderCardStatus } from "../types/OrderCardStatus";
import OrderCardStatusIcon from "./OrderCardStatusIcon";

type Props = {
  name: string;
  spId: string;
  logo?: string;
  united?: boolean;
  showStatusIcon?: boolean;
  orderCardStatus?: OrderCardStatus;
};

export default function PortCardHeader(props: Props) {
  const [defaultIcon, setDefaultIcon] = useState(false);

  return (
    <CardHeader className="d-flex">
      <div className="pc-card-header">
        {defaultIcon ? (
          <div className="avatar rounded-circle bg-primary">
            <i className="fas fa-building fa-lg"></i>
          </div>
        ) : (
          <div className="pc-logo mt-2">
            <img src={props.logo} alt="" onError={() => setDefaultIcon(true)} />
          </div>
        )}
      </div>
      <div className="flex-grow-1 mx-2 d-flex align-items-center">
        <div>
          <div className="mb-0">{props.name}</div>
          <h5 className="mb-0 text-gray">{props.spId}</h5>
          {props.united && (
            <div>
              <h5 className="mb-0 text-success">
                <FormattedMessage id="orders.orderCard.united" />
              </h5>
            </div>
          )}
        </div>
      </div>
      {props.showStatusIcon && props.orderCardStatus && (
        <div className="mr-2 d-flex align-items-center h1">
          <OrderCardStatusIcon status={props.orderCardStatus} />
        </div>
      )}
    </CardHeader>
  );
}
