import React, { useEffect, useState, useContext } from "react";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { DefaultReportDto, ReportDto } from "services/apis/types/reports/ReportDto";
import { ReportsApi } from "services/apis/ReportsApi";
import { handleError } from "services/util/ApiUtil";
import BreadcrumbHeader from "components/common/BreadcrumbHeader";
import { FormattedMessage } from "react-intl";
import { Container } from "reactstrap";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { AppContext } from "services/appContext/AppContext";
import CountsCards from "./CountsCards";
import PortedNumbersChartCard from "./PortedNumbersChartCard";
import ExportCard from "./ExportCard";

export default function Reports() {
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [reportDtoLoaded, setReportDtoLoaded] = useState(false);
  const [reportDto, setReportDto] = useState<ReportDto>(DefaultReportDto);
  const isMounted = useIsMounted();
  const { appContext } = useContext(AppContext);

  useEffect(() => {
    setShowLoadingIndicator(true);
    ReportsApi.overview()
      .then((result) => {
        if (isMounted) {
          setReportDto(result);
          setReportDtoLoaded(true);
        }
      })
      .catch((error) => handleError(error))
      .finally(() => {
        if (isMounted) {
          setShowLoadingIndicator(false);
        }
      });
  }, [isMounted, appContext.localStorageInfo.selectedProfile]);

  return (
    <>
      <BreadcrumbHeader>
        <FormattedMessage id="reports.title" />
      </BreadcrumbHeader>
      <Container fluid>
        {showLoadingIndicator ? (
          <LoadingIndicator white />
        ) : (
          <>
            <CountsCards reportDto={reportDto} />
            {reportDtoLoaded && <PortedNumbersChartCard reportDto={reportDto} />}
            <ExportCard />
          </>
        )}
      </Container> 
    </>
  );
}
