import React, { useState } from "react";
import { Card, CardHeader, CardBody, Row } from "reactstrap";
import TextRow from "components/networkExplorer/common/TextRow";
import { Block } from "services/apis/types/block/Block";
import { BlockResult } from "services/apis/types/block/BlockResult";
import { useIntl, FormattedMessage } from "react-intl";
import FormatDateTime from "components/framework/date/FormatDateTime";
import { ContaminatedNumber } from "services/apis/types/block/ContaminatedNumber";
import ActionDropdown from "../common/ActionDropdown";
import ActionDropdownItem from "../common/ActionDropdownItem";
import CreateBlockModal from "./BlockModal";
import Authorize from "components/framework/authorization/Authorize";
import { PermissionType } from "services/authorization/PermissionType";

type Props = {
  block: BlockResult;
  numbers: ContaminatedNumber[];
};

export default function BlockDetailsCard(props: Props) {
  const intl = useIntl();
  const [actionsOpen, setActionsOpen] = useState(false);
  const [showModifyModal, setShowModifyModal] = useState(false);
  const block: Block = { ...props.block };

  return (
    <>
      <div className="card-wrapper">
        <Card>
          <CardHeader>
            <Row>
              <h3 className="mb-0 col-8">
                <FormattedMessage id="network.block.title" />
              </h3>
              <Authorize spidPermissions={[PermissionType.NetworkWrite]}>
                <ActionDropdown
                  actionsOpen={actionsOpen}
                  actionsToggle={() => setActionsOpen((prevState) => !prevState)}>
                  <ActionDropdownItem
                    handleAction={() => setShowModifyModal(true)}
                    iconClassName="fa fa-edit"
                    labelId="network.block.actions.modify"
                  />
                </ActionDropdown>
              </Authorize>
            </Row>
          </CardHeader>
          <CardBody className="text-xs">
            <table>
              <tbody>
                <TextRow name={intl.formatMessage({ id: "network.block.code" })}>
                  {props.block.blockDash.substring(0, 6)}
                </TextRow>
                <TextRow name={intl.formatMessage({ id: "network.block.codeOwnerOcn" })}>
                  {props.block.codeOwnerInfo.ocn}
                </TextRow>
                <TextRow name={intl.formatMessage({ id: "network.block.codeOcnName" })}>
                  {props.block.codeOwnerInfo.ocnName}
                </TextRow>
                <TextRow name={intl.formatMessage({ id: "network.block.block" })}>
                  {props.block.blockDash}
                </TextRow>
                <TextRow name={intl.formatMessage({ id: "network.block.activatedDate" })}>
                  <FormatDateTime utcDate={props.block.effectiveDate} />
                </TextRow>
                <TextRow name={intl.formatMessage({ id: "network.block.contaminatedNumbers" })}>
                  {props.numbers.length}
                </TextRow>
                <TextRow name={intl.formatMessage({ id: "network.block.blockOwnerOcn" })}>
                  {props.block.ocn}
                </TextRow>
                <TextRow name={intl.formatMessage({ id: "network.block.blockOwnerName" })}>
                  {props.block.ocnName}
                </TextRow>
              </tbody>
            </table>
          </CardBody>
        </Card>
      </div>
      {showModifyModal && (
        <CreateBlockModal closeModal={() => setShowModifyModal(false)} block={block} />
      )}
    </>
  );
}
