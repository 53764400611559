import queryString from "query-string";
import { CustomerInventoryDto } from "./types/customerInventory/CustomerInventoryDto";
import { ApiVersion } from "services/httpClient/ApiVersion";
import { HttpClient } from "services/httpClient/HttpClient";
import { ApiError } from "./types/ApiError";
import { WebhookUrlLogsDto } from "./types/webhookLogs/WebhookUrlLogsDto";
import { EmailLogsDto } from "./types/webhookLogs/WebhookEmailLogsDto";

export const WebhookLogsApi = {
  getWebhookEmailLogsList: (search: string, tenant: string | undefined = undefined): Promise<CustomerInventoryDto[]> => {
    let url = queryString.stringifyUrl({
        url: "wholesalecustomer/customerlist",
        query: {
          searchKey: search && search.length > 0 ? search : undefined,
        },
      }, {
      arrayFormat: 'comma'
    });

    return HttpClient.get<CustomerInventoryDto[]>(url, true, true, tenant, ApiVersion.V3);
  },

  getWebhookUrlLogsList : (
    queryParams: string,
    tenant: string | undefined = undefined
  ): Promise<WebhookUrlLogsDto> => {
      return HttpClient.get<WebhookUrlLogsDto>(
          `logs/webhook?${queryParams}`,
          true,
          true,
          tenant,
          ApiVersion.V3
      );
  },

  getEmailLogsList : (
    queryParams: string,
    tenant: string | undefined = undefined
  ): Promise<EmailLogsDto> => {
    return HttpClient.get<EmailLogsDto>(
        `logs/email?${queryParams}`,
        true,
        true,
        tenant,
        ApiVersion.V3
    );
  },

  retryWebhookLog:(id: string, tenant: string | undefined = undefined): Promise<ApiError> => {
    return HttpClient.post<ApiError>(`logs/webhook/retry/${id}`,{}, true, true, "", ApiVersion.V3);
  },
};
