import React from "react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import BackToStepOneModal from "./BackToStepOneModal";

type Props = {
  backButtonCallback: () => void;
};

export const PortInBackButton = (props: Props) => {
  const [showBackToStepOneModal, setShowBackToStepOneModal] = useState(false);
  return (
    <>
      <span className="cursor-pointer" onClick={() => setShowBackToStepOneModal(true)}>
        <i className="fa fa-arrow-left mr-3" />
      </span>
      <span className="text-gray-dark pc-portin-back-button">
        <FormattedMessage id="orders.portIn.stepOne.orderStepOneTitle" />
      </span>

      {showBackToStepOneModal && (
        <BackToStepOneModal
          discardPreviousData={props.backButtonCallback}
          closeModal={() => setShowBackToStepOneModal(false)}
        />
      )}
    </>
  );
};
