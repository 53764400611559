import React, { useState } from "react";
import { FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { useIntl } from "react-intl";
import classNames from "classnames";
import InputError from "components/framework/errorHandling/InputError";
import { ErrorHandler } from "services/customHooks/useErrors";
import { InputType } from "reactstrap/lib/Input";

type Props = {
  disabled?: boolean;
  value: string;
  errorHandler: ErrorHandler;
  className?: string;
  placeholderTranslationId: string;
  icon: string;
  type?: InputType;
  handleInputChange: (value: string) => void;
};

export default function TextFormInputGroup(props: Props) {
  const [focused, setFocused] = useState(false);
  const intl = useIntl();

  return (
    <FormGroup
      className={classNames("mb-3", props.className, {
        focused: focused
      })}>
      <InputGroup className="input-group-merge input-group-alternative">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className={classNames("fas", props.icon)} />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          disabled={props.disabled}
          placeholder={props.disabled ? "" : intl.formatMessage({ id: props.placeholderTranslationId })}
          type={props.type ? props.type : "text"}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          value={props.value ? props.value : ""}
          onChange={e => {
            props.errorHandler.clear();
            props.handleInputChange(e.target.value);
          }}
        />
      </InputGroup>
      <InputError errors={props.errorHandler.getErrors()} />
    </FormGroup>
  );
}
