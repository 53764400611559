import React, { useRef, useState, useCallback } from "react";
import { Tooltip } from "reactstrap";

type Props = {
  className: string;
  onClick?: () => void;
  tooltip: string;
  tooltipClassName?: string;
};

export default function IconWithTooltip(props: Props) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const toggleTooltip = useCallback(() => setIsTooltipOpen((prev) => !prev), []);
  const iconRef = useRef<HTMLElement | null>(null);

  return (
    <>
      <i className={props.className} onClick={props.onClick} ref={iconRef} />
      <Tooltip
        className={`pointer-events-none ${props.tooltipClassName || ""}`}
        target={iconRef}
        placement="top"
        innerClassName="py-1"
        isOpen={isTooltipOpen}
        toggle={toggleTooltip}>
        {props.tooltip}
      </Tooltip>
    </>
  );
}
