import React, { useState } from "react";
import TradingPartnerHistoryModal from "../common/TradingPartnerHistoryModal";
type Props = {
  tppId: string;
  status: string;
  spId: string;
  serviceProvider: string;
};

export default function StatusCell(props: Props) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {props.status && (
        <>
          {props.tppId ?
            <div className="text-primary cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                setShowModal(!showModal);
              }}>
              {props.status}
            </div> :
            <div>
              {props.status}
            </div>
          }
          {showModal && props.tppId && (
            <TradingPartnerHistoryModal closeModal={() => setShowModal(false)} tppId={props.tppId} spId={props.spId} serviceProvider={props.serviceProvider} />
          )}
        </>
      )}
    </>
  );
}
