export const BodyClassUtil = {
  addClass: function(className: string) {
    document.body.classList.add(className);
  },

  removeClass: function(className: string) {
    document.body.classList.remove(className);
  },

  hasClass: function(className: string): boolean {
    return document.body.classList.contains(className);
  }
};
