import { PermissionType } from "./PermissionType";

export const Permissions: Record<keyof typeof PermissionType, string> = {
  CNAM: "CNAM",
  History: "History",
  Explore: "Explore",
  Network: "Network",
  PortingProfiles: "Network Profiles",
  CarrierProfiles: "Carrier Profiles",
  RealNumberDNO: "RealNumberDNO",
  CustomerInventory: "CustomerInventory",
  TradingPartners: "Trading Partners",
  Admin: "PortControl Admin",
  CompanyAdmin: "Organization Admin",
  NetworkWrite: "Manage Network",
  SOARead: "Read only",
  SOAWrite: "Manage Orders",
  Webhooks: "Webhooks",
  Reports: "Reports",
  WorkQueue: "Work Queue",
  PreOrderFree: "Pre-orders (LSR/CSR)",
  AllowCsr: "Request CSR",
  Search: "Search",
  Provision: "Provision",
  CNAMUpdate: "CNAM Update",
  Notifications: "Notifications",
  DisasterRecovery: "Disaster Recovery",
  SinchSearchAndProvision: "Sinch Search And Provision"
};
