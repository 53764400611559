import { OrderType } from "services/apis/types/order/OrderType";
import { OrderTypeDetails } from "components/orders/types/OrderTypeDetails";

export const OrderTypeConfigurations: Record<OrderType, OrderTypeDetails> = {
  "port-in": {
    backgroundColor: "bg-gradient-pc-blue",
    iconClass: "fa fa-arrow-left",
    messageId: "orders.orderDetails.portInDescription"
  },
  "port-out": {
    backgroundColor: "bg-gradient-pc-orange",
    iconClass: "fa fa-arrow-right",
    messageId: "orders.orderDetails.portOutDescription"
  },
  disconnect: {
    backgroundColor: "bg-gradient-red",
    iconClass: "far fa-undo-alt",
    messageId: "orders.orderDetails.disconnectDescription"
  },
  modifyactive: {
    backgroundColor: "bg-gradient-pc-blue",
    iconClass: "fas fa-pencil-alt",
    messageId: "orders.orderDetails.modifyActiveDescription"
  },
  "csr-only": {
    backgroundColor: "bg-gradient-pc-blue",
    iconClass: "fas fa-pencil-alt",
    messageId: "orders.orderDetails.csrOnlyDescription"
  },
  "Disaster Recovery": {
    backgroundColor: "bg-gradient-pc-blue",
    iconClass: "fas fa-arrow-left",
    messageId: "orders.orderDetails.disasterRecovery"
  },
  "Restore": {
    backgroundColor: "bg-gradient-pc-blue",
    messageId: "orders.disasterRecovery.actions.restore",
    iconClass: "fas fa-arrow-left"
  },
  "New Number": {
    backgroundColor: "bg-gradient-pc-blue",
    messageId: "orders.sinch.portType.description",
    iconClass: "fas fa-arrow-left"
  }
};
