import { getUtcDate } from "services/util/DateUtil";

export type PortingReportDto = {
  adminProjectId: string,
  tn: string;
  date: Date;
  time: Date;
  direction: string;
  gainingSpid: string;
  losingSpid: string;
};

export const DefaultExportDto: PortingReportDto = {
  adminProjectId: "",
  tn: "",
  date: getUtcDate().toDate(),
  time: getUtcDate().toDate(),
  direction: "",
  gainingSpid: "",
  losingSpid: "",
};

export type ExportPortingReportDto = {
  tn: string;
  date: "";
  time: "";
  direction: string;
  gainingSpid: string;
  losingSpid: string;
}

export const DefaultExportPortingReportDto: ExportPortingReportDto = {
  tn: "",
  date: "",
  time: "",
  direction: "",
  gainingSpid: "",
  losingSpid: "",
}
