import { SubscriptionModel } from "./SubscriptionModel";

export type NotificationModel = {
  id: string;
  name: string;
  uri: string;
  subscriptions: SubscriptionModel[];
};

export const DefaultNotificationModel: NotificationModel = {
  id: "",
  name: "",
  uri: "",
  subscriptions: []
};
