import React, { useState } from "react";
import ActionDropdown from "components/networkExplorer/common/ActionDropdown";
import ActionDropdownItem from "components/networkExplorer/common/ActionDropdownItem";
import UserModal from "./UserModal";
import UserDeleteModal from "./UserDeleteModal";
import { CompanyInfo } from "services/apis/types/company/CompanyInfo";
import { PortControlIdentityUser } from "services/apis/types/user/PortControlIdentityUser";
import { DefaultUser } from "components/manage/types/User";
import { ManageAction } from "components/manage/types/ManageAction";

type Props = {
  user: PortControlIdentityUser;
  availableCompanies: CompanyInfo[];
};

export default function UserActionsCell(props: Props) {
  const [showModifyModal, setShowModifyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [actionsOpen, setActionsOpen] = useState(false);

  return (
    <>
      <ActionDropdown actionsOpen={actionsOpen} actionsToggle={() => setActionsOpen(prevState => !prevState)}>
        <ActionDropdownItem
          handleAction={() => setShowModifyModal(true)}
          iconClassName="fa fa-edit"
          labelId="manage.user.table.actions.modify"
        />
        <ActionDropdownItem
          handleAction={() => setShowDeleteModal(true)}
          iconClassName="fa fa-trash-alt"
          labelId="manage.user.table.actions.delete"
        />
      </ActionDropdown>
      {showModifyModal && (
        <UserModal
          manageAction={ManageAction.Modify}
          user={{ ...DefaultUser, ...props.user }}
          availableCompanies={props.availableCompanies}
          closeModal={() => setShowModifyModal(false)}
        />
      )}
      {showDeleteModal && (
        <UserDeleteModal id={props.user.id} name={props.user.userName} closeModal={() => setShowDeleteModal(false)} />
      )}
    </>
  );
}
