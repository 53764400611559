export type ChangePassword = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export const ChangePasswordDefault: ChangePassword = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: ""
};
