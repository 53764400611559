import { ApiError } from "../apis/types/ApiError";
import { showErrorNotification } from "components/framework/notification/NotificationUtil";
import { FieldError } from "../apis/types/FieldError";
import { Errors, AddError, HasFieldErrors } from "components/framework/errorHandling/ErrorUtil";
import { getUniqueElements } from "./ArrayUtil";

export const handleError = (error: ApiError): void => {
  if (error && error.message) {
    showErrorNotification(error.message);
  }
};

export const handleFieldErrors = (error: ApiError): void => {
  if (error && error.fieldErrors) {
    const errors = getFieldErrors(error.fieldErrors);

    for (let key in errors) {
      let value = errors[key];
      value.forEach((x) => showErrorNotification(x.message));
    }
  }
};

export const handleFieldOrApiErrors = (error: ApiError) => {
  if (HasFieldErrors(error)) {
    handleFieldErrors(error);
  } else {
    handleError(error);
  }
};

export const getFieldErrors = (fieldErrors: { [id: string]: FieldError[] } | undefined) => {
  const errors: Errors = {};

  if (fieldErrors) {
    Object.keys(fieldErrors).forEach((key) => {
      fieldErrors[key].forEach((value) => {
        if (value.message) {
          AddError(errors, key.toLowerCase(), value.message, false);
        }
      });
    });
  }

  return errors;
};

export const getErrors = (fieldErrors: { [id: string]: FieldError[] } | undefined) => {
  let errors: string[] = [];

  if (fieldErrors) {
    Object.keys(fieldErrors).forEach((key) => {
      fieldErrors[key].forEach((value) => {
        if (value.message) {
          errors = [...errors, value.message];
        }
      });
    });
  }

  return getUniqueElements(errors);
};
