import { NumberExplorerSearchResult } from "./types/number/NumberExplorerSearchResult";
import { NumberActivity } from "./types/number/NumberActivity";
import { CnamInfo } from "./types/number/CnamInfo";
import { HttpClient } from "services/httpClient/HttpClient";
import { NumberDetails } from "./types/number/NumberDetails";
import { NumberCnamUpsert } from "./types/number/NumberCnamUpsert";
import { SPIDDetailsDto } from "./types/number/SPIDDetailsDto";

export const NumberApi = {
  search: (searchExpression: string): Promise<NumberExplorerSearchResult[]> => {
    return HttpClient.post<NumberExplorerSearchResult[]>("number/search", { searchExpression });
  },

  history: (telephoneNumber: string): Promise<NumberActivity[]> => {
    return HttpClient.get<NumberActivity[]>(`number/${telephoneNumber}/history`);
  },

  cnam: function (telephoneNumber: string): Promise<CnamInfo> {
    return HttpClient.get<CnamInfo>(`number/${telephoneNumber}/cnam`,true,true);
  },

  updateCnam: function (telephoneNumber: string, cnam: NumberCnamUpsert): Promise<CnamInfo> {
    return HttpClient.put<CnamInfo>(`number/${telephoneNumber}/cnam`, cnam,true,true);
  },

  details: function (telephoneNumber: string): Promise<NumberDetails> {
    return HttpClient.get<NumberDetails>(`number/${telephoneNumber}`);
  },

  querySPIDDetials: function (pageNumber: number, pageSize: number, searchValues: string,orderByColumn: string,orderDirection: string): Promise<SPIDDetailsDto> {
    return HttpClient.get<SPIDDetailsDto>(`inventory/getnumbersbyspid?pageNumber=${pageNumber}&pageSize=${pageSize}&searchKey=${searchValues}&orderByColumn=${orderByColumn}&orderDirection=${orderDirection}`, true, true);
  },

  getPooledBlockDashBySpid: function () : Promise<any> {
    return HttpClient.get<any>(`inventory/getpooledblockdashbyspid`, true, true);
  },

  getpoolednumbersbyspid: function (blockDash: string) : Promise<any> {
   return HttpClient.get<any>(`inventory/getpoolednumbersbyspid?blockDash=${blockDash}`, true, true)
  }
};
