import { HttpClient } from "../httpClient/HttpClient";
import { ApiError } from "./types/ApiError";
import { TppAdminDetailsDto, TppDetailsDto, TppUserDetailsDto } from "./types/number/TradingPartner";
import { TradingPartnerProfile } from "./types/tpp/TradingPartnerProfile";
import { TradeRequestStatus } from "./types/tpp/TradeRequestStatus";
import { TppHistory } from "./types/tpp/TppHistory";
import { TppSpidList } from "./types/tpp/TppSpidList";

export const TradingPartnerApi = {
  add: (spid: string): Promise<ApiError> => {
    return HttpClient.post<ApiError>("tpp", { tradingSpId: spid }, true, true);
  },
  get: (spid: string): Promise<TradingPartnerProfile> => {
    return HttpClient.get<TradingPartnerProfile>(`tpp/${spid}`, true, true);
  },
  getTppList: (pageNumber: number, pageSize: number, searchValues: string, filter?: string): Promise<TppDetailsDto> => {
    if (filter)
      return HttpClient.get<TppDetailsDto>(`tpp/tpplist?pageNumber=${pageNumber}&pageSize=${pageSize}&searchKey=${searchValues}&${filter}`, true, true);
    else return HttpClient.get<TppDetailsDto>(`tpp/tpplist?pageNumber=${pageNumber}&pageSize=${pageSize}&searchKey=${searchValues}`, true, true);
  },
  updateStatus: (spid: string, tradeRequestStatus: TradeRequestStatus): Promise<ApiError> => {
    return HttpClient.put<ApiError>(`tpp/${spid}`, tradeRequestStatus, true, true);
  },

  getTppHistory: (spid: string): Promise<TppHistory[]> => {
    return HttpClient.get<TppHistory[]>(`tpp/TppHistory/${spid}`, true, true);
  },

  getSpidList: (): Promise<TppSpidList> => {
    return HttpClient.get<TppSpidList>(`tpp/spids`, true, true);
  },

  getTppAdminList: (pageNumber: number, pageSize: number, searchValues: string,orderByColumn: string,orderDirection: string, filter?: string): Promise<TppAdminDetailsDto> => {
    if (filter)
      return HttpClient.get<TppAdminDetailsDto>(`tpp/tpp?pageNumber=${pageNumber}&pageSize=${pageSize}&searchKey=${searchValues}&orderByColumn=${orderByColumn}&orderDirection=${orderDirection}&${filter}`, true, true);
    else return HttpClient.get<TppAdminDetailsDto>(`tpp/tpp?pageNumber=${pageNumber}&pageSize=${pageSize}&searchKey=${searchValues}&orderByColumn=${orderByColumn}&orderDirection=${orderDirection}`, true, true);
  },
  getTppUserList: (pageNumber: number, pageSize: number, searchValues: string,orderByColumn: string,orderDirection: string, filter?: string): Promise<TppUserDetailsDto> => {
    if (filter)
      return HttpClient.get<TppUserDetailsDto>(`tpp/tpplist/spid?pageNumber=${pageNumber}&pageSize=${pageSize}&searchKey=${searchValues}&orderByColumn=${orderByColumn}&orderDirection=${orderDirection}&${filter}`, true, true);
    else return HttpClient.get<TppUserDetailsDto>(`tpp/tpplist/spid?pageNumber=${pageNumber}&pageSize=${pageSize}&searchKey=${searchValues}&orderByColumn=${orderByColumn}&orderDirection=${orderDirection}`, true, true);
  },
};
