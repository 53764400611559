export type ResetPasswordModel = {
  email: string;
  password: string;
  confirmPassword: string;
};

export const DefaultResetPasswordModel: ResetPasswordModel = {
  email: "",
  password: "",
  confirmPassword: ""
};
