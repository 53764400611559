
export type BillingTransactionsDto = {
  clientBillingId: string;
  organization: string;
  emailAddress: string;
  spId: string;
  productBillingType: string;
  productBillingId: string;
  activity: string;
  direction: string;
  modality: string;
  transactionCount: number;
  transactionDate: Date;
  orderId: string;
  projectId: string; 
  handledBy: string;
};

export const DefaultBillingTransactionsDto: BillingTransactionsDto = {
  clientBillingId: "",
  organization: "",
  emailAddress: "",
  spId: "",
  productBillingType: "",
  productBillingId: "",
  activity: "",
  direction : "",
  modality : "",
  transactionCount: 0,
  transactionDate: new Date(),
  orderId: "",
  projectId: "",
  handledBy: ""
};
