export type AuthenticationInfo = {
  authenticated: boolean;
  username: string;
  access_token: string;
  refresh_token: string;
  expiry: string;
};

export const DefaultAuthenticationInfo: AuthenticationInfo = {
  authenticated: false,
  username: "",
  access_token: "",
  refresh_token: "",
  expiry: ""
};
